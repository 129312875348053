import { parsePlatformId } from "types/enums/ProductIdDisplayName";

export default interface ClientOSPlatforms {
    availablePlatforms: { [index: string]: string };
    autoSelectedPlatforms: { [index: string]: string };
}

export const processClientOSPlatforms = (data: any): ClientOSPlatforms => {
    const result: ClientOSPlatforms = {
        availablePlatforms: {},
        autoSelectedPlatforms: {},
    };

    if (data) {
        if (data.availablePlatforms) {
            Object.keys(data.availablePlatforms).forEach((key) => {
                if (key && data.availablePlatforms[key]) result.availablePlatforms[key] = parsePlatformId(key);
            });
        }

        if (data.autoSelectedPlatform) {
            Object.keys(data.autoSelectedPlatform).forEach((key) => {
                if (key && data.autoSelectedPlatform[key]) result.autoSelectedPlatforms[key] = parsePlatformId(key);
            });
        }
    }

    return result;
};
