import { Trans } from "@lingui/macro";

enum Verify2FAStatus {
    PENDING = "pending",
    APPROVED = "approved",
    DENIED = "denied",
    EXPIRED = "expired",
    INVALID_TOKEN = "invalid_token",
}

export const formatVerify2FAStatusLabel = (verify2FAStatus?: Verify2FAStatus | null) => {
    switch (verify2FAStatus) {
        case Verify2FAStatus.PENDING:
            return <Trans>Pending</Trans>;
        case Verify2FAStatus.APPROVED:
            return <Trans>Approved</Trans>;
        case Verify2FAStatus.DENIED:
            return <Trans>Denied</Trans>;
        case Verify2FAStatus.EXPIRED:
            return <Trans>Expired</Trans>;
        case Verify2FAStatus.INVALID_TOKEN:
            return <Trans>Invalid Token</Trans>;
        default:
            return verify2FAStatus || <Trans>---</Trans>;
    }
};

export const formatVerify2FAStatusBackgroundColor = (verify2FAStatus?: Verify2FAStatus | null) => {
    switch (verify2FAStatus) {
        case Verify2FAStatus.PENDING:
            return "var(--chip-default-background-color)";
        case Verify2FAStatus.APPROVED:
            return "var(--chip-positive-background-color)";
        case Verify2FAStatus.DENIED:
            return "var(--chip-negative-background-color)";
        case Verify2FAStatus.EXPIRED:
            return "var(--chip-warning-background-color)";
        case Verify2FAStatus.INVALID_TOKEN:
            return "var(--chip-negative-background-color)";
        default:
            return "var(--chip-default-background-color)";
    }
};

export const formatVerify2FAStatusColor = (verify2FAStatus?: Verify2FAStatus | null) => {
    switch (verify2FAStatus) {
        case Verify2FAStatus.PENDING:
            return "var(--chip-default-color)";
        case Verify2FAStatus.APPROVED:
            return "var(--chip-positive-color)";
        case Verify2FAStatus.DENIED:
            return "var(--chip-negative-color)";
        case Verify2FAStatus.EXPIRED:
            return "var(--chip-warning-color)";
        case Verify2FAStatus.INVALID_TOKEN:
            return "var(--chip-negative-color)";
        default:
            return "var(--chip-default-color)";
    }
};

export default Verify2FAStatus;
