import { combineReducers } from "redux";

import clientInformationReducer from "./clientInformation";
import applicationInformationReducer from "./applicationInformation";
import environmentInformationReducer from "./environmentInformation";
import themeConfigurationReducer from "./themeConfiguration";
import sessionReducer from "./session";
import currentUserReducer from "./currentUser";
import availableCompaniesReducer from "./availableCompanies";
import availableAppsReducer from "./availableApps";
import availablePrivilegesReducer from "./availablePrivileges";
import currentCompanyAlias from "./currentCompanyAlias";
import systemRoles from "./systemRoles";
import screenSettings from "./screenSettings";
import directAPI from "./directAPI";
import directRTT from "./directRTT";
import directS2S from "./directS2S";
import cloudCodeEditor from "./cloudCodeEditor";
import favorites from "./favorites";
import recentlyViewed from "./recentlyViewed";

const reducer = combineReducers({
    clientInformation: clientInformationReducer,
    applicationInformation: applicationInformationReducer,
    environmentInformation: environmentInformationReducer,
    themeConfiguration: themeConfigurationReducer,

    session: sessionReducer,

    currentUser: currentUserReducer,
    currentCompanyAlias: currentCompanyAlias,

    availableCompanies: availableCompaniesReducer,
    availableApps: availableAppsReducer,
    availablePrivileges: availablePrivilegesReducer,

    systemRoles: systemRoles,
    screenSettings: screenSettings,

    directAPI: directAPI,
    directRTT: directRTT,
    directS2S: directS2S,

    cloudCodeEditor: cloudCodeEditor,

    favorites: favorites,
    recentlyViewed: recentlyViewed,
});

export default reducer;
