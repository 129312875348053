/**
 * ClientId utility functions.
 */
export default abstract class ClientIdUtils {
    // Generates a client id. 
    public static generateClientId() : string {
        let d = new Date().getTime();

        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
        });

        return uuid;
    }
}
