import { AvailableCompanyAction } from "store/actions/availableCompanies";

import TeamInfo from "types/models/TeamInfo";

const initialState: TeamInfo[] = [];

const reducer = (state: TeamInfo[] = initialState, action: AvailableCompanyAction) => {
    let newState = state;

    switch (action.type) {
        case 'SET_AVAILABLE_COMPANIES':
            newState = [...action.payload];

            break;
        default:
            // Do nothing.
    }

    return newState;
};

export default reducer;
