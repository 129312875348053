import axios, { AxiosResponse } from "axios";

import { HttpProvider, ResponseTypes } from "./Http";

// Configure Axios defaults.
axios.defaults.withCredentials = true;

export default class HttpAxios implements HttpProvider {
    GET(url: string, headers?: any, abortSignal?: AbortSignal): Promise<AxiosResponse> {
        console.debug("HttpAxios.GET", url);

        return axios.get(url, {
            headers: headers,
            signal: abortSignal,
        });
    }

    HEAD(url: string, headers?: any, abortSignal?: AbortSignal): Promise<AxiosResponse> {
        console.debug("HttpAxios.HEAD", url);

        return axios.head(url, {
            headers: headers,
            signal: abortSignal,
        });
    }

    POST(url: string, body?: any, headers?: any, abortSignal?: AbortSignal): Promise<AxiosResponse> {
        console.debug("HttpAxios.POST", url);

        return axios.post(url, body, {
            headers: headers,
            signal: abortSignal,
        });
    }

    PUT(url: string, body?: any, headers?: any, abortSignal?: AbortSignal): Promise<AxiosResponse> {
        console.debug("HttpAxios.PUT", url);

        return axios.put(url, body, {
            headers: headers,
            signal: abortSignal,
        });
    }

    DELETE(url: string, headers?: any, abortSignal?: AbortSignal): Promise<AxiosResponse> {
        console.debug("HttpAxios.DELETE", url);

        return axios.delete(url, {
            headers: headers,
            signal: abortSignal,
        });
    }

    CONNECT(_url: string, _headers?: any, _abortSignal?: AbortSignal): Promise<AxiosResponse> {
        console.debug("HttpAxios.CONNECT", _url);

        return Promise.reject("HTTP-Axios method CONNECT not supported");
    }

    OPTIONS(url: string, headers?: any, abortSignal?: AbortSignal): Promise<AxiosResponse> {
        console.debug("HttpAxios.OPTIONS", url);

        return axios.options(url, {
            headers: headers,
            signal: abortSignal,
        });
    }

    TRACE(_url: string, _headers?: any, _abortSignal?: AbortSignal): Promise<AxiosResponse> {
        console.debug("HttpAxios.TRACE", _url);

        return Promise.reject("HTTP-Axios method TRACE not supported");
    }

    PATCH(url: string, body?: any, headers?: any, abortSignal?: AbortSignal): Promise<AxiosResponse> {
        console.debug("HttpAxios.PATCH", url);

        return axios.patch(url, body, {
            headers: headers,
            signal: abortSignal,
        });
    }

    isStatusOk(response: ResponseTypes): boolean {
        const axiosResponse = response as Response;

        if (axiosResponse.status != null && axiosResponse.status >= 200 && axiosResponse.status < 300) {
            return true;
        }

        return false;
    }

    responseData(response: ResponseTypes): Promise<any> {
        const axiosResponse = response as AxiosResponse;

        return Promise.resolve(axiosResponse.data);
    }

    extractHeader(response: ResponseTypes, header: string): string | null {
        const axiosResponse = response as AxiosResponse;

        if (axiosResponse.headers != null) {
            return axiosResponse.headers[header];
        }

        return null;
    }

    redirected(response: ResponseTypes): boolean {
        const axiosResponse = response as AxiosResponse;

        if (axiosResponse.config != null && axiosResponse.request != null && axiosResponse.config.url !== axiosResponse.request.responseURL) {
            return true;
        }

        return false;
    }

    location(response: ResponseTypes): string {
        const axiosResponse = response as AxiosResponse;

        if (axiosResponse.request != null) {
            return axiosResponse.request.responseURL;
        }

        return "";
    }
}
