// Import libraries.
import React from "react";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { withFormValidator, withFormValidatorProps } from "framework/formValidator";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

// Import types.
import DevelopmentType from "types/enums/DevelopmentType";
import { processTeamInfo } from "types/models/TeamInfo";
import { TextFieldOptions } from "components/common/form/fields/TextField";
import { RadioButtonFieldOptions } from "components/common/form/fields/RadioButtonField";

// Import components.
import { Chip, Typography } from "@mui/material";
import FieldWrapper from "components/common/form/FieldWrapper";
import IconButton from "components/common/button/IconButton";
import Button from "components/common/button/Button";

// Import icons.
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Import utilities.
import Http from "utils/networking/Http";

interface OWN_PROPS {
    onComplete: (companyId: string | null) => void;
    onCancel: () => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, withI18nProps, withFormValidatorProps {}

interface STATE {
    companyId: string | null;
    companyName: string;
    developmentType: DevelopmentType;

    createTeamStatus: "pending" | "complete" | "error" | null;
    error: string | null;
}

class CreateTeam extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        companyId: null,
        companyName: "",
        developmentType: DevelopmentType.GAMES,

        createTeamStatus: null,
        error: null,
    };
    private confirmButtonRef = React.createRef<HTMLElement>();

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "companyName":
                this.setState({ companyName: value });
                break;
            case "developmentType":
                this.setState({ developmentType: value });
                break;
            default:
            // Do nothing.
        }
    };

    handleKeyPress = (name: string, key: string) => {
        if (["companyName"].includes(name) && key === "Enter") {
            this.confirmButtonRef.current?.focus();

            this.onConfirm();
        }
    };

    onConfirm = async () => {
        const { bcFormValidator, i18n } = this.props;
        const { companyName, developmentType } = this.state;

        if (!bcFormValidator.validate()) return;

        this.setState({ createTeamStatus: "pending", error: null }, async () => {
            const createTeamResponse = await Http.POST("admin/serveradmin/create-company", { companyName: companyName, developmentType: developmentType }, undefined, Http.JSON_HEADERS);

            if (Http.isStatusOk(createTeamResponse)) {
                if (createTeamResponse.data.error) {
                    this.setState({ createTeamStatus: "error", error: createTeamResponse.data.error });
                } else {
                    const newTeam = processTeamInfo(createTeamResponse.data);

                    this.setState({ createTeamStatus: "complete", error: null, companyId: newTeam.companyId || null }, () => {
                        window.setTimeout(() => {
                            this.props.onComplete(this.state.companyId);
                        }, 1000);
                    });
                }
            } else {
                const error = Http.buildError(createTeamResponse);

                this.setState({ createTeamStatus: "error", error: error.errorMessage || i18n._(t`Could not create team.`) });
            }
        });
    };

    render() {
        const { bcFormValidator, i18n, classes } = this.props;
        const { companyName, developmentType, createTeamStatus, error } = this.state;

        return (
            <div id={"create-team"} className={classes.root}>
                {createTeamStatus !== "complete" && (
                    <>
                        <IconButton id={"clear-logs"} style={{ width: "2em", height: "2em", margin: "0.25em" }} onClick={this.props.onCancel} disabled={createTeamStatus != null && ["pending", "complete"].includes(createTeamStatus)}>
                            <ArrowBackIcon />
                        </IconButton>

                        <Typography style={{ alignSelf: "center", marginBottom: "0.3125rem" }}>
                            <Trans>Create New Team</Trans>
                        </Typography>

                        <FieldWrapper
                            formValidator={bcFormValidator}
                            name={"companyName"}
                            type={"text"}
                            value={companyName}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            required={true}
                            disabled={createTeamStatus != null && ["pending", "complete"].includes(createTeamStatus)}
                            autoFocus={true}
                            options={
                                {
                                    placeholder: i18n._(t`Enter Team Name`),
                                } as TextFieldOptions
                            }
                        />

                        <FieldWrapper
                            formValidator={bcFormValidator}
                            name={"developmentType"}
                            type={"radiobutton"}
                            label={<Trans>My Team Builds Primarily</Trans>}
                            labelPosition={"top"}
                            labelAlignment={"center"}
                            controlStyle={{ marginLeft: "auto", marginRight: "auto", marginTop: "0.5rem", marginBottom: "0.5rem" }}
                            value={developmentType}
                            onChange={this.handleChange}
                            disabled={createTeamStatus != null && ["pending", "complete"].includes(createTeamStatus)}
                            options={
                                {
                                    options: [
                                        { value: DevelopmentType.GAMES, label: <Trans>Games</Trans> },
                                        { value: DevelopmentType.APPS, label: <Trans>Apps</Trans> },
                                        { value: DevelopmentType.THINGS, label: <Trans>Things</Trans> },
                                    ],
                                } as RadioButtonFieldOptions
                            }
                        />

                        <Button ref={this.confirmButtonRef} className={classes.button} id={"create"} type={"primary"} onClick={this.onConfirm} disabled={createTeamStatus != null && ["pending", "complete"].includes(createTeamStatus)}>
                            {createTeamStatus === "pending" ? <Trans>Please Wait...</Trans> : <Trans>Create Team</Trans>}
                        </Button>

                        {createTeamStatus === "error" && <Chip className={classes.chip} label={error} />}
                    </>
                )}

                {createTeamStatus === "complete" && (
                    <div className={classes.creationComplete}>
                        <SuccessIcon />

                        <Typography>
                            <Trans>Team Created</Trans>
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            position: "relative",
        },

        field: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",
        },
        button: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            textTransform: "none",
        },
        chip: {
            height: "2rem",
            fontWeight: "bold",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            backgroundColor: "var(--chip-negative-background-color)",
            color: "var(--chip-negative-color)",
            borderColor: "inherit",
        },

        creationComplete: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",

            overflow: "hidden",

            "& > *": {
                marginLeft: "auto",
                marginRight: "auto",

                "&.MuiSvgIcon-root": {
                    width: "6rem",
                    height: "6rem",

                    color: "var(--label-positive-color, inherit)",
                },

                "&.MuiTypography-root": {
                    marginTop: "2rem",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                },
            },
        },
    });

export default withFormValidator()(withI18n()(withStyles(styles)(CreateTeam)));
