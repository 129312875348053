import PortalRouteDefinition from "types/common/PortalRouteDefinition";

import superRoutes from "./super";
import teamRoutes from "./team";
import appRoutes from "./app";
import userRoutes from "./user";

const routes: PortalRouteDefinition[] = [...superRoutes, ...teamRoutes, ...appRoutes, ...userRoutes];

export default routes;
