import LocalStorageUtils from "utils/LocalStorage";

import { CookieConsentLevel } from "./CookieConsent";

/**
 * Favorites utility functions.
 */
export default abstract class FavoritesUtils {
    static FAVORITES = "favorites";

    public static getFavorites(profileId: string): string[] {
        const favoritesRaw = LocalStorageUtils.getItem(profileId + "-" + this.FAVORITES);

        try {
            const favorites = favoritesRaw ? JSON.parse(favoritesRaw) : [];

            if (Array.isArray(favorites)) {
                return favorites as string[];
            }
        } catch (error: any) {
            console.warn("Unable to read favorites for user", profileId, error);
        }

        return [];
    }

    public static setFavorites(profileId: string, favorites: string[]) {
        LocalStorageUtils.setItem(profileId + "-" + this.FAVORITES, JSON.stringify(favorites), CookieConsentLevel.FUNCTIONALITY);
    }
}
