import { DirectS2SLog, DirectS2SSelection, DirectS2SSession } from "types/common/DirectS2S";

export interface DirectS2SAction {
    type:
        | "SET_DIRECT_S2S_SESSION"
        | "CLEAR_DIRECT_S2S_SESSION"
        | "APPEND_DIRECT_S2S_LOG"
        | "UPDATE_DIRECT_S2S_LOG"
        | "CLEAR_DIRECT_S2S_LOGS"
        | "SET_DIRECT_S2S_BUSY"
        | "SET_DIRECT_S2S_IS_REAL_DISPATCHER"
        | "SET_DIRECT_S2S_IS_SESSION_BASED"
        | "SET_DIRECT_S2S_IS_ASYNCHRONOUS"
        | "SET_DIRECT_S2S_SELECTION";
    payload?: DirectS2SSession | DirectS2SLog | DirectS2SSelection | null | boolean;
}

export const SET_DIRECT_S2S_SESSION = (session: DirectS2SSession): DirectS2SAction => {
    return {
        type: "SET_DIRECT_S2S_SESSION",
        payload: session,
    };
};

export const CLEAR_DIRECT_S2S_SESSION = (session: DirectS2SSession): DirectS2SAction => {
    return {
        type: "CLEAR_DIRECT_S2S_SESSION",
        payload: session,
    };
};

export const APPEND_DIRECT_S2S_LOG = (logToAppend: DirectS2SLog): DirectS2SAction => {
    return {
        type: "APPEND_DIRECT_S2S_LOG",
        payload: logToAppend,
    };
};

export const UPDATE_DIRECT_S2S_LOG = (logToUpdate: DirectS2SLog): DirectS2SAction => {
    return {
        type: "UPDATE_DIRECT_S2S_LOG",
        payload: logToUpdate,
    };
};

export const CLEAR_DIRECT_S2S_LOGS = (): DirectS2SAction => {
    return {
        type: "CLEAR_DIRECT_S2S_LOGS",
    };
};

export const SET_DIRECT_S2S_BUSY = (isBusy: boolean): DirectS2SAction => {
    return {
        type: "SET_DIRECT_S2S_BUSY",
        payload: isBusy,
    };
};

export const SET_DIRECT_S2S_IS_REAL_DISPATCHER = (isRealDispatcher: boolean): DirectS2SAction => {
    return {
        type: "SET_DIRECT_S2S_IS_REAL_DISPATCHER",
        payload: isRealDispatcher,
    };
};

export const SET_DIRECT_S2S_IS_SESSION_BASED = (isSessionBased: boolean): DirectS2SAction => {
    return {
        type: "SET_DIRECT_S2S_IS_SESSION_BASED",
        payload: isSessionBased,
    };
};

export const SET_DIRECT_S2S_IS_ASYNCRHONOUS = (isAsynchronous: boolean): DirectS2SAction => {
    return {
        type: "SET_DIRECT_S2S_IS_ASYNCHRONOUS",
        payload: isAsynchronous,
    };
};

export const SET_DIRECT_S2S_SELECTION = (selection: DirectS2SSelection): DirectS2SAction => {
    return {
        type: "SET_DIRECT_S2S_SELECTION",
        payload: selection,
    };
};
