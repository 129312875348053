// The following functions implements the logic for sending requests via a REST API.
import { AxiosResponse } from "axios";
// import HttpFetch from "./HttpFetch";
import HttpAxios from "./HttpAxios";

// Import types.
import ServerError from "types/common/ServerError";

export type ProviderTypes = "fetch" | "axios";
export type ResponseTypes = Response | AxiosResponse;

export interface HttpProvider {
    GET: (url: string, headers?: any, abortSignal?: AbortSignal) => Promise<ResponseTypes>;
    HEAD: (url: string, headers?: any, abortSignal?: AbortSignal) => Promise<ResponseTypes>;
    POST: (url: string, body: any, headers?: any, abortSignal?: AbortSignal) => Promise<ResponseTypes>;
    PUT: (url: string, body: any, headers?: any, abortSignal?: AbortSignal) => Promise<ResponseTypes>;
    DELETE: (url: string, headers?: any, abortSignal?: AbortSignal) => Promise<ResponseTypes>;
    CONNECT: (url: string, headers?: any, abortSignal?: AbortSignal) => Promise<ResponseTypes>;
    OPTIONS: (url: string, headers?: any, abortSignal?: AbortSignal) => Promise<ResponseTypes>;
    TRACE: (url: string, headers?: any, abortSignal?: AbortSignal) => Promise<ResponseTypes>;
    PATCH: (url: string, body: any, headers?: any, abortSignal?: AbortSignal) => Promise<ResponseTypes>;
    isStatusOk: (response: ResponseTypes) => boolean;
    responseData: (response: ResponseTypes) => Promise<any>;
    extractHeader: (response: ResponseTypes, header: string) => string | null;
    redirected: (response: ResponseTypes) => boolean;
    location: (response: ResponseTypes) => string;
}

export interface HttpResponse {
    url: string;
    redirected: boolean;
    location: string;
    status: number;
    statusText: string;
    data: any;
    error: any;
}

// A function that determines the base URL for brainCloud API requests.
const determineBaseAPIServerUrl = (): string => {
    let currentLocation = window.location.href;

    // Strip out any hash content.
    if (currentLocation.includes("#")) {
        currentLocation = currentLocation.substring(0, currentLocation.indexOf("#"));
    }

    // Strip out any search content.
    if (currentLocation.includes("?")) {
        currentLocation = currentLocation.substring(0, currentLocation.indexOf("?"));
    }

    const result = currentLocation.endsWith("/") ? currentLocation + "api/" : currentLocation + "/api/";

    return result;
};

const applyQueryParameters = (url: string, params?: any): string => {
    let targetUrl = url;

    if (params) {
        if (typeof params === "object" && Object.keys(params).length > 0) {
            targetUrl = targetUrl + "?" + encodeQueryParameters(params);
        }
    }

    return targetUrl;
};

const constructTargetUrl = (url: string, params?: any): string => {
    let targetUrl = url;

    if (url.toLowerCase().startsWith("http://") || url.toLowerCase().startsWith("https://")) {
        targetUrl = applyQueryParameters(url, params);
    } else {
        targetUrl = applyQueryParameters(DEFAULT_URL + url, params);
    }

    return targetUrl;
};

const constructTargetHeaders = (headers?: any): any => {
    let targetHeaders: any = Object.assign({}, DEFAULT_HEADERS);

    if (headers) {
        if (typeof headers === "object" && Object.keys(headers).length > 0) {
            Object.keys(headers).forEach((key) => {
                targetHeaders[key] = headers[key];
            });
        }
    }

    return targetHeaders;
};

const constructTargetBody = (body?: any): any => {
    if (body instanceof FormData) return body;

    switch (typeof body) {
        case "object":
            return JSON.stringify(body);
        default:
            return body;
    }
};

const constructHttpResponse = (url: string, redirected: boolean, location: string | null, status: number, statusText: string | null, data: any, error: any): HttpResponse => {
    const httpResponse: HttpResponse = {
        url: url,
        redirected: redirected,
        location: location ? location : "",
        status: status,
        statusText: statusText ? statusText : "",
        data: data,
        error: error,
    };

    console.debug("HttpResponse", httpResponse);

    return httpResponse;
};

export const encodeQueryParameters = (params?: any): string => {
    let encodedParams = "";

    if (params) {
        encodedParams = Object.keys(params)
            .map((key: string) => {
                if (params[key] !== undefined) {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
                } else {
                    return null;
                }
            })
            .filter((item: string | null) => {
                return item != null;
            })
            .join("&");
    }

    return encodedParams;
};

// The DEFAULT URL for communicating with the brainCloud API server.
const DEFAULT_URL: string = determineBaseAPIServerUrl();

// The HTTP provider used to perform HTTP/REST calls.
const provider: HttpProvider = new HttpAxios();

// A set of default HTTP headers.
const DEFAULT_HEADERS = {
    Accept: "*/*",
};

const Http = {
    JSON_HEADERS: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    JSONP: async (url: string, params?: any, abortSignal?: AbortSignal): Promise<any> => {
        //
        // Implementation of JSONP.
        //
        // SHOULD NEVER BE USED.
        //
        // Unless absolutely necessary... such as avoiding CORS during fetch of external data when absolutely necessary.
        //

        console.debug("Http.JSONP", url);

        return new Promise((resolve, reject) => {
            const targetUrl = constructTargetUrl(url, params);

            const callbackName = "jsonp_callback_" + Math.round(100000 * Math.random());

            (window as any)[callbackName] = (data: any) => {
                console.debug("HttpResponse (JSONP)", data);

                delete (window as any)[callbackName];
                document.body.removeChild(script);

                if (abortSignal?.aborted) {
                    reject(new DOMException("Aborted", "AbortError"));
                } else {
                    resolve(data);
                }
            };

            const script = document.createElement("script");
            script.src = targetUrl + (targetUrl.indexOf("?") >= 0 ? "&" : "?") + "callback=" + callbackName;
            document.body.appendChild(script);
        });
    },
    GET: async (url: string, params?: any, headers?: any, abortSignal?: AbortSignal): Promise<HttpResponse> => {
        return new Promise((resolve, _reject) => {
            const targetUrl = constructTargetUrl(url, params);
            const targetHeaders = constructTargetHeaders(headers);

            provider
                .GET(targetUrl, targetHeaders, abortSignal)
                .then((response) => {
                    if (provider.isStatusOk(response)) {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    } else {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    }
                })
                .catch((error) => {
                    resolve(constructHttpResponse(targetUrl, false, null, -1, null, null, error));
                });
        });
    },
    HEAD: async (url: string, params?: any, headers?: any, abortSignal?: AbortSignal): Promise<HttpResponse> => {
        return new Promise((resolve, _reject) => {
            const targetUrl = constructTargetUrl(url, params);
            const targetHeaders = constructTargetHeaders(headers);

            provider
                .HEAD(targetUrl, targetHeaders, abortSignal)
                .then((response) => {
                    if (provider.isStatusOk(response)) {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    } else {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    }
                })
                .catch((error) => {
                    resolve(constructHttpResponse(targetUrl, false, null, -1, null, null, error));
                });
        });
    },
    POST: async (url: string, params?: any, body?: any, headers?: any, abortSignal?: AbortSignal): Promise<HttpResponse> => {
        return new Promise((resolve, _reject) => {
            const targetUrl = constructTargetUrl(url, params);
            const targetBody = constructTargetBody(body);
            const targetHeaders = constructTargetHeaders(headers);

            provider
                .POST(targetUrl, targetBody, targetHeaders, abortSignal)
                .then((response) => {
                    if (provider.isStatusOk(response)) {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    } else {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    }
                })
                .catch((error) => {
                    resolve(constructHttpResponse(targetUrl, false, null, -1, null, null, error));
                });
        });
    },
    PUT: async (url: string, params?: any, body?: any, headers?: any, abortSignal?: AbortSignal): Promise<HttpResponse> => {
        return new Promise((resolve, _reject) => {
            const targetUrl = constructTargetUrl(url, params);
            const targetBody = constructTargetBody(body);
            const targetHeaders = constructTargetHeaders(headers);

            provider
                .PUT(targetUrl, targetBody, targetHeaders, abortSignal)
                .then((response) => {
                    if (provider.isStatusOk(response)) {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    } else {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    }
                })
                .catch((error) => {
                    resolve(constructHttpResponse(targetUrl, false, null, -1, null, null, error));
                });
        });
    },
    DELETE: async (url: string, params?: any, headers?: any, abortSignal?: AbortSignal): Promise<HttpResponse> => {
        return new Promise((resolve, _reject) => {
            const targetUrl = constructTargetUrl(url, params);
            const targetHeaders = constructTargetHeaders(headers);

            provider
                .DELETE(targetUrl, targetHeaders, abortSignal)
                .then((response) => {
                    if (provider.isStatusOk(response)) {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    } else {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    }
                })
                .catch((error) => {
                    resolve(constructHttpResponse(targetUrl, false, null, -1, null, null, error));
                });
        });
    },
    CONNECT: async (url: string, params?: any, headers?: any, abortSignal?: AbortSignal): Promise<HttpResponse> => {
        return new Promise((resolve, _reject) => {
            const targetUrl = constructTargetUrl(url, params);
            const targetHeaders = constructTargetHeaders(headers);

            provider
                .CONNECT(targetUrl, targetHeaders, abortSignal)
                .then((response) => {
                    if (provider.isStatusOk(response)) {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    } else {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    }
                })
                .catch((error) => {
                    resolve(constructHttpResponse(targetUrl, false, null, -1, null, null, error));
                });
        });
    },
    OPTIONS: async (url: string, params?: any, headers?: any, abortSignal?: AbortSignal): Promise<HttpResponse> => {
        return new Promise((resolve, _reject) => {
            const targetUrl = constructTargetUrl(url, params);
            const targetHeaders = constructTargetHeaders(headers);

            provider
                .OPTIONS(targetUrl, targetHeaders, abortSignal)
                .then((response) => {
                    if (provider.isStatusOk(response)) {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    } else {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    }
                })
                .catch((error) => {
                    resolve(constructHttpResponse(targetUrl, false, null, -1, null, null, error));
                });
        });
    },
    TRACE: async (url: string, params?: any, headers?: any, abortSignal?: AbortSignal): Promise<HttpResponse> => {
        return new Promise((resolve, _reject) => {
            const targetUrl = constructTargetUrl(url, params);
            const targetHeaders = constructTargetHeaders(headers);

            provider
                .TRACE(targetUrl, targetHeaders, abortSignal)
                .then((response) => {
                    if (provider.isStatusOk(response)) {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    } else {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    }
                })
                .catch((error) => {
                    resolve(constructHttpResponse(targetUrl, false, null, -1, null, null, error));
                });
        });
    },
    PATCH: async (url: string, params?: any, body?: any, headers?: any, abortSignal?: AbortSignal): Promise<HttpResponse> => {
        return new Promise((resolve, _reject) => {
            const targetUrl = constructTargetUrl(url, params);
            const targetBody = constructTargetBody(body);
            const targetHeaders = constructTargetHeaders(headers);

            provider
                .PATCH(targetUrl, targetBody, targetHeaders, abortSignal)
                .then((response) => {
                    if (provider.isStatusOk(response)) {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    } else {
                        provider
                            .responseData(response)
                            .then((data) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, data, null));
                            })
                            .catch((error) => {
                                resolve(constructHttpResponse(targetUrl, provider.redirected(response), provider.location(response), response.status, response.statusText, null, error));
                            });
                    }
                })
                .catch((error) => {
                    resolve(constructHttpResponse(targetUrl, false, null, -1, null, null, error));
                });
        });
    },
    isStatusOk: (response: HttpResponse): boolean => {
        if (response.status != null && response.status >= 200 && response.status < 300) {
            return true;
        }

        return false;
    },
    isRedirect: (response: HttpResponse, redirect?: string): boolean => {
        if (response.redirected) {
            if (response.location && redirect && redirect.trim().length > 0) {
                if (response.location.endsWith(redirect)) {
                    return true;
                }
            } else {
                return true;
            }
        }

        return false;
    },
    buildError: (response: HttpResponse): ServerError => {
        // Convert any HttpResponse into a normalized ServerError instance.

        // This basically handles all the different ways an error is reported by the server and converts
        // it into a standard/normalized ServerError (containing an 'errorCode' and 'errorMessage' field).

        if (response.data && response.data.error) {
            if (typeof response.data.error !== "string") {
                return {
                    errorCode: response.data.error.errorCode != null ? response.data.error.errorCode : -1,
                    errorMessage: response.data.error.errorMessage != null ? "" + response.data.error.errorMessage : "Unknown Error",
                };
            } else {
                return {
                    errorCode: -1,
                    errorMessage: "" + response.data.error,
                };
            }
        } else if (response.error) {
            if (response.error.response && response.error.response.data && response.error.response.data.error) {
                if (typeof response.error.response.data.error !== "string") {
                    return {
                        errorCode: response.error.response.data.error.errorCode != null ? response.error.response.data.error.errorCode : -1,
                        errorMessage: response.error.response.data.error.errorMessage != null ? "" + response.error.response.data.error.errorMessage : "Unknown Error",
                    };
                } else {
                    return {
                        errorCode: -1,
                        errorMessage: "" + response.error.response.data.error,
                    };
                }
            } else if (response.error.response && response.error.response.data && response.error.response.data.jsonresponse && response.error.response.data.jsonresponse.error) {
                if (typeof response.error.response.data.jsonresponse.error !== "string") {
                    return {
                        errorCode: response.error.response.data.jsonresponse.error.errorCode != null ? response.error.response.data.jsonresponse.error.errorCode : -1,
                        errorMessage: response.error.response.data.jsonresponse.error.errorMessage != null ? "" + response.error.response.data.jsonresponse.error.errorMessage : "Unknown Error",
                    };
                } else {
                    return {
                        errorCode: -1,
                        errorMessage: "" + response.error.response.data.jsonresponse.error,
                    };
                }
            } else if (response.error.response && response.error.response.data && response.error.response.data.reasonCode && response.error.response.data.localizedMessage) {
                return {
                    errorCode: response.error.response.data.reasonCode,
                    errorMessage: response.error.response.data.localizedMessage,
                };
            } else {
                return {
                    errorCode: -1,
                    errorMessage: response.error != null ? "" + response.error : "Unknown Error",
                };
            }
        } else {
            return {
                errorCode: response.status != null ? response.status : -1,
                errorMessage: response.statusText != null ? "" + response.statusText : "Unknown Error",
            };
        }
    },
};

export default Http;
