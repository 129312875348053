import { ReactNode } from "react";

import NumberFormat from "react-number-format";

export default abstract class NumberFormatter {
    public static formatInteger(value?: number | null): ReactNode {
        return value != null ? <NumberFormat value={value} displayType={"text"} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true} /> : null;
    }
    public static formatFloat(value?: number | null, decimalScale?: number | null): ReactNode {
        return value != null ? <NumberFormat value={value} displayType={"text"} thousandSeparator={true} decimalScale={decimalScale != null ? decimalScale : 3} fixedDecimalScale={true} /> : null;
    }
    public static formatCurrency(value?: number | null, currencySymbol?: string | null, decimalScale?: number | null): ReactNode {
        return value != null ? <NumberFormat value={value} displayType={"text"} thousandSeparator={true} prefix={currencySymbol != null ? currencySymbol : "$"} decimalScale={decimalScale != null ? decimalScale : 2} fixedDecimalScale={true} /> : null;
    }
}
