const HelpCenter = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <g transform="matrix(1 0 0 1 0 0)" fill="currentcolor" fillRule="nonzero">
                <path d="M2 0C.9 0 0 .9 0 2v16c0 1.1.9 2 2 2h16c1.1 0 1.998-.9 1.997-2L19.99 2c0-1.1-.89-2-1.99-2H2Zm8 13h7v2h-7v-2Zm7-4v2h-5V9h5ZM7 15v2H5v-2h2ZM6 5c-1.1 0-2 .9-2 2H2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25l-.9.92C7.45 10.9 7 11.5 7 13H5v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26C7.78 8.05 8 7.55 8 7c0-1.1-.9-2-2-2Zm6 2V5h5v2h-5Z" />
            </g>
        </svg>
    );
};

export default HelpCenter;
