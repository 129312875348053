// Import libraries.
import { useCallback, useEffect, useRef, useState } from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";
import { Resizable } from "re-resizable";

// Import types.
import { ActionDefinition, ColumnDefinition, RowData } from "../../types";

// Import components.
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import FieldWrapper from "components/common/form/FieldWrapper";
import IconButton from "components/common/button/IconButton";

// Import icons.
import DragHandle from "@mui/icons-material/DragHandle";

interface OWN_PROPS {
    id: string;

    className?: string;

    definitions: ColumnDefinition[];

    selectMode?: "single" | "multi"; // The selection mode behaviour ("single" or "multi" mode, applies to the current page. Changing the page clears the selections).
    allSelected?: boolean;

    orderBy: string | null;
    orderDirection: "asc" | "desc";

    actions?: ActionDefinition[] | ((row: RowData) => React.ReactNode);

    actionColumnWidth?: string | number;

    headerCellWidths?: string[];

    swapSelectAndActionColumns?: boolean; // Determines whether the "select" and "actions" columns are swapped (such that actions is on the left and the select is on the right).

    onColumnResize?: ((columnId: number, newWidth: string) => void) | null;

    onSort?: (orderBy: string, orderDirection: "asc" | "desc") => void;

    onHeightCalculated: ((height: number) => void) | null;
    onStickyWidthCalculated: ((width: number) => void) | null;

    onToggleAllSelected?: () => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

const Header = (props: PROPS) => {
    const {
        classes,
        id,
        className,
        definitions,
        headerCellWidths,
        selectMode,
        allSelected,
        orderBy,
        orderDirection,
        actions,
        actionColumnWidth,
        swapSelectAndActionColumns,
        onColumnResize,
        onSort,
        onHeightCalculated,
        onStickyWidthCalculated,
        onToggleAllSelected,
    } = props;

    const [isHovered, setIsHovered] = useState(false);

    const headerRef = useRef<HTMLTableSectionElement | null>(null);

    const stickyColumnLeftRef = useRef<HTMLHeadElement | null>(null);
    const stickyColumnRightRef = useRef<HTMLHeadElement | null>(null);

    useEffect(() => {
        if (onHeightCalculated && headerRef.current) {
            onHeightCalculated(headerRef.current.offsetHeight);
        }

        if (onStickyWidthCalculated && (stickyColumnLeftRef.current || stickyColumnRightRef.current)) {
            onStickyWidthCalculated((stickyColumnLeftRef.current?.offsetWidth || 0) + (stickyColumnRightRef.current?.offsetWidth || 0));
        }
    });

    const handleToggleAllSelected = useCallback(() => {
        if (onToggleAllSelected) {
            onToggleAllSelected();
        }
    }, [onToggleAllSelected]);

    const renderTabelCell = (definition: ColumnDefinition) => {
        return definition.sortable ? (
            <TableSortLabel
                style={{ paddingLeft: definition.align === "center" ? "1.375em" : 0 }}
                active={orderBy === definition.id}
                direction={orderBy === definition.id ? orderDirection : definition.initialSortDirection || "asc"}
                onClick={() => {
                    if (onSort && definition.sortable) {
                        const newOrderDirection = definition.id === orderBy ? (orderDirection === "desc" ? "asc" : "desc") : definition.initialSortDirection || "asc";

                        onSort(definition.id, newOrderDirection);
                    }
                }}
            >
                <span style={{ whiteSpace: "nowrap" }}>{definition.label}</span>

                {orderBy === definition.id ? <span className={classes.visuallyHidden}>{orderDirection === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
            </TableSortLabel>
        ) : (
            <span style={{ whiteSpace: "nowrap" }}>{definition.label}</span>
        );
    };

    return (
        <TableHead ref={headerRef} id={id} className={classnames(classes.root, className)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <TableRow>
                {!swapSelectAndActionColumns && (
                    <>
                        {selectMode && (
                            <TableCell ref={stickyColumnLeftRef} id={id + "-select"} className={classes.stickyColumnLeft} padding="checkbox" style={{ zIndex: 9, minWidth: "2.625em", padding: 0 }}>
                                {selectMode === "multi" && (
                                    <div
                                        style={{
                                            flex: "1 1 auto",
                                            alignSelf: "center",

                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",

                                            overflow: "hidden",
                                        }}
                                    >
                                        <FieldWrapper
                                            style={{ margin: 0 }}
                                            controlStyle={{ zIndex: 7, backgroundColor: "transparent" }}
                                            innerStyle={{ marginLeft: "auto", marginRight: "auto" }}
                                            name={"selectAll"}
                                            type={"checkbox"}
                                            value={allSelected}
                                            onChange={handleToggleAllSelected}
                                        />
                                    </div>
                                )}
                            </TableCell>
                        )}
                    </>
                )}

                {swapSelectAndActionColumns && (
                    <>
                        {actions && (typeof actions === "function" || actions.length > 0) && (
                            <TableCell
                                ref={stickyColumnRightRef}
                                id={id + "-actions"}
                                className={classes.stickyColumnRight}
                                style={{
                                    zIndex: 9,

                                    minWidth: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                    width: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                    maxWidth: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                }}
                            ></TableCell>
                        )}
                    </>
                )}

                {definitions.map((definition: ColumnDefinition, idx: number) => {
                    const cellWidth = headerCellWidths?.[idx] || (definition.width as string | number);

                    return (
                        <TableCell
                            key={definition.id}
                            id={id + "-" + idx}
                            data-name={definition.id}
                            align={definition.align}
                            style={{ minWidth: cellWidth, width: "inherit", zIndex: 8, padding: "0.3125em" }}
                            sortDirection={orderBy === definition.id ? orderDirection : false}
                        >
                            {definition.resizable ? (
                                <Resizable
                                    handleComponent={{
                                        right: isHovered ? (
                                            <IconButton id={"column-resize-handle"} type={"neutral"} style={{ position: "absolute", right: "-0.125em", cursor: "col-resize" }}>
                                                <DragHandle style={{ width: "100%", height: "100%", transform: "rotate(90deg)" }} />
                                            </IconButton>
                                        ) : undefined,
                                    }}
                                    enable={{ right: true }}
                                    size={{ width: "inherit", height: "100%" }}
                                    minWidth={definition.width}
                                    minHeight={"100%"}
                                    maxHeight={"100%"}
                                    onResize={(event, direction, elementRef) => {
                                        if (onColumnResize) onColumnResize(idx, elementRef.style.width);
                                    }}
                                >
                                    {renderTabelCell(definition)}
                                </Resizable>
                            ) : (
                                renderTabelCell(definition)
                            )}
                        </TableCell>
                    );
                })}

                {!swapSelectAndActionColumns && (
                    <>
                        {actions && (typeof actions === "function" || actions.length > 0) && (
                            <TableCell
                                ref={stickyColumnRightRef}
                                id={id + "-actions"}
                                className={classes.stickyColumnRight}
                                style={{
                                    zIndex: 9,

                                    minWidth: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                    width: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                    maxWidth: typeof actions !== "function" ? "2em" : actionColumnWidth || "unset",
                                }}
                            ></TableCell>
                        )}
                    </>
                )}

                {swapSelectAndActionColumns && (
                    <>
                        {selectMode && (
                            <TableCell ref={stickyColumnLeftRef} id={id + "-select"} className={classes.stickyColumnRight} padding="checkbox" style={{ zIndex: 9, minWidth: "2.626em", padding: 0 }}>
                                {selectMode === "multi" && (
                                    <div
                                        style={{
                                            alignSelf: "center",

                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",

                                            overflow: "hidden",
                                        }}
                                    >
                                        <FieldWrapper
                                            style={{ margin: 0 }}
                                            controlStyle={{ backgroundColor: "transparent" }}
                                            innerStyle={{ marginLeft: "auto", marginRight: "auto" }}
                                            name={"select"}
                                            type={"checkbox"}
                                            value={allSelected}
                                            onChange={handleToggleAllSelected}
                                        />
                                    </div>
                                )}
                            </TableCell>
                        )}
                    </>
                )}
            </TableRow>
        </TableHead>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontSize: "inherit",

            "& > .MuiTableRow-root": {
                "& > .MuiTableCell-root:first-child": {
                    paddingLeft: "0.625em !important",
                },
                "& > .MuiTableCell-root:last-child": {
                    paddingRight: "0.625em !important",
                },
            },
        },
        visuallyHidden: {
            display: "none",
        },
        stickyColumnLeft: {
            position: "sticky",
            left: 0,
        },
        stickyColumnRight: {
            position: "sticky",
            right: 0,

            paddingLeft: "0.5em",
            paddingRight: "0.5em",
        },
    });

export default withStyles(styles)(Header);
