// Import libraries.
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

// Import types.
import ApplicationInformation from "types/common/ApplicationInformation";
import PortalState from "types/store";

// Import components.
import { Link, Typography } from "@mui/material";

// Import icons.
import StatusIcon from "@mui/icons-material/FiberManualRecord";

// Import utilities.
import StringUtils from "utils/String";

interface STATE_PROPS {
    applicationInformation: ApplicationInformation;
}
interface DISPATCH_PROPS {
    checkServerStatus: () => void;
}
interface OWN_PROPS {
    type?: "circular" | "linear";
    label?: ReactNode;
    indicatorWidth?: number | string;
    value?: number | null;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

const mapStateToProps = (state: PortalState): STATE_PROPS => {
    return {
        applicationInformation: state.applicationInformation,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        checkServerStatus: () => dispatch({ type: "initialization.checkServerStatus" }),
    };
};

class ServerStatus extends React.PureComponent<PROPS> {
    render() {
        const { i18n, classes, applicationInformation } = this.props;

        if (!applicationInformation.serverStatusConfiguration.enabled) return null;

        const statusUrlAvailable = !StringUtils.isNullOrEmpty(applicationInformation.serverStatusConfiguration.statusUrl);

        return (
            <span id={"server-status"} className={classes.root}>
                {!applicationInformation.serverStatus && (
                    <>
                        <StatusIcon className={classes.statusIcon} style={{ color: statusUrlAvailable ? "var(--label-positive-color, inherit)" : "inherit" }} />

                        {statusUrlAvailable ? (
                            <Link data-id={"server-status"} className={classes.statusMessage} href={applicationInformation.serverStatusConfiguration.statusUrl} target="_blank" noWrap>
                                <Trans>All Systems Operational</Trans>
                            </Link>
                        ) : (
                            <Typography className={classes.statusMessage} noWrap>
                                {<Trans>Status Unavailable</Trans>}
                            </Typography>
                        )}
                    </>
                )}

                {applicationInformation.serverStatus && (
                    <>
                        <StatusIcon
                            className={classes.statusIcon}
                            style={{
                                color:
                                    applicationInformation.serverStatus.status === "major"
                                        ? "var(--label-negative-color, inherit)"
                                        : applicationInformation.serverStatus.status === "minor"
                                        ? "var(--label-warning-color, inherit)"
                                        : applicationInformation.serverStatus.status === "none"
                                        ? "var(--label-positive-color, inherit)"
                                        : "inherit",
                            }}
                        />

                        {!StringUtils.isNullOrEmpty(applicationInformation.serverStatus.message) && (
                            <Link data-id={"server-status"} className={classes.statusMessage} href={applicationInformation.serverStatusConfiguration.statusUrl} target="_blank" noWrap>
                                {i18n._(t`${applicationInformation.serverStatus.message}`)}
                            </Link>
                        )}

                        {StringUtils.isNullOrEmpty(applicationInformation.serverStatus.message) && (
                            <Typography className={classes.statusMessage} noWrap>
                                <Trans>Status Message Unavailable</Trans>
                            </Typography>
                        )}
                    </>
                )}
            </span>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
        },
        statusIcon: {
            width: "1rem",
            height: "1rem",
            marginRight: "0.3125rem",
        },
        statusMessage: {
            fontSize: "0.75rem",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(ServerStatus)));
