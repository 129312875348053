import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

interface PROPS extends TooltipProps {
    alwaysShow?: boolean;
}

const Tooltip: React.FunctionComponent<PROPS> = (props) => {
    const { alwaysShow, ...otherProps } = props;

    const ref = useRef<HTMLElement>(null);

    const [overflowed, setOverflowed] = useState(true);

    const checkOverflow = useCallback((el: HTMLElement) => {
        setOverflowed(el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight);
    }, []);

    useLayoutEffect(() => {
        if (ref.current) checkOverflow(ref.current);
    }, [checkOverflow]);

    const currentElement = ref.current;

    useEffect(() => {
        if (currentElement) {
            const ro = new ResizeObserver((entries) => {
                if (entries.length > 0) checkOverflow(entries[0].target as HTMLElement);
            });

            ro.observe(currentElement);

            return () => {
                ro.disconnect();
            };
        }
    }, [checkOverflow, currentElement]);

    const preventTooltip = !alwaysShow && !overflowed;

    return <MuiTooltip ref={ref} {...otherProps} disableHoverListener={preventTooltip} disableTouchListener={preventTooltip} />;
};

export default Tooltip;
