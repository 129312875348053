import Permission from "types/enums/Permission";

export const hasAccess = (privileges: PortalPrivilege[], id: string, permission?: Permission): boolean => {
    const targetPrivilege = privileges.find((item) => item.id === id);

    if (targetPrivilege) {
        if (permission == null) {
            if (targetPrivilege.permission >= Permission.READ) return true;
        } else {
            if (targetPrivilege.permission >= permission) return true;
        }
    }

    return false;
};

export const hasNoAccess = (privileges: PortalPrivilege[], id: string): boolean => {
    return hasAccess(privileges, id, Permission.NONE);
};

export const hasReadAccess = (privileges: PortalPrivilege[], id: string): boolean => {
    return hasAccess(privileges, id, Permission.READ);
};

export const hasWriteAccess = (privileges: PortalPrivilege[], id: string): boolean => {
    return hasAccess(privileges, id, Permission.WRITE);
};

export default interface PortalPrivilege {
    id: string;
    permission: Permission;
}
