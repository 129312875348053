// Import libraries.
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import store from "store";
import * as serviceWorker from "./serviceWorker";

// Import utilities.
import LocalizationUtils from "utils/Localization";

// Import the Main portal component.
import Main from "components/Main";

// Import the SCSS styles.
import "scss/styles.scss";

// Initialize localization.
LocalizationUtils.initializeLocalization(i18n);

// TODO: Determine the browsers default locale and activate that language (if possible).

// Render the application.
ReactDOM.render(
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        <Provider store={store}>
            <Main />
        </Provider>
    </I18nProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
