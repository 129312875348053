import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Breakpoint, ClickAwayListener, Drawer, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DragHandle } from "@mui/icons-material";

import IconButton from "components/common/button/IconButton";

import StringUtils from "utils/String";

interface OWN_PROPS {
    id?: string;

    style?: React.CSSProperties;

    open: boolean;

    anchor?: "left" | "right";

    onClick?: (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => void;
    onClickAway?: (event: MouseEvent | TouchEvent) => void;
    onSizeComputed?: (size: number | "100%" | null) => void;

    size?: number | string;
    minSize?: DynamicWidths | number | "100%";

    disableResize?: boolean;

    persist?: string;

    disableBoxShadow?: boolean;
}
interface PROPS extends React.PropsWithChildren<OWN_PROPS> {}

interface DynamicWidths {
    xs: number | "100%";
    sm: number | "100%";
    md: number | "100%";
    lg: number | "100%";
    xl: number | "100%";
}

export const RESPONSIVE_MIN_SIZES: DynamicWidths = {
    xs: "100%",
    sm: 300,
    md: 420,
    lg: 540,
    xl: 660,
};

const ResizableDrawer = (props: PROPS) => {
    const { id, style, open, anchor = "right", children, size, minSize, disableResize, persist, disableBoxShadow, onClick, onClickAway, onSizeComputed } = props;

    const theme = useTheme();
    const classes = useStyles();

    const ref = useRef<HTMLDivElement>(null);

    const parentElement = ref.current?.offsetParent as HTMLElement | null | undefined;

    const [parentSize, setParentSize] = useState<number | null>(null);
    const [savedSize, setSavedSize] = useState<number | null>(null);
    const [responsiveSize, setResponsiveSize] = useState<number | "100%" | null>(null);
    const [initialSize, setInitialSize] = useState<number | "100%" | null>(null);

    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [drawerSize, setDrawerSize] = useState<number | "100%" | null>(null);

    useEffect(() => {
        if (ref.current?.offsetParent) {
            setParentSize((ref.current.offsetParent as HTMLElement).offsetWidth || null);
        }
    }, []);

    const parentBreakpoint = useMemo(() => {
        let breakpoint: Breakpoint | null = null;

        if (parentSize != null) {
            breakpoint = "xs";

            if (parentSize >= theme.breakpoints.values.sm) breakpoint = "sm";
            if (parentSize >= theme.breakpoints.values.md) breakpoint = "md";
            if (parentSize >= theme.breakpoints.values.lg) breakpoint = "lg";
            if (parentSize >= theme.breakpoints.values.xl) breakpoint = "xl";
        }

        return breakpoint;
    }, [parentSize, theme.breakpoints.values.lg, theme.breakpoints.values.md, theme.breakpoints.values.sm, theme.breakpoints.values.xl]);

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(persist)) {
            const raw = sessionStorage.getItem("drawer_size_" + persist);

            let parsedSize: number | null = null;

            if (raw) {
                try {
                    parsedSize = Number.parseInt(raw);
                } catch (error: any) {}
            }

            setSavedSize(parsedSize);
        }
    }, [persist]);

    useEffect(() => {
        let responsiveMinSize: number | "100%" | null = null;

        if (parentBreakpoint != null) {
            if (minSize != null) {
                if (typeof minSize === "string" || typeof minSize === "number") {
                    responsiveMinSize = minSize;
                } else {
                    responsiveMinSize = minSize[parentBreakpoint];
                }
            } else {
                responsiveMinSize = RESPONSIVE_MIN_SIZES[parentBreakpoint];
            }
        }

        setResponsiveSize(responsiveMinSize);
    }, [minSize, parentBreakpoint]);

    useEffect(() => {
        if (parentSize != null) {
            if (responsiveSize != null && responsiveSize !== "100%" && responsiveSize <= parentSize) {
                setInitialSize(responsiveSize);
            } else {
                setInitialSize("100%");
            }
        }
    }, [parentSize, responsiveSize]);

    useEffect(() => {
        if (parentSize != null && initialSize != null) {
            let newDrawerSize: number | "100%" | null = null;

            if (initialSize !== "100%") {
                if (initialSize <= parentSize) {
                    newDrawerSize = initialSize;
                } else {
                    newDrawerSize = "100%";
                }

                if (newDrawerSize !== "100%") {
                    if (newDrawerSize > parentSize) {
                        newDrawerSize = "100%";
                    } else {
                        if (savedSize != null && savedSize <= parentSize && savedSize >= initialSize) {
                            newDrawerSize = savedSize;
                        } else {
                            if (responsiveSize != null) {
                                newDrawerSize = responsiveSize;
                            }
                        }
                    }
                }
            } else {
                newDrawerSize = initialSize;
            }

            setDrawerSize(newDrawerSize);
        }
    }, [initialSize, parentSize, responsiveSize, savedSize]);

    useEffect(() => {
        if (parentElement != null) {
            const observer = new ResizeObserver(() => {
                if (parentSize !== parentElement.offsetWidth) {
                    setParentSize(parentElement.offsetWidth);
                }
            });

            observer.observe(parentElement);

            return () => {
                observer.disconnect();
            };
        }
    }, [parentElement, parentSize]);

    const onResizeStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();

        setIsResizing(true);
    }, []);

    const onResize = useCallback(
        (e: MouseEvent | TouchEvent) => {
            if (!parentElement || !isResizing || initialSize == null || initialSize === "100%") return;

            const rect = parentElement.getBoundingClientRect();

            let newWidth = -1;

            const clientX = e.type === "touchmove" ? (e as TouchEvent).targetTouches[0].clientX : (e as MouseEvent).clientX;
            const parentOffsetLeft = rect.left;

            if (anchor === "left") {
                newWidth = rect.width - (rect.width - (clientX - parentOffsetLeft));
            } else {
                newWidth = rect.width - (clientX - parentOffsetLeft);
            }

            if (newWidth >= initialSize && newWidth <= rect.width) {
                setDrawerSize(newWidth);
            } else {
                if (newWidth >= rect.width) {
                    setDrawerSize(rect.width);
                }

                if (newWidth <= initialSize) {
                    setDrawerSize(initialSize);
                }
            }
        },
        [anchor, initialSize, isResizing, parentElement]
    );

    const onResizeStop = useCallback(
        (e: MouseEvent | TouchEvent) => {
            if (isResizing) {
                setIsResizing(false);

                if (!StringUtils.isNullOrEmpty(persist) && drawerSize != null && drawerSize !== "100%") {
                    sessionStorage.setItem("drawer_size_" + persist, "" + drawerSize);

                    setSavedSize(drawerSize);
                }
            }
        },
        [drawerSize, isResizing, persist]
    );

    useEffect(() => {
        document.addEventListener("mousemove", onResize);
        document.addEventListener("touchmove", onResize);

        document.addEventListener("mouseup", onResizeStop);
        document.addEventListener("touchend", onResizeStop);

        return () => {
            document.removeEventListener("mousemove", onResize);
            document.removeEventListener("touchmove", onResize);

            document.removeEventListener("mouseup", onResizeStop);
            document.removeEventListener("touchend", onResizeStop);
        };
    }, [onResize, onResizeStop]);

    useEffect(() => {
        if (onSizeComputed) onSizeComputed(drawerSize);
    }, [drawerSize, onSizeComputed]);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
            if (onClick) {
                onClick(event);
            }
        },
        [onClick]
    );

    const handleClickAway = useCallback(
        (event: MouseEvent | TouchEvent) => {
            if (open && onClickAway) {
                onClickAway(event);
            }
        },
        [onClickAway, open]
    );

    return (
        <Drawer
            id={id}
            ref={ref}
            variant={"persistent"}
            anchor={anchor}
            open={open}
            style={style}
            PaperProps={{
                style: {
                    position: "absolute",
                    width: size || drawerSize || "100%",
                    overflow: "visible",
                    borderStyle: "none",
                    boxShadow: !disableBoxShadow ? (anchor === "left" ? "var(--left-drawer-box-shadow)" : "var(--right-drawer-box-shadow)") : undefined,
                },
            }}
        >
            <ClickAwayListener onClickAway={handleClickAway}>
                <div
                    style={{
                        width: "100%",
                        height: "100%",

                        position: "relative",

                        display: "flex",
                        flexDirection: "column",

                        backgroundColor: "var(--secondary-background-color, inherit)",
                        color: "var(--secondary-color, inherit)",
                        borderColor: "var(--secondary-border-color, inherit)",
                    }}
                    onClick={handleClick}
                >
                    {initialSize !== "100%" && (
                        <div
                            style={{
                                zIndex: 1,

                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                left: anchor === "right" ? 0 : undefined,
                                right: anchor === "left" ? 0 : undefined,

                                width: "0.0625em",

                                overflow: "visible",
                                cursor: !disableResize ? "col-resize" : undefined,

                                backgroundColor: "var(--secondary-border-color, inherit)",
                                color: "inherit",
                                borderColor: "inherit",
                            }}
                            onMouseDown={!disableResize ? onResizeStart : undefined}
                            onTouchStart={!disableResize ? onResizeStart : undefined}
                        >
                            {!disableResize && (
                                <IconButton
                                    id={"drawer-resize-handle"}
                                    style={{
                                        position: "absolute",
                                        top: "calc((100% / 2) - (30px / 2))",
                                        left: -9.5,

                                        width: 20,
                                        height: 30,

                                        margin: 0,

                                        cursor: "col-resize",

                                        backgroundColor: "var(--secondary-background-color, inherit)",
                                        color: "inherit",
                                        borderColor: "inherit",

                                        borderStyle: "solid",
                                        borderWidth: "0.0625em",
                                        borderRadius: "0.625em",
                                    }}
                                    type={"neutral"}
                                >
                                    <DragHandle style={{ width: "100%", height: "100%", transform: "rotate(90deg)" }} />
                                </IconButton>
                            )}
                        </div>
                    )}

                    <div
                        className={classes.content}
                        style={{ zIndex: 0, backgroundColor: "inherit", color: "inherit", borderColor: "inherit", paddingLeft: anchor === "right" ? "0.0625em" : undefined, paddingRight: anchor === "left" ? "0.0625em" : undefined }}
                    >
                        {children}

                        {isResizing && <div style={{ position: "absolute", top: 0, right: 0, width: "100%", height: "100%", opacity: 0, cursor: "default" }}></div>}
                    </div>
                </div>
            </ClickAwayListener>
        </Drawer>
    );
};

const useStyles = makeStyles({
    content: {
        flex: "1 1 auto",

        display: "flex",
        flexDirection: "column",

        overflow: "hidden",

        position: "relative",
    },
});

export default ResizableDrawer;
