// Import libraries.
import { put, select, all, call, takeEvery, takeLatest } from "redux-saga/effects";

// Import types.
import PortalState from "types/store";
import Theme from "types/common/Theme";
import PortalThemeConfig from "types/common/PortalThemeConfig";

// Import redux actions.
import { ADD_THEME, UPDATE_THEME, SET_ACTIVE_THEME } from "store/actions/theme";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";
import BrandingUtils from "utils/Branding";

// Import the default branding (this is the minimum/starting branding data).
import { other as other_light, cssVariables as cssVariables_light } from "assets/data/defaultBranding-light";
import { other as other_dark, cssVariables as cssVariables_dark } from "assets/data/defaultBranding-dark";

interface LoadTheme {
    type: "theme.loadTheme";
    payload: {
        companyId: string | null;
        custom?: string | null;
        activate?: boolean;
        callback?: () => void;
    };
}

interface SetTheme {
    type: "theme.setTheme";
    payload: {
        companyId: string | null;
        fallbackCompanyId?: string | null;
        callback?: () => void;
    };
}

const processPortalThemeConfig = (data: any): PortalThemeConfig => {
    const portalThemeConfig: PortalThemeConfig = {
        vanityURL: data && data.vanityURL ? data.vanityURL : null,
        enabled: data && data.portalxThemeEnabled === true ? true : false,
        light: {
            mainServiceLogo: data && data.light && data.light.mainServiceLogo && data.light.mainServiceLogo.trim().length > 0 ? data.light.mainServiceLogo : null,
            mainServiceLogoUrl: data && data.light && data.light.mainServiceLogoUrl && data.light.mainServiceLogoUrl.trim().length > 0 ? data.light.mainServiceLogoUrl : null,

            loginLogo: data && data.light && data.light.loginLogo && data.light.loginLogo.trim().length > 0 ? data.light.loginLogo : null,
            loginLogoUrl: data && data.light && data.light.loginLogoUrl && data.light.loginLogoUrl.trim().length > 0 ? data.light.loginLogoUrl : null,

            cssVariables: data && data.light && data.light.cssVariables ? data.light.cssVariables : {},
            other: data && data.light && data.light.other ? data.light.other : {},
        },
        dark: {
            mainServiceLogo: data && data.dark && data.dark.mainServiceLogo && data.dark.mainServiceLogo.trim().length > 0 ? data.dark.mainServiceLogo : null,
            mainServiceLogoUrl: data && data.dark && data.dark.mainServiceLogoUrl && data.dark.mainServiceLogoUrl.trim().length > 0 ? data.dark.mainServiceLogoUrl : null,

            loginLogo: data && data.dark && data.dark.loginLogo && data.dark.loginLogo.trim().length > 0 ? data.dark.loginLogo : null,
            loginLogoUrl: data && data.dark && data.dark.loginLogoUrl && data.dark.loginLogoUrl.trim().length > 0 ? data.dark.loginLogoUrl : null,

            cssVariables: data && data.dark && data.dark.cssVariables ? data.dark.cssVariables : {},
            other: data && data.dark && data.dark.other ? data.dark.other : {},
        },
        updatedAt: data.updatedAt != null ? new Date(data.updatedAt) : undefined,
    };

    return portalThemeConfig;
};

const generateTheme = (companyId: string | null, name: string, description: string, portalThemeConfig: PortalThemeConfig): Theme => {
    /**
     * Construct the basic theme instance.
     */
    const theme: Theme = {
        companyId: companyId,
        name: name,
        description: description,
        enabled: portalThemeConfig.enabled,
        vanityURL: portalThemeConfig.vanityURL,
        light: {
            mainServiceLogo: null,
            mainServiceLogoUrl: null,
            loginLogo: null,
            loginLogoUrl: null,
            cssContent: null,
            cssVariables: {},
            monacoTheme: null,
            fontUrl: null,
        },
        dark: {
            mainServiceLogo: null,
            mainServiceLogoUrl: null,
            loginLogo: null,
            loginLogoUrl: null,
            cssContent: null,
            cssVariables: {},
            monacoTheme: null,
            fontUrl: null,
        },
        updatedAt: portalThemeConfig.updatedAt,
    };

    const realCssVariables_light = {};

    Object.assign(realCssVariables_light, cssVariables_light);
    Object.assign(realCssVariables_light, portalThemeConfig.light.cssVariables);

    const realCssVariables_dark = {};

    Object.assign(realCssVariables_dark, cssVariables_dark);
    Object.assign(realCssVariables_dark, portalThemeConfig.dark.cssVariables);

    /**
     * Perform final theme generation actions (generate CSS ref/content and a clean PortalBranding instance).
     *
     * Sample Custom Font URL (from Google Fonts): https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap
     */
    // LIGHT MODE
    theme.light.mainServiceLogo = portalThemeConfig.light.mainServiceLogo;
    theme.light.mainServiceLogoUrl = portalThemeConfig.light.mainServiceLogoUrl;
    theme.light.loginLogo = portalThemeConfig.light.loginLogo;
    theme.light.loginLogoUrl = portalThemeConfig.light.loginLogoUrl;
    theme.light.cssContent = BrandingUtils.generatePortalBrandingCSSContent(portalThemeConfig.light.cssVariables);
    theme.light.cssVariables = realCssVariables_light;
    theme.light.monacoTheme = portalThemeConfig.light.other["monaco"] ? portalThemeConfig.light.other["monaco"] : other_light["monaco"];
    theme.light.fontUrl = portalThemeConfig.light.other["fontUrl"] ? portalThemeConfig.light.other["fontUrl"] : other_light["fontUrl"];

    // DARK MODE
    theme.dark.mainServiceLogo = portalThemeConfig.dark.mainServiceLogo;
    theme.dark.mainServiceLogoUrl = portalThemeConfig.dark.mainServiceLogoUrl;
    theme.dark.loginLogo = portalThemeConfig.dark.loginLogo;
    theme.dark.loginLogoUrl = portalThemeConfig.dark.loginLogoUrl;
    theme.dark.cssContent = BrandingUtils.generatePortalBrandingCSSContent(portalThemeConfig.dark.cssVariables);
    theme.dark.cssVariables = realCssVariables_dark;
    theme.dark.monacoTheme = portalThemeConfig.dark.other["monaco"] ? portalThemeConfig.dark.other["monaco"] : other_dark["monaco"];
    theme.dark.fontUrl = portalThemeConfig.dark.other["fontUrl"] ? portalThemeConfig.dark.other["fontUrl"] : other_dark["fontUrl"];

    // Return the final Theme instance.
    return theme;
};

// Loads the indicated theme.
export function* loadTheme(action: LoadTheme) {
    // Get the current theme configuration.
    const getAvailableThemes = (state: PortalState): Theme[] => [...state.themeConfiguration.availableThemes];
    const availableThemes: Theme[] = yield select(getAvailableThemes);

    try {
        console.log("Load Theme", action.payload.companyId);

        if (action.payload) {
            const { companyId, custom, activate } = action.payload;

            const targetCompanyId = companyId && companyId.endsWith("-PREVIEW") ? companyId.substring(0, companyId.length - 8) : companyId;

            let theme: Theme | null = null;
            let previewTheme: Theme | null = null;

            if (targetCompanyId == null) {
                const portalThemeConfigResponse: HttpResponse = yield Http.GET("portal-x-config-url-new", { brandURL: custom ? custom : null });
                const portalThemeConfigData = Http.isStatusOk(portalThemeConfigResponse) ? portalThemeConfigResponse.data : null;

                // Load the default theme (based on global theme from the portal config).
                theme = generateTheme(null, "__DEFAULT__", "The default portal theme.", processPortalThemeConfig(portalThemeConfigData));
            } else {
                const portalThemeConfigResponse: HttpResponse = yield Http.GET("admin/serveradmin/portal-x-config-team-new", { forceEnableTrue: true, teamId: targetCompanyId });
                const portalThemeConfigData = Http.isStatusOk(portalThemeConfigResponse) ? portalThemeConfigResponse.data : null;

                // Load the company specific theme (and associated preview theme).
                theme = generateTheme(targetCompanyId, "", "The portal theme for team '" + targetCompanyId + "'.", processPortalThemeConfig(portalThemeConfigData));
                previewTheme = generateTheme(targetCompanyId + "-PREVIEW", "", "The preview theme for team '" + targetCompanyId + "'.", processPortalThemeConfig(portalThemeConfigData));
            }

            const existingTheme = availableThemes.find((item) => item.companyId === targetCompanyId);
            const existingPreviewTheme = availableThemes.find((item) => item.companyId === targetCompanyId + "-PREVIEW");

            if (theme) {
                if (existingTheme) {
                    yield put(UPDATE_THEME(theme));
                } else {
                    yield put(ADD_THEME(theme));
                }

                if (previewTheme) {
                    if (existingPreviewTheme) {
                        yield put(UPDATE_THEME(previewTheme));
                    } else {
                        yield put(ADD_THEME(previewTheme));
                    }
                }

                if (activate) {
                    yield call(setTheme, { type: "theme.setTheme", payload: { companyId: targetCompanyId } });
                }
            }

            if (action.payload.callback) {
                action.payload.callback();
            }

            return true;
        }
    } catch (error: any) {
        console.error("theme.loadTheme - ERROR", error);
    }

    return false;
}

// Sets the currently active theme.
export function* setTheme(action: SetTheme) {
    // Get the current theme configuration.
    const getAvailableThemes = (state: PortalState): Theme[] => [...state.themeConfiguration.availableThemes];
    const availableThemes: Theme[] = yield select(getAvailableThemes);

    try {
        if (action.payload.fallbackCompanyId) {
            console.log("Set Theme", action.payload.companyId, action.payload.fallbackCompanyId);
        } else {
            console.log("Set Theme", action.payload.companyId);
        }

        if (action.payload) {
            const { companyId, fallbackCompanyId } = action.payload;

            let targetTheme = null;

            // Determine the actual theme to set (based on whether it's a preview or enabled and falling back as necessary).
            if (companyId && companyId.endsWith("-PREVIEW")) targetTheme = availableThemes.find((item: Theme) => item.companyId === companyId);
            if (companyId && !companyId.endsWith("-PREVIEW")) targetTheme = availableThemes.find((item: Theme) => item.companyId === companyId && item.enabled);

            // Perform fallback to the supplied fallback (if necessary).
            if (!targetTheme) {
                if (fallbackCompanyId && fallbackCompanyId.endsWith("-PREVIEW")) targetTheme = availableThemes.find((item: Theme) => item.companyId === fallbackCompanyId);
                if (fallbackCompanyId && !fallbackCompanyId.endsWith("-PREVIEW")) targetTheme = availableThemes.find((item: Theme) => item.companyId === fallbackCompanyId && item.enabled);
            }

            // Perform fallback to the default theme (if necessary).
            if (!targetTheme) targetTheme = availableThemes.find((item: Theme) => item.companyId === null);

            // Set the active theme (if applicable).
            if (targetTheme) {
                yield put(SET_ACTIVE_THEME(CloneUtils.clone(targetTheme)));
            }

            if (action.payload.callback) {
                action.payload.callback();
            }

            return true;
        }
    } catch (error: any) {
        console.error("theme.setTheme - ERROR", error);
    }

    return false;
}

export default function* root() {
    yield all([takeEvery("theme.loadTheme", loadTheme), takeLatest("theme.setTheme", setTheme)]);
}
