import Http, { HttpResponse } from "utils/networking/Http";

/**
 * Fetches a system property from the server.
 *
 * @param propertyName
 * @returns
 */
const getSystemProperty = async (propertyName: string): Promise<any> => {
    const response: HttpResponse = await Http.GET("get-system-property-value", { propertyName: propertyName }, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

/**
 * Fetches a single app-sepcific property from the server.
 *
 * @param propertyName
 * @returns
 */
const getProperty = async (propertyName: string): Promise<any> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/get-property-value", { propertyName: propertyName }, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

/**
 * Fetches multiple app-sepcific properties from the server.
 *
 * @param propertyNames
 * @returns
 */
const getProperties = async (...propertyNames: string[]): Promise<{ [index: string]: any }> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/get-properties-value", { property: propertyNames }, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const Services = {
    getSystemProperty: getSystemProperty,
    getProperty: getProperty,
    getProperties: getProperties,
};

export default Services;
