// Import libraries.
import React from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import QueryString from "query-string";

// Import components.
import { Chip, Typography } from "@mui/material";
import FieldWrapper from "components/common/form/FieldWrapper";
import Button from "components/common/button/Button";

// Import icons.
import SuccessIcon from "@mui/icons-material/CheckCircleOutline";

// Import utilities.
import Http from "utils/networking/Http";
import StringUtils from "utils/String";

interface OWN_PROPS {
    onComplete: (email: string | null) => void;
    onCancel: () => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

interface STATE {
    email: string;
    token: string;

    unlockAccountStatus: "pending" | "complete" | "error" | null;
    error: string | null;
}

class UnlockAccount extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        email: "",
        token: "",

        unlockAccountStatus: null,
        error: null,
    };
    private confirmButtonRef = React.createRef<HTMLElement>();

    componentDidMount() {
        // Extract any URI parameters that may have been supplied.
        let queryString = window.location.href.indexOf("?") >= 0 ? window.location.href.substring(window.location.href.indexOf("?") + 1) : null;
        if (queryString?.includes("#")) queryString = queryString.substring(0, queryString.indexOf("#"));
        const queryParams = queryString ? QueryString.parse(queryString) : {};

        if (queryParams.email && !Array.isArray(queryParams.email) && !StringUtils.isNullOrEmpty(queryParams.email) && queryParams.token && !Array.isArray(queryParams.token) && !StringUtils.isNullOrEmpty(queryParams.token)) {
            // If the email and token arguments are both present then proceed.
            this.setState({ email: queryParams.email, token: queryParams.token }, this.onUnlockAccount);
        } else {
            // Otheriwse immediately trigger the onComplete handler (returns the user to the standard login).
            this.props.onComplete(null);
        }
    }

    onUnlockAccount = async () => {
        const { i18n } = this.props;
        const { email, token } = this.state;

        this.setState({ unlockAccountStatus: "pending", error: null }, async () => {
            const unlockAccountResponse = await Http.GET("unlockAccount", { email: email, token: token }, Http.JSON_HEADERS);

            if (Http.isStatusOk(unlockAccountResponse)) {
                if (unlockAccountResponse.data.error) {
                    this.setState({ unlockAccountStatus: "error", error: unlockAccountResponse.data.error });
                } else {
                    this.setState({ unlockAccountStatus: "complete", error: null }, () => {
                        window.setTimeout(() => {
                            this.props.onComplete(email);
                        }, 1000);
                    });
                }
            } else {
                const error = Http.buildError(unlockAccountResponse);

                this.setState({ unlockAccountStatus: "error", error: error.errorMessage || i18n._(t`Could not unlock account.`) });
            }
        });
    };

    render() {
        const { classes } = this.props;
        const { email, unlockAccountStatus, error } = this.state;

        return (
            <div id={"unlock-account"} className={classes.root}>
                {unlockAccountStatus !== "complete" && (
                    <>
                        <Typography style={{ alignSelf: "center", marginBottom: "0.3125rem" }}>
                            <Trans>Unlock Account</Trans>
                        </Typography>

                        <FieldWrapper className={classes.field} type={"text"} name={"email"} value={email} disabled={true} />

                        <Button
                            ref={this.confirmButtonRef}
                            className={classes.button}
                            id={"unlock-account"}
                            type={"primary"}
                            onClick={this.onUnlockAccount}
                            disabled={unlockAccountStatus != null && ["pending", "complete"].includes(unlockAccountStatus)}
                        >
                            {unlockAccountStatus === "pending" ? <Trans>Please Wait...</Trans> : <Trans>Unlock Account</Trans>}
                        </Button>

                        <Button className={classes.button} id={"cancel"} type={"secondary"} onClick={this.props.onCancel} disabled={unlockAccountStatus != null && ["pending", "complete"].includes(unlockAccountStatus)}>
                            <Trans>Cancel</Trans>
                        </Button>

                        {unlockAccountStatus === "error" && <Chip className={classes.chip} label={error} />}
                    </>
                )}

                {unlockAccountStatus === "complete" && (
                    <div className={classes.resetPasswordComplete}>
                        <SuccessIcon />

                        <Typography>
                            <Trans>Account Unlocked</Trans>
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            position: "relative",
        },

        field: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",
        },
        button: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            textTransform: "none",
        },
        chip: {
            height: "2rem",
            fontWeight: "bold",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            backgroundColor: "var(--chip-negative-background-color)",
            color: "var(--chip-negative-color)",
            borderColor: "inherit",
        },

        resetPasswordComplete: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",

            overflow: "hidden",

            "& > *": {
                marginLeft: "auto",
                marginRight: "auto",

                "&.MuiSvgIcon-root": {
                    width: "6rem",
                    height: "6rem",

                    color: "var(--label-positive-color, inherit)",
                },

                "&.MuiTypography-root": {
                    marginTop: "2rem",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                },
            },
        },
    });

export default withI18n()(withStyles(styles)(UnlockAccount));
