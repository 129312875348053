export interface RecentlyViewedAction {
    type: "SET_RECENTLY_VIEWED";
    payload: string[];
}

export const SET_RECENTLY_VIEWED = (recentlyViewed: string[]): RecentlyViewedAction => {
    return {
        type: "SET_RECENTLY_VIEWED",
        payload: recentlyViewed,
    };
};
