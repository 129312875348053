import { AvailablePrivilegesAction } from "store/actions/availablePrivileges";

import PortalPrivilege from "types/common/PortalPrivilege";

const initialState: PortalPrivilege[] = [];

const reducer = (state: PortalPrivilege[] = initialState, action: AvailablePrivilegesAction) => {
    let newState = state;

    switch (action.type) {
        case 'SET_AVAILABLE_PRIVILEGES':
            newState = [...action.payload];

            break;
        default:
            // Do nothing.
    }

    return newState;
};

export default reducer;
