import { Trans } from "@lingui/macro";

export enum AppStatus {
    APPROACHING_SUSPENSION = "ApproachingSuspension",
    SUSPENDED = "Suspended",
    DELETED = "deleted",
    ENABLED = "enabled",
    DISABLED = "disabled",
}

export const formatAppStatusLabel = (appStatus?: AppStatus | null) => {
    switch (appStatus) {
        case AppStatus.APPROACHING_SUSPENSION:
            return <Trans>Approaching Suspension</Trans>;
        case AppStatus.SUSPENDED:
            return <Trans>Suspended</Trans>;
        case AppStatus.DELETED:
            return <Trans>Deleted</Trans>;
        case AppStatus.ENABLED:
            return <Trans>Enabled</Trans>;
        case AppStatus.DISABLED:
            return <Trans>Disabled</Trans>;
        default:
            return appStatus || <Trans>---</Trans>;
    }
};

export const formatAppStatusColor = (appStatus?: AppStatus | null) => {
    switch (appStatus) {
        case AppStatus.APPROACHING_SUSPENSION:
            return "var(--label-warning-color)";
        case AppStatus.SUSPENDED:
            return "var(--label-negative-color)";
        case AppStatus.DELETED:
            return "var(--label-negative-color)";
        case AppStatus.ENABLED:
            return "var(--label-positive-color)";
        case AppStatus.DISABLED:
            return "var(--label-default-color)";
        default:
            return "var(--label-default-color)";
    }
};
export const formatAppStatusChipBackgroundColor = (appStatus?: AppStatus | null) => {
    switch (appStatus) {
        case AppStatus.APPROACHING_SUSPENSION:
            return "var(--chip-warning-background-color)";
        case AppStatus.SUSPENDED:
            return "var(--chip-negative-background-color)";
        case AppStatus.DELETED:
            return "var(--chip-negative-background-color)";
        case AppStatus.ENABLED:
            return "var(--chip-positive-background-color)";
        case AppStatus.DISABLED:
            return "var(--chip-warning-background-color)";
        default:
            return "var(--chip-default-background-color)";
    }
};
export const formatAppStatusChipColor = (appStatus?: AppStatus | null) => {
    switch (appStatus) {
        case AppStatus.APPROACHING_SUSPENSION:
            return "var(--chip-warning-color)";
        case AppStatus.SUSPENDED:
            return "var(--chip-negative-color)";
        case AppStatus.DELETED:
            return "var(--chip-negative-color)";
        case AppStatus.ENABLED:
            return "var(--chip-positive-color)";
        case AppStatus.DISABLED:
            return "var(--chip-warning-color)";
        default:
            return "var(--chip-default-color)";
    }
};

export default AppStatus;
