// Import types.
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalPrivilege from "types/common/PortalPrivilege";
import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import { SearchConfiguration, SearchFilter, SearchResult } from "../../types";

// Import utilities.
import PortalRouteUtils from "utils/PortalRoutes";
import ScreenSettings from "types/common/ScreenSettings";

// The name of the filter.
const FILTER_NAME = "PORTAL_PRIVILEGE";

/**
 * This filter attempts to eliminate those search reaults that refer to a target path that the user does not have access to.
 *
 * This filter will perform a "hasAccess" check using the PortalRoute utilities and current configuration.
 *
 * Any search result that does not pass this check will end up being filtered out.
 */
class PortalPrivilegeFilter implements SearchFilter {
    private session: Session | null = null;
    private currentUser: User | null = null;
    private availableCompanies: TeamInfo[] = [];
    private availableApps: AppInfo[] = [];
    private availablePrivileges: PortalPrivilege[] = [];
    private screenSettings: ScreenSettings[] = [];
    private definitions: PortalRouteDefinition[] = [];

    configure = (args?: SearchConfiguration | null) => {
        if (args) {
            this.session = args.session || null;
            this.currentUser = args.currentUser || null;
            this.availableCompanies = args.availableCompanies || [];
            this.availableApps = args.availableApps || [];
            this.availablePrivileges = args.availablePrivileges || [];
            this.screenSettings = args.screenSettings || [];
            this.definitions = args.definitions || [];
        }
    };

    filter = (searchResults: SearchResult[]) => {
        let filterResults: SearchResult[] = [];

        console.debug("Applying Filter", FILTER_NAME);

        try {
            filterResults = searchResults.filter((item) => {
                if (this.session && this.currentUser && this.availablePrivileges.length > 0 && this.definitions.length > 0) {
                    // If applicable, check the portal privileges to ensure the user has access to the target path.
                    return PortalRouteUtils.hasAccess(this.definitions, item.targetPath, this.session, this.currentUser, this.availableCompanies, this.availableApps, this.availablePrivileges);
                }

                return true;
            });
        } catch (error: any) {
            console.warn("Filter - ERROR", FILTER_NAME, error);
        }

        console.debug("Filtered Counts (original vs result)", FILTER_NAME, searchResults.length, filterResults.length);

        return filterResults;
    };
}

export default PortalPrivilegeFilter;
