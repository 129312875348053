// Import libraries.
import { AbstractSearchPlugin } from "..";

// Import types.
import Session from "types/common/Session";
import TeamInfo, { processTeamInfo } from "types/models/TeamInfo";
import { PluginName, Query, QueryOptions, SearchConfiguration, SearchResult } from "../../types";

// Import utilities.
import Http from "utils/networking/Http";
import { ACCOUNT_NUMBER_HINTS, TEAM_ID_HINTS, TEAM_NAME_HINTS } from "../../utils";

// A union of all valid hints.
const VALID_HINTS = [...TEAM_ID_HINTS, ...TEAM_NAME_HINTS, ...ACCOUNT_NUMBER_HINTS];

/**
 * This plugin looks for results that are team-oriented.
 *
 * This includes all of the screens under the TEAM context (based on the nature of the individual screen).
 */
class TeamPlugin extends AbstractSearchPlugin {
    private session: Session | null = null;
    private availableCompanies: TeamInfo[] = [];
    private options: QueryOptions | null = null;

    isHintSupported = (query: Query): boolean => {
        return VALID_HINTS.includes(query.hint.toLowerCase());
    };

    configure = (args?: SearchConfiguration | null) => {
        if (args) {
            this.session = args.session || null;
            this.availableCompanies = args.availableCompanies || [];
            this.options = args.options || null;
        }
    };

    executeQuery = async (query: Query, abortSignal?: AbortSignal) => {
        if (abortSignal?.aborted) {
            throw new DOMException("Aborted", "AbortError");
        }

        console.debug("Executing Plugin", PluginName.TEAM, query);

        // Define a collection to hold the results.
        const searchResults: SearchResult[] = [];

        // Extract those options we are concerned with (i.e. those that the plugin actually supports).
        const exhaustiveSearch = this.options?.exhaustiveSearch || false;

        // Extract the hint and searchTerm from the query.
        const { hint, searchTerm } = query;

        // By default we have the current list of available companies (those that the user has direct access to).
        // This would be used by default or as a fallback if more specialized methods of determnining the list of companies are unavailable.
        let teams = this.availableCompanies;

        if (this.session?.isSuper) {
            // For SUPER users we want to search against all companies system-wide (this could end up being a little slow... but it's probably not too big of a deal).
            const superCompanyResponse = await Http.POST(
                "admin/serveradmin/superUserReadTeamsPage",
                undefined,
                {
                    search: searchTerm,
                    pageNumber: 1,
                    rowsPerPage: exhaustiveSearch ? 9999 : 200,
                    sortColumnName: "companyName",
                    sortDirection: "asc",
                },
                Http.JSON_HEADERS,
                abortSignal
            );

            if (abortSignal?.aborted) {
                throw new DOMException("Aborted", "AbortError");
            }

            if (Http.isStatusOk(superCompanyResponse) && !Http.isRedirect(superCompanyResponse) && Array.isArray(superCompanyResponse.data?.rows)) {
                teams = superCompanyResponse.data.rows.map(processTeamInfo);
            }
        } else {
            // For all other users we simply use the currently available companies collection (which are those companies that they are actually a member of).
        }

        // Filter to applicable companies (based on the hint and searchTerm supplied).
        // This is necessary since the server end-point used to fetch the list of companies does not support any filtering (meaning we get back all companies).
        const applicableTeams = teams.filter((item) => {
            if (ACCOUNT_NUMBER_HINTS.includes(hint.toLowerCase())) {
                return item.accountNumber && item.accountNumber.toLowerCase().includes(searchTerm.toLowerCase());
            }

            if (TEAM_ID_HINTS.includes(hint.toLowerCase())) {
                return item.companyId.toLowerCase().includes(searchTerm.toLowerCase());
            }

            if (TEAM_NAME_HINTS.includes(hint.toLowerCase())) {
                return item.companyName.toLowerCase().includes(searchTerm.toLowerCase());
            }

            return false;
        });

        // Sort the applicable companies by companyName.
        applicableTeams.sort((a, b) => a.companyName.localeCompare(b.companyName));

        // Generate SearchResult's for each applicable company.
        applicableTeams.forEach((team) => {
            // TEAM / Dashboard.
            searchResults.push({
                type: PluginName.TEAM,
                targetPath: "/team/dashboard",
                targetState: null,
                searchTerm: searchTerm,
                data: team,
            });
        });

        console.debug("Plugin Results", PluginName.TEAM, searchResults);

        return searchResults;
    };
}

export default TeamPlugin;
