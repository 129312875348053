import GameAccess, { processGameAccess } from "./GameAccess";

export default interface TeamAccess {
    profileId: string;
    isTeamAdmin: boolean;
    hasApiAccess: boolean;
    gameAccess: GameAccess[];
}

export const processTeamAccess = (data: any): TeamAccess[] => {
    const result: TeamAccess[] = [];

    if (data) {
        Object.keys(data).forEach((key) => {
            const item = data[key];

            result.push({
                profileId: item.profileId,
                isTeamAdmin: item.isTeamAdmin === true ? true : false,
                hasApiAccess: item.hasApiAccess === true ? true : false,
                gameAccess: processGameAccess(item.gameAccess),
            });
        });
    }

    result.sort((a, b) => a.profileId.localeCompare(b.profileId));

    return result;
};
