import { ThemeAction } from "store/actions/theme";

import ThemeSettings from "types/common/ThemeSettings";

import CloneUtils from "utils/Clone";

const initialState: ThemeSettings | null = null;

const reducer = (state: ThemeSettings | null = initialState, action: ThemeAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_THEME_SETTINGS":
            newState = action.payload ? (CloneUtils.clone(action.payload) as ThemeSettings) : null;

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
