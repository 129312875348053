const MAX_ATTEMPTS = 3;

const performImport = async (componentImport: () => Promise<{ default: React.ComponentType<any> }>, failedAttempts: number): Promise<{ default: React.ComponentType<any> }> => {
    return new Promise(async (resolve, reject) => {
        let currentAttempt = (failedAttempts || 0) + 1;

        // console.log("Import Attempt #" + currentAttempt);

        try {
            // if (currentAttempt < MAX_ATTEMPTS) throw new Error("Test Import Failure #" + currentAttempt);

            resolve(await componentImport());
        } catch (error: any) {
            if (currentAttempt >= MAX_ATTEMPTS) {
                reject("Could Not Complete Async Import: Exceeded Retry Limit");
            } else {
                // Declare a kind of "backoff" retry. Every subsequent attempt is deferred by 500 milliseconds.
                // This will hopefully address those situations where multiple 503's (or whatever) are encountered.
                // This should be rare but "may" have a higher chance of happening during a deployment.
                const timeToWait = currentAttempt * 500;

                // console.log("Will Retry in " + timeToWait);

                window.setTimeout(async () => {
                    performImport(componentImport, currentAttempt)
                        .then((component) => {
                            resolve(component);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }, timeToWait);
            }
        }
    });
};

const lazyRetry = function (componentImport: () => Promise<{ default: React.ComponentType<any> }>) {
    return () =>
        new Promise<{ default: React.ComponentType<any> }>(async (resolve, reject) => {
            const storageKey = `retry-lazy-refreshed-${btoa(componentImport.toString())}`;

            let hasRefreshed: boolean = JSON.parse(window.sessionStorage.getItem(storageKey) || "false");

            try {
                const component = await performImport(componentImport, 0);

                window.sessionStorage.removeItem(storageKey);

                resolve(component);
            } catch (error: any) {
                reject(error + " - " + storageKey);

                if (!hasRefreshed) {
                    window.sessionStorage.setItem(storageKey, "" + true);

                    window.location.reload();
                } else {
                    window.sessionStorage.removeItem(storageKey);
                }
            }
        });
};

export default lazyRetry;
