// Import libraries.
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import ApiKeyInfo from "types/models/ApiKeyInfo";
import { SelectFieldOptions } from "components/common/form/fields/SelectField";

// Import components.
import { Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";
import FieldWrapper from "components/common/form/FieldWrapper";
import IconButton from "components/common/button/IconButton";
import Timestamp from "components/common/Timestamp";

// Import table components.
import { ColumnDefinition, PublicTableRefInterface, RowData } from "components/common/table2/types";
import CustomTable from "components/common/table2";

// Import icons.
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";

// Define the various properties accepted by this component.
interface STATE_PROPS {
    session: Session;
}

interface OWN_PROPS {
    context: "self" | "super" | "team";
    apiKeys: ApiKeyInfo[];
    mode: "create" | null;
    description: string | null;
    scope: "superadmin" | "team" | "app" | "user" | null;
    expiry: "1" | "2" | "3" | null;
    onGet: (apiKeyId: string) => void;
    onCreate: () => void;
    onDelete: (apiKeyId: string) => void;
    onChange: (description: string | null, scope: "superadmin" | "team" | "app" | "user" | null, expiry: "1" | "2" | "3" | null) => void;
}
interface PROPS extends OWN_PROPS, STATE_PROPS, WithStyles<typeof styles> {}

// Map redux state to properties.
const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
    };
};

class APIKeys extends React.PureComponent<PROPS> {
    private tableRef = React.createRef<PublicTableRefInterface>();

    componentDidUpdate(prevProps: PROPS) {
        if (prevProps.apiKeys !== this.props.apiKeys) {
            this.refreshTable();
        }
    }

    refreshTable = async () => {
        if (this.tableRef.current) await this.tableRef.current.loadCurrentPage(true);
    };

    handleChange = (name: string, value: any) => {
        const { scope, description, expiry, onChange } = this.props;

        switch (name) {
            case "description":
                onChange(value, scope, expiry);
                break;
            case "scope":
                onChange(description, value, expiry);
                break;
            case "expiry":
                onChange(description, scope, value);
                break;
            default:
            // Do nothing.
        }
    };

    render() {
        const { classes, context, apiKeys, mode, description, scope, expiry } = this.props;

        const columns: ColumnDefinition[] = [
            {
                id: "description",
                sortable: true,
                label: <Trans>Key Name</Trans>,
                format: (value: any, _row: RowData): ReactNode => {
                    return <Typography>{value}</Typography>;
                },
            },
            {
                id: "scope",
                sortable: true,
                align: "center",
                label: <Trans>Scope</Trans>,
                format: (value: any, _row: RowData): ReactNode => {
                    return <Typography>{value}</Typography>;
                },
            },
            {
                id: "createdAt",
                sortable: true,
                align: "center",
                label: <Trans>Created</Trans>,
                format: (value: any, _row: RowData): ReactNode => {
                    return <Timestamp value={value} showSeconds={true} dateOnly={true} />;
                },
            },
            {
                id: "expiresOn",
                sortable: true,
                align: "center",
                label: <Trans>Expires</Trans>,
                format: (value: any, _row: RowData): ReactNode => {
                    return <Timestamp value={value} showSeconds={true} dateOnly={true} />;
                },
            },
            {
                id: "lastUsed",
                sortable: true,
                align: "center",
                label: <Trans>Last Used</Trans>,
                format: (value: any, _row: RowData): ReactNode => {
                    return <Timestamp value={value} showSeconds={true} dateOnly={true} />;
                },
            },
            {
                id: "lastFailed",
                sortable: true,
                align: "center",
                label: <Trans>Last Failed</Trans>,
                format: (value: any, _row: RowData): ReactNode => {
                    return <Timestamp value={value} showSeconds={true} dateOnly={true} />;
                },
            },
        ];

        return (
            <div className={classes.root}>
                <CustomTable
                    id={"api-keys"}
                    ref={this.tableRef}
                    columns={columns}
                    primaryKey="apiKeyId"
                    dataProvider={{ rows: apiKeys, total: apiKeys.length }}
                    actions={
                        context === "self"
                            ? (row: RowData) => {
                                  return (
                                      <span style={{ paddingLeft: "0.3125em", paddingRight: "0.3125em", display: "flex", alignItems: "center" }}>
                                          <Tooltip
                                              alwaysShow
                                              arrow
                                              title={
                                                  <Typography>
                                                      <Trans>Copy API Key to Clipboard</Trans>
                                                  </Typography>
                                              }
                                          >
                                              <IconButton id={"copy-to-clipboard"} onClick={() => this.props.onGet((row as ApiKeyInfo).apiKeyId)}>
                                                  <ContentCopyIcon />
                                              </IconButton>
                                          </Tooltip>

                                          <Tooltip
                                              alwaysShow
                                              arrow
                                              title={
                                                  <Typography>
                                                      <Trans>Delete API Key</Trans>
                                                  </Typography>
                                              }
                                          >
                                              <IconButton id={"delete"} type={"semantic-negative"} onClick={() => this.props.onDelete((row as ApiKeyInfo).apiKeyId)}>
                                                  <DeleteIcon />
                                              </IconButton>
                                          </Tooltip>
                                      </span>
                                  );
                              }
                            : undefined
                    }
                />

                {mode === "create" && (
                    <div className={classes.columnBlock}>
                        <FieldWrapper type={"text"} name={"description"} value={description} label={<Trans>Key Name</Trans>} labelPosition={"top"} labelAlignment={"flex-start"} onChange={this.handleChange} />
                        <FieldWrapper
                            type={"select"}
                            name={"scope"}
                            value={scope}
                            label={<Trans>Scope</Trans>}
                            labelPosition={"top"}
                            labelAlignment={"flex-start"}
                            onChange={this.handleChange}
                            required={true}
                            options={
                                {
                                    options: [
                                        ...(this.props.session.isSuper ? [{ value: "superadmin", label: <Trans>Super level</Trans> }] : []),
                                        { value: "team", label: <Trans>All my teams</Trans> },
                                        // { value: "app", label: <Trans>All My Apps</Trans> },
                                        // { value: "user", label: <Trans>My User Only</Trans> },
                                    ],
                                } as SelectFieldOptions
                            }
                        />
                        <FieldWrapper
                            type={"select"}
                            name={"expiry"}
                            value={expiry}
                            label={<Trans>Expiry</Trans>}
                            labelPosition={"top"}
                            labelAlignment={"flex-start"}
                            onChange={this.handleChange}
                            required={true}
                            options={
                                {
                                    options: [
                                        { value: "1", label: <Trans>Expires in 1 year</Trans> },
                                        { value: "2", label: <Trans>Expires in 2 years</Trans> },
                                        { value: "3", label: <Trans>Expires in 3 years</Trans> },
                                    ],
                                } as SelectFieldOptions
                            }
                        />
                    </div>
                )}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
        },
        columnBlock: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            margin: "0.3125em",
        },
    });

export default connect(mapStateToProps)(withStyles(styles)(APIKeys));
