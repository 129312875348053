import { DirectRTTLog, DirectRTTSession } from "types/common/DirectRTT";

export interface DirectRTTAction {
    type: "SET_DIRECT_RTT_SESSION" | "CLEAR_DIRECT_RTT_SESSION" | "APPEND_DIRECT_RTT_LOG" | "UPDATE_DIRECT_RTT_LOG" | "CLEAR_DIRECT_RTT_LOGS" | "SET_DIRECT_RTT_BUSY" | "SET_DIRECT_RTT_AUTO_CONNECT";
    payload?: DirectRTTSession | DirectRTTLog | boolean;
}

export const SET_DIRECT_RTT_SESSION = (session: DirectRTTSession): DirectRTTAction => {
    return {
        type: "SET_DIRECT_RTT_SESSION",
        payload: session,
    };
};

export const CLEAR_DIRECT_RTT_SESSION = (session: DirectRTTSession): DirectRTTAction => {
    return {
        type: "CLEAR_DIRECT_RTT_SESSION",
        payload: session,
    };
};

export const APPEND_DIRECT_RTT_LOG = (logToAppend: DirectRTTLog): DirectRTTAction => {
    return {
        type: "APPEND_DIRECT_RTT_LOG",
        payload: logToAppend,
    };
};

export const UPDATE_DIRECT_RTT_LOG = (logToUpdate: DirectRTTLog): DirectRTTAction => {
    return {
        type: "UPDATE_DIRECT_RTT_LOG",
        payload: logToUpdate,
    };
};

export const CLEAR_DIRECT_RTT_LOGS = (): DirectRTTAction => {
    return {
        type: "CLEAR_DIRECT_RTT_LOGS",
    };
};

export const SET_DIRECT_RTT_BUSY = (isBusy: boolean): DirectRTTAction => {
    return {
        type: "SET_DIRECT_RTT_BUSY",
        payload: isBusy,
    };
};

export const SET_DIRECT_RTT_AUTO_CONNECT = (autoConnect: boolean): DirectRTTAction => {
    return {
        type: "SET_DIRECT_RTT_AUTO_CONNECT",
        payload: autoConnect,
    };
};
