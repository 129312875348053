// Basic/Common Patterns.
export const ALPHA: RegExp = /^[a-z]*$/i;
export const NUMERIC: RegExp = /^[0-9]*$/i;

export const ALPHA_NUMERIC: RegExp = /^[a-z0-9]*$/i;

export const ALPHA_NUMERIC_UNDERSCORE: RegExp = /^[a-z0-9_]*$/i;
export const ALPHA_NUMERIC_PERIOD: RegExp = /^[a-z0-9.]*$/i;
export const ALPHA_NUMERIC_DASH: RegExp = /^[a-z0-9-]*$/i;

export const ALPHA_NUMERIC_UNDERSCORE_PERIOD: RegExp = /^[a-z0-9_.]*$/i;
export const ALPHA_NUMERIC_UNDERSCORE_DASH: RegExp = /^[a-z0-9_-]*$/i;
export const ALPHA_NUMERIC_PERIOD_DASH: RegExp = /^[a-z0-9.-]*$/i;
export const ALPHA_NUMERIC_UNDERSCORE_PERIOD_DASH: RegExp = /^[a-z0-9_.-]*$/i;

// Comma-separated list Patterns.
export const COMMA_SEPARATED_LIST_OF_ALPHA_NUMERIC_UNDERSCORE: RegExp = /^(([a-z0-9_]+)(,[a-z0-9_]+)*)?$/i;
export const COMMA_SEPARATED_LIST_OF_ALPHA_NUMERIC_UNDERSCORE_DASH: RegExp = /^(([a-z0-9_-]+)(,[a-z0-9_-]+)*)?$/i;
export const COMMA_SEPARATED_LIST_OF_ALPHA_NUMERIC_UNDERSCORE_PERIOD: RegExp = /^(([a-z0-9_.]+)(,[a-z0-9_.]+)*)?$/i;
export const COMMA_SEPARATED_LIST_OF_ALPHA_NUMERIC_UNDERSCORE_PERIOD_DASH: RegExp = /^(([a-z0-9_.-]+)(,[a-z0-9_.-]+)*)?$/i;

// Other useful patterns.
export const EMAIL: RegExp = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9]+)*(\.[a-z]{2,})$/i;
export const URL: RegExp = /^((https?:\/\/)([a-z0-9][a-z0-9-]*[a-z0-9](\.[a-z0-9][a-z0-9-]*[a-z0-9])*)(:\d+)?(\/[^\s]*)?)?$/i;
export const DOMAIN_NAME: RegExp = /^(([a-z0-9][a-z0-9-]*[a-z0-9](\.[a-z0-9][a-z0-9-]*[a-z0-9])*))?$/i;
export const PHONE_CONTACT: RegExp = /^((\+?([0-9]{1,5}))?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})?[-. ]?([0-9]{4})?$)?/i;
export const APP_ID: RegExp = /^\d{5,6}$/i;
export const ACCOUNT_NUMBER: RegExp = /^\d{7,7}$/i;
export const GUID: RegExp = /^([a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12})?$/i;
export const UUID: RegExp = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;
export const IPV4_ADDRESS: RegExp = /^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])$/i;
export const IP_RANGES: RegExp = /^[\d+\-.\s]*$/i;
export const IP_RANGE: RegExp = /^([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})?(\s?-\s?([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3}))?$/i;

// Regex Patterns for matching different CSS color formats (HEX, RGB, RGBa, HSL and HSLa).
export const HEX_PATTERN: RegExp = /^#(([0-9A-F]{6})|([0-9A-F]{3}))$/i;
export const RGB_PATTERN: RegExp = /^rgb\(\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\s*\)$/i;
export const RGBA_PATTERN: RegExp = /^rgba\(\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*(1|1\.0+|0|0\.\d+)\s*\)$/i;
export const HSL_PATTERN: RegExp = /^hsl\(\s*\d{1,3}\s*,\s*\d{1,3}%?\s*,\s*\d{1,3}%?\s*\)$/i;
export const HSLA_PATTERN: RegExp = /^hsla\(\s*\d{1,3}\s*,\s*\d{1,3}%?\s*,\s*\d{1,3}%?\s*,\s*(1|1\.0+|0|0\.\d+)\s*\)$/i;

// Regex Patterns for matching different CSS size formats (px, em and rem).
export const PX_PATTERN: RegExp = /^\d+(.\d+)?px$/i;
export const EM_PATTERN: RegExp = /^\d+(.\d+)?em$/i;
export const REM_PATTERN: RegExp = /^\d+(.\d+)?rem$/i;
export const CSS_SIZE_PATTERN: RegExp = /^\d+(.\d+)?(px|em|rem)$/i;

// Cloud Code Script Patterns.
export const FOLDER_NAME = /^[a-z0-9-_]*$/;
export const SCRIPT_NAME = /^[a-z0-9_]*$/i;
