/**
 * String utility functions.
 */
export default abstract class StringUtils {
    /**
     * Check if the supplied string is null, undefined or empty.
     *
     * Returns TRUE if the supplied string is null, undefined or empty.
     *
     * @param val
     */
    public static isNullOrEmpty(val?: any): boolean {
        if (typeof val === "boolean") return false;
        if (typeof val === "number") return false;
        if (typeof val !== "string") return val == null;

        if (val.trim() === "") {
            return true;
        }

        return false;
    }

    /**
     * Convert the supplied string into a hash code.
     *
     * Returns a hash code computed from the supplied string.
     *
     * @param val
     */
    public static hashCode(val?: string | null): number {
        let hash = 0;

        if (!val || val.length === 0) return hash;

        for (let i = 0; i < val.length; i++) {
            const char = val.charCodeAt(i);

            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32bit integer
        }

        hash = hash < 0 ? (hash *= -1) : hash;

        return hash;
    }

    /**
     * Convert the supplied string into camel-case.
     *
     * Returns a camel-case copy of the supplied string.
     *
     * @param val
     */
    public static toCamelCase(val?: string | null): string | null | undefined {
        if (!val) return val;

        return val.replace(/(?:^\w|[A-Z]|-|\b\w)/g, (ltr, idx) => (idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase())).replace(/\s+|-/g, "");
    }

    /**
     * Convert the supplied string into a number.
     *
     * Returns a number resulting from the parsing of the supplied string.
     *
     * @param val
     */
    public static toNumber(val?: any): number {
        if (typeof val === "boolean") return val ? 1 : 0;
        if (typeof val === "number") return val;
        if (typeof val !== "string") return Number.NaN;

        try {
            return val.includes(".") ? Number.parseFloat(val) : Number.parseInt(val);
        } catch (err) {
            return 0;
        }
    }

    /**
     * Determines if the supplied value represents a "truthy" value.
     *
     * Returns true if the supplied string is true, 'true', 't', 'yes', 'y' or 'on', otherwise returns false.
     *
     * @param val
     */
    public static isTruthy(val?: any): boolean {
        if (typeof val === "boolean") return val;
        if (typeof val === "number") return val !== 0;
        if (typeof val !== "string") return false;

        return ["true", "t", "yes", "y", "on"].includes(val.trim().toLowerCase());
    }

    /**
     * Determines if the supplied value represents a "numerical" value.
     *
     * Returns true if the supplied string represents an actual number, a parsable number (integer or float), otherwise returns false.
     *
     * @param val
     */
    public static isNumber(val: any): boolean {
        if (typeof val === "number" && !Number.isNaN(val)) return true;
        if (typeof val !== "string") return false;

        try {
            const parsedVal = val.includes(".") ? Number.parseFloat(val) : Number.parseInt(val);

            return !Number.isNaN(parsedVal) ? true : false;
        } catch (err) {
            return false;
        }
    }
    /**
     * Determines if the supplied value represents a "jsonObject".
     *
     * Returns true if the supplied value represents an actual jsonObject, otherwise returns false.
     *
     * @param val
     */
    public static isJsonObject(val: any): boolean {
        if (val && typeof val === "object" && !Array.isArray(val)) return true;
        if (typeof val === "string") {
            try {
                const json = JSON.parse(val);
                return json && typeof json === "object" && !Array.isArray(json);
            } catch (error) {}
        }
        return false;
    }
    /**
     * Determines if the supplied value represents a "jsonArray".
     *
     * Returns true if the supplied value represents an actual jsonArray, otherwise returns false.
     *
     * @param val
     */
    public static isJsonArray(val: any): boolean {
        if (val && Array.isArray(val)) return true;
        if (typeof val === "string") {
            try {
                return Array.isArray(JSON.parse(val));
            } catch (error) {}
        }
        return false;
    }
}
