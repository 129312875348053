import { DirectRTTAction } from "store/actions/directRTT";

import DirectRTT, { DirectRTTLog, DirectRTTSession } from "types/common/DirectRTT";

import CloneUtils from "utils/Clone";

const initialState: DirectRTT = {
    sessions: [],

    logs: [],

    isBusy: false,

    autoConnect: false,
};

const reducer = (state: DirectRTT = initialState, action: DirectRTTAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_DIRECT_RTT_SESSION":
            const sessionToSet = newState.sessions.find((item) => item.appId === (action.payload as DirectRTTSession).appId) || null;

            if (sessionToSet) {
                const sessionToSetIdx = newState.sessions.indexOf(sessionToSet);

                newState = CloneUtils.clone(state) as DirectRTT;

                newState.sessions[sessionToSetIdx] = CloneUtils.clone(action.payload);
            } else {
                newState = CloneUtils.clone(state) as DirectRTT;

                newState.sessions.push(CloneUtils.clone(action.payload));
            }

            break;
        case "CLEAR_DIRECT_RTT_SESSION":
            const sessionToClear = newState.sessions.find((item) => item.appId === (action.payload as DirectRTTSession).appId) || null;

            if (sessionToClear) {
                const sessionToClearIdx = newState.sessions.indexOf(sessionToClear);

                newState = CloneUtils.clone(state) as DirectRTT;

                newState.sessions.splice(sessionToClearIdx, 1);
            }

            break;
        case "APPEND_DIRECT_RTT_LOG":
            if (action.payload) {
                newState = CloneUtils.clone(state) as DirectRTT;

                newState.logs.push(CloneUtils.clone(action.payload));
            }

            break;
        case "UPDATE_DIRECT_RTT_LOG":
            if (action.payload) {
                const log = action.payload as DirectRTTLog;

                const target = newState.logs.find((item) => item.appId === log.appId && item.timestamp.getTime() === log.timestamp.getTime()) || null;
                const idx = target ? newState.logs.indexOf(target) : -1;

                if (idx > -1) {
                    newState = CloneUtils.clone(state) as DirectRTT;

                    newState.logs[idx] = log;
                }
            }

            break;
        case "CLEAR_DIRECT_RTT_LOGS":
            newState = CloneUtils.clone(state) as DirectRTT;

            newState.logs = [];

            break;
        case "SET_DIRECT_RTT_BUSY":
            newState = CloneUtils.clone(state) as DirectRTT;

            newState.isBusy = action.payload as boolean;

            break;
        case "SET_DIRECT_RTT_AUTO_CONNECT":
            newState = CloneUtils.clone(state) as DirectRTT;

            newState.autoConnect = action.payload as boolean;

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
