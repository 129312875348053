// Import libraries.
import React, { PropsWithChildren } from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import classnames from "classnames";

// Import components.
import Button from "components/common/button/Button";
import CustomDialog from "components/common/dialog/CustomDialog";

interface OWN_PROPS {
    className?: string;
    title: React.ReactNode;
    actionInProgress: boolean | undefined;
    actionMode?: "delete";
    confirmLabel?: React.ReactNode;
    cancelLabel?: React.ReactNode;
    onClose: (confirmed: boolean) => void;
}
interface PROPS extends PropsWithChildren<OWN_PROPS>, WithStyles<typeof styles> {}

class ConfirmActionDialog extends React.PureComponent<PROPS> {
    onClose = () => {
        this.props.onClose(false);
    };

    onConfirm = async () => {
        this.props.onClose(true);
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === "Enter") {
            this.onConfirm();
        } else if (event.code === "Escape") {
            this.onClose();
        }
    };

    render() {
        const { classes, className, actionInProgress, actionMode, children, title, cancelLabel, confirmLabel } = this.props;

        return (
            <CustomDialog
                id={"confirm-action"}
                className={classnames(classes.root, className)}
                open={true}
                ready={true}
                header={title}
                content={children}
                hideFullscreen={true}
                hideDismiss={true}
                onKeyDown={this.handleKeyDown}
                actions={
                    <>
                        <Button id={"cancel"} type={"neutral"} onClick={this.onClose}>
                            {cancelLabel ? cancelLabel : <Trans>Cancel</Trans>}
                        </Button>

                        <Button id={"confirm"} type={actionMode === "delete" ? "semantic-negative-primary" : "primary"} onClick={this.onConfirm} disabled={actionInProgress}>
                            {confirmLabel ? confirmLabel : actionMode === "delete" ? <Trans>Delete</Trans> : <Trans>Confirm</Trans>}
                        </Button>
                    </>
                }
            />
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            "& > .MuiDialog-container > .MuiDialog-paper > .MuiDialogContent-root": {
                justifyContent: "center",
            },
        },
    });

export default withStyles(styles)(ConfirmActionDialog);
