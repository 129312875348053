import User from "types/common/User";

export interface CurrentUserAction {
    type: "SET_CURRENT_USER";
    payload: User | null;
}

export const SET_CURRENT_USER = (user: User | null): CurrentUserAction => {
    return {
        type: "SET_CURRENT_USER",
        payload: user,
    };
};
