import { ClientInformationAction } from "store/actions/client";

import ClientInformation from "types/common/ClientInformation";

const initialState: ClientInformation = {
    browserClientId: null,
    tabClientId: null
};

const reducer = (state: ClientInformation = initialState, action: ClientInformationAction) => {
    let newState = state;

    switch (action.type) {
        case 'SET_CLIENT_INFORMATION':
            newState = Object.assign({}, action.payload);

            break;
        default:
            // Do nothing.
    }

    return newState;
};

export default reducer;
