import StringUtils from "utils/String";

export default interface Statistic {
    category: string;
    name: string;
    value: number;
}

export interface StatisticMetadata {
    category: string;
    name: string;
    description: string | null;
    type: string;
    minValue: number;
    maxValue: number;
    initialValue: number;
    mergeAction?: string;
    timestamp: Date | null;
}

export interface Categories {
    restricted: string[];
    unrestricted: string[];
}

export const processStatistics = (data: any): Statistic[] => {
    if (data && Array.isArray(data)) {
        return data.map((item) => {
            return {
                category: item.category,
                name: item.name,
                value: item.value,
            } as Statistic;
        });
    }

    return [];
};

export const processStatisticMetadata = (data: any): StatisticMetadata => {
    return {
        category: data.category,
        name: data.name,
        description: data.description,
        type: data.type,
        minValue: Number(data.minValue),
        maxValue: Number(data.maxValue),
        initialValue: Number(data.initialValue),
        mergeAction: data.mergeAction ? data.mergeAction : undefined,
        timestamp: data.timestamp ? new Date(data.timestamp) : null,
    } as StatisticMetadata;
};

export const processCategories = (data: any): Categories => {
    return {
        restricted: data.restricted ? data.restricted : [],
        unrestricted: data.unrestricted ? data.unrestricted.filter((category: string) => !StringUtils.isNullOrEmpty(category)) : [],
    };
};
