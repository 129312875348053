// Import libraries.
import { put, all, takeLatest } from "redux-saga/effects";

// Import types.
import { processSystemRole } from "types/common/SystemRole";

// Import redux actions.
import { SET_SYSTEM_ROLES } from "store/actions/systemRoles";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";

interface PopulateSystemRoles {
    type: "systemRole.populateSystemRoles";
}

// Attempts to populate the available system roles.
export function* populateSystemRoles(_action?: PopulateSystemRoles) {
    try {
        console.log("Populating System Roles...");

        const response: HttpResponse = yield Http.GET("admin/serveradmin/get-application-roles-list");
        if (Http.isStatusOk(response) && Array.isArray(response.data)) {
            const systemRoles = response.data.map(processSystemRole);

            yield put(SET_SYSTEM_ROLES(systemRoles));

            return true;
        }
    } catch (error: any) {
        console.error("populateSystemRoles - ERROR", error);
    }

    yield put(SET_SYSTEM_ROLES([]));

    return false;
}

export default function* root() {
    yield all([takeLatest("systemRole.populateSystemRoles", populateSystemRoles)]);
}
