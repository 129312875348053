import { Trans } from "@lingui/macro";

enum RunState {
    DELETED = "Deleted",
    DISABLED = "Disabled",
    RESTRICTED = "Restricted",
    LIMITED = "Limited",
    UNLIMITED = "Unlimited",
}
export const formatRunState = (type: RunState) => {
    switch (type) {
        case RunState.DELETED:
            return <Trans>Deleted</Trans>;
        case RunState.DISABLED:
            return <Trans>Disabled</Trans>;
        case RunState.RESTRICTED:
            return <Trans>Restricted</Trans>;
        case RunState.LIMITED:
            return <Trans>Limited</Trans>;
        case RunState.UNLIMITED:
            return <Trans>Unlimited</Trans>;
        default:
            return type || <Trans>---</Trans>;
    }
};
export const formatRunStateColor = (type?: RunState) => {
    switch (type) {
        case RunState.DELETED:
            return "var(--label-negative-color)";
        case RunState.DISABLED:
            return "var(--label-warning-color)";
        case RunState.RESTRICTED:
            return "var(--label-negative-color)";
        case RunState.LIMITED:
            return "var(--label-warning-color)";
        case RunState.UNLIMITED:
            return "var(--label-positive-color)";
        default:
            return "var(--label-default-color)";
    }
};

export default RunState;
