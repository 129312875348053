/**
 * Clone utility functions.
 */
export default abstract class CloneUtils {
    public static cloneObject(val: any): any {
        if (val != null && typeof (val) === 'object' && !Array.isArray(val)) {
            if (val instanceof Date) {
                return new Date(val);
            } else {
                const clone = Object.assign({} as any, val);

                Object.keys(clone).forEach(key => {
                    if (Array.isArray(clone[key])) {
                        clone[key] = CloneUtils.cloneArray(clone[key]);
                    } else {
                        if (typeof (clone[key]) === 'object') {
                            clone[key] = CloneUtils.cloneObject(clone[key]);
                        }
                    }
                });
    
                return clone;
            }
        }

        return null;
    }

    public static cloneArray(val: any[] | null): any[] | null {
        if (val != null && Array.isArray(val)) {
            const clone: any[] = [];

            val.forEach(item => {
                if (val instanceof Date) {
                    clone.push(new Date(val));
                } else {
                    if (Array.isArray(item)) {
                        clone.push(CloneUtils.cloneArray(item));
                    } else {
                        if (typeof (item) === 'object') {
                            clone.push(CloneUtils.cloneObject(item));
                        } else {
                            clone.push(item)
                        }
                    }
                }
            });

            return clone;
        }

        return null;
    }

    public static clone(val: any): any {
        if (val != null) {
            if (Array.isArray(val)) {
                return CloneUtils.cloneArray(val);
            } else {
                if (typeof (val) === 'object') {
                    if (val instanceof Date) {
                        return new Date(val);
                    } else {
                        return CloneUtils.cloneObject(val);
                    }
                } else {
                    return val;
                }
            }
        }

        return null;
    }
}
