// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Divider, Theme } from "@mui/material";

import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

// Import types.
import AppInfo from "types/models/AppInfo";
import BillingPlan, { FeaturesList } from "types/models/BillingPlan";
import { RadioButtonFieldOptions } from "components/common/form/fields/RadioButtonField";
import { LabelledRadioOption } from "types/common/LabelledRadioOption";
import Session from "types/common/Session";
import PortalState from "types/store";

// Import components.
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import CustomDialog from "components/common/dialog/CustomDialog";
import FieldWrapper from "components/common/form/FieldWrapper";
import Button from "components/common/button/Button";
import Tabs from "components/common/tabs";

// Import services.
import Services from "../services";

// Import utilities.
import NumberFormatter from "utils/formatters/Number";
import PublishState from "types/enums/PublishState";

// Import icons.
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";

interface OWN_PROPS {
    context: "super" | "team";
    appInfo: AppInfo;
    onClose: (submitted: boolean) => void;
}

interface STATE_PROPS {
    session: Session;
}
interface DISPATCH_PROPS {
    populateAvailableApps: () => void;
}

interface PROPS extends OWN_PROPS, STATE_PROPS, DISPATCH_PROPS, WithStyles<typeof styles> {}

interface STATE {
    billingPlans: BillingPlan[];
    originalPlanCode: string | null;
    currentPlanCode: string | null;
    activeTabIndex: number;
    isBusy: boolean;
}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        populateAvailableApps: () => dispatch({ type: "app.populateAvailableApps" }),
    };
};

class ChooseBillingPlanDialog extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        billingPlans: [],
        originalPlanCode: null,
        currentPlanCode: null,
        activeTabIndex: 0,
        isBusy: false,
    };

    async componentDidMount() {
        const { appInfo, context } = this.props;

        try {
            this.setState({ isBusy: true });

            const promiseBatch = await Promise.all([Services.getBillingPlans(context === "super"), Services.getCurrentBillingPlanCode(appInfo.appId)]);

            const currentPlan = promiseBatch[0].find((item) => item.planCode === appInfo.billing_plan?.planCode);

            const activePlanType = currentPlan?.isPlusPlan === 1 && currentPlan?.isBulkPlan !== 1 ? "plus-plans" : currentPlan?.isBulkPlan === 1 ? "bulk-plans" : "basic-plans";

            const plansTagConfig = this.getTagsConfig(promiseBatch[0]);
            let activeTabIndex = 0;
            if (plansTagConfig.length) {
                activeTabIndex = plansTagConfig.findIndex((tab) => tab.id === activePlanType) || 0;
            }

            this.setState({ billingPlans: promiseBatch[0], originalPlanCode: promiseBatch[1], currentPlanCode: promiseBatch[1], activeTabIndex, isBusy: false });
        } catch (error: any) {
            toast.error("[" + error.errorCode + "] - " + error.errorMessage);

            this.setState({ isBusy: false });
        }
    }
    handleTabChange = (index: number) => {
        this.setState({ activeTabIndex: index });
    };
    handleDismiss = () => this.props.onClose(false);

    handleConfirm = async () => {
        const { appInfo, session } = this.props;
        const { billingPlans, currentPlanCode } = this.state;

        const billingPlan = billingPlans.find((item) => item.planCode === currentPlanCode);

        if (!billingPlan) return;

        this.setState({ isBusy: true });

        try {
            if (!session.isSuper && appInfo.publishState !== PublishState.LIVE && billingPlan.isLive) {
                await Services.setBillingPlanLive(appInfo.appId, billingPlan.planCode);
            } else {
                await Services.swapBillingPlan(appInfo.appId, billingPlan.planCode);
            }

            toast.success(<Trans>Successfully Swapped Plan!</Trans>);

            this.setState({ isBusy: false }, () => {
                this.props.populateAvailableApps();
                this.props.onClose(true);
            });
        } catch (error: any) {
            toast.error("[" + error.errorCode + "] - " + error.errorMessage);

            this.setState({ isBusy: false });
        }
    };

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "planCode":
                this.setState({ currentPlanCode: value });

                break;
            default:
            // Do nothing.
        }
    };

    isFormValid = () => {
        const { billingPlans, currentPlanCode } = this.state;

        const billingPlan = billingPlans.find((item) => item.planCode === currentPlanCode);

        if (!billingPlan) return false;

        return true;
    };

    hasUnsavedChanges = () => this.state.currentPlanCode !== this.state.originalPlanCode;

    getBillingPlansOptions = (billingPlan: BillingPlan[], type: string) => {
        const { classes, context, appInfo } = this.props;
        const { currentPlanCode } = this.state;
        return billingPlan
            .sort((a, b) => a.basePrice - b.basePrice)
            .filter(
                (plan: BillingPlan) =>
                    (context === "team" && appInfo.publishState === PublishState.LIVE ? plan.isLive : true) &&
                    ((type === "basic-plans" && plan.isPlusPlan !== 1 && plan.isBulkPlan !== 1) || (type === "plus-plans" && plan.isPlusPlan === 1 && plan.isBulkPlan !== 1) || (type === "bulk-plans" && plan.isBulkPlan === 1))
            )
            .map((plan, idx) => {
                return {
                    value: plan.planCode,
                    label: (
                        <span key={idx}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", color: "var(--primary-color, inherit)" }}>
                                <Typography variant={"h6"} style={{ fontSize: "1rem" }}>
                                    {plan.name}
                                </Typography>
                                <div style={{ display: "flex", alignItems: "baseline" }}>
                                    <Typography variant={"h6"}>{plan.basePrice > 0 ? <>${NumberFormatter.formatInteger(plan.basePrice)}</> : <>{plan.planCode === "bcnomin2" ? <>$0</> : <Trans>Free</Trans>}</>}</Typography>
                                    <Typography>
                                        /<Trans>month + usage</Trans>
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.planDetailSmall}>{plan.planCode === currentPlanCode && this.getFeaturesList(plan)}</div>
                        </span>
                    ),
                } as LabelledRadioOption;
            });
    };
    getFeaturesList = (selectedPlan: BillingPlan, type?: string) => {
        const { classes } = this.props;
        const featureList: FeaturesList[] = [
            {
                key: "coreFeatures",
                label: <Trans>Core Features</Trans>,
            },
            {
                key: "includesEnterprise",
                label: <Trans>Enterprise Features</Trans>,
            },
            {
                key: "includesRTT",
                label: <Trans>Real-time Tech (RTT)</Trans>,
            },
            {
                key: "includesHosting",
                label: <Trans>Custom App Servers</Trans>,
            },
            {
                key: "maxDau",
                label: <Trans>Daily Active Users</Trans>,
            },
            {
                key: "maxAccounts",
                label: <Trans>Accounts</Trans>,
            },
            {
                key: "baseApiIncluded",
                label: <Trans>Free API Calls</Trans>,
            },
        ];
        return (
            <div className={classes.featureDetail}>
                {featureList.map((feature: FeaturesList, idx) => {
                    const { key, label } = feature;
                    return (
                        <span key={idx}>
                            {key === "maxDau" && <Divider style={{ margin: "0.3125rem" }} />}
                            <div style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
                                {key === "coreFeatures" || selectedPlan[key] ? <CheckCircleIcon style={{ color: "var(--primary-color, inherit)" }} /> : <BlockIcon />}
                                {key !== "coreFeatures" && typeof selectedPlan[key] === "number" && <span>{Number(selectedPlan[key]) > 0 ? this.convertNumber(selectedPlan, key) : <Trans>Unlimited</Trans>}</span>}
                                {label}
                                {selectedPlan.bulkSavings > 0 && key === "baseApiIncluded" && (
                                    <span style={{ color: "var(--negitive-color, inherit)" }}>
                                        (<Trans>Save $</Trans>
                                        {NumberFormatter.formatInteger(selectedPlan.bulkSavings)})
                                    </span>
                                )}
                            </div>
                        </span>
                    );
                })}
                {type === "bulk-plans" && (
                    <div style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
                        <CheckCircleIcon style={{ color: "var(--primary-color, inherit)" }} />
                        <Trans>Additional usage discounts</Trans>
                    </div>
                )}
            </div>
        );
    };
    getTagsConfig = (billingPlans: BillingPlan[]) => {
        const { classes } = this.props;
        const { currentPlanCode, isBusy } = this.state;
        const selectedPlan = billingPlans.find((item) => item.planCode === currentPlanCode);

        const basicPlans: LabelledRadioOption[] = this.getBillingPlansOptions(billingPlans, "basic-plans");
        const plusPlans: LabelledRadioOption[] = this.getBillingPlansOptions(billingPlans, "plus-plans");
        const bulkPlans: LabelledRadioOption[] = this.getBillingPlansOptions(billingPlans, "bulk-plans");

        const configs = [
            ...(basicPlans.length >= 1
                ? [
                      {
                          id: "basic-plans",
                          label: <Trans>Basic Plans</Trans>,
                          component: (
                              <div style={{ display: "flex", flex: "1 1 auto" }}>
                                  <FieldWrapper
                                      name={"planCode"}
                                      type={"radiobutton"}
                                      value={currentPlanCode}
                                      className={classes.field}
                                      controlStyle={{ height: "unset" }}
                                      onChange={this.handleChange}
                                      disabled={isBusy}
                                      options={
                                          {
                                              optionsDirection: "column",
                                              radioAlignment: "flex-start",
                                              contained: true,
                                              multiline: true,

                                              options: this.getBillingPlansOptions(billingPlans, "basic-plans"),
                                          } as RadioButtonFieldOptions
                                      }
                                  />
                                  <div className={classes.planDetail}>{selectedPlan && this.getFeaturesList(selectedPlan)}</div>
                              </div>
                          ),
                      },
                  ]
                : []),
            ...(plusPlans.length >= 1
                ? [
                      {
                          id: "plus-plans",
                          label: <Trans>Plus Plans</Trans>,
                          component: (
                              <div style={{ display: "flex", flex: "1 1 auto" }}>
                                  <FieldWrapper
                                      name={"planCode"}
                                      type={"radiobutton"}
                                      value={currentPlanCode}
                                      className={classes.field}
                                      controlStyle={{ height: "unset" }}
                                      onChange={this.handleChange}
                                      disabled={isBusy}
                                      options={
                                          {
                                              optionsDirection: "column",
                                              radioAlignment: "flex-start",
                                              contained: true,
                                              multiline: true,
                                              options: this.getBillingPlansOptions(billingPlans, "plus-plans"),
                                          } as RadioButtonFieldOptions
                                      }
                                  />
                                  <div className={classes.planDetail}>{selectedPlan && this.getFeaturesList(selectedPlan)}</div>
                              </div>
                          ),
                      },
                  ]
                : []),
            ...(bulkPlans.length >= 1
                ? [
                      {
                          id: "bulk-plans",
                          label: <Trans>Bulk Plans</Trans>,
                          component: (
                              <div style={{ display: "flex", flex: "1 1 auto" }}>
                                  <FieldWrapper
                                      name={"planCode"}
                                      type={"radiobutton"}
                                      value={currentPlanCode}
                                      className={classes.field}
                                      controlStyle={{ height: "unset" }}
                                      onChange={this.handleChange}
                                      disabled={isBusy}
                                      options={
                                          {
                                              optionsDirection: "column",
                                              radioAlignment: "flex-start",
                                              contained: true,
                                              multiline: true,
                                              options: this.getBillingPlansOptions(billingPlans, "bulk-plans"),
                                          } as RadioButtonFieldOptions
                                      }
                                  />
                                  <div className={classes.planDetail}>{selectedPlan && this.getFeaturesList(selectedPlan, "bulk-plans")}</div>
                              </div>
                          ),
                      },
                  ]
                : []),
        ];
        return configs;
    };
    convertNumber = (selectedPlan: BillingPlan, key: "includesEnterprise" | "includesRTT" | "includesHosting" | "maxDau" | "maxAccounts" | "baseApiIncluded") => {
        if (key === "baseApiIncluded") {
            return Number(selectedPlan[key]) >= 1000 ? (
                <>
                    {NumberFormatter.formatInteger(Number(selectedPlan[key]) / 1000)}
                    <Trans>Billion</Trans>
                </>
            ) : (
                <>{NumberFormatter.formatInteger(selectedPlan[key])}M</>
            );
        }
        return <>{selectedPlan[key]}</>;
    };
    render() {
        const { appInfo } = this.props;
        const { billingPlans, isBusy, activeTabIndex } = this.state;

        const currentPlan = billingPlans.find((item) => item.planCode === appInfo.billing_plan?.planCode);

        return (
            <CustomDialog
                id={"choose-billing-plan"}
                open={true}
                ready={!isBusy}
                onClose={this.handleDismiss}
                header={<Trans>Choose Billing Plan - {appInfo.appName}</Trans>}
                content={
                    <>
                        <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "column", padding: "0.3125rem", marginBottom: "0.625rem" }}>
                            {currentPlan && (
                                <Typography>
                                    <Trans>
                                        Your current plan is <b>{currentPlan.name}</b>.
                                    </Trans>
                                </Typography>
                            )}

                            <Typography>
                                <Trans>Once you go live, you will not be able to return to Development-grade plans.</Trans>
                            </Typography>
                        </div>
                        <Tabs orientation={"horizontal"} configs={this.getTagsConfig(billingPlans)} value={activeTabIndex} onTabChanged={this.handleTabChange} />
                    </>
                }
                actions={
                    <>
                        <Link data-id={"learn-more"} href={"http://getbraincloud.com/pricing"} target={"_blank"} style={{ marginLeft: "1.25rem" }}>
                            <Trans>Learn more about brainCloud plans!</Trans>
                        </Link>

                        <div style={{ flex: "0 0 auto", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                            <Button id={"cancel"} type={"neutral"} onClick={this.handleDismiss} disabled={isBusy}>
                                {this.hasUnsavedChanges() ? <Trans>Cancel</Trans> : <Trans>Close</Trans>}
                            </Button>

                            <Button id={"confirm"} type={"primary"} onClick={this.handleConfirm} disabled={isBusy || !this.isFormValid() || !this.hasUnsavedChanges()}>
                                <Trans>Confirm</Trans>
                            </Button>
                        </div>
                    </>
                }
            />
        );
    }
}
const styles = (theme: Theme) =>
    createStyles({
        field: {
            flex: "0 0 auto",
            alignItems: "flex-start !important",
            padding: "2rem",
            "& .MuiFormGroup-root": {
                gap: "1rem",
            },
            "& label": {
                width: "25rem !important",
            },
            [theme.breakpoints.down("lg")]: {
                flex: "1 1 auto",
                "& label": {
                    width: "auto !important",
                },
            },
        },
        planDetail: {
            display: "block",
            [theme.breakpoints.down("lg")]: { display: "none" },
        },
        planDetailSmall: {
            display: "none",
            [theme.breakpoints.down("lg")]: { display: "block" },
        },
        featureDetail: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            padding: "2rem",
            gap: "0.25rem",
            [theme.breakpoints.down("lg")]: { padding: "0 0 2rem 0" },
        },
    });
export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChooseBillingPlanDialog));
