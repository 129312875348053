import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";

/**
 * Clipboard utility functions.
 */
export default abstract class ClipboardUtils {
    public static prepareForCopy(value: any) {
        if (value != null) {
            if (typeof value === "string") {
                try {
                    return JSON.stringify(JSON.parse(value), undefined, 2);
                } catch (error) {
                    return "" + value;
                }
            } else {
                if (typeof value === "object") {
                    return JSON.stringify(value, undefined, 2);
                } else {
                    return "" + value;
                }
            }
        } else {
            return "";
        }
    }

    public static writeText(value: any) {
        navigator.clipboard
            .writeText(ClipboardUtils.prepareForCopy(value))
            .then(() => {
                toast.success(<Trans>Copied to clipboard!</Trans>);
            })
            .catch((error) => {
                console.error("Could not copy to clipboard", error);

                toast.error(<Trans>Copy to clipboard Failed!</Trans>);
            });
    }

    public static async writeTextAsync(p: Promise<any>) {
        try {
            if (typeof ClipboardItem !== "undefined") {
                // Use ClipboardItem if available
                const item = new ClipboardItem({
                    "text/plain": p.then((result) => {
                        if (!result) {
                            return Promise.resolve(new Blob([ClipboardUtils.prepareForCopy("")], { type: "text/plain" }));
                        }

                        return Promise.resolve(new Blob([ClipboardUtils.prepareForCopy(result)], { type: "text/plain" }));
                    }),
                });

                await navigator.clipboard.write([item]);
            } else {
                // Fallback for browsers without ClipboardItem support
                const result = await p;
                const textToCopy = ClipboardUtils.prepareForCopy(result || "");

                await navigator.clipboard.writeText(textToCopy);
            }
            toast.success(<Trans>Copied to clipboard!</Trans>);
        } catch (error) {
            console.error("Could not copy to clipboard", error);

            toast.error(<Trans>Copy to clipboard Failed!</Trans>);
        }
    }
}
