import ApplicationInformation from "types/common/ApplicationInformation";

export interface ApplicationInformationAction {
    type: "SET_APPLICATION_INFORMATION";
    payload: ApplicationInformation;
}

export const SET_APPLICATION_INFORMATION = (applicationInformation: ApplicationInformation): ApplicationInformationAction => {
    return {
        type: "SET_APPLICATION_INFORMATION",
        payload: applicationInformation,
    };
};
