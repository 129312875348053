// Import libraries.
import { put, all, takeLatest, select } from "redux-saga/effects";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import { processScreenSettings } from "types/common/ScreenSettings";

// Import redux actions.
import { SET_SCREEN_SETTINGS } from "store/actions/screenSettings";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";

interface PopulateScreenSettings {
    type: "screenSetting.populateScreenSettings";
}

// Attempts to populate the available screen settings that are applicable to the current user and app/game.
export function* populateScreenSettings(_action?: PopulateScreenSettings) {
    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    try {
        console.log("Populating Screen Settings...");

        if (session.appId) {
            const response: HttpResponse = yield Http.GET("admin/design/get-application-screen-enabled-settings");
            if (Http.isStatusOk(response) && Array.isArray(response.data)) {
                const screenSettings = response.data.map(processScreenSettings);

                screenSettings.sort((a, b) => a.id.localeCompare(b.id));

                yield put(SET_SCREEN_SETTINGS(screenSettings));

                return true;
            }
        }
    } catch (error: any) {
        console.error("populateSceenSettings - ERROR", error);
    }

    yield put(SET_SCREEN_SETTINGS([]));

    return false;
}

export default function* root() {
    yield all([takeLatest("screenSetting.populateScreenSettings", populateScreenSettings)]);
}
