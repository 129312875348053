import CookieUtils from "./Cookie";
import LocalStorageUtils from "./LocalStorage";
import SessionStorageUtils from "./SessionStorage";
import StringUtils from "./String";

export enum CookieConsentLevel {
    STRICTLY_NECESSARY = "strictly-necessary",
    FUNCTIONALITY = "functionality",
    TRACKING = "tracking",
    TARGETING = "targeting",
}

/**
 * CookieConsent utility functions.
 */
export default abstract class CookieConsentUtils {
    static COOKIE_CONSENT_LEVEL = "cookie_consent_level";
    static COOKIE_CONSENT_USER_ACCEPTED = "cookie_consent_user_accepted";
    static COOKIE_CONSENT_USER_CONSENT_TOKEN = "cookie_consent_user_consent_token";

    public static isLevelPermitted(level: CookieConsentLevel): boolean {
        const consetLevels = CookieUtils.getCookie(CookieConsentUtils.COOKIE_CONSENT_LEVEL);

        if (typeof consetLevels !== "object") return true;

        return consetLevels[level] === true;
    }

    public static isUserAccepted(): boolean {
        const raw = CookieUtils.getCookie(CookieConsentUtils.COOKIE_CONSENT_USER_ACCEPTED);

        return StringUtils.isTruthy(raw);
    }

    public static clearUnauthorizedData() {
        if (!CookieConsentUtils.isLevelPermitted(CookieConsentLevel.STRICTLY_NECESSARY)) {
            // These always remain present.
        }

        if (!CookieConsentUtils.isLevelPermitted(CookieConsentLevel.FUNCTIONALITY)) {
            // Delete items from session storage.
            SessionStorageUtils.deleteItem(SessionStorageUtils.TAB_CLIENT_ID);

            // Delete items from local storage.
            LocalStorageUtils.deleteItem(LocalStorageUtils.BROWSER_CLIENT_ID);
            LocalStorageUtils.deleteItem(LocalStorageUtils.PORTAL_SESSION);

            const localStorageKeys = LocalStorageUtils.getItemKeys();

            localStorageKeys.forEach((key) => {
                if (key.startsWith("lastteam_autoselect_")) LocalStorageUtils.deleteItem(key);
                if (key.startsWith("lastteam_")) LocalStorageUtils.deleteItem(key);
                if (key.startsWith("lastalias_")) LocalStorageUtils.deleteItem(key);
                if (key.startsWith("lastgame_")) LocalStorageUtils.deleteItem(key);
                if (key.startsWith("recently_viewed_players")) LocalStorageUtils.deleteItem(key);
                if (key.endsWith("favorites")) LocalStorageUtils.deleteItem(key);
            });
        }

        if (!CookieConsentUtils.isLevelPermitted(CookieConsentLevel.TRACKING)) {
            // Nothing to do.
        }

        if (!CookieConsentUtils.isLevelPermitted(CookieConsentLevel.TARGETING)) {
            // Nothing to do.
        }
    }
}
