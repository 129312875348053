// Import libraries.
import { useCallback, useEffect, useRef } from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";

// Import types.
import { ColumnDefinition, RowData } from "../../types";

// Import components.
import { TableCell } from "@mui/material";

interface OWN_PROPS {
    id: string;

    rowIdx: number;
    colIdx: number;

    className?: string;

    height?: string | number;
    width?: string | number;

    definition: ColumnDefinition;

    row: RowData;

    onClick?: (row: RowData, rowIdx: number, colIdx: number) => void;
    onChange?: (columnId: string, newValue: any, row: RowData) => void;

    onHeightCalculated: ((rowIdx: number, colIdx: number, height: number) => void) | null;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

const Cell = (props: PROPS) => {
    const { classes, id, rowIdx, colIdx, className, height, width, definition, row, onClick, onChange, onHeightCalculated } = props;

    const innerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (onHeightCalculated && innerRef.current) {
            onHeightCalculated(rowIdx, colIdx, innerRef.current.offsetHeight);
        }
    });

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (!event.isDefaultPrevented() && onClick) {
                onClick(row, rowIdx, colIdx);
            }
        },
        [row, rowIdx, colIdx, onClick]
    );

    const cellWidth = width ? width : definition.width;

    return (
        <TableCell
            id={id}
            data-name={definition.id}
            data-index={colIdx}
            className={classnames(classes.root, className)}
            style={{ minWidth: cellWidth, width: cellWidth, maxWidth: cellWidth, cursor: onClick ? "pointer" : "default" }}
            onClick={handleClick}
        >
            <div className={classes.wrapper} style={{ minHeight: "1em", height: height, maxHeight: height, width: width, maxWidth: width }}>
                <div
                    ref={innerRef}
                    style={{
                        flex: "1 1 auto",

                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: definition.align,

                        width: "inherit",

                        zIndex: 3,

                        padding: definition.disableCellPadding ? 0 : "0.3125em",

                        marginTop: "auto",
                        marginBottom: "auto",

                        overflow: "hidden",
                    }}
                >
                    {definition.format ? definition.format(row[definition.id], row, onChange) : "" + row[definition.id]}
                </div>

                <div className={"stripeOverlay"} style={{ zIndex: 1 }}></div>
                <div className={"hoverOverlay"} style={{ zIndex: 2 }}></div>
            </div>
        </TableCell>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontSize: "inherit",

            padding: 0,
        },
        wrapper: {
            position: "relative",

            width: "100%",

            display: "flex",
            alignItems: "flex-start",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",

            "& *": {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },

            "& .MuiTypography-root": {
                fontSize: "inherit",
            },
        },
    });

export default withStyles(styles)(Cell);
