// Import types used by this module.
import SystemRole from "types/common/SystemRole";

export interface SystemRolesAction {
    type:
        'SET_SYSTEM_ROLES'
    payload: SystemRole[]
}

export const SET_SYSTEM_ROLES = (systemRoles: SystemRole[]) : SystemRolesAction => {
    return {
        type: 'SET_SYSTEM_ROLES',
        payload: systemRoles
    };
}
