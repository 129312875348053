import { SystemRolesAction } from "store/actions/systemRoles";

import SystemRole from "types/common/SystemRole";

const initialState: SystemRole[] = [];

const reducer = (state: SystemRole[] = initialState, action: SystemRolesAction) => {
    let newState = state;

    switch (action.type) {
        case 'SET_SYSTEM_ROLES':
            newState = [...action.payload];

            break;
        default:
            // Do nothing.
    }

    return newState;
};

export default reducer;
