import EnvironmentInformation from "types/common/EnvironmentInformation";

export interface EnvironmentInformationAction {
    type: "SET_ENVIRONMENT_INFORMATION";
    payload: EnvironmentInformation;
}

export const SET_ENVIRONMENT_INFORMATION = (environmentInformation: EnvironmentInformation): EnvironmentInformationAction => {
    return {
        type: "SET_ENVIRONMENT_INFORMATION",
        payload: environmentInformation,
    };
};
