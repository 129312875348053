import { Trans } from "@lingui/macro";

import Statistic, { processStatistics } from "types/models/StatisticsInfo";

export interface PlayerStatistics {
    experienceLevel: number;
    experiencePoints: number;
    statistics: Statistic[];
}

export const processPlayerStatistics = (data: any): PlayerStatistics => {
    return {
        experiencePoints: Number.parseInt(data.experiencePoints),
        experienceLevel: Number.parseInt(data.experienceLevel),
        statistics: processStatistics(data.stats),
    };
};

export interface PlayerRelation {
    type: RelationType;
    gameId: string;
    gameName: string | null;
    levelName: string | null;
    playerId: string;
    playerName: string | null;
    emailAddress: string | null;
}

export const processPlayerRelation = (data: any): PlayerRelation => {
    return {
        type: parseRelationType(data.type),
        gameId: data.gameId || "",
        gameName: data.gameName || null,
        levelName: data.levelName || null,
        playerId: data.playerId || "",
        playerName: data.playerName || null,
        emailAddress: data.emailAddress || null,
    };
};

export interface FlaggedData {
    gameId: string;
    playerId: string;
    isActive: boolean;
    notes: string;
    updatedAt?: Date | null;
}

export const processFlaggedData = (data: any): FlaggedData => {
    return {
        gameId: data.gameId || "",
        playerId: data.playerId || "",
        isActive: data.isActive === true,
        notes: data.notes || "",
        updatedAt: data.updatedAt ? new Date(data.updatedAt) : null,
    };
};

export enum RelationType {
    PARENT = "Parent",
    CHILD = "Child",
    PEER = "Peer",
    PEER_CLIENT = "Peer Client",
    UNKNOWN = "Unknown",
}

export const parseRelationType = (value: any) => {
    switch (value) {
        case "Parent":
            return RelationType.PARENT;
        case "Child":
            return RelationType.CHILD;
        case "Peer":
            return RelationType.PEER;
        case "Peer Client":
            return RelationType.PEER_CLIENT;
        default:
            return RelationType.UNKNOWN;
    }
};

export const formatRelationType = (value: RelationType | null) => {
    switch (value) {
        case RelationType.PARENT:
            return <Trans>Parent</Trans>;
        case RelationType.CHILD:
            return <Trans>Child</Trans>;
        case RelationType.PEER:
            return <Trans>Peer</Trans>;
        case RelationType.PEER_CLIENT:
            return <Trans>Peer Client</Trans>;
        default:
            return <Trans>Unknown</Trans>;
    }
};
