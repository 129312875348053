// Import types.
import PortalBranding from "types/common/PortalBranding";

/**
 * Branding utility functions.
 */
export default abstract class BrandingUtils {
    /**
     * Convert a PortalBranding instance into injectable CSS content.
     *
     * @param name
     */
    public static generatePortalBrandingCSSContent(data?: PortalBranding | null): string | null {
        if (!data) return null;

        /* Support for customized scroll bars */
        let result = "*::-webkit-scrollbar {\n";
        if (data["scrollbar-size"]) result = result + "width: " + data["scrollbar-size"] + ";\n";
        if (data["scrollbar-size"]) result = result + "height: " + data["scrollbar-size"] + ";\n";
        result = result + "}\n";

        result = result + "*::-webkit-scrollbar-track {\n";
        if (data["scrollbar-track-color"]) result = result + "background-color: " + data["scrollbar-track-color"] + ";\n";
        if (data["scrollbar-track-color"]) result = result + "-box-shadow: " + data["scrollbar-track-color"] + ";\n";
        if (data["scrollbar-track-color"]) result = result + "-webkit-box-shadow: " + data["scrollbar-track-color"] + ";\n";
        result = result + "}\n";

        result = result + "*::-webkit-scrollbar-thumb {\n";
        if (data["scrollbar-thumb-color"]) result = result + "background-color: " + data["scrollbar-thumb-color"] + ";\n";
        result = result + "outline: 1px solid transparent;\n";
        if (data["scrollbar-thumb-radius"]) result = result + "border-radius: " + data["scrollbar-thumb-radius"] + ";\n";
        result = result + "}\n";

        /* Support for all other CSS variables */
        result = result + "\n:root {\n";
        Object.keys(data).forEach((key) => {
            // We skip ANY field that starts with and ends with "__" as these repreesent other types of branding data (like logo urls or the custom moncao theme).
            if (!(key.startsWith("__") && key.endsWith("__"))) {
                const value = data[key];

                if (value) {
                    result = result + "--" + key + ": " + value + ";\n";
                }
            }
        });
        result = result + "}\n";

        // Return the resulting CSS content.
        return result;
    }
}
