import { Trans } from "@lingui/macro";

enum CompanyStatus {
    FREE = "Free",
    BILLABLE = "Billable",
    BILLING = "Billing",
    RESTRICTED = "Restricted",
    CANCELLED = "Cancelled",
}

export const formatCompanyStatusLabel = (companyStatus?: CompanyStatus | null, raw?: boolean) => {
    if (raw) {
        switch (companyStatus) {
            case CompanyStatus.FREE:
                return <Trans>Free</Trans>;
            case CompanyStatus.BILLABLE:
                return <Trans>Billable</Trans>;
            case CompanyStatus.BILLING:
                return <Trans>Billing</Trans>;
            case CompanyStatus.RESTRICTED:
                return <Trans>Restricted</Trans>;
            case CompanyStatus.CANCELLED:
                return <Trans>Cancelled</Trans>;
            default:
                return companyStatus || <Trans>---</Trans>;
        }
    } else {
        switch (companyStatus) {
            case CompanyStatus.FREE:
                return <Trans>Free Development</Trans>;
            case CompanyStatus.BILLABLE:
                return <Trans>Ready to go live</Trans>;
            case CompanyStatus.BILLING:
                return <Trans>Live (good standing)</Trans>;
            case CompanyStatus.RESTRICTED:
                return <Trans>Live (restricted)</Trans>;
            case CompanyStatus.CANCELLED:
                return <Trans>Service Terminate</Trans>;
            default:
                return companyStatus || <Trans>---</Trans>;
        }
    }
};

export const formatCompanyStatusColor = (companyStatus?: CompanyStatus | null) => {
    switch (companyStatus) {
        case CompanyStatus.FREE:
            return "var(--label-default-color)";
        case CompanyStatus.BILLABLE:
            return "var(--label-default-color)";
        case CompanyStatus.BILLING:
            return "var(--label-positive-color)";
        case CompanyStatus.RESTRICTED:
            return "var(--label-warning-color)";
        case CompanyStatus.CANCELLED:
            return "var(--label-negative-color)";
        default:
            return "var(--label-default-color)";
    }
};

export default CompanyStatus;
