// Import libraries.
import PortalComponent from "framework/PortalComponent";
import { connect } from "react-redux";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import AppInfo from "types/models/AppInfo";
import DialogMode from "types/enums/DialogMode";

// Import components.
import { Link, Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";
import VerifyAppNameDialog from "components/common/dialog/VerifyAppNameDialog";
import IconButton from "components/common/button/IconButton";
import FieldWrapper from "components/common/form/FieldWrapper";
import { SwitchFieldOptions } from "components/common/form/fields/SwitchField";

// Import icons.
import LockedIcon from "@mui/icons-material/Lock";
import UnlockedIcon from "@mui/icons-material/LockOpen";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// Import utilities.
import { getScreenDataForId } from "assets/data/screens";

interface STATE_PROPS {
    session: Session;
    availableApps: AppInfo[];
}
interface DISPATCH_PROPS {
    checkLiveLock: (privilegeId?: string | null) => void;
    toggleLiveLock: (appName?: string | null) => void;
}
interface OWN_PROPS {}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        availableApps: state.availableApps,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        checkLiveLock: (privilegeId?: string | null) => dispatch({ type: "app.checkLiveLock", payload: { privilegeId } }),
        toggleLiveLock: (appName?: string | null) => dispatch({ type: "app.toggleLiveLock", payload: { appName } }),
    };
};

interface STATE {
    dialogMode: DialogMode.READ | null;
}

class LiveLock extends PortalComponent<PROPS> {
    state: Readonly<STATE> = {
        dialogMode: null,
    };

    componentDidMount(): void {
        const screenInfo = getScreenDataForId(this.props.location.pathname);

        if (screenInfo?.pageKey) {
            this.props.checkLiveLock(screenInfo.pageKey);
        }
    }

    componentDidUpdate(prevProps: PROPS): void {
        if (prevProps.session.appId !== this.props.session.appId || prevProps.location.pathname !== this.props.location.pathname) {
            const screenInfo = getScreenDataForId(this.props.location.pathname);

            if (screenInfo?.pageKey) {
                this.props.checkLiveLock(screenInfo.pageKey);
            }
        }
    }

    onChange = () => {
        const { session } = this.props;

        if (session.disableLiveLock) return;

        if (!session.isLiveLocked) {
            this.props.toggleLiveLock(null);
        } else {
            this.setState({ dialogMode: DialogMode.READ });
        }
    };

    handleClose = (submitted?: boolean, appName?: string | null) => {
        this.setState({ dialogMode: null }, () => {
            if (submitted) this.props.toggleLiveLock(appName);
        });
    };

    render() {
        const { location, classes, session, availableApps } = this.props;
        const { dialogMode } = this.state;

        const isAppPath = location.pathname.startsWith("/app");
        const isUserPath = location.pathname.startsWith("/user");

        const targetApp = availableApps.find((item) => item.appId === session.appId) || null;

        if (!isAppPath || !targetApp || !targetApp.isLive || session.disableLiveLock || isUserPath) return null;

        return (
            <div className={classes.root}>
                <div
                    className={classes.row}
                    style={{
                        color: session.isLiveLocked ? "var(--live-lock-banner-locked-color)" : "var(--live-lock-banner-unlocked-color)",
                        backgroundColor: session.isLiveLocked ? "var(--live-lock-banner-locked-background-color)" : "var(--live-lock-banner-unlocked-background-color)",
                        border: session.isLiveLocked ? "1px solid var(--live-lock-banner-locked-border-color)" : "1px solid var(--live-lock-banner-unlocked-border-color)",
                    }}
                >
                    <Typography style={{ display: "flex", alignItems: "center" }}>
                        {session.isLiveLocked ? (
                            <>
                                <CheckCircleOutlineIcon style={{ width: "1em", height: "1em", margin: "0 0.3125em", color: "var(--live-lock-banner-locked-border-color)" }} />
                                <span className={classes.info}>
                                    <Trans>
                                        <strong>App is Live & Locked</strong> - Unlock to Edit
                                    </Trans>
                                </span>
                            </>
                        ) : (
                            <>
                                <WarningAmberIcon style={{ width: "1em", height: "1em", margin: "0 0.3125em" }} />
                                <span className={classes.info}>
                                    <Trans>
                                        <strong>App is Live & Unlocked</strong> - Edit with caution!
                                    </Trans>
                                </span>
                            </>
                        )}
                    </Typography>
                    <FieldWrapper
                        type={"switch"}
                        name={"appLock"}
                        value={!session.isLiveLocked}
                        className={session.isLiveLocked ? classes.locked : classes.unlocked}
                        onChange={this.onChange}
                        options={
                            {
                                uncheckedIcon: (
                                    <>
                                        <LockedIcon style={{ backgroundColor: "var(--live-lock-banner-locked-border-color)", color: "var(--live-lock-banner-icon-color)", width: "100%", height: "100%", padding: "0.125em" }} />
                                        <Typography className={classes.label} style={{ left: "1.5em" }}>
                                            <Trans>LOCKED</Trans>
                                        </Typography>
                                    </>
                                ),
                                checkedIcon: (
                                    <>
                                        <Typography className={classes.label} style={{ right: "1.5em" }}>
                                            <Trans>UNLOCKED</Trans>
                                        </Typography>
                                        <UnlockedIcon style={{ backgroundColor: "var(--live-lock-banner-unlocked-background-color)", color: "var(--live-lock-banner-icon-color)", width: "100%", height: "100%", padding: "0.125em" }} />
                                    </>
                                ),
                            } as SwitchFieldOptions
                        }
                    />
                    <Tooltip alwaysShow arrow title={<Trans>Learn more about live lock</Trans>}>
                        <Link data-id={"lock-info"} target="_blank" href="https://help.getbraincloud.com/en/articles/2284354-i-set-my-app-live-and-now-it-is-locked" style={{ overflow: "inherit" }}>
                            <IconButton id={"help-icon"} style={{ width: "1.75em", height: "1.75em", color: session.isLiveLocked ? "var(--live-lock-banner-locked-border-color)" : "var(--live-lock-banner-unlocked-color)" }}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </div>

                {dialogMode === DialogMode.READ && (
                    <VerifyAppNameDialog
                        details={
                            <Trans>
                                Unlock the app <strong>{targetApp.appName}</strong> for editing?
                            </Trans>
                        }
                        appName={targetApp.appName}
                        onClose={this.handleClose}
                    />
                )}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            "& > Muibutton-root": {
                width: "100%",
            },
        },
        row: {
            margin: "0.625em",
            padding: "0.5em 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            borderRadius: "0.25em",
        },
        info: {
            "@media(max-width: 1320px)": {
                display: "none",
            },
        },
        locked: {
            position: "absolute",
            left: "45%",
            backgroundColor: "transparent",
            "@media(max-width: 1320px)": {
                left: "40%",
            },
            "@media(max-width: 800px)": {
                left: "30%",
            },
            "& .MuiSwitch-root": {
                width: "11em",
                borderRadius: "calc(var(--field-height) / 2)",
                "& .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: "var(--primary-background-color) !important",
                    borderColor: "var(--live-lock-banner-locked-border-color) !important",
                },
                "& .MuiSwitch-input": {
                    left: "-600%",
                    width: "1200%",
                },
            },
        },
        unlocked: {
            position: "absolute",
            left: "45%",
            backgroundColor: "transparent",
            "@media(max-width: 1320px)": {
                left: "40%",
            },
            "@media(max-width: 800px)": {
                left: "30%",
            },
            "& .MuiSwitch-root": {
                width: "11em",
                borderRadius: "calc(var(--field-height) / 2)",
                "& .Mui-checked": {
                    transform: "translateX(9.2em) !important",
                },
                "& .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: "var(--primary-background-color) !important",
                    borderColor: "var(--live-lock-banner-unlocked-border-color) !important",
                },
                "& .MuiSwitch-input": {
                    left: "-600%",
                    width: "1200%",
                },
            },
        },
        label: {
            color: "var(--primary-color)",
            fontWeight: "bold",
            fontSize: "1em",
            position: "absolute",
            top: "0.25em",
            width: "9em !important",
            textAlign: "center",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(LiveLock)));
