import { RecentlyViewedAction } from "store/actions/recentlyViewed";

const initialState: string[] = [];

const reducer = (state: string[] = initialState, action: RecentlyViewedAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_RECENTLY_VIEWED":
            newState = [...action.payload];

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
