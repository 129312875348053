// Import types.
import { Query, QueryGenerator } from "../types";

export abstract class AbstractQueryGenerator implements QueryGenerator {
    /**
     * Generates a collection of queries from the supplied query string.
     *
     * @param queryString
     * @param abortSignal
     * @returns Promise<Query[]>
     */
    generate = async (queryString: string, abortSignal?: AbortSignal) => {
        if (abortSignal?.aborted) {
            throw new DOMException("Aborted", "AbortError");
        } else {
            const queries = await this.generateQueries(queryString, abortSignal);

            if (abortSignal?.aborted) {
                throw new DOMException("Aborted", "AbortError");
            } else {
                return queries;
            }
        }
    };

    /**
     * Generates a collection of queries from the supplied query string.
     *
     * This function should be asynchronous (i.e. return a Promise) that resolves with the actual collection of Query instances.
     *
     * Be sure to utilize the abort signal that is also passed in to take full advantage of the query cancellation feature.
     *
     * @param queryString
     * @param abortSignal
     * @returns Promise<Query[]>
     */
    protected abstract generateQueries(queryString: string, abortSignal?: AbortSignal): Promise<Query[]>;
}
