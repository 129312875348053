// Import libraries.
import React from "react";
import { Theme } from "@mui/material";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from "@lingui/macro";

// Import components.
import FieldWrapper from "components/common/form/FieldWrapper";

interface OWN_PROPS {
    portalxAccessPermitted: boolean | null;
    disabled: boolean;
    onChange: (name: string, value: any) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class CustomData extends React.PureComponent<PROPS> {
    render() {
        const { classes, disabled, portalxAccessPermitted } = this.props;

        return (
            <div className={classes.root}>
                <FieldWrapper
                    type={"checkbox"}
                    name={"portalxAccessPermitted"}
                    value={portalxAccessPermitted === true}
                    label={<Trans>Permit Access to Portal-X</Trans>}
                    labelPosition={"right"}
                    labelStyle={{ flex: "1 1 auto" }}
                    controlStyle={{ flex: "0 0 auto" }}
                    onChange={this.props.onChange}
                    disabled={disabled === true}
                />
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
        },
    });

export default withStyles(styles)(CustomData);
