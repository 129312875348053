import React from "react";

export default abstract class HighlightFormatter {
    private static regEscape(input: string) {
        return input.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    }

    public static highlight(text?: string | null, match?: string | null, className?: string | null): React.ReactNode {
        // If there was no text or className supplied, then just return the supplied text (no highlight treatment whatsoever).
        if (!text || !className) return text;

        // If there was no match supplied, then apply the highlight treatment to the full text.
        if (!match) {
            return <span className={className}>{text}</span>;
        }

        // First extract the list of actual matches (ignoring case so that the results will preserve the original case from the supplied text).
        const matches = text.match(new RegExp("(" + HighlightFormatter.regEscape(match) + ")", "ig"));

        if (matches && matches.length > 0) {
            // Split the supplied text into parts. A substitution will be inserted in between each resulting part.
            // The indices will be used to determine the original case-sensitive match (to preserve the original case from the supplied text).
            const parts = text.split(new RegExp(HighlightFormatter.regEscape(match), "ig"));

            return parts.map((part, idx) => {
                if (idx < parts.length - 1) {
                    return (
                        <span key={idx}>
                            <span>{part}</span>
                            <span className={className}>{matches[idx]}</span>
                        </span>
                    );
                } else {
                    return <span key={idx}>{part}</span>;
                }
            });
        } else {
            return <span>{text}</span>;
        }
    }
}
