import { ThemeAction } from "store/actions/theme";

import Theme from "types/common/Theme";

import CloneUtils from "utils/Clone";

const initialState: Theme | null = null;

const reducer = (state: Theme | null = initialState, action: ThemeAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_ACTIVE_THEME":
            newState = action.payload ? (CloneUtils.clone(action.payload) as Theme) : null;

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
