// Import types used by this module.
import ScreenSettings from "types/common/ScreenSettings";

export interface ScreenSettingsAction {
    type:
        'SET_SCREEN_SETTINGS'
    payload: ScreenSettings[]
}

export const SET_SCREEN_SETTINGS = (screenSettings: ScreenSettings[]) : ScreenSettingsAction => {
    return {
        type: 'SET_SCREEN_SETTINGS',
        payload: screenSettings
    };
}
