// Define an enum to represent the various poller status values.
export enum PollerStatus {
    STARTED = "started",
    RUNNING = "running",
    PAUSED = "paused",
    STOPPED = "stopped",
}

// Define an interface to represent a poller.
export interface Poller {
    id: string;
    startDelay: number;
    maxIterations: number;
    iterationInterval: number;
    currentIteration: number;
    callback: (...args: any[]) => Promise<any>;
    callbackArgs: any[];
    status: PollerStatus;
    onStatusChange?: (newStatus: PollerStatus, prevStatus: PollerStatus) => void;
}

/**
 * Poller utility functions.
 */
export default abstract class PollerUtils {
    // Define a collection of pollers.
    private static pollers: Poller[] = [];

    /**
     * Starts a new poller.
     *
     * @param id The unique id of the poller to start.
     * @param startDelay The delay (in ms) prior to starting.
     * @param maxIterations The maximum number of iterations to perform.
     * @param iterationInterval The interval (in ms) between iterations.
     * @param callback The callback function which is executed on every poll iteration (i.e. the actual implementation).
     * @param callbackArgs The array of arguments that are pased to the callback function associated with the poller.
     * @param onStatusChange An optional callback function which is executed every time the status of the poller changes.
     */
    public static startPoller(
        id: string,
        startDelay: number,
        maxIterations: number,
        iterationInterval: number,
        callback: (...args: any[]) => Promise<any>,
        callbackArgs: any[],
        onStatusChange?: (newStatus: PollerStatus, prevStatus?: PollerStatus) => void
    ): boolean {
        // Find the pollers with the supplied id (and are not already STOPPED).
        const existingPollers = PollerUtils.pollers.filter((item) => item.id === id && item.status !== PollerStatus.STOPPED);

        // Make sure a poller with the supplied id and a status other then STOPPED doesn't already exist.
        // A poller with a status of STOPPED will automatically remove itself from our collection, so in
        // this situation it's totally OK to start a new one with the same id.
        if (existingPollers.length > 0) {
            console.warn("Poller '" + id + "' already exists");
            return false;
        }

        // Construct a new poller.
        const poller: Poller = {
            id: id,
            startDelay: startDelay,
            maxIterations: maxIterations,
            iterationInterval: iterationInterval,
            currentIteration: 0,
            callback: callback,
            callbackArgs: callbackArgs,
            status: PollerStatus.STARTED,
            onStatusChange: onStatusChange,
        };

        console.info("Starting poller '" + id + "': startDelay = " + poller.startDelay + ", maxIterations = " + poller.maxIterations + ", iterationInterval = " + poller.iterationInterval, poller.callbackArgs);

        // Add the new poller to our collection of pollers.
        PollerUtils.pollers.push(poller);

        // Construct a wrapper function that will handle the polling behaviour.
        const wrapper = async () => {
            // Get the current status of the poller.
            const currentStatus = poller.status;

            if (currentStatus === PollerStatus.PAUSED) {
                // Schedule the next iteration using the supplied iteration interval.
                window.setTimeout(wrapper, poller.iterationInterval);
                console.debug("Skipping execution of paused poller '" + id + "'");
                return;
            }

            if (currentStatus === PollerStatus.STOPPED) {
                // Remove the stopped poller from our collection of pollers.
                PollerUtils.pollers.splice(PollerUtils.pollers.indexOf(poller), 1);
                console.debug("Removing poller '" + id + "'");
                return;
            }

            if (currentStatus === PollerStatus.STARTED) {
                // Check that we have not exceeded the max iteration limit for this poller.
                if (poller.maxIterations > 0 && poller.currentIteration >= poller.maxIterations) {
                    // If we have reached the max iteration limit, then stop this poller.
                    poller.status = PollerStatus.STOPPED;

                    // Trigger the onStatusChange handler (if applicable).
                    if (poller.onStatusChange) poller.onStatusChange(poller.status, currentStatus);
                } else {
                    // Increment the current iteration of this poller.
                    poller.currentIteration++;

                    // Mark the poller as RUNNING.
                    poller.status = PollerStatus.RUNNING;

                    // Trigger the onStatusChange handler (if applicable).
                    if (poller.onStatusChange) poller.onStatusChange(poller.status, currentStatus);

                    // Execute the callback function (passing any defined arguments).
                    try {
                        await poller.callback(...poller.callbackArgs);
                    } catch (error) {
                        console.error("An error occured while executing poller '" + id + "'. This is a bug in the provided callback implementation which should NEVER throw an unhandled error.", error);
                    }

                    // If the poller has NOT been PAUSED or STOPPED in the interim (while we were in the middle of executing the callback), mark the poller as STARTED.
                    if (![PollerStatus.PAUSED, PollerStatus.STOPPED].includes(poller.status)) {
                        poller.status = PollerStatus.STARTED;

                        // Trigger the onStatusChange handler (if applicable).
                        if (poller.onStatusChange) poller.onStatusChange(poller.status, currentStatus);
                    }
                }

                // Schedule the next iteration using the supplied iteration interval.
                window.setTimeout(wrapper, poller.iterationInterval);

                return;
            }
        };

        // Schedule the initial iteration using the supplied delay.
        window.setTimeout(wrapper, poller.startDelay);

        // Return true (to indicate the call actually started the poller).
        return true;
    }

    /**
     * Pauses an existing poller.
     *
     * @param id The unique id of the poller to pause.
     */
    public static pausePoller(id: string): boolean {
        // Find the poller with the supplied id.
        const existingPoller = PollerUtils.getPoller(id);

        // Make sure the poller with the supplied id actually exists.
        if (!existingPoller) {
            console.warn("Poller '" + id + "' does not exist");
            return false;
        }

        // Get the current status of the poller.
        const currentStatus = existingPoller.status;

        // If the poller is already PAUSED, then just return false (to indicate the call did nothing).
        if (currentStatus === PollerStatus.PAUSED) {
            return false;
        }

        console.debug("Pausing poller '" + id + "'");

        // Pause the poller.
        existingPoller.status = PollerStatus.PAUSED;

        // Trigger the onStatusChange handler (if applicable).
        if (existingPoller.onStatusChange) existingPoller.onStatusChange(existingPoller.status, currentStatus);

        // Return true (to indicate the call actually paused the poller).
        return true;
    }

    /**
     * Resumes a paused existing poller.
     *
     * @param id The unique id of the poller to resume.
     */
    public static resumePoller(id: string): boolean {
        // Find the poller with the supplied id.
        const existingPoller = PollerUtils.getPoller(id);

        // Make sure the poller with the supplied id actually exists.
        if (!existingPoller) {
            console.warn("Poller '" + id + "' does not exist");
            return false;
        }

        // Get the current status of the poller.
        const currentStatus = existingPoller.status;

        // If the poller is NOT already PAUSED, then just return false (to indicate the call did nothing).
        if (currentStatus !== PollerStatus.PAUSED) {
            return false;
        }

        console.debug("Resuming poller '" + id + "'");

        // Unpause the poller.
        existingPoller.status = PollerStatus.STARTED;

        // Trigger the onStatusChange handler (if applicable).
        if (existingPoller.onStatusChange) existingPoller.onStatusChange(existingPoller.status, currentStatus);

        // Return true (to indicate the call actually resumed the poller).
        return true;
    }

    /**
     * Stops an existing poller.
     *
     * @param id The unique id of the poller to stop.
     */
    public static stopPoller(id: string): boolean {
        // Find the poller with the supplied id.
        const existingPoller = PollerUtils.getPoller(id);

        // Make sure the poller with the supplied id actually exists.
        if (!existingPoller) {
            console.warn("Poller '" + id + "' does not exist (or may have already been stopped)");
            return false;
        }

        // Get the current status of the poller.
        const currentStatus = existingPoller.status;

        console.info("Stopping poller '" + id + "'");

        // Otherwise we stop the poller.
        existingPoller.status = PollerStatus.STOPPED;

        // Trigger the onStatusChange handler (if applicable).
        if (existingPoller.onStatusChange) existingPoller.onStatusChange(existingPoller.status, currentStatus);

        // Return true (to indicate the call actually stopped the poller).
        return true;
    }

    /**
     * Updates an existing poller with new iteration options and callback arguments.
     *
     * @param id The unique id of the poller to update.
     * @param maxIterations The maximum number of iterations to perform.
     * @param iterationInterval The interval (in ms) between iterations.
     * @param callbackArgs  The array of arguments that are pased to the callback function associated with the poller.
     */
    public static updatePoller(id: string, maxIterations: number, iterationInterval: number, callbackArgs: any[], onStatusChange?: (newStatus: PollerStatus, prevStatus?: PollerStatus) => void): boolean {
        // Find the poller with the supplied id.
        const existingPoller = PollerUtils.getPoller(id);

        // Make sure the poller with the supplied id actually exists.
        if (!existingPoller) {
            console.warn("Poller '" + id + "' does not exist");
            return false;
        }

        console.debug("Updating poller '" + id + "': maxIterations = " + maxIterations + ", iterationInterval = " + iterationInterval, callbackArgs);

        // Update the poller interval options.
        existingPoller.maxIterations = maxIterations;
        existingPoller.iterationInterval = iterationInterval;

        // Update the poller calback arguments.
        existingPoller.callbackArgs = callbackArgs;

        // Update the poller onStatusChange handler.
        existingPoller.onStatusChange = onStatusChange;

        // Return true (to indicate the call actually updated the poller).
        return true;
    }

    /**
     * Returns the current poller for the supplied id, or null if no such poller exists.
     * Poller's with a status of STOPPED are excluded from the search.
     *
     * @param id The unique id of the poller to retrieve.
     */
    public static getPoller(id: string): Poller | null {
        return PollerUtils.pollers.find((item) => item.id === id && item.status !== PollerStatus.STOPPED) || null;
    }

    /**
     * Optional function that returns an array of pollers that satisfy the supplied paramters
     * @param id
     * @returns
     */
    public static getPollers(id?: string, status?: PollerStatus): Poller[] {
        if (id != null && status != null) {
            return PollerUtils.pollers.filter((item) => item.id === id && item.status === status);
        } else if (id != null) {
            return PollerUtils.pollers.filter((item) => item.id === id);
        } else if (status != null) {
            return PollerUtils.pollers.filter((item) => item.status === status);
        } else {
            return [...PollerUtils.pollers];
        }
    }
}
