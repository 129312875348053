// Import libraries.
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { en, fr } from "make-plural/plurals";
import moment from "moment-timezone";
import { CookieConsentLevel } from "utils/CookieConsent";

// Import types.
import SupportedLanguage from "types/enums/SupportedLanguage";

// Import the default localization catalog.
import LocalStorageUtils from "./LocalStorage";

/**
 * Localization utility functions.
 */
export default abstract class LocalizationUtils {
    public static initializeLocalization(i18n: I18n) {
        const defaultLanguage = LocalStorageUtils.getItem("userSelectedLanguage") === SupportedLanguage.FRENCH ? SupportedLanguage.FRENCH : SupportedLanguage.ENGLISH;

        this.setActiveLanguage(i18n, defaultLanguage);
    }

    public static async setActiveLanguage(i18n: I18n, language: SupportedLanguage | null) {
        if (language != null) {
            console.log("Setting Active Language", LocalizationUtils.formatLanguage(i18n, language));

            // Storing the user's preferred language in local storage
            LocalStorageUtils.setItem("userSelectedLanguage", language, CookieConsentLevel.FUNCTIONALITY);

            let plurals = null;
            let catalog: { messages: any } | null = null;

            // Load the appropriate Plural configuration for the language.
            switch (language) {
                case SupportedLanguage.ENGLISH:
                    plurals = en;

                    break;
                case SupportedLanguage.FRENCH:
                    plurals = fr;

                    break;
                default:
                // Do nothing.
            }

            // Load the appropriate catalog for the language.
            catalog = await import(
                /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
                `../assets/locales/${language}/messages`
            );

            // If we have a valid catalog, proceed with updating the i18n library.
            if (catalog != null) {
                // Set the active language for moment (used when formatting dates/timestamps).
                moment.locale(language);

                // Load the plurals configuration for the language (if applicable).
                if (plurals != null) {
                    i18n.loadLocaleData(language, { plurals });
                }

                // Load the catalog for the language.
                i18n.load(language, catalog.messages);

                // Set the active language.
                i18n.activate(language);
            } else {
                console.warn("Attempted to set unsupported language", language);
            }
        }
    }

    public static getActiveLanguage = (i18n: I18n) => {
        return i18n.locale === SupportedLanguage.FRENCH ? SupportedLanguage.FRENCH : SupportedLanguage.ENGLISH;
    };

    public static formatLanguage(i18n: I18n, language: SupportedLanguage | null) {
        if (language != null) {
            switch (language) {
                case SupportedLanguage.ENGLISH:
                    return i18n ? i18n._(t`English`) : "English";
                case SupportedLanguage.FRENCH:
                    return i18n ? i18n._(t`French`) : "French";
                default:
                    return null;
            }
        }

        return null;
    }
}
