const FlyoutFilled = (props: any) => {
    return (
        <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 16 16" {...props}>
            <path
                d="M15.2977778,2.45333333 C14.8444444,2.02666667 14.2488889,1.78666667 13.5911111,1.78666667 L5.15555556,1.78666667 C4.51555556,1.78666667 3.92,2.02666667 3.44888889,2.45333333 C2.99555556,2.88 2.73777778,3.45777778 2.73777778,4.06222222 L2.73777778,12.0355556 C2.73777778,12.6488889 2.99555556,13.2088889 3.44888889,13.6533333 C3.90222222,14.08 4.49777778,14.32 5.15555556,14.32 L13.5822222,14.32 C14.2222222,14.32 14.8266667,14.08 15.2888889,13.6533333 C15.7422222,13.2266667 15.9911111,12.6577778 15.9911111,12.0355556 L15.9911111,4.07111111 C15.9911111,3.45777778 15.7422222,2.89777778 15.2888889,2.46222222 L15.2977778,2.45333333 Z M0,1.77777778 L0.96,1.77777778 L0.96,14.4 L0,14.4 L0,1.77777778 Z"
                fill="currentcolor"
                fillRule="nonzero"
            ></path>
        </svg>
    );
};

export default FlyoutFilled;
