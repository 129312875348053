import { createContext, useContext, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PortalState from "types/store";
import { DirectS2SLog, DirectS2SRequest, DirectS2SRequestSessionBased, DirectS2SResponse, DirectS2SResponseCallStats, DirectS2SResponseLogEntry, DirectS2SSelection, DirectS2SSession } from "types/common/DirectS2S";

import {
    APPEND_DIRECT_S2S_LOG,
    CLEAR_DIRECT_S2S_LOGS,
    CLEAR_DIRECT_S2S_SESSION,
    SET_DIRECT_S2S_BUSY,
    SET_DIRECT_S2S_SELECTION,
    SET_DIRECT_S2S_SESSION,
    UPDATE_DIRECT_S2S_LOG,
    SET_DIRECT_S2S_IS_REAL_DISPATCHER,
    SET_DIRECT_S2S_IS_SESSION_BASED,
    SET_DIRECT_S2S_IS_ASYNCRHONOUS,
} from "store/actions/directS2S";

import Http, { HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";
import StringUtils from "utils/String";

export type DirectS2SContextType = {
    directS2SSession: DirectS2SSession | null;
    setDirectS2SSession: (session: DirectS2SSession) => void;
    clearDirectS2SSession: (session: DirectS2SSession) => void;
    directS2SIsRealDispatcher: boolean;
    setDirectS2SIsRealDispatcher: (isRealDispatcher: boolean) => void;
    directS2SIsSessionBased: boolean;
    setDirectS2SIsSessionBased: (isSessionBased: boolean) => void;
    directS2SIsAsynchronous: boolean;
    setDirectS2SIsAsynchronous: (isAsynchronous: boolean) => void;
    directS2SSelections: DirectS2SSelection[];
    setDirectS2SSelection: (selection: DirectS2SSelection) => void;
    directS2SLogs: DirectS2SLog[];
    clearDirectS2SLogs: () => void;
    invokeDirectS2SCall: (serverName: string, serverSecret: string, service: string, operation: string, data: any, abortSignal?: AbortSignal) => Promise<any>;
    directS2SBusy: boolean;
};

const DEFAULT_VALUE: DirectS2SContextType = {
    directS2SSession: null,
    setDirectS2SSession: (_session: DirectS2SSession) => {
        return;
    },
    clearDirectS2SSession: (_session: DirectS2SSession) => {
        return;
    },
    directS2SIsRealDispatcher: false,
    setDirectS2SIsRealDispatcher: (_isRealDispatcher: boolean) => {
        return;
    },
    directS2SIsSessionBased: false,
    setDirectS2SIsSessionBased: (_isSessionBased: boolean) => {
        return;
    },
    directS2SIsAsynchronous: false,
    setDirectS2SIsAsynchronous: (_isAsynchronous: boolean) => {
        return;
    },
    directS2SSelections: [],
    setDirectS2SSelection: (_selection: DirectS2SSelection) => {
        return;
    },
    directS2SLogs: [],
    clearDirectS2SLogs: () => {
        return;
    },
    invokeDirectS2SCall: (_serverName: string, _serverSecret: string, _service: string, _operation: string, _data: any, _abortSignal?: AbortSignal) => {
        return Promise.resolve({} as any);
    },
    directS2SBusy: false,
};

const DEFAULT_ASYNC_POLL_INTERVAL = 1000;

export const DirectS2SContext = createContext<DirectS2SContextType>(DEFAULT_VALUE);

export const DirectS2SContextProvider = (props: any) => {
    const dispatch = useDispatch();

    const appId = useSelector((state: PortalState) => state.session.appId || "");
    const directS2S = useSelector((state: PortalState) => state.directS2S);

    const s2sSession = directS2S.sessions.find((item) => item.appId === appId) || null;

    const setDirectS2SSession = useCallback(
        (session: DirectS2SSession) => {
            dispatch(SET_DIRECT_S2S_SESSION(session));
        },
        [dispatch]
    );

    const clearDirectS2SSession = useCallback(
        (session: DirectS2SSession) => {
            dispatch(CLEAR_DIRECT_S2S_SESSION(session));
        },
        [dispatch]
    );

    const setDirectS2SIsRealDispatcher = useCallback(
        (isRealDispatcher: boolean) => {
            dispatch(SET_DIRECT_S2S_IS_REAL_DISPATCHER(isRealDispatcher));
        },
        [dispatch]
    );

    const setDirectS2SIsSessionBased = useCallback(
        (isSessionBased: boolean) => {
            dispatch(SET_DIRECT_S2S_IS_SESSION_BASED(isSessionBased));
        },
        [dispatch]
    );

    const setDirectS2SIsAsynchronous = useCallback(
        (isAsynchronous: boolean) => {
            dispatch(SET_DIRECT_S2S_IS_ASYNCRHONOUS(isAsynchronous));
        },
        [dispatch]
    );

    const setDirectS2SSelection = useCallback(
        (selection: DirectS2SSelection) => {
            dispatch(SET_DIRECT_S2S_SELECTION(selection));
        },
        [dispatch]
    );

    const setDirectS2SBusy = useCallback(
        (isBusy: boolean) => {
            dispatch(SET_DIRECT_S2S_BUSY(isBusy));
        },
        [dispatch]
    );

    const clearDirectS2SLogs = useCallback(() => {
        dispatch(CLEAR_DIRECT_S2S_LOGS());
    }, [dispatch]);

    const buildDispatcherRequest = useCallback(
        (serverName: string, serverSecret: string, service: string, operation: string, data: any): DirectS2SRequest | DirectS2SRequestSessionBased => {
            if (directS2S.isSessionBased) {
                // Determine if the call is an authentication call.
                const isAuthenticationCall = ["authentication", "authenticationV1", "authenticationV2"].includes(service) && operation === "AUTHENTICATE";

                const sessionBasedRequest: DirectS2SRequestSessionBased = {
                    sessionId: isAuthenticationCall ? null : s2sSession?.sessionId || null,
                    packetId: isAuthenticationCall ? 0 : s2sSession?.packetId || 1,
                    messages: [
                        {
                            service: service,
                            operation: operation,
                            data: data,
                        },
                    ],
                };

                return sessionBasedRequest;
            } else {
                const sessionlessRequest: DirectS2SRequest = {
                    appId: appId,
                    serverName: serverName,
                    serverSecret: serverSecret,
                    service: service,
                    operation: operation,
                    data: data,
                };

                return sessionlessRequest;
            }
        },
        [appId, directS2S.isSessionBased, s2sSession?.packetId, s2sSession?.sessionId]
    );

    const buildDispatcherResponse = useCallback(
        (response: HttpResponse, directS2SLog: DirectS2SLog): DirectS2SResponse => {
            directS2SLog.response = {
                data: undefined,
                status: 0,
            };

            if (Http.isStatusOk(response)) {
                let data: any = null;
                let status: any = null;

                // Extract the response data and status.
                if (directS2S.isRealDispatcher) {
                    if (directS2S.isAsynchronous) {
                        // The "real" dispatcher doesn't support asynchronous calls (i.e. start/poll).
                    } else {
                        if (directS2S.isSessionBased) {
                            const messageResponse = Array.isArray(response.data?.messageResponses) && (response.data?.messageResponses as any[]).length > 0 ? (response.data?.messageResponses as any[])[0] : {};

                            data = messageResponse?.data != null ? CloneUtils.clone(messageResponse.data) : messageResponse != null ? CloneUtils.clone(messageResponse) : null;
                            status = messageResponse?.status != null ? CloneUtils.clone(messageResponse.status) : response.status;
                        } else {
                            data = response.data != null ? CloneUtils.clone(response.data) : null;
                            status = CloneUtils.clone(response.status);
                        }
                    }
                } else {
                    if (directS2S.isAsynchronous) {
                        data = response.data?.taskState?.response?.data != null ? CloneUtils.clone(response.data.taskState.response.data) : response.data?.taskState?.response != null ? CloneUtils.clone(response.data.taskState.response) : null;
                        status = response.data?.taskState?.response?.status != null ? CloneUtils.clone(response.data.taskState.response.status) : response.status;

                        if (response.data?.taskState?.duration != null) {
                            directS2SLog.duration = Number.parseInt(response.data.taskState.duration);
                        }
                        if (response.data?.taskState?.response?.logList != null) {
                            data.logList = response.data.taskState.response.logList;
                        }
                        if (response.data?.taskState?.response?.callStats != null) {
                            data.callStats = response.data.taskState.response.callStats;
                        }
                        if (response.data?.taskState?.response?.totalCount != null) {
                            data.totalCount = response.data.taskState.response.totalCount;
                        }
                        if (response.data?.taskState?.response?.apiCount != null) {
                            data.apiCount = response.data.taskState.response.apiCount;
                        }
                    } else {
                        data = response.data?.data != null ? CloneUtils.clone(response.data.data) : response.data != null ? CloneUtils.clone(response.data) : null;
                        status = response.data?.status != null ? CloneUtils.clone(response.data?.status) : response.status;
                    }
                }

                // Construct the Direct S2S Log Response.
                directS2SLog.response = {
                    data: CloneUtils.clone(data),
                    status: CloneUtils.clone(status),
                };

                // Process any log list that may be present (typically only returned for script executions).
                if (directS2SLog.response.data?.logList != null) {
                    directS2SLog.response.logList = directS2SLog.response.data.logList.map((item: any) => {
                        return {
                            level: item.level ? item.level : "",
                            message: item.msg ? item.msg : "",
                            context: item.context ? item.context : {},
                            time: item.time ? item.time : "",
                        } as DirectS2SResponseLogEntry;
                    });

                    delete directS2SLog.response.data.logList;
                }

                // Process any call stats that may be present (typically only returned for script executions).
                if (directS2SLog.response.data?.callStats != null) {
                    directS2SLog.response.callStats = directS2SLog.response.data.callStats as DirectS2SResponseCallStats;

                    delete directS2SLog.response.data.callStats;
                }

                // Process any call counts that may be present (typically only returned for script executions).
                if (directS2SLog.response.data?.totalCount != null || directS2SLog.response.data?.apiCount != null) {
                    directS2SLog.response.callCounts = {
                        total: directS2SLog.response.data?.totalCount != null ? directS2SLog.response.data.totalCount : -1,
                        api: directS2SLog.response.data?.apiCount != null ? directS2SLog.response.data.apiCount : -1,
                    };

                    delete directS2SLog.response.data.totalCount;
                    delete directS2SLog.response.data.apiCount;
                }

                // Process any common error fields.
                if (directS2SLog.response.data?.status != null) {
                    delete directS2SLog.response.data.status;
                }
                if (directS2SLog.response.data?.status_message != null) {
                    directS2SLog.response.status_message = directS2SLog.response.data.status_message;

                    delete directS2SLog.response.data.status_message;
                }
                if (data?.reason_code != null) {
                    directS2SLog.response.reason_code = directS2SLog.response.data.reason_code;

                    delete directS2SLog.response.data.reason_code;
                }
                if (data?.severity != null) {
                    directS2SLog.response.severity = directS2SLog.response.data.severity;

                    delete directS2SLog.response.data.severity;
                }
                if (data?.x_stack_trace != null) {
                    directS2SLog.response.x_stack_trace = directS2SLog.response.data.x_stack_trace;

                    delete directS2SLog.response.data.x_stack_trace;
                }

                // if the data object is empty, them delete it.
                if (directS2SLog.response.data != null && Object.keys(directS2SLog.response.data).length === 0) {
                    delete directS2SLog.response.data;
                }
            } else {
                let data: any = null;
                let status: any = null;

                // Extract the response data and status.
                if (response.error?.response) {
                    data = response.error.response.data != null ? CloneUtils.clone(response.error.response.data) : null;
                    status = CloneUtils.clone(response.error.response.status);
                }

                // Construct the Direct S2S Log Response.
                directS2SLog.response = {
                    data: CloneUtils.clone(data),
                    status: CloneUtils.clone(status),
                };

                // Process any common error fields.
                if (directS2SLog.response.data?.status != null) {
                    delete directS2SLog.response.data.status;
                }
                if (directS2SLog.response.data?.status_message != null) {
                    directS2SLog.response.status_message = directS2SLog.response.data.status_message;

                    delete directS2SLog.response.data.status_message;
                }
                if (data?.reason_code != null) {
                    directS2SLog.response.reason_code = directS2SLog.response.data.reason_code;

                    delete directS2SLog.response.data.reason_code;
                }
                if (data?.severity != null) {
                    directS2SLog.response.severity = directS2SLog.response.data.severity;

                    delete directS2SLog.response.data.severity;
                }
                if (data?.x_stack_trace != null) {
                    directS2SLog.response.x_stack_trace = directS2SLog.response.data.x_stack_trace;

                    delete directS2SLog.response.data.x_stack_trace;
                }

                // if the data object is empty, then delete it.
                if (directS2SLog.response.data != null && Object.keys(directS2SLog.response.data).length === 0) {
                    delete directS2SLog.response.data;
                }

                if (response.error?.message === "canceled") {
                    directS2SLog.cancelled = true;
                }
            }

            return directS2SLog.response;
        },
        [directS2S.isAsynchronous, directS2S.isSessionBased, directS2S.isRealDispatcher]
    );

    const invokeDirectS2SCallAsynchronous = useCallback(
        async (serverName: string, serverSecret: string, service: string, operation: string, data: any, abortSignal?: AbortSignal, asyncPollInterval?: number): Promise<any> => {
            if (directS2S.isRealDispatcher) {
                return Promise.reject("The real S2S Dispatcher does not support asynchronous calls");
            }

            setDirectS2SBusy(true);

            try {
                const dispatcherRequest = buildDispatcherRequest(serverName, serverSecret, service, operation, data);

                const directS2SLog: DirectS2SLog = {
                    timestamp: new Date(),

                    appId: appId,

                    request: dispatcherRequest,

                    response: null,

                    duration: -1,

                    cancelled: false,
                    expired: false,
                };

                dispatch(APPEND_DIRECT_S2S_LOG(directS2SLog));

                const startTime = new Date();

                let pollFinished = false;
                let pollExpired = false;

                let response = await Http.POST("admin/development/dispatcher/s2s/start", undefined, directS2SLog.request, Http.JSON_HEADERS, abortSignal);

                if (Http.isStatusOk(response) && !StringUtils.isNullOrEmpty(response.data?.taskId)) {
                    const taskId: string = response.data.taskId;

                    const performPoll = async (): Promise<void> => {
                        response = await Http.POST("admin/development/dispatcher/s2s/poll", { taskId: taskId }, undefined, Http.JSON_HEADERS, abortSignal);

                        if (Http.isStatusOk(response) && response.data?.statusCode === 200) {
                            if (response.data?.taskState?.running === false) {
                                pollFinished = true;

                                return;
                            }
                        } else {
                            pollFinished = true;

                            if (response.data?.statusCode === 400) {
                                pollExpired = true;
                            } else {
                                console.log("Unknown Task Status Code", response.data);
                            }

                            return;
                        }
                    };

                    while (!pollFinished) {
                        await new Promise<void>((resolve) => {
                            window.setTimeout(() => {
                                resolve();
                            }, asyncPollInterval || DEFAULT_ASYNC_POLL_INTERVAL);
                        });

                        await performPoll();
                    }
                } else {
                    pollFinished = true;
                }

                const endTime = new Date();

                directS2SLog.duration = endTime.getTime() - startTime.getTime();

                const dispatcherResponse = buildDispatcherResponse(response, directS2SLog);

                if (abortSignal?.aborted) {
                    directS2SLog.cancelled = true;
                }

                if (pollExpired) {
                    directS2SLog.expired = true;
                }

                dispatch(UPDATE_DIRECT_S2S_LOG(directS2SLog));

                if (directS2S.isSessionBased) {
                    // Determine if the call is an authentication call.
                    const isAuthenticationCall = ["authentication", "authenticationV1", "authenticationV2"].includes(service) && operation === "AUTHENTICATE";

                    const updatedDirectS2SSession = s2sSession
                        ? (CloneUtils.clone(s2sSession) as DirectS2SSession)
                        : {
                              appId: appId,
                              serverName: null,
                              sessionId: null,
                              packetId: 0,
                          };

                    if (isAuthenticationCall) {
                        updatedDirectS2SSession.serverName = data?.serverName || null;
                        updatedDirectS2SSession.sessionId = dispatcherResponse.data?.sessionId || null;
                        updatedDirectS2SSession.packetId = 1;
                    } else {
                        updatedDirectS2SSession.packetId += 1;
                    }

                    setDirectS2SSession(updatedDirectS2SSession);
                }

                return Promise.resolve(directS2SLog.response);
            } finally {
                setDirectS2SBusy(false);
            }
        },
        [appId, buildDispatcherRequest, buildDispatcherResponse, directS2S.isSessionBased, directS2S.isRealDispatcher, dispatch, s2sSession, setDirectS2SBusy, setDirectS2SSession]
    );

    const invokeDirectS2SCallDirect = useCallback(
        async (serverName: string, serverSecret: string, service: string, operation: string, data: any, abortSignal?: AbortSignal): Promise<any> => {
            setDirectS2SBusy(true);

            try {
                const dispatcherRequest = buildDispatcherRequest(serverName, serverSecret, service, operation, data);

                const directS2SLog: DirectS2SLog = {
                    timestamp: new Date(),

                    appId: appId,

                    request: dispatcherRequest,

                    response: null,

                    duration: -1,

                    cancelled: false,
                    expired: false,
                };

                dispatch(APPEND_DIRECT_S2S_LOG(directS2SLog));

                const startTime = new Date();

                const response = await Http.POST(directS2S.isRealDispatcher ? "s2sdispatcher" : "admin/development/direct-s2s-call", undefined, directS2SLog.request, Http.JSON_HEADERS, abortSignal);

                const endTime = new Date();

                directS2SLog.duration = endTime.getTime() - startTime.getTime();

                const dispatcherResponse = buildDispatcherResponse(response, directS2SLog);

                dispatch(UPDATE_DIRECT_S2S_LOG(directS2SLog));

                if (directS2S.isSessionBased) {
                    // Determine if the call is an authentication call.
                    const isAuthenticationCall = ["authentication", "authenticationV1", "authenticationV2"].includes(service) && operation === "AUTHENTICATE";

                    const updatedDirectS2SSession = s2sSession
                        ? (CloneUtils.clone(s2sSession) as DirectS2SSession)
                        : {
                              appId: appId,
                              serverName: null,
                              sessionId: null,
                              packetId: 0,
                          };

                    if (isAuthenticationCall) {
                        updatedDirectS2SSession.serverName = data?.serverName || null;
                        updatedDirectS2SSession.sessionId = dispatcherResponse.data.sessionId || null;
                        updatedDirectS2SSession.packetId = 1;
                    } else {
                        updatedDirectS2SSession.packetId += 1;
                    }

                    setDirectS2SSession(updatedDirectS2SSession);
                }

                return Promise.resolve(directS2SLog.response);
            } finally {
                setDirectS2SBusy(false);
            }
        },
        [appId, buildDispatcherRequest, buildDispatcherResponse, directS2S.isSessionBased, directS2S.isRealDispatcher, dispatch, s2sSession, setDirectS2SBusy, setDirectS2SSession]
    );

    const invokeDirectS2SCall = useCallback(
        async (serverName: string, serverSecret: string, service: string, operation: string, data: any, abortSignal?: AbortSignal): Promise<any> => {
            if (directS2S.isAsynchronous) {
                return invokeDirectS2SCallAsynchronous(serverName, serverSecret, service, operation, data, abortSignal);
            } else {
                return invokeDirectS2SCallDirect(serverName, serverSecret, service, operation, data, abortSignal);
            }
        },
        [directS2S.isAsynchronous, invokeDirectS2SCallAsynchronous, invokeDirectS2SCallDirect]
    );

    const [value, setValue] = useState<DirectS2SContextType>({
        directS2SSession: s2sSession,
        setDirectS2SSession: setDirectS2SSession,
        clearDirectS2SSession: clearDirectS2SSession,
        directS2SIsRealDispatcher: directS2S.isRealDispatcher,
        setDirectS2SIsRealDispatcher: setDirectS2SIsRealDispatcher,
        directS2SIsSessionBased: directS2S.isSessionBased,
        setDirectS2SIsSessionBased: setDirectS2SIsSessionBased,
        directS2SIsAsynchronous: directS2S.isAsynchronous,
        setDirectS2SIsAsynchronous: setDirectS2SIsAsynchronous,
        directS2SSelections: directS2S.selections,
        setDirectS2SSelection: setDirectS2SSelection,
        directS2SLogs: directS2S.logs,
        clearDirectS2SLogs: clearDirectS2SLogs,
        invokeDirectS2SCall: invokeDirectS2SCall,
        directS2SBusy: directS2S.isBusy,
    });

    useEffect(() => {
        setValue({
            directS2SSession: s2sSession,
            setDirectS2SSession: setDirectS2SSession,
            clearDirectS2SSession: clearDirectS2SSession,
            directS2SIsRealDispatcher: directS2S.isRealDispatcher,
            setDirectS2SIsRealDispatcher: setDirectS2SIsRealDispatcher,
            directS2SIsSessionBased: directS2S.isSessionBased,
            setDirectS2SIsSessionBased: setDirectS2SIsSessionBased,
            directS2SIsAsynchronous: directS2S.isAsynchronous,
            setDirectS2SIsAsynchronous: setDirectS2SIsAsynchronous,
            directS2SSelections: directS2S.selections,
            setDirectS2SSelection: setDirectS2SSelection,
            directS2SLogs: directS2S.logs,
            clearDirectS2SLogs: clearDirectS2SLogs,
            invokeDirectS2SCall: invokeDirectS2SCall,
            directS2SBusy: directS2S.isBusy,
        });
    }, [
        clearDirectS2SLogs,
        clearDirectS2SSession,
        directS2S.isAsynchronous,
        directS2S.isBusy,
        directS2S.isSessionBased,
        directS2S.logs,
        directS2S.selections,
        directS2S.isRealDispatcher,
        invokeDirectS2SCall,
        invokeDirectS2SCallAsynchronous,
        s2sSession,
        setDirectS2SIsAsynchronous,
        setDirectS2SIsSessionBased,
        setDirectS2SSelection,
        setDirectS2SSession,
        setDirectS2SIsRealDispatcher,
    ]);

    return <DirectS2SContext.Provider value={value}>{props.children}</DirectS2SContext.Provider>;
};

export const useDirectS2S = () => useContext(DirectS2SContext);
