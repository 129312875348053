import SystemPrivilege, { processSystemPrivilege } from "./SystemPrivilege";

export default interface SystemRole {
    id: string;
    nameEn: string | null;
    nameFr: string | null;
    descriptionEn: string | null;
    descriptionFr: string | null;
    sortOrder: number | null;
    privileges?: SystemPrivilege[];
}

export const processSystemRole = (data: any): SystemRole => {
    return {
        id: "" + data["role-id"],
        nameEn: data["name"] != null ? "" + data["name"] : null,
        nameFr: data["name-fr"] != null ? "" + data["name-fr"] : null,
        descriptionEn: data["description"] != null ? "" + data["description"] : null,
        descriptionFr: data["description-fr"] != null ? "" + data["description-fr"] : null,
        sortOrder: data["sort-order"] != null ? Number.parseInt(data["sort-order"]) : null,
        privileges: data["privileges"] && Array.isArray(data["privileges"]) ? data["privileges"].filter((data: any) => data.name !== "" && data["is-showable"] === true).map(processSystemPrivilege) : undefined,
    };
};
