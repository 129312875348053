// Import libraries.
import React, { CSSProperties } from "react";
import { Trans } from "@lingui/macro";
import { Theme } from "@mui/material";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import classnames from "classnames";

// Import components.
import Tooltip from "components/common/Tooltip";
import IconButton from "components/common/button/IconButton";

// Import icons.
import IntercomIcon from "@mui/icons-material/Comment";
interface OWN_PROPS {
    style?: CSSProperties;
}

interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

const IntercomLauncher = (props: PROPS) => {
    if ((window as any).Intercom == null) return null;
    const { classes, style } = props;
    return (
        <Tooltip alwaysShow arrow title={<Trans>Ask Support</Trans>}>
            <IconButton id="toggle-intercom" className={classnames("bc-intercom-launcher", classes.iconButton)} style={{ ...style }}>
                <IntercomIcon style={{ width: "100%", height: "100%" }} />
            </IconButton>
        </Tooltip>
    );
};
const styles = (theme: Theme) =>
    createStyles({
        iconButton: {
            color: "var(--header-intercom-launcher-color, inherit)",
            margin: 0,
            padding: 0,
            "&:hover .MuiTouchRipple-root": {
                backgroundColor: "transparent !important",
            },
        },
    });
export default React.memo(withStyles(styles)(IntercomLauncher));
