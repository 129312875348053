import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import PortalRoute from "types/common/PortalRoute";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalPrivilege from "types/common/PortalPrivilege";

/**
 * Portal Route utility functions.
 */
export default abstract class PortalRouteUtils {
    // Define some static constants.
    public static PATH_SEP = "/";

    /**
     * Sanitized the supplied path by removing router-based path parameters.
     */
    public static sanitizeRoutePath = (path: string) => {
        // Sanitize the route path by removing any router path variables.
        const sanitizedPath = path.includes(":") ? path.substring(0, path.indexOf(":") - 1) : path;

        return sanitizedPath;
    };

    /**
     * Generate a relative path (without the leading PATH_SEP) for the supplied basePath/path.
     * Also converts result to lower-case.
     */
    public static relativeRoutePath = (basePath?: string, path?: string): string => {
        const empty = [PortalRouteUtils.PATH_SEP, ""];
        const trimmedBasePath = basePath ? basePath.trim() : null;
        const trimmedPath = path ? path.trim() : null;

        if (!trimmedBasePath || empty.includes(trimmedBasePath)) {
            if (!trimmedPath || empty.includes(trimmedPath)) {
                return "";
            } else {
                return trimmedPath.startsWith(PortalRouteUtils.PATH_SEP) ? trimmedPath.substring(1) : trimmedPath;
            }
        } else {
            if (!trimmedPath || empty.includes(trimmedPath)) {
                return trimmedBasePath.startsWith(PortalRouteUtils.PATH_SEP) ? trimmedBasePath.substring(1) : trimmedBasePath;
            } else {
                return (
                    (trimmedBasePath.startsWith(PortalRouteUtils.PATH_SEP) ? trimmedBasePath.substring(1) : trimmedBasePath) + PortalRouteUtils.PATH_SEP + (trimmedPath.startsWith(PortalRouteUtils.PATH_SEP) ? trimmedPath.substring(1) : trimmedPath)
                );
            }
        }
    };

    /**
     * Generate an absolute path (with the leading PATH_SEP) for the supplied basePath/path.
     * Also converts result to lower-case.
     */
    public static absoluteRoutePath = (basePath?: string, path?: string): string => {
        const empty = [PortalRouteUtils.PATH_SEP, ""];
        const trimmedBasePath = basePath ? basePath.trim() : null;
        const trimmedPath = path ? path.trim() : null;

        if (!trimmedBasePath || empty.includes(trimmedBasePath)) {
            if (!trimmedPath || empty.includes(trimmedPath)) {
                return PortalRouteUtils.PATH_SEP;
            } else {
                return !trimmedPath.startsWith(PortalRouteUtils.PATH_SEP) ? PortalRouteUtils.PATH_SEP + trimmedPath : trimmedPath;
            }
        } else {
            if (!trimmedPath || empty.includes(trimmedPath)) {
                return !trimmedBasePath.startsWith(PortalRouteUtils.PATH_SEP) ? PortalRouteUtils.PATH_SEP + trimmedBasePath : trimmedBasePath;
            } else {
                return (
                    (!trimmedBasePath.startsWith(PortalRouteUtils.PATH_SEP) ? PortalRouteUtils.PATH_SEP + trimmedBasePath : trimmedBasePath) +
                    (!trimmedPath.startsWith(PortalRouteUtils.PATH_SEP) ? PortalRouteUtils.PATH_SEP + trimmedPath : trimmedPath)
                );
            }
        }
    };

    /**
     * Generate a collection of path conponents for the supplied path.
     *
     * The optional startPath argument is used to provide a starting point for determining
     * the path components. The startPath will NOT be considered/included in the results.
     *
     * Exmaples:
     *   "PortalRouteUtils.routePathComponents('/a/b/c');"            returns: ['a', 'b', 'c']
     *   "PortalRouteUtils.routePathComponents('/a/b/c', '/a');"      returns: ['b', 'c']
     *   "PortalRouteUtils.routePathComponents('/a/b/c', '/a/b');"    returns: ['c']
     *
     * Any leading slash(s) are irrelavnet (they will get stripped regardless during processing).
     */
    public static routePathComponents = (path?: string, startPath?: string): string[] => {
        const relativePath = PortalRouteUtils.relativeRoutePath(undefined, path);

        if (relativePath.length > 0) {
            if (startPath) {
                const relativeStartPath = PortalRouteUtils.relativeRoutePath(undefined, startPath);
                const relativePathSegment = PortalRouteUtils.relativeRoutePath(undefined, relativePath.substring(relativeStartPath.length));
                return relativePathSegment.split(PortalRouteUtils.PATH_SEP);
            } else {
                return relativePath.split(PortalRouteUtils.PATH_SEP);
            }
        }

        return [];
    };

    /**
     * Converts a PortalRouteDefinition instance into a PortalRoute instance.
     */
    public static generatePortalRouteFromDefinition = (definition: PortalRouteDefinition, basePath?: string): PortalRoute[] => {
        const routes: PortalRoute[] = [];

        const routePath = PortalRouteUtils.absoluteRoutePath(basePath, definition.path);

        const route: PortalRoute = {
            path: routePath,
            selected: false,
            expanded: false,
        };

        routes.push(route);

        if (definition.routes && definition.routes.length > 0) {
            definition.routes.forEach((route) => {
                routes.push(...PortalRouteUtils.generatePortalRouteFromDefinition(route, routePath));
            });
        }

        return routes;
    };

    /**
     * Converts a colection/tree of PortalRouteDefinition instances into a collection of PortalRoute instances.
     */
    public static generatePortalRoutesFromDefinitions = (definitions: PortalRouteDefinition[]): PortalRoute[] => {
        const routes: PortalRoute[] = [];

        definitions.forEach((definition) => {
            routes.push(...PortalRouteUtils.generatePortalRouteFromDefinition(definition));
        });

        return routes;
    };

    /**
     * Find and return the specific (being first) PortalRouteDefinition in the supplied collection/tree that matches the supplied path.
     */
    public static getPortalRouteDefinitionForPath = (definitions: PortalRouteDefinition[], path: string): PortalRouteDefinition | undefined => {
        let result: PortalRouteDefinition | undefined = undefined;

        const routePathComponents = PortalRouteUtils.routePathComponents(path);

        let definition: PortalRouteDefinition | undefined = undefined;

        if (routePathComponents.length > 0) {
            definition = definitions.find((item) => PortalRouteUtils.sanitizeRoutePath(item.path) === routePathComponents[0]);

            for (let idx = 1; idx <= routePathComponents.length; idx++) {
                if (!definition) break;

                if (idx === routePathComponents.length) {
                    result = definition;
                    break;
                }

                if (definition.routes && definition.routes.length > 0) {
                    definition = definition.routes.find((item) => PortalRouteUtils.sanitizeRoutePath(item.path) === routePathComponents[idx]);
                } else {
                    definition = undefined;
                }
            }
        }

        return result;
    };

    /**
     * Find and return a flat collection of PortalRouteDefinition's in the supplied collection/tree that are part of the supplied path.
     */
    public static getPortalRouteDefinitionsInPath = (definitions: PortalRouteDefinition[], path: string): PortalRouteDefinition[] => {
        const results: PortalRouteDefinition[] = [];

        const routePathComponents = PortalRouteUtils.routePathComponents(path);

        let definition: PortalRouteDefinition | undefined = undefined;

        if (routePathComponents.length > 0) {
            definition = definitions.find((item) => PortalRouteUtils.sanitizeRoutePath(item.path) === routePathComponents[0]);

            for (let idx = 1; idx <= routePathComponents.length; idx++) {
                if (!definition) break;

                results.push(definition);

                if (definition.routes && definition.routes.length > 0) {
                    definition = definition.routes.find((item) => PortalRouteUtils.sanitizeRoutePath(item.path) === routePathComponents[idx]);
                } else {
                    definition = undefined;
                }
            }
        }

        return results;
    };

    /**
     * Find and return a flat collection of PortalRouteDefinition's in the supplied collection/tree that are under the supplied path.
     */
    public static getPortalRouteDefinitionsUnderPath = (definitions: PortalRouteDefinition[], path: string): PortalRouteDefinition[] => {
        const results: PortalRouteDefinition[] = [];

        const definition = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, path);

        if (definition && definition.routes) {
            definition.routes.forEach((item) => {
                results.push(item);
                results.push(...PortalRouteUtils.getPortalRouteDefinitionsUnderPath(definitions, path + PortalRouteUtils.PATH_SEP + PortalRouteUtils.sanitizeRoutePath(item.path)));
            });
        }

        return results;
    };

    /**
     * Find and return the specific (being first) PortalRoute in the supplied collection that matches the supplied path.
     */
    public static getPortalRouteForPath = (routes: PortalRoute[], path: string): PortalRoute | undefined => {
        const routePath = PortalRouteUtils.absoluteRoutePath(undefined, path);

        return routes.find((item) => PortalRouteUtils.sanitizeRoutePath(item.path) === routePath);
    };

    /**
     * Find and return a collection of PortalRoute's in the supplied collection that are part of the supplied path.
     *
     * The optional startPath argument is used to provide a starting point for determining
     * the path components. The startPath will NOT be considered/included in the results.
     *
     * Please refer to PortalRouteUtils.routePathComponents implementation for more information.
     */
    public static getPortalRoutesInPath = (routes: PortalRoute[], path: string, startPath?: string): PortalRoute[] => {
        const results: PortalRoute[] = [];

        const routePathComponents = PortalRouteUtils.routePathComponents(path, startPath);

        let route: PortalRoute | undefined = undefined;

        if (routePathComponents.length > 0) {
            // Determine the initial route satisfying the supplied part and optional startPath.
            route = routes.find((item) => PortalRouteUtils.sanitizeRoutePath(item.path) === PortalRouteUtils.absoluteRoutePath(startPath ? startPath : undefined, routePathComponents[0]));

            for (let idx = 1; idx <= routePathComponents.length; idx++) {
                if (!route) break;

                results.push(route);

                route = routes.find((item) => PortalRouteUtils.sanitizeRoutePath(item.path) === PortalRouteUtils.absoluteRoutePath(startPath ? startPath : undefined, routePathComponents.slice(0, idx + 1).join(PortalRouteUtils.PATH_SEP)));
            }
        }

        return results;
    };

    /**
     * Find and return a collection of PortalRoute's in the supplied collection that are under the supplied path.
     */
    public static getPortalRoutesUnderPath = (routes: PortalRoute[], path: string): PortalRoute[] => {
        const targetPath = PortalRouteUtils.absoluteRoutePath(path);

        return routes.filter((item) => PortalRouteUtils.sanitizeRoutePath(item.path).startsWith(targetPath) && PortalRouteUtils.sanitizeRoutePath(item.path) !== targetPath);
    };

    /**
     * Returns a copy of the supplied collection of PortalRoute's with the expanded/selected flags updated
     * based on the supplied expanded/selected arguments (absence of both expanded/selected arguments will
     * result in a straight copy of the PortalRoute's being returned).
     */
    public static updatePortalRoutes = (routes: PortalRoute[], expanded?: boolean, selected?: boolean) => {
        let results: PortalRoute[] = [];

        routes.forEach((route) => {
            results.push({
                path: route.path,
                expanded: expanded != null ? expanded : route.expanded,
                selected: selected != null ? selected : route.selected,
            });
        });

        return results;
    };

    /**
     * Returns a boolean value indicating whether or not the user has permission/access to a given route (and every intermediate route leading up to it).
     */
    public static hasAccess = (definitions: PortalRouteDefinition[], path: string, session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]): boolean => {
        // Determine the route path components.
        const pathComponents = PortalRouteUtils.routePathComponents(path);

        // Define a re-entrant function for checking the access of route definitions (recursively).
        const checkAccess = (localDefinitons: PortalRouteDefinition[] | null | undefined, pathComponents: string[]): { allowed: boolean; fallback?: string | null } => {
            const localDefiniton = localDefinitons ? localDefinitons.find((item) => PortalRouteUtils.sanitizeRoutePath(item.path) === pathComponents[0]) : undefined;

            if (localDefiniton && (!localDefiniton.hasAccess || localDefiniton.hasAccess(session, currentUser, availableCompanies, availableApps, availablePrivileges))) {
                if (pathComponents.length > 1) {
                    const subDefinitions = localDefiniton.routes;
                    const subComponents = pathComponents.slice(1);

                    const subAccess = checkAccess(subDefinitions, subComponents);

                    return subAccess.allowed ? subAccess : { allowed: false };
                } else {
                    return { allowed: true };
                }
            } else {
                return { allowed: false };
            }
        };

        // Call the re-entrant "checkAccess" function to determine if the target path is accessible.
        const accessControl = checkAccess(definitions, pathComponents);

        // If the access control check evaluates to false, the user is denied access.
        if (!accessControl.allowed) {
            return false;
        } else {
            return true;
        }
    };
}
