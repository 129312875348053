import { Trans } from "@lingui/macro";

enum PublishState {
    DELETED = "Deleted",
    SUSPENDED = "Suspended",
    DEVELOPMENT = "Development",
    LIVE = "Live",
}

export const formatPublishStateLabel = (publishState?: PublishState | null) => {
    switch (publishState) {
        case PublishState.DELETED:
            return <Trans>Deleted</Trans>;
        case PublishState.SUSPENDED:
            return <Trans>Suspended</Trans>;
        case PublishState.DEVELOPMENT:
            return <Trans>Development</Trans>;
        case PublishState.LIVE:
            return <Trans>Live</Trans>;
        default:
            return publishState || <Trans>---</Trans>;
    }
};

export const formatPublishStateColor = (publishState?: PublishState | null) => {
    switch (publishState) {
        case PublishState.DELETED:
            return "var(--label-negative-color)";
        case PublishState.SUSPENDED:
            return "var(--label-warning-color)";
        case PublishState.DEVELOPMENT:
            return "var(--label-default-color)";
        case PublishState.LIVE:
            return "var(--label-positive-color)";
        default:
            return "var(--label-default-color)";
    }
};
export const formatPublishStateChipColor = (publishState?: PublishState | null) => {
    switch (publishState) {
        case PublishState.DELETED:
            return "var(--chip-negative-color)";
        case PublishState.SUSPENDED:
            return "var(--chip-warning-color)";
        case PublishState.DEVELOPMENT:
            return "var(--chip-default-color)";
        case PublishState.LIVE:
            return "var(--chip-positive-color)";
        default:
            return "var(--chip-default-color)";
    }
};
export const formatPublishStateChipBackgroundColor = (publishState?: PublishState | null) => {
    switch (publishState) {
        case PublishState.DELETED:
            return "var(--chip-negative-background-color)";
        case PublishState.SUSPENDED:
            return "var(--chip-warning-background-color)";
        case PublishState.DEVELOPMENT:
            return "var(--chip-default-background-color)";
        case PublishState.LIVE:
            return "var(--chip-positive-background-color)";
        default:
            return "var(--chip-default-background-color)";
    }
};

export default PublishState;
