export default interface RecentIntercomUser {
    profileId: string;
    email: string;
    fullName: string;
    lastLogin: Date | null;
}

export const processRecentIntercomUsers = (data: any): RecentIntercomUser[] => {
    const result: RecentIntercomUser[] = [];

    Object.keys(data).forEach((key) => {
        result.push({
            profileId: data[key].profileId,
            email: data[key].email,
            fullName: data[key].fullName,
            lastLogin: data[key].lastLogin != null ? new Date(data[key].lastLogin) : null,
        });
    });

    // Sorting by lastLogin (descending)
    result.sort((a, b) => {
        if (a.lastLogin != null && b.lastLogin == null) return -1;
        else if (a.lastLogin == null && b.lastLogin != null) return 1;
        else {
            if (a.lastLogin != null && b.lastLogin != null) {
                return a.lastLogin < b.lastLogin ? 1 : a.lastLogin > b.lastLogin ? -1 : 0;
            } else {
                return 0;
            }
        }
    });

    return result;
};
