import Http, { HttpResponse } from "utils/networking/Http";

const updateBillingAccount = async (companyId: string, emailAddress: string | null): Promise<{ action: "RedirectToUrl" | "DoNothing"; url: string | null }> => {
    const response: HttpResponse = await Http.POST("admin/activate-account", { companyId, emailAddress: emailAddress != null ? emailAddress : "" }, null, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        const data = response && response.data ? response.data : {};

        return Promise.resolve({
            action: data.action ? data.action : "DoNothing",
            url: data.url ? data.url : null,
        });
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const Services = {
    updateBillingAccount,
};

export default Services;
