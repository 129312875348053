import { ThemeAction } from "store/actions/theme";

import Theme from "types/common/Theme";

const initialState: Theme[] = [];

const reducer = (state: Theme[] = initialState, action: ThemeAction) => {
    let newState = state;

    if (!action.payload) return newState;

    const existing: Theme | undefined = state.find((item: Theme) => action.payload != null && (action.payload as Theme).companyId === item.companyId && (action.payload as Theme).name === item.name);

    switch (action.type) {
        case "ADD_THEME":
            if (!existing) {
                newState = [...state, action.payload as Theme];
            } else {
                console.warn("Theme already exists", existing);
            }

            break;
        case "UPDATE_THEME":
            if (existing) {
                newState = [...state];

                newState[newState.indexOf(existing)] = action.payload as Theme;
            } else {
                console.warn("Theme doesn't exists", existing);
            }

            break;
        case "DELETE_THEME":
            if (existing) {
                newState = state.filter((item) => item !== existing);
            } else {
                console.warn("Theme doesn't exists", existing);
            }

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
