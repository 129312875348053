import React from "react";

/**
 * Initial Experimental abstract component that provides safe setState support.
 *
 * Attempts to address those scenarios where an asynchronous call to the server
 * results in a call to setState but the component has already been unmounted
 * prior to the response being recceived.
 *
 * FOR EXAMPLE: Quickly flipping between screens prior to the data being fully loaded from the server.
 *
 * NOTES:
 *     - This is an anti-pattern and not really desirable, I will look into
 *       alternate solutions as opportunity allows.
 */

interface PROPS {}

abstract class PortalComponent<P = {}, S = {}, SS = any> extends React.PureComponent<P & PROPS, S, SS> {
    private _portalComponentIsMounted: boolean = true;

    setState<K extends keyof S>(state: ((prevState: Readonly<S>, props: Readonly<P & PROPS>) => Pick<S, K> | S | null) | (Pick<S, K> | S | null), callback?: () => void) {
        if (this._portalComponentIsMounted) {
            super.setState(state, callback);
        }
    }

    componentWillUnmount() {
        this._portalComponentIsMounted = false;
    }
}

export default PortalComponent;
