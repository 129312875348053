import React from "react";
import { isMobile, useMobileOrientation } from "react-device-detect";

export interface WithOrientationProps {
    isMobile: boolean;
    isPortrait: boolean;
    isLandscape: boolean;
}

export const withOrientation = () => {
    return <P extends WithOrientationProps>(Component: React.ComponentType<P>): React.ComponentType<Pick<P, Exclude<keyof P, keyof WithOrientationProps>>> => {
        return (props: Pick<P, Exclude<keyof P, keyof WithOrientationProps>>) => {
            const { isPortrait, isLandscape } = useMobileOrientation();

            return <Component {...(props as P)} isMobile={isMobile} isPortrait={isPortrait} isLandscape={isLandscape} />;
        };
    };
};
