// Import types.
import { Query, QueryGenerator, SearchConfiguration, SearchFilter, SearchPlugin, SearchResult, SearchSort } from "./types";

// Import generators.
import SmartGenerator from "./generators/SmartGenerator";
import ExternalAuthGenerator from "./generators/ExternalAuthGenerator";

// Import plugins.
import TeamPlugin from "./plugins/TeamPlugin";
import AppPlugin from "./plugins/AppPlugin";
import PlayerPlugin from "./plugins/PlayerPlugin";
import ScreenPlugin from "./plugins/ScreenPlugin";

// Import filters.
import PortalPrivilegeFilter from "./filters/PortalPrivilegeFilter";

// Import sorters.
// import ClosenessSort from "./sorts/ClosenessSort";
// import RankSort from "./sorts/RankSort";

// Import utilities.
import { parseQuery } from "./utils";

class SearchEngine {
    private _abortController: AbortController = new AbortController();

    private generators: QueryGenerator[] = [];
    private plugins: SearchPlugin[] = [];
    private filters: SearchFilter[] = [];
    private sorts: SearchSort[] = [];

    constructor() {
        this.generators.push(new SmartGenerator());
        this.generators.push(new ExternalAuthGenerator());

        this.plugins.push(new TeamPlugin());
        this.plugins.push(new AppPlugin());
        this.plugins.push(new PlayerPlugin());
        this.plugins.push(new ScreenPlugin());

        this.filters.push(new PortalPrivilegeFilter());

        // this.sorts.push(new ClosenessSort());
        // this.sorts.push(new RankSort());
    }

    configure = (args?: SearchConfiguration | null) => {
        this.generators.forEach((generator) => {
            if (generator.configure) generator.configure(args);
        });

        this.plugins.forEach((plugin) => {
            if (plugin.configure) plugin.configure(args);
        });

        this.filters.forEach((filter) => {
            if (filter.configure) filter.configure(args);
        });

        this.sorts.forEach((sort) => {
            if (sort.configure) sort.configure(args);
        });
    };

    execute = (queryString: string) => {
        this._abortController.abort();
        this._abortController = new AbortController();

        return this.performQuery(queryString, this._abortController.signal);
    };

    private performQuery = async (queryString: string, abortSignal?: AbortSignal) => {
        if (abortSignal?.aborted) {
            throw new DOMException("Aborted", "AbortError");
        }

        const startTime = new Date();

        console.debug("Executing Query", queryString);

        // Attempt to parse the query string into a Query instance (if possible).
        // We also specify the "treatUnknownHintsAsNull" parameter with a value of TRUE to prevent generation of unknown hints.
        const query = parseQuery(queryString.trim(), true);

        // Determine the queries to perform.
        const queriesToPerform: Query[] = [];
        if (typeof query === "string" || query === null) {
            // If the type of the parsed query is 'string' OR it is null then it was not parseable OR is an unknown hint, so pass it through the generators.
            // This will attempt to infer potential queries from the original query string (this is the so called "smart search").
            const deferredGeneratorResponses = await Promise.allSettled(this.generators.map((generator) => generator.generate(queryString.trim(), abortSignal)));

            if (abortSignal?.aborted) {
                throw new DOMException("Aborted", "AbortError");
            }

            deferredGeneratorResponses.forEach((item) => {
                if (item.status === "fulfilled") {
                    queriesToPerform.push(...item.value);
                }
            });
        } else {
            // Otherwise the type of the parsed query is 'object' (i.e. an instnace of Query), so we simply use it as-is.
            queriesToPerform.push(query);
        }

        console.debug("Queries to Execute", queriesToPerform);

        // Execute the queries using the plugins.
        const queryResults: SearchResult[] = [];
        const deferredPluginResponses = await Promise.allSettled(this.plugins.map((plugin) => plugin.execute(queriesToPerform, abortSignal)));

        if (abortSignal?.aborted) {
            throw new DOMException("Aborted", "AbortError");
        }

        deferredPluginResponses.forEach((item) => {
            if (item.status === "fulfilled") {
                queryResults.push(...item.value);
            }
        });

        console.debug("Query Results (un-filtered)", queryResults.length);

        // Filter the results using the filters.
        let filteredResults = queryResults;
        this.filters.forEach((filter) => {
            filteredResults = filter.filter(filteredResults);
        });

        console.debug("Query Results (filtered)", filteredResults.length);

        // Apply any sorters (supports multi-level sorting, beginning with level 0).
        // Sort levels are implied by the insertion order of the sorters collection.
        const sortedResults = filteredResults.sort((a, b) => {
            return this.sort(a, b, 0);
        });

        console.debug("Query Results (sorted)", sortedResults);

        const endTime = new Date();

        console.log("Query Duration " + (endTime.getTime() - startTime.getTime()) + "ms", queryString);

        // Return the final results.
        return {
            queries: queriesToPerform,
            results: sortedResults,
        };
    };

    private sort = (a: SearchResult, b: SearchResult, level: number) => {
        let sortResult = 0;

        if (this.sorts.length > 0 && level < this.sorts.length) {
            sortResult = this.sorts[level].sort(a, b);

            if (sortResult === 0) {
                sortResult = this.sort(a, b, level + 1);
            }
        }

        return sortResult;
    };
}

export default SearchEngine;
