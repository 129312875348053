// Import libraries.
import React from "react";
import { Theme } from "@mui/material";

import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

// Import types.
import { TextFieldOptions } from "../../../form/fields/TextField";
import { NumberFieldOptions } from "../../../form/fields/NumberField";
import { CheckboxFieldOptions } from "../../../form/fields/CheckboxField";
import { SwitchFieldOptions } from "../../../form/fields/SwitchField";
import { SelectFieldOptions } from "../../../form/fields/SelectField";
import { ComboboxFieldOptions } from "../../../form/fields/ComboboxField";
import { DateTimeFieldOptions } from "../../../form/fields/DateTimeField";
import { DateFieldOptions } from "../../../form/fields/DateField";
import { TimeFieldOptions } from "../../../form/fields/TimeField";

// Import components.
import Typography from "@mui/material/Typography";
import FieldWrapper from "../../../form/FieldWrapper";

// Define the set of filter/field types supported by this component.
export type BasicFilterType = "text" | "number" | "checkbox" | "switch" | "select" | "combobox" | "datetime" | "date" | "time";

// Define the set of filter/field options supported by this component.
export type BasicFilterOptions = TextFieldOptions | NumberFieldOptions | CheckboxFieldOptions | SwitchFieldOptions | SelectFieldOptions | ComboboxFieldOptions | DateTimeFieldOptions | DateFieldOptions | TimeFieldOptions;

interface OWN_PROPS {
    name: string;
    type: BasicFilterType;
    label?: React.ReactNode;
    labelPosition?: "left" | "top" | "right" | "bottom";
    labelAlignment?: "flex-start" | "center" | "flex-end";
    value?: any;
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    options?: BasicFilterOptions;
    onChange: (name: string, value: any) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class BasicFilter extends React.PureComponent<PROPS> {
    render() {
        const { classes, name, type, label, value, required, readonly, disabled, options, labelPosition, labelAlignment } = this.props;

        return (
            <div className={classes.root}>
                <FieldWrapper
                    type={type}
                    name={name}
                    value={value}
                    className={classes.field}
                    label={<Typography>{label}</Typography>}
                    labelPosition={labelPosition}
                    labelAlignment={labelAlignment}
                    required={required}
                    readonly={readonly}
                    disabled={disabled}
                    onChange={this.props.onChange}
                    options={options}
                />
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "1 1 auto",
            display: "flex",
            alignItems: "center",
        },
        field: {
            width: "100%",
            margin: 0,
            "& .FieldWrapper-label": {
                flex: "0 0 auto",
                marginRight: "0.3125em",
            },
            "& .FieldWrapper-control": {
                flex: "1 1 auto",
            },
        },
    });

export default withStyles(styles)(BasicFilter);
