// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Divider } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import { TextFieldOptions } from "components/common/form/fields/TextField";

// Import components.
import { Link, Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";
import FieldWrapper from "components/common/form/FieldWrapper";
import IconButton from "components/common/button/IconButton";
import Timestamp from "components/common/Timestamp";

// Import icons.
import EmailIcon from "@mui/icons-material/Email";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

// Import utilities.
import * as Patterns from "utils/Patterns";

interface STATE_PROPS {
    session: Session;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    context: "self" | "super" | "team";
    fullName: string | null;
    email: string | null;
    createdAt: Date | null;
    lastLogin: Date | null;
    isCurrentSessionUser: boolean;
    isActive: boolean;
    onChange: (fullName: string | null, email: string | null) => void;
    onSendResetPasswordEmail: () => void;
    onResendActivationEmail: () => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
    };
};

const mapDispatchToProps = () => {
    return {};
};

class Information extends React.PureComponent<PROPS> {
    handleChange = (name: string, value: any) => {
        const { fullName, email, onChange } = this.props;

        switch (name) {
            case "fullName":
                onChange(value, email);
                break;
            case "email":
                onChange(fullName, value);
                break;
            default:
            // Unknown field.
        }
    };

    render() {
        const { i18n, classes, session, context, fullName, email, createdAt, lastLogin, isCurrentSessionUser, isActive } = this.props;

        return (
            <div className={classes.root}>
                <FieldWrapper
                    type={"text"}
                    name={"fullName"}
                    value={fullName}
                    label={<Trans>Full Name</Trans>}
                    labelPosition={"top"}
                    labelAlignment={"flex-start"}
                    onChange={this.handleChange}
                    required={true}
                    disabled={!isCurrentSessionUser && !(session.isSuper && session.isTeamAdmin)}
                    options={{ placeholder: i18n._(t`User's Full Name...`) } as TextFieldOptions}
                />

                <FieldWrapper
                    type={"text"}
                    name={"email"}
                    value={email}
                    label={
                        <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center", columnGap: "0.3125rem" }}>
                            <Typography>{<Trans>Email</Trans>}</Typography>

                            <Tooltip alwaysShow arrow title={<Trans>Send Email</Trans>}>
                                <IconButton id={"send-email"} onClick={() => (window.location.href = "mailto:" + email)}>
                                    <EmailIcon />
                                </IconButton>
                            </Tooltip>

                            {!isCurrentSessionUser && ((context === "super" && session.isSuper && session.isTeamAdmin) || (context === "team" && session.isTeamAdmin)) && (
                                <Tooltip alwaysShow arrow title={<Typography>{isActive ? <Trans>Send Password Reset Email</Trans> : <Trans>Resend Activation Email</Trans>}</Typography>}>
                                    <IconButton id={isActive ? "reset-password" : "activate"} onClick={isActive ? this.props.onSendResetPasswordEmail : this.props.onResendActivationEmail}>
                                        <MarkEmailReadIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!isActive && <Typography>{<Trans>(Not Validated)</Trans>}</Typography>}
                        </span>
                    }
                    labelPosition={"top"}
                    labelAlignment={"flex-start"}
                    onChange={this.handleChange}
                    required={true}
                    disabled={!isCurrentSessionUser}
                    options={
                        {
                            placeholder: i18n._(t`User's Email Address...`),
                            pattern: Patterns.EMAIL,
                            patternMessage: <Trans>Must be a valid email address.</Trans>,
                        } as TextFieldOptions
                    }
                />

                <Typography style={{ margin: "0.3125rem", padding: "0.3125rem", fontSize: ".875em", textAlign: "center", alignSelf: "center" }}>
                    <Trans>You may configure an avatar for your chosen email through Gravatar</Trans>
                </Typography>

                <Link data-id={"configure"} rel="noreferrer" style={{ alignSelf: "center" }} onClick={() => window.open("https://en.gravatar.com/site/implement/images/")}>
                    {i18n._(t`Configure Avatar`)}
                </Link>

                <Divider className={classes.divider} />

                <div className={classes.rowBlock}>
                    <div className={classes.columnBlock} style={{ padding: "0.625rem", borderStyle: "solid", borderWidth: "0.0625rem", borderRadius: "0.25em" }}>
                        <Typography style={{ marginBottom: "0.3125rem" }}>{<Trans>Account Created</Trans>}</Typography>

                        <Timestamp value={createdAt} humanize={true} empty={<Trans>Never</Trans>} />
                    </div>

                    <div className={classes.columnBlock} style={{ padding: "0.625rem", borderStyle: "solid", borderWidth: "0.0625rem", borderRadius: "0.25em" }}>
                        <Typography style={{ marginBottom: "0.3125rem" }}>{<Trans>Last Login</Trans>}</Typography>

                        <Timestamp value={lastLogin} humanize={true} empty={<Trans>Never</Trans>} />
                    </div>
                </div>
            </div>
        );
    }
}

// Styling for this component.
const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",

            overflow: "auto",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
        rowBlock: {
            flex: "0 0 auto",

            display: "flex",
            flexWrap: "wrap",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            margin: "0.3125rem",
        },
        columnBlock: {
            flex: "1 1 0px",

            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            margin: "0.3125rem",
        },

        divider: {
            marginTop: "1em",
            backgroundColor: "var(--navigation-border-color, inherit)",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(Information)));
