import Session from "types/common/Session";

export interface SessionAction {
    type:
        'SET_SESSION'
    payload: Session
}

export const SET_SESSION = (session: Session) : SessionAction => {
    return {
        type: 'SET_SESSION',
        payload: session
    };
};
