// Import libraries.
import React, { CSSProperties } from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import classnames from "classnames";

// Import components.
import { TooltipProps, Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";
import IconButton from "../button/IconButton";

// Import icons.
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Import utilities.
import ClipboardUtils from "utils/Clipboard";

interface PROPS extends WithStyles<typeof styles> {
    id: string;
    style?: CSSProperties;
    children?: React.ReactNode;
    value: any;
    copyOnHover?: boolean;
    labelStyle?: CSSProperties;
    tooltipOptions?: Omit<TooltipProps, "children"> & { alwaysShow?: boolean };
}

class LabelWithCopy extends React.PureComponent<PROPS> {
    render() {
        const { classes, id, style, children, value, labelStyle, copyOnHover, tooltipOptions } = this.props;

        return (
            <span id={"label-with-copy-" + id} className={classnames({ [classes.root]: true, [classes.copyOnHover]: copyOnHover })} style={style}>
                {children &&
                    (tooltipOptions ? (
                        <Tooltip {...tooltipOptions}>
                            <Typography style={labelStyle} noWrap>
                                {children}
                            </Typography>
                        </Tooltip>
                    ) : (
                        <Typography style={labelStyle} noWrap>
                            {children}
                        </Typography>
                    ))}

                {value && (
                    <Tooltip alwaysShow arrow title={<Trans>Copy To Clipboard</Trans>}>
                        <IconButton id={"copy-to-clipboard"} onClick={() => ClipboardUtils.writeText(value)}>
                            <ContentCopyIcon style={{ color: "var(--button-secondary-color)" }} />
                        </IconButton>
                    </Tooltip>
                )}
            </span>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            "& > .MuiIconButton-root": {
                flex: "0 0 auto",
                padding: "0.25em",
                margin: 0,
            },
        },
        copyOnHover: {
            "& > .MuiIconButton-root": {
                opacity: 0,
                transition: "opacity 0.2s ease",
            },
            "&:hover > .MuiIconButton-root": {
                opacity: 1,
            },
        },
    });

export default withStyles(styles)(LabelWithCopy);
