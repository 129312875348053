import Theme from "types/common/Theme";
import ThemeSettings from "types/common/ThemeSettings";

export interface ThemeAction {
    type: "ADD_THEME" | "UPDATE_THEME" | "DELETE_THEME" | "SET_ACTIVE_THEME" | "SET_THEME_SETTINGS";
    payload: Theme | ThemeSettings | null;
}

export const ADD_THEME = (theme: Theme): ThemeAction => {
    return {
        type: "ADD_THEME",
        payload: theme,
    };
};

export const UPDATE_THEME = (theme: Theme): ThemeAction => {
    return {
        type: "UPDATE_THEME",
        payload: theme,
    };
};

export const DELETE_THEME = (theme: Theme): ThemeAction => {
    return {
        type: "DELETE_THEME",
        payload: theme,
    };
};

export const SET_ACTIVE_THEME = (theme: Theme | null): ThemeAction => {
    return {
        type: "SET_ACTIVE_THEME",
        payload: theme,
    };
};

export const SET_THEME_SETTINGS = (settings: ThemeSettings | null): ThemeAction => {
    return {
        type: "SET_THEME_SETTINGS",
        payload: settings,
    };
};
