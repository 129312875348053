/**
 * Unit utility functions.
 */
export default abstract class UnitUtils {
    /**
     * Test if the supplied value represents a number.
     *
     * @param val
     */
    public static isValueNumber(val?: string | number | null): boolean {
        if (val == null) return false;

        if (typeof val === "number") return true;

        let trimmedVal = val.trim();

        if (trimmedVal.length === 0) return false;

        try {
            return !Number.isNaN(Number(trimmedVal));
        } catch (ex) {
            return false;
        }
    }

    /**
     * Test if the supplied value represents a PX value.
     *
     * @param val
     */
    public static isValuePX(val?: string | number | null): boolean {
        if (val == null || typeof val === "number") return false;

        let trimmedVal = val.trim();

        if (trimmedVal.length === 0) return false;

        if (trimmedVal.endsWith("em") || trimmedVal.endsWith("%")) {
            return false;
        } else {
            if (trimmedVal.endsWith("px")) {
                try {
                    return !Number.isNaN(Number(trimmedVal.substring(0, trimmedVal.length - 2)));
                } catch (ex) {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    /**
     * Test if the supplied value represents a EM value.
     *
     * @param val
     */
    public static isValueEM(val?: string | number | null): boolean {
        if (val == null || typeof val === "number") return false;

        let trimmedVal = val.trim();

        if (trimmedVal.length === 0) return false;

        if (trimmedVal.endsWith("rem") || trimmedVal.endsWith("%") || trimmedVal.endsWith("px")) {
            return false;
        } else {
            if (trimmedVal.endsWith("em")) {
                try {
                    return !Number.isNaN(Number(trimmedVal.substring(0, trimmedVal.length - 2)));
                } catch (ex) {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    /**
     * Test if the supplied value represents a REM value.
     *
     * @param val
     */
    public static isValueREM(val?: string | number | null): boolean {
        if (val == null || typeof val === "number") return false;

        let trimmedVal = val.trim();

        if (trimmedVal.length === 0) return false;

        if (trimmedVal.endsWith("%") || trimmedVal.endsWith("px")) {
            return false;
        } else {
            if (trimmedVal.endsWith("rem")) {
                try {
                    return !Number.isNaN(Number(trimmedVal.substring(0, trimmedVal.length - 3)));
                } catch (ex) {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    /**
     * Test if the supplied value represents a % (percentage) value.
     *
     * @param val
     */
    public static isValuePercentage(val?: string | number | null): boolean {
        if (val == null || typeof val === "number") return false;

        let trimmedVal = val.trim();

        if (trimmedVal.length === 0) return false;

        if (trimmedVal.endsWith("em") || trimmedVal.endsWith("px")) {
            return false;
        } else {
            if (trimmedVal.endsWith("%")) {
                try {
                    return !Number.isNaN(Number(trimmedVal.substring(0, trimmedVal.length - 1)));
                } catch (ex) {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    /**
     * Converts the given PX string value into a integer.
     *
     * If the value is NOT a string OR does NOT represent an PX value, then 0 is returned.
     *
     * @param val
     * @returns
     */
    public static pxToNumber(val?: string | number | null): number {
        if (typeof val === "string" && UnitUtils.isValuePX(val)) {
            const trimmedVal = val.trim();

            try {
                return Number.parseInt(trimmedVal.substring(0, trimmedVal.length - 2));
            } catch (ex) {
                return 0;
            }
        } else {
            return 0;
        }
    }

    /**
     * Converts the given EM string value into a float.
     *
     * If the value is NOT a string OR does NOT represent an EM value, then 0 is returned.
     *
     * @param val
     * @returns
     */
    public static emToNumber(val?: string | number | null): number {
        if (typeof val === "string" && UnitUtils.isValueEM(val)) {
            const trimmedVal = val.trim();

            try {
                return Number.parseFloat(trimmedVal.substring(0, trimmedVal.length - 2));
            } catch (ex) {
                return 0;
            }
        } else {
            return 0;
        }
    }

    /**
     * Converts the given REM string value into a float.
     *
     * If the value is NOT a string OR does NOT represent an REM value, then 0 is returned.
     *
     * @param val
     * @returns
     */
    public static remToNumber(val?: string | number | null): number {
        if (typeof val === "string" && UnitUtils.isValueREM(val)) {
            const trimmedVal = val.trim();

            try {
                return Number.parseFloat(trimmedVal.substring(0, trimmedVal.length - 3));
            } catch (ex) {
                return 0;
            }
        } else {
            return 0;
        }
    }

    /**
     * Converts the given % (percentage) string value into a float (in the range 0.0 to 1.0).
     *
     * If the value is NOT a string OR does NOT represent an % (percentage) value, then 0 is returned.
     *
     * @param val
     * @returns
     */
    public static percentageToNumber(val?: string | number | null): number {
        if (typeof val === "string" && UnitUtils.isValuePercentage(val)) {
            const trimmedVal = val.trim();

            try {
                return Number.parseFloat(trimmedVal.substring(0, trimmedVal.length - 1)) / 100;
            } catch (ex) {
                return 0;
            }
        } else {
            return 0;
        }
    }

    /**
     * Determines the base/root font size and line height as numbers.
     *
     * @returns
     */
    public static determineBaseFontSizeAndLineHeightAsNumber(element?: HTMLElement): { fontSize: number; lineHeight: number; offsetHeight: number } {
        // Compoute the base font-size and line height in PX.
        const rootElement = element || document.body;
        const rootDiv = document.createElement("div");
        rootDiv.style.width = "1000em";
        rootDiv.style.color = "transparent";
        rootDiv.style.backgroundColor = "transparent";
        rootDiv.innerHTML = "<label>TEST</label>";
        rootElement.appendChild(rootDiv);

        var rootFontSizeInPX = rootDiv.offsetWidth / 1000;
        var rootLineHeight = 1.5;
        const offsetHeight = rootDiv.offsetHeight;

        rootElement.removeChild(rootDiv);

        return {
            fontSize: rootFontSizeInPX,
            lineHeight: rootLineHeight,
            offsetHeight: offsetHeight,
        };
    }
}
