// Import types.
import { Query, SearchPlugin, SearchResult } from "../types";

export abstract class AbstractSearchPlugin implements SearchPlugin {
    /**
     * Determining whether or not the supplied query is actually supported by the plugin.
     *
     * If the query is not supported then the plugin will ignore it.
     *
     * If the plugin does not override this function then it is assumed to NOT have support for ANY hints.
     *
     * @param query
     * @returns boolean
     */
    protected isHintSupported = (query: Query): boolean => {
        return false;
    };

    /**
     * Executes a single query or a collection of queries.
     *
     * @param query
     * @param abortSignal
     * @returns Promise<SearchResult[]>
     */
    execute = async (query: Query[] | Query, abortSignal?: AbortSignal) => {
        if (abortSignal?.aborted) {
            throw new DOMException("Aborted", "AbortError");
        } else {
            const supportedQueries = Array.isArray(query) ? query.filter((item) => this.isHintSupported(item)) : this.isHintSupported(query) ? [query] : [];

            const results = await Promise.allSettled(supportedQueries.map((item) => this.executeQuery(item, abortSignal)));

            if (abortSignal?.aborted) {
                throw new DOMException("Aborted", "AbortError");
            } else {
                const aggregatedResults: SearchResult[] = [];

                results.forEach((item) => {
                    if (item.status === "fulfilled") {
                        aggregatedResults.push(...item.value);
                    }
                });

                return aggregatedResults;
            }
        }
    };

    /**
     * Executes a single query.
     *
     * This function should be asynchronous (i.e. return a Promise) that resolves with the actual collection of SearchResult instances.
     *
     * Be sure to utilize the abort signal that is also passed in to take full advantage of the query cancellation feature.
     *
     * @param query
     * @param abortSignal
     * @returns Promise<SearchResult[]>
     */
    protected abstract executeQuery(query: Query, abortSignal?: AbortSignal): Promise<SearchResult[]>;
}
