import { DirectAPILog, DirectAPISelection, DirectAPISession } from "types/common/DirectAPI";

export interface DirectAPIAction {
    type: "SET_DIRECT_API_SESSION" | "CLEAR_DIRECT_API_SESSION" | "APPEND_DIRECT_API_LOG" | "UPDATE_DIRECT_API_LOG" | "CLEAR_DIRECT_API_LOGS" | "SET_DIRECT_API_BUSY" | "SET_DIRECT_API_BYPASS_USER_ENABLED_CHECK" | "SET_DIRECT_API_SELECTION";
    payload?: DirectAPISession | DirectAPILog | DirectAPISelection | boolean;
}

export const SET_DIRECT_API_SESSION = (session: DirectAPISession): DirectAPIAction => {
    return {
        type: "SET_DIRECT_API_SESSION",
        payload: session,
    };
};

export const CLEAR_DIRECT_API_SESSION = (session: DirectAPISession): DirectAPIAction => {
    return {
        type: "CLEAR_DIRECT_API_SESSION",
        payload: session,
    };
};

export const APPEND_DIRECT_API_LOG = (logToAppend: DirectAPILog): DirectAPIAction => {
    return {
        type: "APPEND_DIRECT_API_LOG",
        payload: logToAppend,
    };
};

export const UPDATE_DIRECT_API_LOG = (logToUpdate: DirectAPILog): DirectAPIAction => {
    return {
        type: "UPDATE_DIRECT_API_LOG",
        payload: logToUpdate,
    };
};

export const CLEAR_DIRECT_API_LOGS = (): DirectAPIAction => {
    return {
        type: "CLEAR_DIRECT_API_LOGS",
    };
};

export const SET_DIRECT_API_BUSY = (isBusy: boolean): DirectAPIAction => {
    return {
        type: "SET_DIRECT_API_BUSY",
        payload: isBusy,
    };
};

export const SET_DIRECT_API_BYPASS_USER_ENABLED_CHECK = (bypassUserEnabledCheck: boolean): DirectAPIAction => {
    return {
        type: "SET_DIRECT_API_BYPASS_USER_ENABLED_CHECK",
        payload: bypassUserEnabledCheck,
    };
};

export const SET_DIRECT_API_SELECTION = (selection: DirectAPISelection): DirectAPIAction => {
    return {
        type: "SET_DIRECT_API_SELECTION",
        payload: selection,
    };
};
