import CookieConsentUtils, { CookieConsentLevel } from "./CookieConsent";

/**
 * LocalStorage utility functions.
 */
export default abstract class LocalStorageUtils {
    // Define some static constants used in the session storage.
    static BROWSER_CLIENT_ID = "browserClientId";
    static PORTAL_SESSION = "portalSession";
    static DEFAULT_THEME_MODE = "defaultThemeMode";

    /**
     * Get the list of keys from the local storage.
     */
    public static getItemKeys(): string[] {
        const keys: string[] = [];

        for (let x = 0; x < localStorage.length; x++) {
            const key = localStorage.key(x);

            if (key) {
                keys.push(key);
            }
        }

        return keys;
    }

    /**
     * Get the item value associated with the supplied name.
     *
     * @param name
     */
    public static getItem(name: string): string | null {
        return localStorage.getItem(name);
    }

    /**
     * Set the item value associated with the supplied name.
     *
     * @param name
     * @param value
     */
    public static setItem(name: string, value: string, cookieConsentLevel: CookieConsentLevel): void {
        if (CookieConsentUtils.isLevelPermitted(cookieConsentLevel)) {
            localStorage.setItem(name, value);
        } else {
            localStorage.removeItem(name);
        }
    }

    /**
     * Delete the item value associated with the supplied name.
     * @param name
     */
    public static deleteItem(name: string): void {
        localStorage.removeItem(name);
    }

    /**
     * Clear (i.e. delete all items) in the local storage.
     */
    public static clear(): void {
        localStorage.clear();
    }
}
