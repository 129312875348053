import Http, { HttpResponse } from "utils/networking/Http";

import TwoFactorType from "types/enums/TwoFactorType";

const sendSMS = async (type: TwoFactorType): Promise<void> => {
    let response: HttpResponse | null = null;

    switch (type) {
        case TwoFactorType.AUTHY:
            response = await Http.POST("tfa/send-tfa-sms", undefined, undefined, Http.JSON_HEADERS);

            break;
        default:
            return Promise.reject("Unsupported two factor authentication type: " + type);
    }

    if (response && Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const Services = {
    sendSMS: sendSMS,
};

export default Services;
