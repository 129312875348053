import { Trans } from "@lingui/macro";

enum ServiceStatus {
    ACTIVE = "Active",
    DRAFT = "Draft",
    SUSPENDED = "Suspended",
}

export const formatServiceStatusLabel = (serviceStatus?: ServiceStatus | null) => {
    switch (serviceStatus) {
        case ServiceStatus.ACTIVE:
            return <Trans>Active</Trans>;
        case ServiceStatus.DRAFT:
            return <Trans>Draft</Trans>;
        case ServiceStatus.SUSPENDED:
            return <Trans>Suspended</Trans>;
        default:
            return serviceStatus || <Trans>---</Trans>;
    }
};

export const formatServiceStatusColor = (serviceStatus?: ServiceStatus | null) => {
    switch (serviceStatus) {
        case ServiceStatus.ACTIVE:
            return "var(--label-positive-color)";
        case ServiceStatus.DRAFT:
            return "var(--label-default-color)";
        case ServiceStatus.SUSPENDED:
            return "var(--label-negative-color)";
        default:
            return "var(--label-default-color)";
    }
};

export default ServiceStatus;
