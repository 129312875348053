import { EnvironmentInformationAction } from "store/actions/env";

import EnvironmentInformation from "types/common/EnvironmentInformation";
import CloneUtils from "utils/Clone";

const initialState: EnvironmentInformation = {
    supportEmail: null,

    portalDomainName: null,
    portalxDomainName: null,

    carouselUrl: null,
};

const reducer = (state: EnvironmentInformation = initialState, action: EnvironmentInformationAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_ENVIRONMENT_INFORMATION":
            newState = CloneUtils.clone(action.payload) as EnvironmentInformation;

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
