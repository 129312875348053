// Import libraries.
import { AbstractQueryGenerator } from "..";

// Import types.
import { Query, QueryOptions, SearchConfiguration } from "../../types";
import { parseQuery } from "../../utils";

// The name of the generator.
const GENERATOR_NAME = "EXTERNAL-AUTH";

// Define some regex patterns used to "guess" potential hints.
const EXTERNALID_REGEX: RegExp = /^(externalid)-([a-z0-9_-]+)\s*:\s*(.*)$/i;

/**
 * This generator attempts to "guess" the nature of the query being performed (for those queries that are missing any kind of supported "hint").
 *
 * This generator will analyse the raw query string using a series of regular expresssions and try to infer possible hints.
 *
 * It will then return those inferred hints (as a collection of Query instances) so that they can be executed by the search engine.
 *
 * More specifically this generator will generate hints based on authType and externalId.
 *
 * Example: "externalid-<auth_type>: <search_term>" OR "<auth_type>: <search_term>".
 */
class ExternalAuthGenerator extends AbstractQueryGenerator {
    private options: QueryOptions | null = null;

    configure = (args?: SearchConfiguration | null) => {
        if (args) {
            this.options = args.options || null;
        }
    };

    generateQueries = async (queryString: string, abortSignal?: AbortSignal) => {
        if (abortSignal?.aborted) {
            throw new DOMException("Aborted", "AbortError");
        }

        console.debug("Generating Query", GENERATOR_NAME, queryString, this.options);

        // Define a collection to hold the results.
        const queryResults: Query[] = [];

        const matches = EXTERNALID_REGEX.exec(queryString);

        if (matches && matches.length === 4) {
            const hint = matches[1].trim();
            const authType = matches[2].trim();
            let searchTerm = matches[3].trim();

            if ((searchTerm.startsWith('"') && searchTerm.endsWith('"')) || (searchTerm.startsWith("'") && searchTerm.endsWith("'"))) {
                searchTerm = searchTerm.substring(1, searchTerm.length - 1);
            }

            queryResults.push({
                hint: hint,
                subHint: authType,
                searchTerm: searchTerm,
            });
        } else {
            const query = parseQuery(queryString);

            if (query != null && typeof query === "object") {
                queryResults.push({
                    hint: "externalid",
                    subHint: query.hint,
                    searchTerm: query.searchTerm,
                });
            }
        }

        console.debug("Generator Results", GENERATOR_NAME, queryResults);

        return queryResults;
    };
}

export default ExternalAuthGenerator;
