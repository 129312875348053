import AppInfo from 'types/models/AppInfo';

export interface AvailableAppAction {
    type:
        'SET_AVAILABLE_APPS'
    payload: AppInfo[]
}

export const SET_AVAILABLE_APPS = (apps: AppInfo[]) : AvailableAppAction => {
    return {
        type: 'SET_AVAILABLE_APPS',
        payload: apps
    };
}
