class MeasurerCache {
    private headerCache: number = 0;
    private stickyCache: number = 0;
    private cellCache: number[][] = [];
    private headerCellCache: string[] = [];

    private listener: ((headerHeight: number, stickyWidth: number, rowHeights: number[], headerCellWidths: string[]) => void) | null = null;

    private timeout: number = 0;

    constructor(listener: (headerHeight: number, stickyWidth: number, rowHeights: number[], headerCellWidths: string[]) => void) {
        this.listener = listener;
    }

    public resetHeaderCache = () => {
        this.headerCache = 0;

        this.setDirty();
    };

    public resetStickynCache = () => {
        this.stickyCache = 0;

        this.setDirty();
    };

    public resetCellCache = () => {
        this.cellCache = [];

        this.setDirty();
    };

    public resetHeaderCellCache = () => {
        this.headerCellCache = [];

        this.setDirty();
    };

    public resetAll = () => {
        this.headerCache = 0;
        this.stickyCache = 0;
        this.cellCache = [];
        this.headerCellCache = [];

        this.setDirty();
    };

    public setHeaderValue = (value: number) => {
        if (this.headerCache !== value) {
            this.headerCache = value;

            this.setDirty();
        }
    };

    public setStickyValue = (value: number) => {
        if (this.stickyCache !== value) {
            this.stickyCache = value;

            this.setDirty();
        }
    };

    public setCellValue = (row: number, col: number, value: number) => {
        if (!this.cellCache[row]) {
            this.cellCache[row] = [];
        }

        if (this.cellCache[row][col] !== value) {
            this.cellCache[row][col] = value;

            this.setDirty();
        }
    };

    public setHeaderCellValue = (col: number, value: string) => {
        if (this.headerCellCache[col] !== value) {
            this.headerCellCache[col] = value;

            this.setDirty();
        }
    };

    public setDirty = () => {
        if (this.timeout > 0) {
            window.clearTimeout(this.timeout);
        }

        if (!this.listener) return;

        this.timeout = window.setTimeout(() => {
            const rowHeights: number[] = [];

            for (let x = 0, n = this.cellCache.length; x < n; ++x) {
                const rowCache = this.cellCache[x];
                const validValues = rowCache ? rowCache.filter((value) => value != null) : [];

                if (validValues.length > 0) {
                    rowHeights[x] = Math.max(...validValues);

                    if (rowHeights[x] === -1) {
                        rowHeights[x] = 0;
                    }
                } else {
                    rowHeights[x] = 0;
                }
            }

            if (this.listener) {
                console.debug("Triggering MeasurerCache Listener...");

                const startTime = new Date();

                this.listener(this.headerCache, this.stickyCache, rowHeights, this.headerCellCache);

                const endTime = new Date();

                console.debug("MeasurerCache Listener Took " + (endTime.getTime() - startTime.getTime()) + "ms");
            }
        }, 0);
    };
}

export default MeasurerCache;
