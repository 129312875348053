enum AccessMode {
    DISABLED = "disabled",
    OPEN = "open",
    RESTRICTED = "restricted",
    SUPER = "super",
}

export const parseAccessMode = (value: any) => {
    switch (value) {
        case "open":
            return AccessMode.OPEN;
        case "restricted":
            return AccessMode.RESTRICTED;
        case "super":
            return AccessMode.SUPER;
        default:
            return AccessMode.DISABLED;
    }
};

export default AccessMode;
