// Import libraries.
import { put, call, all, takeLatest, select } from "redux-saga/effects";

// Import types.
import { CookieConsentLevel } from "utils/CookieConsent";
import PortalState from "types/store";
import ClientInformation from "types/common/ClientInformation";
import Session, { sessionFromString, sessionToString } from "types/common/Session";

// Import redux actions.
import { SET_SESSION } from "store/actions/session";
import { SET_CLIENT_INFORMATION } from "store/actions/client";

// Import utilities.
import ClientIdUtils from "utils/ClientId";
import LocalStorageUtils from "utils/LocalStorage";
import SessionStorageUtils from "utils/SessionStorage";
import CloneUtils from "utils/Clone";

interface RestoreSessionFromLocalStorage {
    type: "session.restoreSessionFromLocalStorage";
}

interface SaveSessionToLocalStorage {
    type: "session.saveSessionToLocalStorage";
}

interface RestoreSessionFromSessionStorage {
    type: "session.restoreSessionFromSessionStorage";
}

interface SaveSessionToSessionStorage {
    type: "session.saveSessionToSessionStorage";
}

interface RestoreSession {
    type: "session.restoreSession";
}

interface SaveSession {
    type: "session.saveSession";
}

// Restores the session using the local storage.
export function* restoreSessionFromLocalStorage(_action?: RestoreSessionFromLocalStorage) {
    try {
        // Get the current client information from the redux store.
        const getClientInformation = (state: PortalState): ClientInformation => CloneUtils.clone(state.clientInformation);
        const clientInformation: ClientInformation = yield select(getClientInformation);

        // Get the 'browserClientId' from local storage.
        clientInformation.browserClientId = LocalStorageUtils.getItem(LocalStorageUtils.BROWSER_CLIENT_ID);

        // If there is no 'browserClientId', generate a new one.
        if (!clientInformation.browserClientId) clientInformation.browserClientId = ClientIdUtils.generateClientId();

        // Save the restored client information to the redux store.
        yield put(SET_CLIENT_INFORMATION(clientInformation));

        // Get the session information from the local storage and save it to the redux store.
        yield put(SET_SESSION(sessionFromString(LocalStorageUtils.getItem(LocalStorageUtils.PORTAL_SESSION))));

        return true;
    } catch (error: any) {
        console.error("restoreSessionFromLocalStorage - ERROR", error);
    }

    return false;
}

// Restores the session using the session storage.
export function* restoreSessionFromSessionStorage(_action?: RestoreSessionFromSessionStorage) {
    try {
        // Get the current client information from the redux store.
        const getClientInformation = (state: PortalState): ClientInformation => CloneUtils.clone(state.clientInformation);
        const clientInformation: ClientInformation = yield select(getClientInformation);

        // Get the 'tabClientId' from session storage.
        clientInformation.tabClientId = SessionStorageUtils.getItem(SessionStorageUtils.TAB_CLIENT_ID);

        // If there is no 'tabClientId', generate a new one.
        if (!clientInformation.tabClientId) clientInformation.tabClientId = ClientIdUtils.generateClientId();

        // Save the restored client information to the redux store.
        yield put(SET_CLIENT_INFORMATION(clientInformation));

        return true;
    } catch (error: any) {
        console.error("restoreSessionFromSessionStorage - ERROR", error);
    }

    return false;
}

// Restores the session using the indicated source (cookie or local storage).
export function* restoreSession(_action?: RestoreSession) {
    try {
        console.log("Restoring Session Information...");

        yield call(restoreSessionFromLocalStorage);
        yield call(restoreSessionFromSessionStorage);

        return true;
    } catch (error: any) {
        console.error("restoreSession - ERROR", error);
    }

    return false;
}

export function* saveSessionToLocalStorage(_action?: SaveSessionToLocalStorage) {
    try {
        // Get the current client information from the redux store.
        const getClientInformation = (state: PortalState): ClientInformation => CloneUtils.clone(state.clientInformation);
        const clientInformation: ClientInformation = yield select(getClientInformation);

        // Save the 'browserClientId' to local storage.
        if (clientInformation.browserClientId) {
            LocalStorageUtils.setItem(LocalStorageUtils.BROWSER_CLIENT_ID, clientInformation.browserClientId, CookieConsentLevel.FUNCTIONALITY);
        } else {
            LocalStorageUtils.deleteItem(LocalStorageUtils.BROWSER_CLIENT_ID);
        }

        // Get the session information from the redux store and save it to the local storage.
        const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
        LocalStorageUtils.setItem(LocalStorageUtils.PORTAL_SESSION, sessionToString(yield select(getSession)), CookieConsentLevel.FUNCTIONALITY);

        return true;
    } catch (error: any) {
        console.error("saveSessionToLocalStorage - ERROR", error);
    }

    return false;
}

export function* saveSessionToSessionStorage(_action?: SaveSessionToSessionStorage) {
    try {
        // Get the current client information from the redux store.
        const getClientInformation = (state: PortalState): ClientInformation => CloneUtils.clone(state.clientInformation);
        const clientInformation: ClientInformation = yield select(getClientInformation);

        // Save the 'tabClientId' to session storage.
        if (clientInformation.tabClientId) {
            SessionStorageUtils.setItem(SessionStorageUtils.TAB_CLIENT_ID, clientInformation.tabClientId, CookieConsentLevel.FUNCTIONALITY);
        } else {
            SessionStorageUtils.deleteItem(SessionStorageUtils.TAB_CLIENT_ID);
        }

        return true;
    } catch (error: any) {
        console.error("saveSessionToSessionStorage - ERROR", error);
    }

    return false;
}

export function* saveSession(_action?: SaveSession) {
    console.debug("Saving Session Information...");

    try {
        yield call(saveSessionToLocalStorage);
        yield call(saveSessionToSessionStorage);

        return true;
    } catch (error: any) {
        console.error("saveSession - ERROR", error);
    }

    return false;
}

export default function* root() {
    yield all([takeLatest("session.restoreSession", restoreSession), takeLatest("session.saveSession", saveSession)]);
}
