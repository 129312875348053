// Import libraries.
import React from "react";
import { connect } from "react-redux";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";

// Import components.
import DefaultPortalContext from "./Default";

// Define the properties accepted by this component.
interface STATE_PROPS {
    session: Session;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS {}

// Map redux state to properties.
const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
    };
};

// Map redux actions/sagas to properties.
const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

class Dashboard extends React.PureComponent<PROPS> {
    constructor(props: PROPS) {
        super(props);
        console.log("Constructing Dashboard");
    }

    render() {
        const { session } = this.props;

        // Render the appropriate portal context view.
        // A portal context is a customized role centric view.
        switch (session.portalContext) {
            case "default":
                return <DefaultPortalContext />;
            default:
                return null;
        }
    }
}

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(Dashboard);
