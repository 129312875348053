// Import types used by this module.
import PortalPrivilege from "types/common/PortalPrivilege";

export interface AvailablePrivilegesAction {
    type:
        'SET_AVAILABLE_PRIVILEGES'
    payload: PortalPrivilege[]
}

export const SET_AVAILABLE_PRIVILEGES = (availablePrivileges: PortalPrivilege[]) : AvailablePrivilegesAction => {
    return {
        type: 'SET_AVAILABLE_PRIVILEGES',
        payload: availablePrivileges
    };
}
