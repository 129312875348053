import { CurrentCompanyAliasAction } from "store/actions/currentCompanyAlias";

import TeamInfo from "types/models/TeamInfo";

const initialState: TeamInfo | null = null;

const reducer = (state: TeamInfo | null = initialState, action: CurrentCompanyAliasAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_CURRENT_COMPANY_ALIAS":
            if (action.payload != null) {
                newState = Object.assign({}, action.payload);
            } else {
                newState = null;
            }

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
