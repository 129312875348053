// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import md5 from "md5";

// Import types.
import PortalState from "types/store";
import User from "types/common/User";

// Import components.
import { Typography, Link } from "@mui/material";
import Timestamp from "components/common/Timestamp";

// Import icons.
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface STATE_PROPS {
    currentUser: User | null;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles> {}

const mapStateToProps = (state: PortalState): STATE_PROPS => {
    return {
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Function): DISPATCH_PROPS => {
    return {};
};

interface STATE {
    dateNow: Date;
}

class UserSummary extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        dateNow: new Date(),
    };
    private interval?: number = undefined;

    componentDidMount = () => {
        this.interval = window.setInterval(() => {
            const currentTime = new Date();

            this.setState({ dateNow: currentTime });
        }, 1000);
    };

    componentWillUnmount = () => {
        window.clearInterval(this.interval);
    };

    render() {
        const { classes, currentUser } = this.props;

        return (
            <div className={classes.root}>
                {currentUser?.email && <img className={classes.avatar} alt={"user-avatar"} src={"https://s.gravatar.com/avatar/" + md5(currentUser.email) + ".jpg?s=96&d=mm"} />}

                <Typography className={classes.fullName} noWrap>
                    {currentUser?.fullName}
                </Typography>

                <Link data-id={currentUser?.profileId} data-name={currentUser?.fullName} data-email={currentUser?.email} data-type={"user"} className={classes.emailAddress} href={"mailto:" + currentUser?.email} noWrap>
                    {currentUser?.email}
                </Link>

                <span className={classes.time}>
                    <AccessTimeIcon />

                    <Timestamp value={this.state.dateNow} timeOnly humanize />
                </span>
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            gap: "0.3125em",
            padding: "1em 0",
        },
        avatar: {
            alignSelf: "center",
            width: "6em",
            height: "6em",
            borderRadius: "50%",
            overflow: "hidden",
        },
        fullName: {
            alignSelf: "center",
            fontWeight: "bold",
        },
        emailAddress: {
            alignSelf: "center",
        },
        time: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            gap: ".3125em",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserSummary));
