import { ReactNode } from "react";
import { Trans } from "@lingui/macro";

export default abstract class BooleanFormatter {
    public static formatBoolean(value?: boolean | null, humanize?: boolean | null): ReactNode {
        if (humanize) {
            return value === true ? <Trans>Yes</Trans> : <Trans>No</Trans>;
        } else {
            return value === true ? <Trans>TRUE</Trans> : <Trans>FALSE</Trans>;
        }
    }
}
