// Import libraries.
import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { withI18n, withI18nProps } from "@lingui/react";
import moment from "moment-timezone";

// Import types.
import SupportedLanguage from "types/enums/SupportedLanguage";
import PortalState from "types/store";

// Import components.
import { Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";

// Import utilities.
import DateTimeFormatter from "utils/formatters/DateTime";

interface STATE_PROPS {
    preferredLanguage: SupportedLanguage;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    className?: string;
    style?: CSSProperties;
    value?: Date | null;
    humanize?: boolean;
    dateOnly?: boolean;
    timeOnly?: boolean;
    showSeconds?: boolean;
    showMilliseconds?: boolean;
    timezone?: string;
    multiline?: boolean;
    renderAsString?: boolean;
    disableTooltip?: boolean;
    empty?: JSX.Element;
    customTooltip?: JSX.Element;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, withI18nProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        preferredLanguage: state.currentUser?.preferredLanguage || SupportedLanguage.ENGLISH,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

interface STATE {}

class Timestamp extends React.Component<PROPS, STATE> {
    render() {
        const { i18n, className, style, value, humanize, dateOnly, timeOnly, showSeconds, showMilliseconds, timezone, multiline, renderAsString, disableTooltip, empty, customTooltip } = this.props;

        if (value == null) {
            return (
                empty ||
                (renderAsString ? (
                    i18n._(t`---`)
                ) : (
                    <Typography>
                        <Trans>---</Trans>
                    </Typography>
                ))
            );
        }

        if (dateOnly && timeOnly) {
            return renderAsString ? (
                i18n._(t`Timestamp cannot be BOTH date-only AND time-only!`)
            ) : (
                <Typography>
                    <Trans>Timestamp cannot be BOTH date-only AND time-only!</Trans>
                </Typography>
            );
        }

        let dateFormat = humanize ? "MMM Do YYYY" : "YYYY-MM-DD";
        let timeFormat = humanize ? "h:mm A z" : "HH:mm z";

        if (humanize) {
            if (showSeconds) timeFormat = "h:mm:ss A z";
            if (showMilliseconds) timeFormat = "h:mm:ss.SSS A z";
        } else {
            if (showSeconds) timeFormat = "HH:mm:ss z";
            if (showMilliseconds) timeFormat = "HH:mm:ss.SSS z";
        }
        const renderedDate = !timeOnly ? (moment(value).isSame(new Date().getTime(), "day") ? i18n._(t`Today`) : DateTimeFormatter.formatDateTimeCustom(value, dateFormat, timezone)) : null;
        const renderedTime = !dateOnly ? DateTimeFormatter.formatDateTimeCustom(value, timeFormat, timezone) : null;

        if (renderAsString) {
            if (dateOnly) {
                return renderedDate;
            } else if (timeOnly) {
                return renderedTime;
            } else {
                return renderedDate + (multiline ? "\n" : " ") + renderedTime;
            }
        } else {
            const renderedTimestamp = multiline ? (
                <span style={{ display: "flex", flexDirection: "column", fontFamily: "inherit", fontSize: "inherit", fontWeight: "inherit" }}>
                    {dateOnly && (
                        <Typography style={{ fontFamily: "inherit", fontSize: "inherit", fontWeight: "inherit" }} noWrap>
                            {renderedDate}
                        </Typography>
                    )}
                    {timeOnly && (
                        <Typography style={{ fontFamily: "inherit", fontSize: "inherit", fontWeight: "inherit" }} noWrap>
                            {renderedTime}
                        </Typography>
                    )}
                    {!dateOnly && !timeOnly && (
                        <>
                            <Typography style={{ fontFamily: "inherit", fontSize: "inherit", fontWeight: "inherit" }} noWrap>
                                {renderedDate}
                            </Typography>
                            <Typography style={{ fontFamily: "inherit", fontSize: "inherit", fontWeight: "bold", marginLeft: "0.625em" }} noWrap>
                                {renderedTime}
                            </Typography>
                        </>
                    )}
                </span>
            ) : (
                <>
                    {dateOnly && (
                        <Typography style={{ fontFamily: "inherit", fontSize: "inherit", fontWeight: "inherit" }} noWrap>
                            {renderedDate}
                        </Typography>
                    )}
                    {timeOnly && (
                        <Typography style={{ fontFamily: "inherit", fontSize: "inherit", fontWeight: "inherit" }} noWrap>
                            {renderedTime}
                        </Typography>
                    )}
                    {!dateOnly && !timeOnly && (
                        <Typography style={{ fontFamily: "inherit", fontSize: "inherit", fontWeight: "inherit" }} noWrap>
                            {renderedDate + " " + renderedTime}
                        </Typography>
                    )}
                </>
            );

            if (!disableTooltip) {
                return (
                    <Tooltip
                        alwaysShow
                        arrow
                        title={
                            customTooltip ? (
                                customTooltip
                            ) : (
                                <span style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography noWrap>{DateTimeFormatter.formatDateTimeCustom(value, showMilliseconds ? "YYYY-MM-DD HH:mm:ss.SSS z" : showSeconds ? "YYYY-MM-DD HH:mm:ss z" : "YYYY-MM-DD HH:mm z", "UTC")}</Typography>
                                    <Typography noWrap>{value.getTime() + " ms"}</Typography>
                                </span>
                            )
                        }
                    >
                        <span className={className} style={style} onClick={this.props.onClick}>
                            {renderedTimestamp}
                        </span>
                    </Tooltip>
                );
            } else {
                return (
                    <span className={className} style={style} onClick={this.props.onClick}>
                        {renderedTimestamp}
                    </span>
                );
            }
        }
    }
}

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(Timestamp));
