import TeamInfo from 'types/models/TeamInfo';

export interface AvailableCompanyAction {
    type:
        'SET_AVAILABLE_COMPANIES'
    payload: TeamInfo[]
}

export const SET_AVAILABLE_COMPANIES = (companies: TeamInfo[]) : AvailableCompanyAction => {
    return {
        type: 'SET_AVAILABLE_COMPANIES',
        payload: companies
    };
}
