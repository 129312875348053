// Import libraries.
import { AbstractSearchPlugin } from "..";
import { I18n } from "@lingui/core";

// Import types.
import SupportedLanguage from "types/enums/SupportedLanguage";
import User from "types/common/User";
import ScreenSettings from "types/common/ScreenSettings";
import { PluginName, Query, SearchConfiguration, SearchResult } from "../../types";

// Import screen data collection.
import { allScreens, getScreenDataForPageKey, ScreenData, superScreens, teamScreens } from "assets/data/screens";

// Import utilities.
import { PAGE_KEY_HINTS, PAGE_NAME_HINTS } from "../../utils";

// A union of all valid hints.
const VALID_HINTS = [...PAGE_KEY_HINTS, ...PAGE_NAME_HINTS];

/**
 * This plugin looks for results that are screen-oriented.
 */
class ScreenPlugin extends AbstractSearchPlugin {
    private i18n: I18n | null = null;
    private currentUser: User | null = null;
    private screenSettings: ScreenSettings[] = [];

    isHintSupported = (query: Query): boolean => {
        return VALID_HINTS.includes(query.hint.toLowerCase());
    };

    configure = (args?: SearchConfiguration | null) => {
        if (args) {
            this.i18n = args.i18n || null;
            this.currentUser = args.currentUser || null;
            this.screenSettings = args.screenSettings || [];
        }
    };

    executeQuery = async (query: Query, abortSignal?: AbortSignal) => {
        if (abortSignal?.aborted) {
            throw new DOMException("Aborted", "AbortError");
        }

        // this.checkPageKeys();

        console.debug("Executing Plugin", PluginName.SCREEN, query);

        // Define a collection to hold the results.
        const searchResults: SearchResult[] = [];

        // Extract the hint and searchTerm from the query.
        const { hint, searchTerm } = query;

        // By default we have an empty list of "screenSettings" and "screenData".
        let applicableScreenSettings: ScreenSettings[] = [];
        let applicableScreenData: ScreenData[] = [];

        if (PAGE_KEY_HINTS.includes(hint.toLowerCase())) {
            // Always include the app related screen settings.
            applicableScreenSettings = this.screenSettings.filter((item) => {
                return item.id.toLowerCase().includes(searchTerm.toLowerCase());
            });
        } else if (PAGE_NAME_HINTS.includes(hint.toLowerCase())) {
            // If the user is logged in as SUPER, then always include the super related screen data.
            if (this.currentUser?.isSuper) {
                superScreens.forEach((item) => {
                    if (this.i18n && item.search?.title) {
                        if (item.search.title(this.i18n).toLowerCase().includes(searchTerm.toLowerCase())) {
                            applicableScreenData.push(item);
                        }
                    }
                });
            }

            // Always include the team related screen data.
            teamScreens.forEach((item) => {
                if (this.i18n && item.search?.title) {
                    if (item.search.title(this.i18n).toLowerCase().includes(searchTerm.toLowerCase())) {
                        applicableScreenData.push(item);
                    }
                }
            });

            // Always include the app related screen settings.
            applicableScreenSettings = this.screenSettings.filter((item) => {
                if (this.currentUser?.preferredLanguage === SupportedLanguage.FRENCH) {
                    return item.namexFr?.toLowerCase().includes(searchTerm.toLowerCase());
                } else {
                    return item.namexEn?.toLowerCase().includes(searchTerm.toLowerCase());
                }
            });
        }

        // Sort the applicable screens by page name.
        applicableScreenSettings.sort((a, b) => {
            if (this.currentUser?.preferredLanguage === SupportedLanguage.FRENCH) {
                if (a.namexFr && b.namexFr) return a.namexFr.localeCompare(b.namexFr);
                if (a.namexFr && !b.namexFr) return -1;
                if (!a.namexFr && b.namexFr) return 1;
            } else {
                if (a.namexEn && b.namexEn) return a.namexEn.localeCompare(b.namexEn);
                if (a.namexEn && !b.namexEn) return -1;
                if (!a.namexEn && b.namexEn) return 1;
            }

            return 0;
        });

        // Generate SearchResult's for each applicable screen.
        applicableScreenSettings.forEach((screenSettings) => {
            const screenData = getScreenDataForPageKey(screenSettings.id);

            searchResults.push({
                type: PluginName.SCREEN,
                targetPath: screenData?.id || "",
                targetState: null,
                searchTerm: searchTerm,
                data: screenSettings,
            });
        });

        // Generate SearchResult's for each applicable super/team screen.
        applicableScreenData.forEach((item) => {
            searchResults.push({
                type: PluginName.SCREEN,
                targetPath: item.id,
                targetState: null,
                searchTerm: searchTerm,
                data: item,
            });
        });

        console.debug("Plugin Results", PluginName.SCREEN, searchResults);

        return searchResults;
    };

    checkPageKeys = () => {
        console.log("Checking page keys / screen data", this.screenSettings.length, allScreens.length);

        this.screenSettings.forEach((screenSettings) => {
            const target = getScreenDataForPageKey(screenSettings.id);

            if (!target) {
                console.log("MISSING PAGE_KEY SUPPORT", screenSettings.id);
            }
        });

        allScreens.forEach((screenData) => {
            const target = this.screenSettings.find((screenSettings) => screenSettings.id === screenData.pageKey) || null;

            if (!target) {
                console.log("MISSING SCREEN_DATA SUPPORT", screenData.pageKey, screenData.id);
            }
        });
    };
}

export default ScreenPlugin;
