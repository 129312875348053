// Import libraries.
import React, { ReactNode } from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";

// Import components.
import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import classnames from "classnames";

// Define the various properties accepted by this component.
interface OWN_PROPS {
    className?: string;
    type?: "circular" | "linear";
    label?: ReactNode;
    hideLabel?: boolean | null;
    indicatorWidth?: number | string;
    value?: number | null;
    disablePadding?: boolean | null;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

interface STATE {
    secondsCount: number;
}

// Styling for this component.
const DEFAULT_INDICATOR_WIDTH = "4em";

class LoadingProgress extends React.Component<PROPS, STATE> {
    state: Readonly<STATE> = {
        secondsCount: 0,
    };
    private interval?: number = undefined;

    componentDidMount = () => {
        this.interval = window.setInterval(() => {
            this.setState({ secondsCount: this.state.secondsCount + 1 });
        }, 1000);
    };

    componentWillUnmount = () => {
        window.clearInterval(this.interval);
    };

    render() {
        const { classes, className, type = "circular", label, hideLabel, indicatorWidth, value, disablePadding } = this.props;

        const { secondsCount } = this.state;

        return (
            <div className={classnames(classes.root, !disablePadding ? classes.padding : undefined, className)}>
                {type === "linear" && (
                    <div className={classes.indicator}>
                        <LinearProgress variant={value != null ? "determinate" : "indeterminate"} value={value != null ? value : undefined} style={{ width: indicatorWidth ? indicatorWidth : DEFAULT_INDICATOR_WIDTH }} />
                    </div>
                )}

                {type === "circular" && (
                    <div className={classes.indicator}>
                        <Box sx={{ position: "relative", display: "inline-flex" }}>
                            <CircularProgress variant={value != null ? "determinate" : "indeterminate"} value={value != null ? value : undefined} size={indicatorWidth ? indicatorWidth : DEFAULT_INDICATOR_WIDTH} />

                            {secondsCount > 4 && (
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography component="div" style={{ color: "var(--primary-color, inherit)", fontWeight: "bold" }}>
                                        {this.state.secondsCount}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </div>
                )}

                {!hideLabel && (
                    <Typography className={classes.label} component={"span"}>
                        {label != null && label}
                        {label == null && <Trans>Loading...</Trans>}
                    </Typography>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",

            position: "relative",

            overflow: "hidden",
        },
        indicator: {
            flex: "0 0 auto",

            color: "var(--progress-indicator-color, inherit)",
        },
        label: {
            flex: "0 0 auto",

            color: "var(--progress-indicator-text-color, inherit)",

            marginTop: "0.625em",
        },
        padding: {
            padding: "0.3125em",
        },
    });

export default withStyles(styles)(LoadingProgress);
