export default interface GameAccess {
    appId: string;
    systemRoles: string[];
    customRoles: string[];
}

export const processGameAccess = (data: any): GameAccess[] => {
    const result: GameAccess[] = [];

    if (data) {
        Object.keys(data).forEach((key) => {
            const item = data[key];

            result.push({
                appId: item.gameId,
                systemRoles: item.systemRoles && Array.isArray(item.systemRoles) ? item.systemRoles : [],
                customRoles: item.customRoles && Array.isArray(item.customRoles) ? item.customRoles : [],
            });
        });
    }

    result.sort((a, b) => a.appId.localeCompare(b.appId));

    return result;
};
