// Import libraries.
import { put, all, takeLatest, select } from "redux-saga/effects";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import PortalPrivilege from "types/common/PortalPrivilege";

// Import redux actions.
import { SET_AVAILABLE_PRIVILEGES } from "store/actions/availablePrivileges";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";

interface PopulateAvailablePrivileges {
    type: "privileges.populateAvailablePrivileges";
}

// Attempts to populate the available privileges that are applicable to the current user.
export function* populateAvailablePrivileges(_action?: PopulateAvailablePrivileges) {
    // Get the current session information.
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);
    const session: Session = yield select(getSession);

    try {
        console.log("Populating Available Privileges...");

        if (session.appId) {
            const response: HttpResponse = yield Http.GET("admin/development/get-application-privileges");
            if (Http.isStatusOk(response) && response.data && typeof response.data === "object") {
                const availablePrivileges: PortalPrivilege[] = [];

                Object.keys(response.data).forEach((field) => {
                    availablePrivileges.push({
                        id: field,
                        permission: Number.parseInt(response.data[field]),
                    });
                });

                availablePrivileges.sort((a, b) => a.id.localeCompare(b.id));

                yield put(SET_AVAILABLE_PRIVILEGES(availablePrivileges));

                return true;
            }
        }
    } catch (error: any) {
        console.error("populateAvailablePrivileges - ERROR", error);
    }

    yield put(SET_AVAILABLE_PRIVILEGES([]));

    return false;
}

export default function* root() {
    yield all([takeLatest("privileges.populateAvailablePrivileges", populateAvailablePrivileges)]);
}
