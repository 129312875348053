// Import libraries.
import { I18n } from "@lingui/core";

// Import types.
import Session from "types/common/Session";
import User from "types/common/User";
import AppInfo from "types/models/AppInfo";
import TeamInfo from "types/models/TeamInfo";
import PortalPrivilege from "types/common/PortalPrivilege";
import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import ScreenSettings from "types/common/ScreenSettings";

// Represents the navigation context.
export enum Context {
    SUPER = "SUPER",
    TEAM = "TEAM",
    APP = "APP",
}

// Represents the available plugins.
export enum PluginName {
    TEAM = "TEAM",
    APP = "APP",
    PLAYER = "PLAYER",
    SCREEN = "SCREEN",
}

// Represents a Query (a hint and searchTerm).
export interface Query {
    hint: string;
    subHint?: string;
    searchTerm: string;
}

// Represents a set of query options that may be utilized by individual plugins.
// These are in addition to the standard search configuration data (see further below).
export interface QueryOptions {
    exhaustiveSearch: boolean;
}

// Represents a set of formating options that may be utilized by search results.
export interface FormatOptions {
    queries?: boolean | null;
    condensed?: boolean | null;
    highlight?: string | null;
}

// Represents the configuration data for the search engine. All of these will be made available to the plugins.
// Each plugin is free to use any data from here to assist in it's own processing.
export interface SearchConfiguration {
    i18n: I18n;
    context?: Context | null;
    session?: Session | null;
    currentUser?: User | null;
    availableCompanies?: TeamInfo[] | null;
    availableApps?: AppInfo[] | null;
    availablePrivileges?: PortalPrivilege[] | null;
    definitions?: PortalRouteDefinition[] | null;
    screenSettings?: ScreenSettings[] | null;
    options?: QueryOptions | null;
}

// Represents a generator (something that generates queries).
export interface QueryGenerator {
    configure?: (args?: SearchConfiguration | null) => void;
    generate: (queryString: string, abortSignal?: AbortSignal) => Promise<Query[]>;
}

// Represents a plugin (something that executes queries).
export interface SearchPlugin {
    processHints?: (queryString: string) => Query | null;
    configure?: (args?: SearchConfiguration | null) => void;
    execute: (queries: Query[] | Query, abortSignal?: AbortSignal) => Promise<SearchResult[]>;
}

// Represents a filter (something that filters search results).
export interface SearchFilter {
    configure?: (args?: SearchConfiguration | null) => void;
    filter: (searchResults: SearchResult[]) => SearchResult[];
}

// Represents a sorter (something that sorts search results).
export interface SearchSort {
    configure?: (args?: SearchConfiguration | null) => void;
    sort: (a: SearchResult, b: SearchResult) => 1 | 0 | -1;
}

// Represents a specific search result.
// Contains all necessary information for rendering as well as data needed for the resulting navigation actiom.
export interface SearchResult {
    type: PluginName;
    targetPath: string;
    targetState?: { [index: string]: any } | null;
    searchTerm: string;
    data?: {
        [index: string]: any;
    };
}
