// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";
import { withWidth, WithWidthProps } from "framework/width";
import { FlyoutContextProvider } from "framework/contextFlyout";

// Import types.
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalPrivilege from "types/common/PortalPrivilege";
import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import { FormatOptions, QueryOptions } from "./components/SearchResults/engine/types";

// Import components.
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Profile from "./components/Profile";
import SearchResults from "./components/SearchResults";
import Info from "components/common/screen/Info";
import LiveLock from "components/common/screen/LiveLock";
import Screen from "components/common/screen/index";
import SystemMessageBanner from "components/common/screen/SystemMessageBanner";
import BreadcrumbBarAndBanner from "./components/BreadcrumbBarAndBanner";

// Import utilities.
import CloneUtils from "utils/Clone";
import PortalRouteUtils from "utils/PortalRoutes";
import CookieConsentUtils, { CookieConsentLevel } from "utils/CookieConsent";

// Import the route definitions for this view context.
import routeDefinitions from "./routes";
import ResizableDrawer from "components/common/ResizableDrawer";
import LocalStorageUtils from "utils/LocalStorage";

interface STATE_PROPS {
    applicationInformation: ApplicationInformation;
    session: Session;
    currentUser: User | null;
    availableCompanies: TeamInfo[];
    availableApps: AppInfo[];
    availablePrivileges: PortalPrivilege[];
    favorites: string[];
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithWidthProps, WithStyles<typeof styles> {}

const mapStateToProps = (state: PortalState) => {
    return {
        applicationInformation: state.applicationInformation,
        session: state.session,
        currentUser: state.currentUser,
        availableCompanies: state.availableCompanies,
        availableApps: state.availableApps,
        availablePrivileges: state.availablePrivileges,
        favorites: state.favorites,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

interface STATE {
    navigatorOpen: boolean;
    navigatorPinned: boolean;

    profileOpen: boolean;
    profilePinned: boolean;

    searchOpen: boolean;
    searchString: string;
    searchQueryOptions: QueryOptions;
    searchFormatOptions: FormatOptions;
    searchForce: boolean;

    bannerOpen: boolean;
    bannerMode: "team" | "app" | "player";

    infoOpen: boolean;

    fullscreen: boolean;
}

class DefaultPortalContext extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        navigatorOpen: this.props.width !== "xs",
        navigatorPinned: this.props.width !== "xs",

        profileOpen: false,
        profilePinned: false,

        searchOpen: false,
        searchString: "",
        searchQueryOptions: {
            exhaustiveSearch: false,
        },
        searchFormatOptions: {
            queries: false,
            condensed: false,
            highlight: this.props.classes.highlightSearchTerm,
        },
        searchForce: false,

        bannerOpen: false,
        bannerMode: "team",

        infoOpen: true,

        fullscreen: false,
    };

    constructor(props: PROPS) {
        super(props);

        console.log("Constructing DefaultPortalContext");
    }

    componentDidMount = () => {
        const isScreenInfoOpen = LocalStorageUtils.getItem("isScreenInfoOpen");

        this.setState({ infoOpen: isScreenInfoOpen === "false" ? false : true });
    };

    componentDidUpdate(prevProps: Readonly<PROPS>): void {
        if (prevProps.width === "xs" && this.props.width !== "xs") {
            this.setState({
                navigatorOpen: true,
                navigatorPinned: true,
                profileOpen: false,
                profilePinned: false,
            });
        }

        if (prevProps.width !== "xs" && this.props.width === "xs") {
            this.setState({
                navigatorOpen: false,
                navigatorPinned: false,
                profileOpen: false,
                profilePinned: false,
            });
        }
    }

    applyFavorites(basePath: string, definitions: PortalRouteDefinition[]) {
        const { favorites } = this.props;

        const basePathComponents = PortalRouteUtils.routePathComponents(basePath);

        const targetDefinition = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, basePath);

        if (targetDefinition && basePathComponents.length > 1) {
            const applicableFavorites = favorites.filter((item) => item.startsWith(PortalRouteUtils.PATH_SEP + basePathComponents[0]));

            targetDefinition.routes = [];

            applicableFavorites.forEach((favoritePath, idx) => {
                const originalDefinition = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, favoritePath);

                if (originalDefinition) {
                    targetDefinition.routes?.push({
                        path: "" + idx,
                        originalPath: favoritePath,
                        hasAccess: originalDefinition.hasAccess,
                        label: originalDefinition.label,
                        icon: originalDefinition.icon,
                        exact: true,
                        component: originalDefinition.component,
                    });
                }
            });

            if (targetDefinition.routes && targetDefinition.routes?.length > 0) {
                targetDefinition.hasAccess = () => true;
                targetDefinition.redirect = "0";
            } else {
                targetDefinition.hasAccess = () => true;
                delete targetDefinition.redirect;
            }

            if (!CookieConsentUtils.isLevelPermitted(CookieConsentLevel.FUNCTIONALITY)) {
                targetDefinition.hasAccess = () => false;
            }
        }
    }

    constructRoutes(basePath: string, definitions: PortalRouteDefinition[]): React.ReactNode[] {
        const { session, currentUser, availableCompanies, availableApps, availablePrivileges } = this.props;

        const results: React.ReactNode[] = [];

        // Process every route definition.
        definitions.forEach((definition) => {
            // Only render the route if the user has access to it (by calling the optional "hasAccess" function from the definition).
            if (definition && (!definition.hasAccess || definition.hasAccess(session, currentUser, availableCompanies, availableApps, availablePrivileges))) {
                // Construct the absolute route path (based on the supplied "basePath" and definition "path").
                const routePath = basePath !== PortalRouteUtils.PATH_SEP ? PortalRouteUtils.absoluteRoutePath(basePath, definition.path) : PortalRouteUtils.absoluteRoutePath(definition.path);

                if (definition.exact) {
                    if (definition.redirect) {
                        const redirectPath = definition.redirect.startsWith(PortalRouteUtils.PATH_SEP) ? definition.redirect : PortalRouteUtils.absoluteRoutePath(routePath, definition.redirect);

                        results.push(
                            <Route key={routePath} exact path={routePath}>
                                <Redirect to={redirectPath} />
                            </Route>
                        );
                    } else {
                        results.push(
                            <Route key={routePath} exact path={routePath}>
                                <Screen id={routePath} originalPath={definition.originalPath || routePath}>
                                    {definition.component}
                                </Screen>
                            </Route>
                        );
                    }
                } else {
                    if (definition.redirect) {
                        const redirectPath = definition.redirect.startsWith(PortalRouteUtils.PATH_SEP) ? definition.redirect : PortalRouteUtils.absoluteRoutePath(routePath, definition.redirect);

                        results.push(
                            <Route key={routePath} path={routePath}>
                                <Redirect to={redirectPath} />
                            </Route>
                        );
                    } else {
                        results.push(
                            <Route key={routePath} path={routePath}>
                                <Screen id={routePath} originalPath={definition.originalPath || routePath}>
                                    {definition.component}
                                </Screen>
                            </Route>
                        );
                    }
                }

                if (definition.routes && definition.routes.length > 0) {
                    results.push(...this.constructRoutes(routePath, definition.routes));
                }
            }
        });

        // Generate a fallback redirect to the FIRST accessible route in the current route tree (if possible)
        //
        // This is done so that when a user changes teams/companies/apps and the current path becomes inaccessible
        // due to new/updated permissions (or any other reason) they would automatically get re-routed to the first
        // accessible route in the current route tree instead of getting potentially booted to an entirely different
        // section ("Super" for super users and "Team" for non-super users) instead of remaining under the current
        // top-level section (if any sub-route is still accessbile).
        //
        if (definitions.length > 0) {
            // Determine the first accessible route (based on a permission check, if applicable).
            const firstAccessibleRoute = definitions.find((definition) => {
                // return definition && (!definition.hasAccess || definition.hasAccess(session, currentUser, availableCompanies, availableApps, availablePrivileges));

                // SPECIAL CASE: Ignore the '/debug' top-level route.
                return (
                    definition &&
                    PortalRouteUtils.absoluteRoutePath(basePath, PortalRouteUtils.sanitizeRoutePath(definition.path)) !== "/debug" &&
                    (!definition.hasAccess || definition.hasAccess(session, currentUser, availableCompanies, availableApps, availablePrivileges))
                );
            });

            if (firstAccessibleRoute) {
                // Add default fallback to the first accessible route for unrecognized route paths.
                results.push(
                    <Route key={"__fallback__"} path={PortalRouteUtils.absoluteRoutePath(basePath)}>
                        <Redirect to={PortalRouteUtils.absoluteRoutePath(basePath, PortalRouteUtils.sanitizeRoutePath(firstAccessibleRoute.path))} />
                    </Route>
                );
            }
        }

        return results;
    }

    onToggleNavigatorOpen = (open?: boolean) => {
        this.setState({ navigatorOpen: open != null ? open : !this.state.navigatorOpen });
    };

    onToggleNavigatorPinned = (pinned?: boolean) => {
        this.setState({ navigatorPinned: pinned != null ? pinned : !this.state.navigatorPinned }, () => {
            if (!this.state.navigatorPinned) {
                this.onToggleNavigatorOpen(false);
            }
        });
    };

    onToggleProfileOpen = (open?: boolean) => {
        this.setState({ profileOpen: open != null ? open : !this.state.profileOpen });
    };

    onToggleProfilePinned = (pinned?: boolean) => {
        this.setState({ profilePinned: pinned != null ? pinned : !this.state.profilePinned }, () => {
            if (!this.state.profilePinned) {
                this.onToggleProfileOpen(false);
            }
        });
    };

    onToggleSearchOpen = (open?: boolean) => {
        this.setState({ searchOpen: open != null ? open : !this.state.searchOpen }, () => {
            if (this.state.searchOpen) {
                if (!this.state.navigatorPinned) {
                    this.onToggleNavigatorOpen(false);
                }

                if (!this.state.profilePinned) {
                    this.onToggleProfileOpen(false);
                }
            }
        });
    };

    handleSearchChange = (search: string, force?: boolean) => {
        this.setState({ searchString: search, searchForce: force === true });
    };

    handleSearchComplete = () => {
        this.setState({ searchForce: false });
    };

    handleSearchQueryOptionChange = (name: string, value: any) => {
        const { searchQueryOptions } = this.state;

        const updatedSearchQueryOptions = CloneUtils.clone(searchQueryOptions) as QueryOptions;

        switch (name) {
            case "exhaustiveSearch":
                updatedSearchQueryOptions.exhaustiveSearch = value;

                break;
            default:
            // Do nothing.
        }

        this.setState({ searchQueryOptions: updatedSearchQueryOptions });
    };

    handleSearchFormatOptionChange = (name: string, value: any) => {
        const { classes } = this.props;
        const { searchFormatOptions } = this.state;

        const updatedSearchFormatOptions = CloneUtils.clone(searchFormatOptions) as FormatOptions;

        switch (name) {
            case "queries":
                updatedSearchFormatOptions.queries = value;

                break;
            case "condensed":
                updatedSearchFormatOptions.condensed = value;

                break;
            case "highlight":
                updatedSearchFormatOptions.highlight = value ? classes.highlightSearchTerm : null;

                break;
            default:
            // Do nothing.
        }

        this.setState({ searchFormatOptions: updatedSearchFormatOptions });
    };

    onToggleBannerOpen = (mode: "team" | "app" | "player", open?: boolean, saveToLocalStorage?: boolean) => {
        const { bannerOpen } = this.state;

        if (open != null && saveToLocalStorage) {
            LocalStorageUtils.setItem("isBannerOpen", String(open), CookieConsentLevel.FUNCTIONALITY);
        }

        this.setState({ bannerOpen: open != null ? open : bannerOpen, bannerMode: mode });
    };

    onToggleInfoOpen = () => {
        const { infoOpen } = this.state;

        this.setState({ infoOpen: !infoOpen }, () => {
            LocalStorageUtils.setItem("isScreenInfoOpen", String(!infoOpen), CookieConsentLevel.FUNCTIONALITY);
        });
    };

    onToggleFullscreen = () => {
        const { navigatorPinned, profilePinned, fullscreen } = this.state;

        this.setState({ fullscreen: !fullscreen, navigatorOpen: fullscreen && navigatorPinned ? true : false, profileOpen: fullscreen && profilePinned ? true : false });
    };

    render() {
        const { width, isWidthUp, classes, applicationInformation, session, currentUser } = this.props;
        const { navigatorOpen, navigatorPinned, profileOpen, profilePinned, searchOpen, searchString, searchForce, searchQueryOptions, searchFormatOptions, bannerOpen, bannerMode, infoOpen, fullscreen } = this.state;

        // If for some reason there is no session or currentUser then just return null;
        // This prevents unnecessary rendering when we are not completely logged in.
        if (!session || !currentUser) return null;

        // Enhance the route definitions with any applicable favorites.
        // IMPORTANT: The definitions are being modified in place. Meaning the array/collection is always the same one.
        //            Keep this in mind when passing the definitions around as props.
        //            If you want to trigger a re-render of a component based on changes to the definitions, then use
        //            the spread operater or explicitly detemine if there are in fact changes.
        this.applyFavorites("/super/favorites", routeDefinitions);
        this.applyFavorites("/team/favorites", routeDefinitions);
        this.applyFavorites("/app/favorites", routeDefinitions);
        this.applyFavorites("/user/favorites", routeDefinitions);

        // Construct the full react router tree.
        const renderedRoutes = this.constructRoutes("/", routeDefinitions);

        // Append default fallback routes (in case the current location path didn't match any of the rendered routes).
        // This is a safety net in case of messed up route definitions being present.
        if (session.isSuper) {
            // If the user is logged in as SUPER, fallback based on companyIdAlias and appId.
            if (session.companyIdAlias) {
                // If companyIdAlias is present, fallback based on appId.
                if (session.appId) {
                    // If appId is present, fallback to the design route.
                    renderedRoutes.push(<Redirect key={"__fallback__"} to={"/app"} />);
                } else {
                    // If appId is NOT present, fallback to the team route.
                    renderedRoutes.push(<Redirect key={"__fallback__"} to={"/team"} />);
                }
            } else {
                // If companyIdAlias is NOT present, fallback to the super route.
                renderedRoutes.push(<Redirect key={"__fallback__"} to={"/super"} />);
            }
        } else {
            // If the user is NOT logged in as SUPER, fallback based on appId.
            if (session.appId) {
                // If appId is present, fallback to the design route.
                renderedRoutes.push(<Redirect key={"__fallback__"} to={"/app"} />);
            } else {
                // If appId is NOT present, fallback to the team route.
                renderedRoutes.push(<Redirect key={"__fallback__"} to={"/team"} />);
            }
        }

        return (
            <div id="portal-context-default" className={classes.root}>
                <Router>
                    <Route exact path={"/"}>
                        {session.isSuper && <Redirect to={"/super"} />}
                        {!session.isSuper && <Redirect to={"/team"} />}
                    </Route>

                    <Route path={"/"}>
                        {!fullscreen && (
                            <Header
                                navigatorOpen={navigatorOpen}
                                navigatorPinned={navigatorPinned}
                                profileOpen={profileOpen}
                                profilePinned={profilePinned}
                                onToggleNavigatorOpen={this.onToggleNavigatorOpen}
                                onToggleNavigatorPinned={this.onToggleNavigatorPinned}
                                onToggleProfileOpen={this.onToggleProfileOpen}
                                onToggleProfilePinned={this.onToggleProfilePinned}
                                onToggleSearchOpen={this.onToggleSearchOpen}
                                searchOpen={searchOpen}
                                searchString={searchString}
                                onSearchChange={this.handleSearchChange}
                            />
                        )}

                        <div className={classes.contentWrapper}>
                            <ResizableDrawer
                                style={{ zIndex: 1 }}
                                anchor="left"
                                open={navigatorOpen}
                                disableResize
                                size={isWidthUp("xs", width) ? 300 : "100%"}
                                disableBoxShadow={navigatorPinned}
                                onClickAway={() => (!navigatorPinned ? this.onToggleNavigatorOpen(false) : null)}
                            >
                                <Navigation
                                    open={navigatorOpen}
                                    pinned={navigatorPinned}
                                    definitions={[...routeDefinitions]}
                                    onToggleOpen={this.onToggleNavigatorOpen}
                                    onTogglePinned={this.onToggleNavigatorPinned}
                                    onToggleProfileOpen={this.onToggleProfileOpen}
                                />
                            </ResizableDrawer>

                            <div className={classes.content} style={fullscreen ? { zIndex: 0, position: "fixed", width: "100%", height: "100%" } : { zIndex: 0, marginLeft: navigatorOpen && navigatorPinned ? 300 : 0 }}>
                                <div
                                    style={{
                                        position: "relative",

                                        flex: "1 1 auto",
                                        display: "flex",
                                        flexDirection: "column",

                                        backgroundColor: "inherit",
                                        color: "inherit",
                                        borderColor: "inherit",

                                        overflow: "hidden",
                                    }}
                                >
                                    <FlyoutContextProvider providerId={"dashboard"}>
                                        {!applicationInformation.loadingLoginState && !applicationInformation.loadingBasicState && (
                                            <>
                                                {!fullscreen && <BreadcrumbBarAndBanner definitions={[...routeDefinitions]} bannerOpen={bannerOpen} bannerMode={bannerMode} onToggle={this.onToggleBannerOpen} />}

                                                <SystemMessageBanner style={{ margin: "0.625em" }} />

                                                <LiveLock />

                                                <Info
                                                    open={infoOpen}
                                                    fullscreen={fullscreen}
                                                    onToggleInfoOpen={this.onToggleInfoOpen}
                                                    onToggleFullscreen={this.onToggleFullscreen}
                                                    bannerOpen={bannerOpen}
                                                    bannerMode={bannerMode}
                                                    definitions={[...routeDefinitions]}
                                                />

                                                <div className={classes.screenContent}>{!applicationInformation.loadingBasicState && <Switch>{renderedRoutes}</Switch>}</div>
                                            </>
                                        )}
                                    </FlyoutContextProvider>
                                </div>
                            </div>

                            <ResizableDrawer
                                style={{ zIndex: 1 }}
                                anchor="right"
                                open={profileOpen}
                                minSize={{
                                    xs: "100%",
                                    sm: 300,
                                    md: 300,
                                    lg: 340,
                                    xl: 380,
                                }}
                                disableBoxShadow={profilePinned}
                                onClickAway={() => (!profilePinned ? this.onToggleProfileOpen(false) : null)}
                            >
                                <Profile open={profileOpen} onToggleOpen={this.onToggleProfileOpen} />
                            </ResizableDrawer>

                            <div className={classnames({ [classes.searchResults]: true, [classes.searchResultsOpen]: searchOpen })} style={{ zIndex: 2 }}>
                                <SearchResults
                                    navigatorOpen={navigatorOpen}
                                    navigatorPinned={navigatorPinned}
                                    profileOpen={profileOpen}
                                    profilePinned={profilePinned}
                                    onToggleNavigatorOpen={this.onToggleNavigatorOpen}
                                    onToggleNavigatorPinned={this.onToggleNavigatorPinned}
                                    onToggleProfileOpen={this.onToggleProfileOpen}
                                    onToggleProfilePinned={this.onToggleProfilePinned}
                                    open={searchOpen}
                                    query={searchString}
                                    force={searchForce}
                                    queryOptions={searchQueryOptions}
                                    formatOptions={searchFormatOptions}
                                    definitions={routeDefinitions}
                                    onClose={() => this.onToggleSearchOpen(false)}
                                    onQueryOptionChange={this.handleSearchQueryOptionChange}
                                    onFormatOptionChange={this.handleSearchFormatOptionChange}
                                    onSearchComplete={this.handleSearchComplete}
                                />
                            </div>
                        </div>
                    </Route>
                </Router>
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",

            position: "relative",

            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },

        searchResults: {
            position: "absolute",

            top: 0,
            left: 0,
            right: 0,

            height: 0,

            transition: "height 0.2s linear",

            backgroundColor: "var(--popup-menu-background-color, inherit)",
            color: "var(--popup-menu-color, inherit)",
            borderColor: "var(--popup-menu-border-color, inherit)",

            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },

            [theme.breakpoints.up("sm")]: {
                width: "37.5em",

                marginLeft: "calc((100% - 37.5em) / 2)",
                marginRight: "calc((100% - 37.5em) / 2)",

                overflow: "hidden",
            },
        },
        searchResultsOpen: {
            [theme.breakpoints.down("sm")]: {
                height: "100%",
            },

            [theme.breakpoints.up("sm")]: {
                height: "50%",

                borderStyle: "solid",
                borderWidth: "0.0625em",
                borderBottomLeftRadius: "0.25em",
                borderBottomRightRadius: "0.25em",

                boxShadow: "var(--standard-box-shadow)",
            },
        },

        contentWrapper: {
            width: "100%",
            height: "100%",

            position: "relative",

            display: "flex",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },
        content: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",

            transition: "margin 0.2s linear",
        },
        screenContent: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "hidden",
            overflowY: "auto",
        },

        highlightSearchTerm: {
            color: "var(--label-default-color, inherit)",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(DefaultPortalContext)));
