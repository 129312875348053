import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
import rootSaga from "../sagas";

// Create a saga middleware.
export const sagaMiddleware = createSagaMiddleware();

export const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

// Create our redux store with the applicable middleware.
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

// Run the root saga to tie everything together.
sagaMiddleware.run(rootSaga);

// Export our redux store.
export default store;
