import ServerStatus from "types/common/ServerStatus";

/**
 * Fetches the current statuspage.io status for the brainCloud environment.
 *
 * @param page
 * @returns
 */
const getServerStatus = async (page: string) => {
    const statusPage = new (window as any).StatusPage.page({ page: page });

    return new Promise<ServerStatus>((resolve, reject) => {
        statusPage.status({
            success: (data: any) => {
                try {
                    resolve({
                        message: data.status.description,
                        status: data.status.indicator,
                    });
                } catch (error) {
                    console.error("StatusPage.IO Error: Could not extract response", error);

                    reject();
                }
            },
            error: (data: any) => {
                console.error("StatusPage.IO Error", data);

                reject();
            },
        });
    });
};

const Services = {
    getServerStatus: getServerStatus,
};

export default Services;
