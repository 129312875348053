import DevelopmentType from "types/enums/DevelopmentType";
import AccountStatus from "types/enums/AccountStatus";
import CompanyStatus from "types/enums/CompanyStatus";
import ServiceStatus from "types/enums/ServiceStatus";
import AppInfo from "./AppInfo";
import TeamAccess, { processTeamAccess } from "./TeamAccess";
import TeamMemberInfo from "./TeamMemberInfo";
import TermsOfServiceAcceptance, { processTermsOfServiceAcceptance } from "./TermsOfServiceAcceptance";

export default interface TeamInfo {
    // Common.
    createdAt?: Date;
    updatedAt?: Date;

    companyId: string;
    companyName: string;

    developmentType?: DevelopmentType;

    contactName?: string;
    contactEmail?: string;
    contactPhoneNumber?: string;

    accountNumber?: string;
    accountOwnerEmail?: string;
    accountPortalUser?: string | null;
    accountRefreshedAt?: Date;

    imageUrl?: string | null;
    absoluteImageUrl?: string | null;

    // Status.
    accountStatus?: AccountStatus;
    serviceStatus?: ServiceStatus;
    companyStatus?: CompanyStatus;

    //Credit Information
    accountCredits?: number;

    availableFunds?: number;

    // Counts.
    appCount?: number;
    memberCount?: number;

    // Boolean Flags.
    accountHasPaymentMethods?: boolean;
    apiEnabled?: boolean;

    isAccountConfigured?: boolean;
    isBillable?: boolean;
    isEnabled?: boolean;
    isSuper?: boolean;
    isAdmin?: boolean;

    // App IDs / Apps.
    appIds?: string[];
    apps?: AppInfo[];

    // Team Members.
    members?: TeamMemberInfo[];

    // Team Member Access.
    teamAccess?: TeamAccess[];

    // Terms Of Service Acceptances.
    tosAcceptances?: TermsOfServiceAcceptance[];

    // Other.
    vanityURL?: string | null;
}

export const processTeamInfo = (data: any): TeamInfo => {
    return {
        createdAt: data.createdAt != null ? new Date(data.createdAt) : undefined,
        updatedAt: data.updatedAt != null ? new Date(data.updatedAt) : undefined,

        companyId: data.companyId,
        companyName: data.companyName,

        developmentType: data.developmentType,

        contactName: data.contactName,
        contactEmail: data.contactEmail,
        contactPhoneNumber: data.contactPhoneNumber,

        accountNumber: data.accountNumber,
        accountOwnerEmail: data.accountOwnerEmail,
        accountPortalUser: data.accountPortalUser,
        accountRefreshedAt: data.accountRefreshedAt != null ? new Date(data.accountRefreshedAt) : undefined,

        imageUrl: data.imageUrl,
        absoluteImageUrl: data.absoluteImageUrl,

        accountStatus: data.accountStatus,
        serviceStatus: data.serviceStatus,
        companyStatus: data.companyStatus,
        accountCredits: data.accountCredits,
        availableFunds: data.availableFunds,

        appCount: data.appCount != null ? Number.parseInt(data.appCount) : 0,
        memberCount: data.memberCount != null ? Number.parseInt(data.memberCount) : data.playerCount != null ? Number.parseInt(data.playerCount) : 0,

        accountHasPaymentMethods: data.accountHasPaymentMethods === true ? true : false,
        apiEnabled: data.apiEnabled === true ? true : false,

        isAccountConfigured: data.isAccountConfigured === true ? true : false,
        isBillable: data.isBillable === true ? true : false,
        isEnabled: data.isEnabled === true ? true : false,
        isSuper: data.isBrainCloud === true || data.isSuper === true ? true : false,
        isAdmin: data.isTeamAdmin === true ? true : false,

        appIds: data.appIds && Array.isArray(data.appIds) ? data.appIds : data.games && Array.isArray(data.games) ? data.games : undefined,

        apps: undefined,

        members: undefined,

        teamAccess: data.teamAccess != null ? processTeamAccess(data.teamAccess) : undefined,

        tosAcceptances: data.tosAcceptances != null ? processTermsOfServiceAcceptance(data.tosAcceptances) : undefined,

        vanityURL: data.vanityURL,
    };
};
