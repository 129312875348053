// Import libraries.
import React, { ReactNode, useCallback, useEffect } from "react";

// Import types.
import ApplicationInformation from "types/common/ApplicationInformation";
import Session from "types/common/Session";
import User from "types/common/User";

const IntercomWidget = (props: { applicationInformation: ApplicationInformation; session: Session; currentUser: User; children?: ReactNode }) => {
    const { applicationInformation, currentUser } = props;

    // Extract some fields from the application information.
    const isLoadingBasicState = applicationInformation.loadingBasicState;
    const intercomEnabled = applicationInformation.intercomConfiguration.enabled;
    const intercomAppId = applicationInformation.intercomConfiguration.appId;

    // Get the reference to the Intercom Javascript API.
    const Intercom = (window as any).Intercom;

    const initializeIntercom = useCallback(async () => {
        if (!Intercom) return;

        if (intercomEnabled && intercomAppId) {
            const intercomData = {
                app_id: intercomAppId,
                hide_default_launcher: true,
            };

            Intercom("boot", intercomData);
        }
    }, [intercomEnabled, intercomAppId, Intercom]);

    const updateIntercom = useCallback(async () => {
        if (!Intercom) return;

        if (!isLoadingBasicState && intercomEnabled && intercomAppId && currentUser && currentUser.intercomUserHash) {
            console.log("Updating Intercom...");

            const intercomData = {
                app_id: intercomAppId,
                email: currentUser.email ? currentUser.email : undefined,
                name: currentUser.fullName ? currentUser.fullName : undefined,
                created_at: currentUser.createdAt ? "" + currentUser.createdAt.getTime() / 1000 : undefined,
                user_hash: currentUser.intercomUserHash ? currentUser.intercomUserHash : undefined,
                language_override: currentUser.preferredLanguage ? currentUser.preferredLanguage : undefined,
                hide_default_launcher: true,
                custom_launcher_selector: ".bc-intercom-launcher",
            };

            Intercom("update", intercomData);
        }
    }, [isLoadingBasicState, intercomEnabled, intercomAppId, currentUser, Intercom]);

    useEffect(() => {
        initializeIntercom();
    }, [initializeIntercom]);

    useEffect(() => {
        updateIntercom();
    }, [updateIntercom]);

    return null;
};

export default React.memo(IntercomWidget);
