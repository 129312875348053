import { ScreenSettingsAction } from "store/actions/screenSettings";

import ScreenSettings from "types/common/ScreenSettings";

const initialState: ScreenSettings[] = [];

const reducer = (state: ScreenSettings[] = initialState, action: ScreenSettingsAction) => {
    let newState = state;

    switch (action.type) {
        case 'SET_SCREEN_SETTINGS':
            newState = [...action.payload];

            break;
        default:
            // Do nothing.
    }

    return newState;
};

export default reducer;
