import StringUtils from "utils/String";

export default interface SystemPrivilege {
    id: string;
    parentId: string | null;
    nameEn: string | null;
    nameFr: string | null;
    descriptionEn: string | null;
    descriptionFr: string | null;
    sortCategory: string | null;
    sortMajor: number | null;
    sortMinor: number | null;
    isDependent: boolean;
    isProtected: boolean;
    isShowable: boolean;
    permissions: number;
    superOnly: boolean;
}

export const processSystemPrivilege = (data: any): SystemPrivilege => {
    return {
        id: "" + data["privilege-id"],
        parentId: data["parent-id"] != null ? "" + data["parent-id"] : null,
        nameEn: data["namex"] != null ? "" + data["namex"] : null,
        nameFr: data["namex-fr"] != null ? "" + data["namex-fr"] : null,
        descriptionEn: data["description"] != null ? "" + data["description"] : null,
        descriptionFr: data["description-fr"] != null ? "" + data["description-fr"] : null,
        sortCategory: data["sort-category"] != null ? "" + data["sort-category"] : null,
        sortMajor: data["sort-major"] != null ? Number.parseInt(data["sort-major"]) : null,
        sortMinor: data["sort-minor"] != null ? Number.parseInt(data["sort-minor"]) : null,
        isDependent: StringUtils.isTruthy(data["is-dependent"]),
        isProtected: StringUtils.isTruthy(data["is-protected"]),
        isShowable: StringUtils.isTruthy(data["is-showable"]),
        permissions: Number.parseInt(data["permissions"]),
        superOnly: StringUtils.isTruthy(data["super-only"]),
    };
};
