export default interface CredentialInfo {
    // Common.
    fullName: string;
    email: string;

    // Comapny / Team Information.
    companyId: string;
    companyIds: string[];

    // Boolean Flags.
    isActive: boolean;
    isEnabled: boolean;

    // Dates / Timestamps.
    createdAt: Date;
    lastLogin: Date | null;
}

export const processCredentialInfo = (data: any): CredentialInfo => {
    return {
        fullName: data.fullName,
        email: data.email,

        companyId: data.companyId,
        companyIds: Array.isArray(data.companyIds) ? data.companyIds : [],

        isActive: data.isActive === true ? true : false,
        isEnabled: data.isEnabled === true ? true : false,

        createdAt: new Date(data.createdAt),
        lastLogin: data.lastLogin != null ? new Date(data.lastLogin) : null,
    };
};
