export default interface ApiKeyInfo {
    apiKey: string;
    apiKeyId: string;
    description: string;
    scope: "superadmin" | "team" | "app" | "user";
    createdAt: Date;
    expiresOn: Date;
    lastUsed: Date | null;
    lastFailed: Date | null;
    teams: string[];
}

export const processApiKeyInfo = (data: any): ApiKeyInfo => {
    return {
        apiKey: data.apiKey,
        apiKeyId: data.apiKeyId,
        description: data.desc,
        scope: data.scope ? data.scope : "user",
        createdAt: data.createdAt != null && data.createdAt > 0 ? new Date(data.createdAt) : new Date(0),
        expiresOn: data.expiresOn != null && data.expiresOn > 0 ? new Date(data.expiresOn) : new Date(0),
        lastUsed: data.lastUsed != null && data.lastUsed > 0 ? new Date(data.lastUsed) : null,
        lastFailed: data.lastFailed != null && data.lastFailed > 0 ? new Date(data.lastFailed) : null,
        teams: data.teams && Array.isArray(data.teams) ? data.teams.filter((item: any) => typeof item === "string") : [],
    };
};
