export default interface AppAggregateCounts {
    account: {
        count: number;
        byPlatform: { [index: string]: number };
    };

    bulkOpsCount: number;

    ccScriptInvokeCount: number;

    companyId: string | null;

    createdAt?: Date;

    currentAccountCount: number;

    dauCount: number;

    dispV1CallCount: number;

    emailsSentCount: number;

    freePeerApiCallCount: number;

    gameId: string;

    kbDownloaded: number;

    mauCount: number;

    monthlyHistory: { [index: string]: any };

    notification: {
        count: number;
        byTemplateId: { [index: string]: number };
        byPromotionId: { [index: string]: number };
    };

    offboardEventCount: number;

    purchase: {
        count: number;
        byPlatform: { [index: string]: number };
    };

    revenue: {
        count: number;
        byPlatform: { [index: string]: number };
    };

    scheduledScriptCallCount: number;

    session: {
        count: number;
        byPlatform: { [index: string]: number };
        byLibrary: { [index: string]: number };
        byLibraryVersion: { [index: string]: number };
    };

    updatedAt?: Date;
}

export const processAppAggregateCounts = (data: any): AppAggregateCounts => {
    return {
        account: {
            count: data.accountCount != null ? Number.parseInt(data.accountCount) : 0,
            byPlatform: data.accountCountPlatform || {},
        },
        bulkOpsCount: data.bulkOpsCount != null ? Number.parseInt(data.bulkOpsCount) : 0,
        ccScriptInvokeCount: data.ccScriptInvokeCount != null ? Number.parseInt(data.ccScriptInvokeCount) : 0,
        companyId: data.companyId != null ? data.companyId : null,
        createdAt: data.createdAt ? new Date(data.createdAt) : undefined,
        currentAccountCount: data.currentAccountCount != null ? Number.parseInt(data.currentAccountCount) : 0,
        dauCount: data.dauCount != null ? Number.parseInt(data.dauCount) : 0,
        dispV1CallCount: data.dispV1CallCount != null ? Number.parseInt(data.dispV1CallCount) : 0,
        emailsSentCount: data.emailsSentCount != null ? Number.parseInt(data.emailsSentCount) : 0,
        freePeerApiCallCount: data.freePeerApiCallCount != null ? Number.parseInt(data.freePeerApiCallCount) : 0,
        gameId: data.gameId != null ? data.gameId : "",
        kbDownloaded: data.kbDownloaded != null ? Number.parseInt(data.kbDownloaded) : 0,
        mauCount: data.mauCount != null ? Number.parseInt(data.mauCount) : 0,
        monthlyHistory: data.monthlyHistory || {},
        notification: {
            count: data.notificationCount != null ? Number.parseInt(data.notificationCount) : 0,
            byTemplateId: data.notificationCountPromotionId || {},
            byPromotionId: data.notificationCountTemplateId || {},
        },
        offboardEventCount: data.offboardEventCount != null ? Number.parseInt(data.offboardEventCount) : 0,
        purchase: {
            count: data.purchaseCount != null ? Number.parseInt(data.purchaseCount) : 0,
            byPlatform: data.purchaseCountPlatform || {},
        },
        revenue: {
            count: data.revenue != null ? Number.parseInt(data.revenue) : 0,
            byPlatform: data.revenuePlatform || {},
        },
        scheduledScriptCallCount: data.scheduledScriptCallCount != null ? Number.parseInt(data.scheduledScriptCallCount) : 0,
        session: {
            count: data.sessionCount != null ? Number.parseInt(data.sessionCount) : 0,
            byPlatform: data.sessionCountPlatform || {},
            byLibrary: data.sessionCountLibrary || {},
            byLibraryVersion: data.sessionCountLibraryVersion || {},
        },
        updatedAt: data.updatedAt ? new Date(data.updatedAt) : undefined,
    };
};
