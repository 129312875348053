import Http, { HttpResponse } from "utils/networking/Http";

const updateUserPreferredThemeMode = async (profileId: string, themeMode: "dark" | "light"): Promise<void> => {
    const params: any = {
        profileId,
        themeMode,
    };

    const response: HttpResponse = await Http.POST("admin/useradmin/update-user-details", params, undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const Services = {
    updateUserPreferredThemeMode: updateUserPreferredThemeMode,
};

export default Services;
