import { editor } from "monaco-editor";

import CloudCodeEditor from "types/common/CloudCodeEditor";
import CloudCodeFileList, { CloudCodeScriptVersion, CloudCodeScriptWithContent } from "types/models/CloudCodeFileList";

import { CloudCodeEditorAction } from "store/actions/cloudCodeEditor";

const initialState: CloudCodeEditor = {
    fileList: {
        rootFolder: {
            type: "folder",
            parentId: "",
            id: "",
            name: "",
            description: "",
            folderPath: "",
            folders: [],
            files: [],
            totalFileCount: 0,
        },

        recoveredFolderId: null,
        templatesFolderId: null,

        treeVersion: 0,

        isLoading: false,
    },
    archivedScriptVersions: {},
    scriptsWithContent: {},
    openScriptsWithContent: {},
    viewStates: {},
};

const reducer = (state: CloudCodeEditor = initialState, action: CloudCodeEditorAction) => {
    let newState: CloudCodeEditor | null = null;

    switch (action.type) {
        case "CLOUD_CODE_EDITOR_RESET":
            newState = initialState;

            break;
        case "CLOUD_CODE_EDITOR_SET_FILE_LIST":
            if (action.payload) {
                newState = { ...state };

                newState.fileList = action.payload as CloudCodeFileList;
            }

            break;
        case "CLOUD_CODE_EDITOR_SET_ARCHIVED_SCRIPT_VERSIONS":
            if (action.payload) {
                newState = { ...state };

                newState.archivedScriptVersions = { ...state.archivedScriptVersions };

                const archivedScriptVersions_payload = action.payload as { scriptId: string; archivedScriptVersions: CloudCodeScriptVersion[] | null };

                if (archivedScriptVersions_payload.archivedScriptVersions != null) {
                    newState.archivedScriptVersions[archivedScriptVersions_payload.scriptId] = archivedScriptVersions_payload.archivedScriptVersions;
                } else {
                    delete newState.archivedScriptVersions[archivedScriptVersions_payload.scriptId];
                }
            }

            break;
        case "CLOUD_CODE_EDITOR_SET_SCRIPTS_WITH_CONTENT":
            if (action.payload) {
                newState = { ...state };

                newState.scriptsWithContent = { ...state.scriptsWithContent };

                const scriptsWithContent_payload = action.payload as { scriptId: string; scriptsWithContent: CloudCodeScriptWithContent[] | null };

                if (scriptsWithContent_payload.scriptsWithContent != null) {
                    newState.scriptsWithContent[scriptsWithContent_payload.scriptId] = scriptsWithContent_payload.scriptsWithContent;
                } else {
                    delete newState.scriptsWithContent[scriptsWithContent_payload.scriptId];
                }
            }

            break;
        case "CLOUD_CODE_EDITOR_SET_OPEN_SCRIPTS_WITH_CONTENT":
            if (action.payload) {
                newState = { ...state };

                newState.openScriptsWithContent = { ...state.openScriptsWithContent };

                const scriptWithContent_payload = action.payload as { scriptId: string; scriptWithContent: CloudCodeScriptWithContent | null };

                if (scriptWithContent_payload.scriptWithContent != null) {
                    newState.openScriptsWithContent[scriptWithContent_payload.scriptId] = scriptWithContent_payload.scriptWithContent;
                } else {
                    delete newState.openScriptsWithContent[scriptWithContent_payload.scriptId];
                }
            }

            break;
        case "CLOUD_CODE_EDITOR_SET_VIEW_STATE":
            if (action.payload) {
                newState = { ...state };

                newState.viewStates = { ...state.viewStates };

                const viewState_payload = action.payload as { scriptId: string; viewState: editor.ICodeEditorViewState | null };

                if (viewState_payload.viewState != null) {
                    newState.viewStates[viewState_payload.scriptId] = viewState_payload.viewState;
                } else {
                    delete newState.viewStates[viewState_payload.scriptId];
                }
            }

            break;
        default:
        // Do nothing.
    }

    return newState ? newState : state;
};

export default reducer;
