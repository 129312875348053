export interface FavoritesAction {
    type: "SET_FAVORITES";
    payload: string[];
}

export const SET_FAVORITES = (favorites: string[]): FavoritesAction => {
    return {
        type: "SET_FAVORITES",
        payload: favorites,
    };
};
