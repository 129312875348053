// Import libraries.
import { all, call, takeLatest } from "redux-saga/effects";

import { companyGeneratorMap } from "./companySagas";
import { appGeneratorMap } from "./appSagas";

interface CallMultipleSagas {
    type: "utility.callMultipleSagas";
    payload: { type: string; payload: any }[];
}

// Attempts to call each supplied Saga in sequence.
export function* callMultipleSagas(action: CallMultipleSagas) {
    try {
        for (let x = 0; x < action.payload.length; x++) {
            const target = action.payload[x];

            let targetGenerator = (companyGeneratorMap as any)[target.type] || (appGeneratorMap as any)[target.type] || null;

            if (targetGenerator) {
                console.debug("Calling Saga", target.type, target.payload);

                yield call(targetGenerator, target);
            } else {
                console.warn("Target Saga Not Found", target.type);
            }
        }

        return true;
    } catch (error: any) {
        console.error("callMultipleSagas - ERROR", error);
    }

    return false;
}

export default function* root() {
    yield all([takeLatest("utility.callMultipleSagas", callMultipleSagas)]);
}
