// Import libraries.
import React from "react";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// Import types.
import PortalState from "types/store";
import User from "types/common/User";
import AppInfo from "types/models/AppInfo";
import RecentIntercomUser from "types/models/RecentIntercomUser";

// Import components.
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import CustomDialog from "components/common/dialog/CustomDialog";
import FieldWrapper from "components/common/form/FieldWrapper";
import CustomMenu from "components/common/menu";
import Button from "components/common/button/Button";
import Timestamp from "components/common/Timestamp";

// Import icons.
import CheckIcon from "@mui/icons-material/Check";

// Import services.
import Services from "../services";

interface STATE_PROPS {
    currentUser: User | null;
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    appInfo: AppInfo;
    onClose: (submitted: boolean) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS {}

interface STATE {
    anchorEl: any;
    recentUsers: RecentIntercomUser[];
    toUsers: RecentIntercomUser[];
    subject: string;
    body: string;
    isBusy: boolean;
}

const mapStateToProps = (state: PortalState) => {
    return {
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

class SendIntercomMessageDialog extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        anchorEl: null,
        recentUsers: [],
        toUsers: [],
        subject: "",
        body: "",
        isBusy: false,
    };

    async componentDidMount() {
        const { appInfo } = this.props;

        try {
            this.setState({ isBusy: true });

            const recentUsers = await Services.getRecentIntercomUsers(appInfo.companyId, appInfo.appId);

            let subject = `[Important!] Issue with ${appInfo.appName} (${appInfo.appId})`;
            let body = `Hi, We found an issue with your app: ${appInfo.appName} (${appInfo.appId}).`;

            this.setState({ recentUsers, subject, body, isBusy: false });
        } catch (error: any) {
            toast.error("[" + error.errorCode + "] - " + error.errorMessage);

            this.setState({ isBusy: false });
        }
    }

    openRecipientMenu = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    closeRecipientMenu = () => {
        this.setState({ anchorEl: null });
    };

    addAllRecipients = () => {
        const { recentUsers } = this.state;

        this.setState({ toUsers: [...recentUsers] });
    };

    removeAllRecipients = () => {
        this.setState({ toUsers: [] });
    };

    toggleRecipient = (user: RecentIntercomUser) => {
        const { toUsers } = this.state;

        const existing = toUsers.find((item) => item.profileId === user.profileId);

        if (existing) {
            this.setState({ toUsers: toUsers.filter((item) => item.profileId !== user.profileId) });
        } else {
            this.setState({ toUsers: [...toUsers, user] });
        }
    };

    handleDismiss = () => {
        this.props.onClose(false);
    };

    handleConfirm = async () => {
        const { appInfo } = this.props;
        const { toUsers, subject, body } = this.state;

        try {
            this.setState({ isBusy: true });

            await Services.sendIntercomMessage(appInfo.companyId, appInfo.appId, appInfo.appName, toUsers, subject, body);

            toast.success(<Trans>Successfully Sent Intercom Message for {appInfo.appName}!</Trans>);

            this.setState({ isBusy: false }, () => this.props.onClose(true));
        } catch (error: any) {
            toast.error("[" + error.errorCode + "] - " + error.errorMessage);

            this.setState({ isBusy: false });
        }
    };

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "subject":
                this.setState({ subject: value });

                break;
            case "body":
                this.setState({ body: value });

                break;
            default:
            // Do nothing.
        }
    };

    isFormValid = () => {
        const { toUsers, subject, body } = this.state;

        if (toUsers.length === 0) return false;
        if (subject.trim().length === 0) return false;
        if (body.trim().length === 0) return false;

        return true;
    };

    render() {
        const { appInfo } = this.props;
        const { anchorEl, recentUsers, toUsers, subject, body, isBusy } = this.state;

        return (
            <CustomDialog
                id={"send-intercom-message"}
                open={true}
                ready={true}
                onClose={this.handleDismiss}
                header={<Trans>Send Message {appInfo ? "- " + appInfo.appName : ""}</Trans>}
                content={
                    <>
                        <div style={{ flex: "0 0 auto", display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0.3125em" }}>
                            <Typography style={{ margin: "0.3125em" }}>
                                <Trans>To:</Trans>
                            </Typography>

                            <Button id={"add-or-remove-recipients"} type={"primary"} onClick={this.openRecipientMenu} disabled={isBusy}>
                                <Trans>Add/Remove Recipients</Trans>
                            </Button>

                            <CustomMenu
                                anchorElement={anchorEl}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={Boolean(anchorEl)}
                                onClose={this.closeRecipientMenu}
                                menuItems={[
                                    {
                                        id: "add-all",
                                        label: <Trans>Add All</Trans>,
                                        onClick: this.addAllRecipients,
                                    },
                                    {
                                        id: "remove-all",
                                        label: <Trans>Remove All</Trans>,
                                        onClick: this.removeAllRecipients,
                                    },
                                    ...recentUsers.map((user) => {
                                        const isCurrentlySelected = toUsers.find((item) => item.profileId === user.profileId) != null;

                                        return {
                                            id: user.profileId,
                                            label: (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ flex: "1 1 auto", display: "flex", justifyContent: "space-between" }}>
                                                        <Typography>
                                                            {user.fullName} {user.email ? `(@${user.email.split("@")[1]})` : null}
                                                        </Typography>

                                                        <Timestamp style={{ fontStyle: "italic", opacity: 0.5, marginLeft: "0.3125em" }} value={user.lastLogin} dateOnly={true} />
                                                    </span>

                                                    <div style={{ flex: "0 0 auto", marginLeft: "0.3125em", width: "1em", height: "1em" }}>{isCurrentlySelected && <CheckIcon style={{ width: "100%", height: "100%" }} />}</div>
                                                </div>
                                            ),
                                            onClick: () => this.toggleRecipient(user),
                                        };
                                    }),
                                ]}
                            />
                        </div>

                        <div
                            style={{
                                flex: "0 0 auto",
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                margin: "0.3125em",
                                padding: "0.3125em",
                                minHeight: "3.25em",
                                backgroundColor: "var(--primary-background-color, inheirt)",
                                overflow: "auto",
                            }}
                        >
                            {toUsers.map((user) => {
                                return (
                                    <Chip key={user.profileId} style={{ height: "1.5em", fontWeight: "bold", margin: "0.3125em", backgroundColor: "var(--chip-default-background-color)", color: "var(--chip-default-color)" }} label={user.fullName} />
                                );
                            })}
                        </div>

                        <FieldWrapper type={"text"} name={"subject"} label={<Trans>Subject:</Trans>} labelPosition={"top"} labelAlignment={"flex-start"} value={subject} required={true} disabled={isBusy} onChange={this.handleChange} />

                        <FieldWrapper
                            type={"textarea"}
                            name={"body"}
                            label={<Trans>Body:</Trans>}
                            labelPosition={"top"}
                            labelAlignment={"flex-start"}
                            value={body}
                            options={{ rows: 5 }}
                            required={true}
                            disabled={isBusy}
                            onChange={this.handleChange}
                        />
                    </>
                }
                actions={
                    <>
                        <Button id={"cancel"} type={"neutral"} onClick={this.handleDismiss} disabled={isBusy}>
                            <Trans>Cancel</Trans>
                        </Button>
                        <Button id={"confirm"} type={"primary"} onClick={this.handleConfirm} disabled={isBusy || !this.isFormValid()}>
                            <Trans>Confirm</Trans>
                        </Button>
                    </>
                }
            />
        );
    }
}

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(SendIntercomMessageDialog);
