import Http, { HttpResponse } from "utils/networking/Http";

import TeamInfo, { processTeamInfo } from "types/models/TeamInfo";
import BillingPlan, { processBillingPlan } from "types/models/BillingPlan";
import ClientOSPlatforms, { processClientOSPlatforms } from "types/models/ClientOSPlatforms";
import RecentIntercomUser, { processRecentIntercomUsers } from "types/models/RecentIntercomUser";
import AppInfo, { processAppInfo, processPurchasesTotalRevenue, PurchasesTotalRevenue } from "types/models/AppInfo";

const getCompanies = async (): Promise<TeamInfo[]> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/companies-list", undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response) && Array.isArray(response.data)) {
        return Promise.resolve(response.data.map(processTeamInfo));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const createApp = async (appName: string, isGamificationEnabled: boolean, supportedPlatforms: string[]): Promise<AppInfo> => {
    const response: HttpResponse = await Http.POST(
        "admin/serveradmin/game-create",
        undefined,
        {
            gameName: appName,
            isGamificationEnabled: isGamificationEnabled === true,
            supportedPlatforms: supportedPlatforms,
        },
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processAppInfo(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const moveApp = async (appId: string, companyId: string, roleId: string): Promise<void> => {
    const response: HttpResponse = await Http.POST(
        "admin/serveradmin/move-game",
        {
            gameId: appId,
            toCompanyId: companyId,
            applicationRoleId: roleId,
        },
        undefined,
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const softDeleteApp = async (appId: string): Promise<void> => {
    const response: HttpResponse = await Http.POST(
        "admin/serveradmin/game-soft-delete",
        {
            gameId: appId,
        },
        undefined,
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const hardDeleteApp = async (appId: string): Promise<void> => {
    const response: HttpResponse = await Http.POST(
        "admin/serveradmin/game-hard-delete",
        {
            gameId: appId,
        },
        undefined,
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const getBillingPlans = async (isSuper: boolean): Promise<BillingPlan[]> => {
    const response: HttpResponse = await Http.GET(isSuper ? "admin/serveradmin/super-billing-plans-read" : "admin/serveradmin/billing-plans-read", undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        const data = response && response.data && Array.isArray(response.data) ? response.data : [];

        return Promise.resolve(data.map(processBillingPlan));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const getCurrentBillingPlanCode = async (appId: string): Promise<string | null> => {
    const response: HttpResponse = await Http.GET(
        "admin/serveradmin/current-plan-code",
        {
            gameId: appId,
        },
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const swapBillingPlan = async (appId: string, planCode: string): Promise<void> => {
    const response: HttpResponse = await Http.POST(
        "admin/serveradmin/game-billing-plan-swap",
        {
            gameId: appId,
            planCode: planCode,
        },
        undefined,
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const setBillingPlanLive = async (appId: string, planCode: string): Promise<void> => {
    const response: HttpResponse = await Http.POST(
        "admin/serveradmin/game-set-billing-live",
        {
            gameId: appId,
            planCode: planCode,
        },
        undefined,
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const applyCoupon = async (appId: string, couponCode: string): Promise<void> => {
    const response: HttpResponse = await Http.POST(
        "admin/serveradmin/game-apply-billing-coupon",
        {
            gameId: appId,
            couponCode: couponCode,
        },
        undefined,
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const getSupportedPlatforms = async (): Promise<ClientOSPlatforms> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/client-os-platforms", undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        const data = response.data ? response.data : {};

        return Promise.resolve(processClientOSPlatforms(data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const getRecentIntercomUsers = async (teamId: string, appId: string): Promise<RecentIntercomUser[]> => {
    const response: HttpResponse = await Http.GET(
        "admin/serveradmin/app-intercom-recent-users",
        {
            teamId: teamId,
            appId: appId,
        },
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processRecentIntercomUsers(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const sendIntercomMessage = async (teamId: string, appId: string, appName: string, toUsers: RecentIntercomUser[], subject: string, body: string): Promise<void> => {
    const response: HttpResponse = await Http.POST(
        "admin/serveradmin/game-send-intercom-message",
        undefined,
        {
            teamId: teamId,
            appId: appId,
            appName: appName,
            subject: subject,
            body: "<p>[Note: This message is being sent individually to: " + toUsers.map((item) => item.fullName).join(", ") + "] &nbsp;</p>" + body,
            toUsers: toUsers.map((user) => {
                return { name: user.profileId, value: user.email };
            }),
        },
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};
const scanPurchasesAndUpdateTotalRevenue = async (appId: string, update: boolean): Promise<PurchasesTotalRevenue> => {
    const response: HttpResponse = await Http.POST("/admin/serveradmin/scanPurchaseAndUpdateTotalRevenue", { appId, update }, undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processPurchasesTotalRevenue(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const addApiCounts = async (appId: string, apiCalls: number, notifications: number, webCalls: number, s2sCalls: number, cloudCalls: number, kbDownloaded: number, ccScriptsCalled: number, ccScriptsScheduled: number): Promise<void> => {
    const response: HttpResponse = await Http.POST(
        "admin/serveradmin/game-billing-add-counts",
        {
            gameId: appId,
            apiCount: apiCalls,
            notificationCount: notifications,
            webCount: webCalls,
            s2sCount: s2sCalls,
            ccApiCount: cloudCalls,
            kbDownloaded: kbDownloaded,
            ccScriptsCalled: ccScriptsCalled,
            ccScriptsScheduled: ccScriptsScheduled,
        },
        undefined,
        Http.JSON_HEADERS
    );

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const getTeam = async (): Promise<TeamInfo> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/team-admin-company-read", null, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        const data = response && response.data ? response.data : {};

        return Promise.resolve(processTeamInfo(data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const readActiveSubscriptionIds = async (gameId: string): Promise<string[]> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/active-subscriptionids-read", { gameId }, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        const data = response && Array.isArray(response.data.subscriptionIds) ? response.data.subscriptionIds : [];

        return Promise.resolve(data);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const updateFusebillSubscriptionId = async (gameId: string, subscriptionId: string): Promise<void> => {
    const response: HttpResponse = await Http.POST("admin/serveradmin/game-override-subscriptionid", { gameId, subscriptionId }, undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const Services = {
    getCompanies,
    createApp,
    moveApp,
    softDeleteApp,
    hardDeleteApp,
    getBillingPlans,
    getCurrentBillingPlanCode,
    swapBillingPlan,
    setBillingPlanLive,
    applyCoupon,
    getSupportedPlatforms,
    getRecentIntercomUsers,
    sendIntercomMessage,
    addApiCounts,
    getTeam,
    scanPurchasesAndUpdateTotalRevenue,
    readActiveSubscriptionIds,
    updateFusebillSubscriptionId,
};

export default Services;
