import React, { Suspense } from "react";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import lazyRetry from "utils/LazyRetry";

import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalPrivilege from "types/common/PortalPrivilege";
import DashboardIcon from "@mui/icons-material/Speed";
import FavoritesIcon from "@mui/icons-material/StarOutline";

import LoadingProgress from "components/common/widgets/LoadingProgress";

const Dashboard = React.lazy(lazyRetry(() => import("components/screens/team/Dashboard")));
const Apps = React.lazy(lazyRetry(() => import("components/screens/team/manage/Apps")));
const Members = React.lazy(lazyRetry(() => import("components/screens/team/manage/Members")));
const Permissions = React.lazy(lazyRetry(() => import("components/screens/team/manage/Permissions")));
const AuditLogs = React.lazy(lazyRetry(() => import("components/screens/common/AuditLogs")));
const TeamInformation = React.lazy(lazyRetry(() => import("components/screens/team/manage/TeamInfo")));
const Branding = React.lazy(lazyRetry(() => import("components/screens/common/Branding")));

const routes: PortalRouteDefinition[] = [
    {
        path: "team",
        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
            return session.isSuper === true ? session.companyIdAlias != null : session.companyId != null;
        },
        label: (i18n: I18n) => i18n._(t`Team`),
        icon: null,
        exact: true,
        redirect: "dashboard",
        routes: [
            {
                path: "dashboard",
                label: (i18n: I18n) => i18n._(t`Dashboard`),
                icon: <DashboardIcon />,
                exact: true,
                component: (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <Dashboard />
                    </Suspense>
                ),
            },
            {
                path: "favorites",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return false;
                },
                label: (i18n: I18n) => i18n._(t`Favorites`),
                icon: <FavoritesIcon />,
                exact: true,
                routes: [],
            },
            {
                path: "manage",
                label: (i18n: I18n) => i18n._(t`Manage`),
                icon: null,
                exact: true,
                redirect: "apps",
                routes: [
                    {
                        path: "apps",
                        label: (i18n: I18n) => i18n._(t`Apps`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Apps />
                            </Suspense>
                        ),
                    },
                    {
                        path: "members",
                        label: (i18n: I18n) => i18n._(t`Members`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Members />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "security",
                label: (i18n: I18n) => i18n._(t`Security`),
                icon: null,
                exact: true,
                redirect: "members",
                routes: [
                    {
                        path: "permissions",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return session.isSuper === true || session.isTeamAdmin === true;
                        },
                        label: (i18n: I18n) => i18n._(t`Permissions`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Permissions />
                            </Suspense>
                        ),
                    },
                    {
                        path: "audit-log",
                        label: (i18n: I18n) => i18n._(t`Audit Log`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <AuditLogs context={"team"} />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "setup",
                label: (i18n: I18n) => i18n._(t`Setup`),
                icon: null,
                exact: true,
                redirect: "team-info",
                routes: [
                    {
                        path: "team-info",
                        label: (i18n: I18n) => i18n._(t`Team Info`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <TeamInformation />
                            </Suspense>
                        ),
                    },
                    {
                        path: "branding",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return session.isSuper === true;
                        },
                        label: (i18n: I18n) => i18n._(t`Branding`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Branding context={"team"} />
                            </Suspense>
                        ),
                    },
                ],
            },
        ],
    },
];

export default routes;
