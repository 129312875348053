import TeamInfo from "types/models/TeamInfo";

export interface CurrentCompanyAliasAction {
    type: "SET_CURRENT_COMPANY_ALIAS";
    payload: TeamInfo | null;
}

export const SET_CURRENT_COMPANY_ALIAS = (teamInfo: TeamInfo | null): CurrentCompanyAliasAction => {
    return {
        type: "SET_CURRENT_COMPANY_ALIAS",
        payload: teamInfo,
    };
};
