// Import libraries.
import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import { Theme, Typography } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";

// Import components.
import PortalComponent from "framework/PortalComponent";
import Tooltip from "components/common/Tooltip";

// Import icons.
import LockedIcon from "@mui/icons-material/Lock";
import UnlockedIcon from "@mui/icons-material/LockOpen";

interface STATE_PROPS {
    session: Session;
}
interface DISPATCH_PROPS {
    toggleLiveLock: () => void;
}
interface OWN_PROPS {
    style?: CSSProperties;
    thumbAndTextColor?: string | null;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles> {}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        toggleLiveLock: () => dispatch({ type: "app.togglePlayerLock" }),
    };
};

class PlayerLiveLock extends PortalComponent<PROPS> {
    render() {
        const { classes, session, style, thumbAndTextColor } = this.props;

        const { height = "1em" } = style || {};

        const gapSize = "(" + height + " * 0.05)";

        const trackSize = "(" + height + " - (" + gapSize + " * 2))";

        const thumbSize = "(" + trackSize + " - (" + gapSize + " * 2))";

        const locked = session.isPlayerLocked === true;

        return (
            <span className={classes.root} style={{ minWidth: height, ...style }}>
                <span
                    className={classes.track}
                    style={{
                        height: "calc(" + trackSize + ")",
                        borderRadius: "calc(" + trackSize + " / 2)",
                        backgroundColor: !locked ? "var(--live-lock-banner-unlocked-background-color)" : "var(--navigation-user-context-color, inherit)",
                        margin: "calc(" + gapSize + ")",
                    }}
                    onClick={this.props.toggleLiveLock}
                >
                    <span
                        className={classes.thumb}
                        style={{
                            width: "calc(" + thumbSize + ")",
                            height: "calc(" + thumbSize + ")",
                            marginLeft: !locked ? "calc(100% - (" + thumbSize + " + (" + gapSize + "))" : "calc(" + gapSize + ")",
                            marginRight: "calc(" + gapSize + ")",
                            padding: "calc(" + gapSize + " * 2)",
                            backgroundColor: thumbAndTextColor || "white",
                            color: !locked ? "var(--live-lock-banner-unlocked-background-color)" : "var(--navigation-user-context-color, inherit)",
                        }}
                    >
                        {locked && <LockedIcon style={{ width: "100%", height: "100%" }} />}
                        {!locked && <UnlockedIcon style={{ width: "100%", height: "100%" }} />}
                    </span>

                    <Tooltip arrow title={locked ? <Trans>User Is Locked</Trans> : <Trans>User Is Unlocked</Trans>}>
                        <Typography
                            style={{
                                fontSize: "calc(" + trackSize + " * 0.6)",
                                fontWeight: "bold",
                                color: thumbAndTextColor || "white",
                                position: "absolute",
                                left: !locked ? "calc(" + gapSize + " * 8)" : "calc(" + thumbSize + " + (" + gapSize + " * 8))",
                                right: locked ? "calc(" + gapSize + " * 8)" : "calc(" + thumbSize + " + (" + gapSize + " * 8))",
                                textAlign: "center",
                            }}
                            noWrap
                        >
                            {locked ? <Trans>LOCKED</Trans> : <Trans>UNLOCKED</Trans>}
                        </Typography>
                    </Tooltip>

                    <div className={"hoverOverlay"} style={{ backgroundColor: thumbAndTextColor || "white", pointerEvents: "none" }}></div>
                </span>
            </span>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            backgroundColor: "transparent",
        },
        track: {
            flex: "1 1 auto",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            cursor: "pointer",
            position: "relative",

            "& > div.hoverOverlay": {
                zIndex: 2,
                display: "none",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0.4,
            },

            "&:hover > div.hoverOverlay": {
                display: "block",
            },
        },
        thumb: {
            zIndex: 1,
            flex: "0 0 auto",
            display: "flex",
            borderRadius: "50%",
            overflow: "hidden",
            transition: "margin-left 0.1s linear",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlayerLiveLock));
