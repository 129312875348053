import Cookies from "universal-cookie";

/**
 * Cookie utility functions.
 */
export default abstract class CookieUtils {
    // Initialize a cookies instance from the "universal-cookie" library.
    // This provides convienent access to cookies.
    private static cookies = new Cookies();

    private static cookieSetOptions = {
        // Enforce the cookie is associated with the same site.
        sameSite: true,
    };

    private static cookieGetOptions = {
        // No default get options.
    };

    /**
     * Get the cookie value associated with the supplied name.
     *
     * @param name
     */
    public static getCookie(name: string): any {
        return CookieUtils.cookies.get(name, CookieUtils.cookieGetOptions);
    }

    /**
     * Set the cookie value associated with the supplied name.
     *
     * @param name
     * @param value
     */
    public static setCookie(name: string, value: any): void {
        CookieUtils.cookies.set(name, value, CookieUtils.cookieSetOptions);
    }

    /**
     * Delete the cookie value associated with the supplied name.
     * @param name
     */
    public static deleteCookie(name: string): void {
        CookieUtils.cookies.remove(name, CookieUtils.cookieSetOptions);
    }
}
