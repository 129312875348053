// Import libraries.
import React from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";

// Import types.
import { FormatOptions, SearchResult } from "../../../../engine/types";
import User from "types/common/User";
import PlayerInfo from "types/models/PlayerInfo";
import PlayerSummary from "types/models/PlayerSummaryInfo";

// Import components.
import { Typography, MenuItem } from "@mui/material";
import ImageWrapper from "components/common/ImageWrapper";
import Timestamp from "components/common/Timestamp";
import UserImagePlaceHolder from "components/common/UserImagePlaceHolder";

// Import formatters.
import HighlightFormatter from "utils/formatters/Highlight";

interface OWN_PROPS {
    data: SearchResult;

    formatOptions?: FormatOptions | null;

    onResultClick: (result: SearchResult) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class PlayerResult extends React.PureComponent<PROPS> {
    render() {
        const { classes, data, formatOptions } = this.props;

        const player = data.data;

        return (
            <MenuItem component={"div"} className={classes.result} onClick={() => this.props.onResultClick(data)}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <ImageWrapper
                        style={{ width: "2.25em", height: "2.25em", marginRight: "0.625em" }}
                        src={player?.pictureUrl}
                        fallback={<UserImagePlaceHolder data={player as PlayerSummary | PlayerInfo | User | null} style={{ fontSize: "0.75em" }} />}
                    />

                    <span className={classes.resultInfo}>
                        <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                            <Typography noWrap>
                                <Trans>User:</Trans>
                            </Typography>

                            <Typography style={{ flex: "0 1 auto", marginLeft: "0.3125em", fontWeight: "bold", overflow: "hidden" }} noWrap>
                                {HighlightFormatter.highlight(player?.playerName || player?.email, data.searchTerm, formatOptions?.highlight)} ({HighlightFormatter.highlight(player?.playerId, data.searchTerm, formatOptions?.highlight)})
                            </Typography>
                        </span>

                        <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                            <Typography noWrap>
                                <Trans>Last Login:</Trans>
                            </Typography>

                            <Timestamp style={{ flex: "0 1 auto", marginLeft: "0.3125em", fontWeight: "bold" }} value={player?.lastLogin} />
                        </span>
                    </span>
                </div>
            </MenuItem>
        );
    }
}

const styles = () =>
    createStyles({
        result: {
            flex: "0 0 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            padding: "0.625em",

            "&:not(:first-of-type)": {
                borderTopStyle: "solid",
                borderTopWidth: "0.0625em",
            },

            "&:hover": {
                backgroundColor: "var(--popup-menu-hover-background-color, inherit)",
                color: "var(--popup-menu-hover-color, inherit)",
            },
        },
        resultInfo: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            overflow: "hidden",
        },
    });

export default withStyles(styles)(PlayerResult);
