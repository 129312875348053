import TeamInfo, { processTeamInfo } from "types/models/TeamInfo";
import Http, { HttpResponse } from "utils/networking/Http";

const getTeamInfo = async (): Promise<TeamInfo> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/team-admin-company-read", undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processTeamInfo(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const acceptTermsOfService = async (): Promise<void> => {
    const response: HttpResponse = await Http.POST("admin/serveradmin/terms-of-service-accept", undefined, undefined, Http.JSON_HEADERS);

    if (Http.isStatusOk(response)) {
        return Promise.resolve();
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const Services = {
    getTeamInfo: getTeamInfo,
    acceptTermsOfService,
};

export default Services;
