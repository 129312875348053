import { createContext, useContext, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PortalState from "types/store";
import { DirectAPILog, DirectAPIResponse, DirectAPIResponseCallStats, DirectAPIResponseLogEntry, DirectAPISelection, DirectAPISession } from "types/common/DirectAPI";

import { APPEND_DIRECT_API_LOG, CLEAR_DIRECT_API_LOGS, CLEAR_DIRECT_API_SESSION, SET_DIRECT_API_BUSY, SET_DIRECT_API_BYPASS_USER_ENABLED_CHECK, SET_DIRECT_API_SELECTION, SET_DIRECT_API_SESSION, UPDATE_DIRECT_API_LOG } from "store/actions/directAPI";

import Http from "utils/networking/Http";
import CloneUtils from "utils/Clone";
import StringUtils from "utils/String";

export type DirectAPIContextType = {
    directAPISession: DirectAPISession | null;
    setDirectAPISession: (session: DirectAPISession) => void;
    clearDirectAPISession: (session: DirectAPISession) => void;
    directAPIBypassUserEnabledCheck: boolean;
    setDirectAPIBypassUserEnabledCheck: (bypassUserEnabledCheck: boolean) => void;
    directAPISelections: DirectAPISelection[];
    setDirectAPISelection: (selection: DirectAPISelection) => void;
    directAPILogs: DirectAPILog[];
    clearDirectAPILogs: () => void;
    invokeDirectAPICall: (service: string, operation: string, data: any, abortSignal?: AbortSignal) => Promise<DirectAPIResponse>;
    invokeDirectAPICallAsynchronous: (service: string, operation: string, data: any, abortSignal?: AbortSignal) => Promise<DirectAPIResponse>;
    directAPIBusy: boolean;
};

const DEFAULT_VALUE: DirectAPIContextType = {
    directAPISession: null,
    setDirectAPISession: (_session: DirectAPISession) => {
        return;
    },
    clearDirectAPISession: (_session: DirectAPISession) => {
        return;
    },
    directAPIBypassUserEnabledCheck: true,
    setDirectAPIBypassUserEnabledCheck: (_bypassUserEnabledCheck: boolean) => {
        return;
    },
    directAPISelections: [],
    setDirectAPISelection: (_selection: DirectAPISelection) => {
        return;
    },
    directAPILogs: [],
    clearDirectAPILogs: () => {
        return;
    },
    invokeDirectAPICall: (_service: string, _operation: string, _data: any, _abortSignal?: AbortSignal) => {
        return Promise.resolve({} as DirectAPIResponse);
    },
    invokeDirectAPICallAsynchronous: (_service: string, _operation: string, _data: any, _abortSignal?: AbortSignal) => {
        return Promise.resolve({} as DirectAPIResponse);
    },
    directAPIBusy: false,
};

const DEFAULT_ASYNC_POLL_INTERVAL = 1000;

export const DirectAPIContext = createContext<DirectAPIContextType>(DEFAULT_VALUE);

export const DirectAPIContextProvider = (props: any) => {
    const dispatch = useDispatch();

    const appId = useSelector((state: PortalState) => state.session.appId || "");
    const directAPI = useSelector((state: PortalState) => state.directAPI);

    const apiSession = directAPI.sessions.find((item) => item.appId === appId) || null;

    const setDirectAPISession = useCallback(
        (session: DirectAPISession) => {
            dispatch(SET_DIRECT_API_SESSION(session));
        },
        [dispatch]
    );

    const clearDirectAPISession = useCallback(
        (session: DirectAPISession) => {
            dispatch(CLEAR_DIRECT_API_SESSION(session));
        },
        [dispatch]
    );

    const setDirectAPIBypassUserEnabledCheck = useCallback(
        (bypassUserenabledCheck: boolean) => {
            dispatch(SET_DIRECT_API_BYPASS_USER_ENABLED_CHECK(bypassUserenabledCheck));
        },
        [dispatch]
    );

    const setDirectAPISelection = useCallback(
        (selection: DirectAPISelection) => {
            dispatch(SET_DIRECT_API_SELECTION(selection));
        },
        [dispatch]
    );

    const setDirectAPIBusy = useCallback(
        (isBusy: boolean) => {
            dispatch(SET_DIRECT_API_BUSY(isBusy));
        },
        [dispatch]
    );

    const clearDirectAPILogs = useCallback(() => {
        dispatch(CLEAR_DIRECT_API_LOGS());
    }, [dispatch]);

    const invokeDirectAPICallAsynchronous = useCallback(
        async (service: string, operation: string, data: any, abortSignal?: AbortSignal, asyncPollInterval?: number): Promise<DirectAPIResponse> => {
            setDirectAPIBusy(true);

            try {
                // Determine if the call is an authentication call.
                const isAuthenticationCall = ["authentication", "authenticationV1", "authenticationV2"].includes(service) && operation === "AUTHENTICATE";

                // Build the request log.
                const directAPILog: DirectAPILog = {
                    timestamp: new Date(),

                    appId: appId,

                    request: {
                        sessionId: isAuthenticationCall ? null : apiSession?.sessionId || null,
                        packetId: isAuthenticationCall ? 0 : apiSession?.packetId || 1,
                        bypassUserEnabledCheck: directAPI.bypassUserEnabledCheck,
                        messages: [
                            {
                                service: service,
                                operation: operation,
                                data: data,
                            },
                        ],
                    },

                    response: null,

                    duration: -1,

                    cancelled: false,
                    expired: false,
                };

                dispatch(APPEND_DIRECT_API_LOG(directAPILog));

                const startTime = new Date();

                let pollFinished = false;
                let pollExpired = false;

                let response = await Http.POST("admin/development/dispatcher/api/start", undefined, directAPILog.request, Http.JSON_HEADERS, abortSignal);

                if (Http.isStatusOk(response) && !StringUtils.isNullOrEmpty(response.data?.taskId)) {
                    const taskId: string = response.data.taskId;

                    const performPoll = async (): Promise<void> => {
                        response = await Http.POST("admin/development/dispatcher/api/poll", { taskId: taskId }, undefined, Http.JSON_HEADERS, abortSignal);

                        if (Http.isStatusOk(response) && response.data?.statusCode === 200) {
                            if (response.data?.taskState?.running === false) {
                                pollFinished = true;

                                return;
                            }
                        } else {
                            pollFinished = true;

                            if (response.data?.statusCode === 400) {
                                pollExpired = true;
                            } else {
                                console.log("Unknown Task Status Code", response.data);
                            }

                            return;
                        }
                    };

                    while (!pollFinished) {
                        await new Promise<void>((resolve) => {
                            window.setTimeout(() => {
                                resolve();
                            }, asyncPollInterval || DEFAULT_ASYNC_POLL_INTERVAL);
                        });

                        await performPoll();
                    }
                } else {
                    pollFinished = true;
                }

                const endTime = new Date();

                directAPILog.duration = endTime.getTime() - startTime.getTime();

                if (Http.isStatusOk(response)) {
                    if (response.data?.statusCode === 200) {
                        directAPILog.response = {
                            data: response.data?.taskState?.response?.data || {},
                            status: response.data?.taskState?.response?.status || -1,
                        };

                        if (response.data?.taskState?.response?.logList != null) {
                            directAPILog.response.logList = response.data.taskState.response.logList.map((item: any) => {
                                return {
                                    level: item.level ? item.level : "",
                                    message: item.msg ? item.msg : "",
                                    context: item.context ? item.context : {},
                                    time: item.time ? item.time : "",
                                } as DirectAPIResponseLogEntry;
                            });
                        }

                        if (response.data?.taskState?.response?.callStats != null) {
                            directAPILog.response.callStats = response.data.taskState.response.callStats as DirectAPIResponseCallStats;
                        }

                        if (response.data?.taskState?.response?.totalCount != null || response.data?.taskState?.response?.apiCount != null) {
                            directAPILog.response.callCounts = {
                                total: response.data?.taskState?.response?.totalCount != null ? response.data.taskState.response.totalCount : -1,
                                api: response.data?.taskState?.response?.apiCount != null ? response.data.taskState.response.apiCount : -1,
                            };
                        }

                        if (response.data?.taskState?.response?.status_message != null) {
                            directAPILog.response.statusMessage = response.data.taskState.response.status_message;
                        } else {
                            if (response.data?.response) {
                                directAPILog.response.statusMessage = "" + response.data.response;
                            }
                        }

                        if (response.data?.taskState?.response?.reason_code != null) directAPILog.response.reasonCode = response.data.taskState.response.reason_code;
                        if (response.data?.taskState?.response?.severity != null) directAPILog.response.severity = response.data.taskState.response.severity;
                        if (response.data?.taskState?.response?.x_stack_trace != null) directAPILog.response.stackTrace = response.data.taskState.response.x_stack_trace;

                        if (response.data?.taskState.duration != null) directAPILog.duration = response.data.taskState.duration;
                    } else {
                        directAPILog.response = {
                            data: {},
                            status: response.data?.statusCode,
                        };
                    }

                    // Process any other fields that may be present.
                    if (response.data?.taskState?.response) {
                        Object.keys(response.data?.taskState?.response).forEach((key) => {
                            if (!["data", "status", "logList", "callStats", "status_message", "reason_code", "severity", "x_stack_trace", "apiCount", "totalCount"].includes(key)) {
                                (directAPILog.response as any)[key] = response.data.taskState.response[key];
                            }
                        });
                    }
                } else {
                    directAPILog.response = {
                        data: response.error?.response?.data != null ? response.error.response.data : {},
                        status: response.error?.response?.status != null ? response.error.response.status : -1,
                    };

                    if (response.error?.response?.statusText != null) directAPILog.response.statusMessage = response.error.response.statusText;

                    // Process any other fields that may be present.
                    if (response.error?.response) {
                        Object.keys(response.error.response).forEach((key) => {
                            if (!["data", "status", "statusText"].includes(key)) {
                                (directAPILog.response as any)[key] = response.error.response[key];
                            }
                        });
                    }

                    if (response.error?.message === "canceled") {
                        directAPILog.cancelled = true;
                    }
                }

                if (abortSignal?.aborted) {
                    directAPILog.cancelled = true;
                }

                if (pollExpired) {
                    directAPILog.expired = true;
                }

                dispatch(UPDATE_DIRECT_API_LOG(directAPILog));

                const updatedDirectAPISession = apiSession
                    ? (CloneUtils.clone(apiSession) as DirectAPISession)
                    : {
                          appId: appId,
                          profileId: null,
                          sessionId: null,
                          packetId: 0,
                      };

                if (isAuthenticationCall) {
                    updatedDirectAPISession.profileId = response.data?.taskState?.response?.data?.profileId || null;
                    updatedDirectAPISession.sessionId = response.data?.taskState?.response?.data?.sessionId || null;
                    updatedDirectAPISession.packetId = 1;
                } else {
                    updatedDirectAPISession.packetId += 1;
                }

                setDirectAPISession(updatedDirectAPISession);

                return Promise.resolve(directAPILog.response);
            } finally {
                setDirectAPIBusy(false);
            }
        },
        [setDirectAPIBusy, appId, apiSession, directAPI.bypassUserEnabledCheck, dispatch, setDirectAPISession]
    );

    const invokeDirectAPICall = useCallback(
        async (service: string, operation: string, data: any, abortSignal?: AbortSignal): Promise<DirectAPIResponse> => {
            setDirectAPIBusy(true);

            try {
                const isAuthenticationCall = ["authentication", "authenticationV1", "authenticationV2"].includes(service) && operation === "AUTHENTICATE";

                const directAPILog: DirectAPILog = {
                    timestamp: new Date(),

                    appId: appId,

                    request: {
                        sessionId: isAuthenticationCall ? null : apiSession?.sessionId || null,
                        packetId: isAuthenticationCall ? 0 : apiSession?.packetId || 1,
                        bypassUserEnabledCheck: directAPI.bypassUserEnabledCheck,
                        messages: [
                            {
                                service: service,
                                operation: operation,
                                data: data,
                            },
                        ],
                    },

                    response: null,

                    duration: -1,

                    cancelled: false,
                    expired: false,
                };

                dispatch(APPEND_DIRECT_API_LOG(directAPILog));

                const startTime = new Date();

                const response = await Http.POST("admin/development/direct-api-call", undefined, directAPILog.request, Http.JSON_HEADERS, abortSignal);

                const endTime = new Date();

                directAPILog.duration = endTime.getTime() - startTime.getTime();

                if (Http.isStatusOk(response)) {
                    directAPILog.response = {
                        data: response.data?.data != null ? response.data.data : {},
                        status: response.data?.status != null ? response.data.status : -1,
                    };

                    if (response.data?.logList != null) {
                        directAPILog.response.logList = response.data.logList.map((item: any) => {
                            return {
                                level: item.level ? item.level : "",
                                message: item.msg ? item.msg : "",
                                context: item.context ? item.context : {},
                                time: item.time ? item.time : "",
                            } as DirectAPIResponseLogEntry;
                        });
                    }

                    if (response.data?.callStats != null) {
                        directAPILog.response.callStats = response.data.callStats as DirectAPIResponseCallStats;
                    }

                    if (response.data?.totalCount != null || response.data?.apiCount != null) {
                        directAPILog.response.callCounts = {
                            total: response.data?.totalCount != null ? response.data.totalCount : -1,
                            api: response.data?.apiCount != null ? response.data.apiCount : -1,
                        };
                    }

                    if (response.data?.status_message != null) directAPILog.response.statusMessage = response.data.status_message;
                    if (response.data?.reason_code != null) directAPILog.response.reasonCode = response.data.reason_code;
                    if (response.data?.severity != null) directAPILog.response.severity = response.data.severity;
                    if (response.data?.x_stack_trace != null) directAPILog.response.stackTrace = response.data.x_stack_trace;

                    // Process any other fields that may be present.
                    if (response.data) {
                        Object.keys(response.data).forEach((key) => {
                            if (!["data", "status", "logList", "callStats", "status_message", "reason_code", "severity", "x_stack_trace", "apiCount", "totalCount"].includes(key)) {
                                (directAPILog.response as any)[key] = response.data[key];
                            }
                        });
                    }
                } else {
                    directAPILog.response = {
                        data: response.error?.response?.data != null ? response.error.response.data : {},
                        status: response.error?.response?.status != null ? response.error.response.status : -1,
                    };

                    if (response.error?.response?.statusText != null) directAPILog.response.statusMessage = response.error.response.statusText;

                    // Process any other fields that may be present.
                    if (response.error?.response) {
                        Object.keys(response.error.response).forEach((key) => {
                            if (!["data", "status", "statusText"].includes(key)) {
                                (directAPILog.response as any)[key] = response.error.response[key];
                            }
                        });
                    }

                    if (response.error?.message === "canceled") {
                        directAPILog.cancelled = true;
                    }
                }

                dispatch(UPDATE_DIRECT_API_LOG(directAPILog));

                const updatedDirectAPISession = apiSession
                    ? (CloneUtils.clone(apiSession) as DirectAPISession)
                    : {
                          appId: appId,
                          profileId: null,
                          sessionId: null,
                          packetId: 0,
                      };

                if (isAuthenticationCall) {
                    updatedDirectAPISession.profileId = response.data?.data?.profileId || null;
                    updatedDirectAPISession.sessionId = response.data?.data?.sessionId || null;
                    updatedDirectAPISession.packetId = 1;
                } else {
                    updatedDirectAPISession.packetId += 1;
                }

                setDirectAPISession(updatedDirectAPISession);

                return Promise.resolve(directAPILog.response);
            } finally {
                setDirectAPIBusy(false);
            }
        },
        [setDirectAPIBusy, appId, apiSession, directAPI.bypassUserEnabledCheck, dispatch, setDirectAPISession]
    );

    const [value, setValue] = useState<DirectAPIContextType>({
        directAPISession: apiSession,
        setDirectAPISession: setDirectAPISession,
        clearDirectAPISession: clearDirectAPISession,
        directAPIBypassUserEnabledCheck: directAPI.bypassUserEnabledCheck,
        setDirectAPIBypassUserEnabledCheck: setDirectAPIBypassUserEnabledCheck,
        directAPISelections: directAPI.selections,
        setDirectAPISelection: setDirectAPISelection,
        directAPILogs: directAPI.logs,
        clearDirectAPILogs: clearDirectAPILogs,
        invokeDirectAPICall: invokeDirectAPICall,
        invokeDirectAPICallAsynchronous: invokeDirectAPICallAsynchronous,
        directAPIBusy: directAPI.isBusy,
    });

    useEffect(() => {
        setValue({
            directAPISession: apiSession,
            setDirectAPISession: setDirectAPISession,
            clearDirectAPISession: clearDirectAPISession,
            directAPIBypassUserEnabledCheck: directAPI.bypassUserEnabledCheck,
            setDirectAPIBypassUserEnabledCheck: setDirectAPIBypassUserEnabledCheck,
            directAPISelections: directAPI.selections,
            setDirectAPISelection: setDirectAPISelection,
            directAPILogs: directAPI.logs,
            clearDirectAPILogs: clearDirectAPILogs,
            invokeDirectAPICall: invokeDirectAPICall,
            invokeDirectAPICallAsynchronous: invokeDirectAPICallAsynchronous,
            directAPIBusy: directAPI.isBusy,
        });
    }, [directAPI, apiSession, setDirectAPISession, clearDirectAPISession, setDirectAPIBypassUserEnabledCheck, setDirectAPISelection, clearDirectAPILogs, invokeDirectAPICall, invokeDirectAPICallAsynchronous]);

    return <DirectAPIContext.Provider value={value}>{props.children}</DirectAPIContext.Provider>;
};

export const useDirectAPI = () => useContext(DirectAPIContext);
