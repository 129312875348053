enum LoginStatus {
    PENDING = "pending",
    AUTHENTICATED = "authenticated",
    NOT_CONFIGURED = "not_configured",
    USER_IS_LOCKED = "user_is_locked",
    UNKNOWN_USER = "unknown_user",
    BAD_PASSWORD = "bad_password",
    MUST_LOGIN_WITH_SSO = "must_login_with_sso",
    MUST_NOT_HAVE_SSO_CREDENTIALS = "must_not_have_sso_credentials",
    RESTRICTED = "restricted",
    UNKNOWN_ERROR = "unknown_error",
}

export default LoginStatus;
