// Import libraries.
import { I18n } from "@lingui/core";
import { select, all, takeLatest, put, call } from "redux-saga/effects";

// Import types.
import PortalState from "types/store";
import ThemeConfiguration from "types/common/ThemeConfiguration";
import Session from "types/common/Session";
import User from "types/common/User";
import AppRole from "types/common/AppRole";
import ApiKeyInfo from "types/models/ApiKeyInfo";
import TeamInfo from "types/models/TeamInfo";

// Import redux actions.
import { SET_SESSION } from "store/actions/session";
import { SET_CURRENT_USER } from "store/actions/currentUser";
import { SET_FAVORITES } from "store/actions/favorites";
import { SET_RECENTLY_VIEWED } from "store/actions/recentlyViewed";

// Import redux sagas.
import { logout } from "./authenticationSagas";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";
import LocalizationUtils from "utils/Localization";
import CloneUtils from "utils/Clone";
import FavoriteUtils from "utils/Favorites";
import RecentlyViewedUtils from "utils/RecentlyViewed";

// Import formatters.
import DateTimeFormatter from "utils/formatters/DateTime";

interface PopulateCurrentUser {
    type: "user.populateCurrentUser";
    payload: {
        i18n: I18n;
        basic: boolean;
    };
}

// Attempts to populate the current user information.
export function* populateCurrentUser(action: PopulateCurrentUser) {
    // Define some getters for retrieving redux state.
    const getThemeConfiguration = (state: PortalState): ThemeConfiguration => CloneUtils.clone(state.themeConfiguration);
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);

    // Get the current theme configuration.
    const themeConfiguration: ThemeConfiguration = yield select(getThemeConfiguration);

    // Get the current session.
    const session: Session = yield select(getSession);

    try {
        console.log("Populating Current User...");

        const response: HttpResponse = action.payload.basic ? yield Http.GET("admin/useradmin/currentuser") : yield Http.GET("admin/useradmin/readuser", { email: session.email, isSuperOverride: false });

        if (Http.isStatusOk(response) && !response.redirected && response.data) {
            const data = response.data;

            //Update user-selected language on the server.
            const activeLanguage = LocalizationUtils.getActiveLanguage(action.payload.i18n);
            if (activeLanguage !== data.languageCode) {
                yield Http.POST("admin/useradmin/update-user-details", { profileId: data.profileId, language: activeLanguage }, undefined, Http.JSON_HEADERS);
            }

            const user: User = {
                profileId: data.profileId,
                email: data.email,
                fullName: data.fullName,

                createdAt: new Date(data.createdAt),
                lastLogin: new Date(data.lastLogin),

                isEnabled: data.isEnabled,
                isActive: data.isActive,
                isLocked: data.isLocked,
                isSuper: data.isSuperUser,
                isTeamAdmin: data.isTeamAdmin,

                preferredLanguage: activeLanguage !== data.languageCode ? activeLanguage : data.languageCode,
                preferredCountry: data.countryCode,
                preferredTimezone: data.timezone,
                preferredThemeMode: data.themeMode,

                intercomUserHash: data.intercomUserHash,

                appRoles: data.appRoles ? (data.appRoles as AppRole[]).sort((a, b) => a.gameName.localeCompare(b.gameName)) : [],
                companyIds: data.companyIds && Array.isArray(data.companyIds) ? data.companyIds.filter((item?: string | null) => item != null) : [],
                companies:
                    data.companies && typeof data.companies === "object" && Array.isArray(data.companies)
                        ? (data.companies as any[]).map((company) => {
                              const teamInfo: TeamInfo = {
                                  companyId: company.companyId,
                                  companyName: company.companyName,
                                  accountNumber: company.accountNumber,
                              };

                              return teamInfo;
                          })
                        : [],

                twoFactorAuthentication: {
                    enabled: data.tfaEnabled === true,
                    pending: data.tfaPending === true,
                    authyId: data.tfaId != null && Number.parseInt(data.tfaId) !== 0 ? Number.parseInt(data.tfaId) : null,
                    verifyId: data.tfaTotpSid != null ? data.tfaTotpSid : null,
                    countryCode: data.tfaCountryCode ? data.tfaCountryCode : null,
                    phoneNumber: data.tfaPhoneNo ? data.tfaPhoneNo : null,
                    lastVerify: data.tfaLastVerify ? data.tfaLastVerify : null,
                },

                builderApi: {
                    enabled: data.hasApiAccess === true,
                    apiKeys:
                        data.apiKeys && Array.isArray(data.apiKeys)
                            ? data.apiKeys.map((item: any) => {
                                  const apiKey: ApiKeyInfo = {
                                      apiKey: item.apiKey,
                                      apiKeyId: item.apiKeyId,
                                      description: item.desc,
                                      scope: item.scope,
                                      createdAt: new Date(item.createdAt),
                                      expiresOn: new Date(item.expiresOn),
                                      lastUsed: item.lastUsed != null && item.lastUsed > 0 ? new Date(item.lastUsed) : null,
                                      lastFailed: item.lastFailed != null && item.lastFailed > 0 ? new Date(item.lastFailed) : null,
                                      teams: Array.isArray(item.teams) ? item.teams.filter((temp: any) => temp != null && typeof temp === "string") : [],
                                  };

                                  return apiKey;
                              })
                            : [],
                },

                customData: data.customData || null,
            };

            // Update the current timezone in the DateTime formatter utility based on the user's preferred timezone/region.
            DateTimeFormatter.setCurrentTimezone(user.preferredTimezone);

            // Update the current localization language based on the user's preferred language.
            yield LocalizationUtils.setActiveLanguage(action.payload.i18n, user.preferredLanguage);

            // Update the current user.
            yield put(SET_CURRENT_USER(user));

            // Update the current theme mode based on the user's preferred theme mode (if permitted).
            const themeOverrideEnabled = themeConfiguration.settings?.permitUserOverride || session.isSuper;
            if (themeOverrideEnabled && user.preferredThemeMode && ["light", "dark"].includes(user.preferredThemeMode)) {
                session.themeMode = user.preferredThemeMode === "dark" ? "dark" : "light";
                yield put(SET_SESSION(session));
            } else {
                session.themeMode = themeConfiguration.settings?.defaultMode === "dark" ? "dark" : "light";
                yield put(SET_SESSION(session));
            }

            // Initialize the current favorites based on the user's favorites (retrieved from local storage).
            const favorites = FavoriteUtils.getFavorites(user.profileId);
            yield put(SET_FAVORITES(favorites));

            // Initialize the current recently viewed based on the user's recently viewed (retrieved from local storage).
            const recentlyViewed = RecentlyViewedUtils.getRecentlyViewed(user.profileId);
            yield put(SET_RECENTLY_VIEWED(recentlyViewed));

            return true;
        }
    } catch (error: any) {
        console.error("populateCurrentUser - ERROR", error);
    }

    yield put(SET_CURRENT_USER(null));

    // If we fail to fetch the information for the current user, then something is wrong... in this scneario we will simply log out the user.
    // We treat the inability to fetch the current user's information as a type of authentication failure.
    yield call(logout, { type: "authentication.logout", payload: true });

    return false;
}

export default function* root() {
    yield all([takeLatest("user.populateCurrentUser", populateCurrentUser)]);
}
