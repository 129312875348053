interface PlatformCounts {
    [index: string]: number;
}

interface PromotionCounts {
    [index: string]: number;
}

interface TemplateCounts {
    [index: string]: number;
}

interface ServiceOperationCounts {
    [index: string]: {
        [index: string]: number;
    };
}

interface ItemCounts {
    [index: string]: number;
}

interface LibraryCounts {
    APIExplorer: number;
    CPP: number;
    CSHARP: number;
    JAVA: number;
    JAVASCRIPT: number;
    OBJC: number;
}

interface LibraryVersionCounts {
    [index: string]: number;
}

interface ScriptStats {
    [index: string]: number;
}

export default interface AppDailyCounts {
    createdAt?: Date;
    updatedAt?: Date;

    date?: string;

    appId: string;

    users: {
        count: number;
        byPlatform: PlatformCounts;
        hourly: number[];
    };

    accountCreation: {
        count: number;
        byPlatform: PlatformCounts;
    };

    apiCalls: {
        count: number;
        countsByServiceOperation: ServiceOperationCounts;
        timingsByServiceOperation: ServiceOperationCounts;
        errors: {
            sys: ServiceOperationCounts;
            app: ServiceOperationCounts;
        };
        warnings: {
            app: ServiceOperationCounts;
        };
    };

    s2sCalls: {
        count: number;
        errors: {
            sys: number;
            app: number;
        };
        warnings: {
            app: number;
        };
    };

    webCalls: {
        count: number;
    };

    webHooks: {
        count: number;
        errors: {
            sys: number;
            app: number;
        };
        warnings: {
            app: number;
        };
    };

    cloudCode: {
        called: number;
        invoked: number;
    };

    bulkOperations: {
        count: number;
    };

    hosted: {
        cost: number;
        hours: number;
    };
    mauCount: number;

    deepData: {
        byChatHistory: number;
        byChatHistoryBase: number;
        kbDeepData: number;
        kbDeepIndex: number;
        kbWeightedTotal: number;
        numChatChannels: number;
    };

    dormant: {
        accountsStoredUpto30Days: number;
        accountsStored30to90Days: number;
        accountsStored90to180Days: number;
        accountsStored180to365Days: number;
        accountsStoredGT365Days: number;
        accountsStoredDormant: number;
        accountsStoredTotal: number;
    };

    notifications: {
        count: number;
        byPlatform: PlatformCounts;
        byPromotion: PromotionCounts;
        byTemplate: TemplateCounts;
    };

    offboardEvents: {
        count: number;
    };

    rtt: {
        ccxTotal: number;
        ccxHourly: { [index: string]: number };
        ccxOverageHourly: { [index: string]: number };
        ccxOveragesTotal: number;
        entries: number;
        sends: number;
        deliveries: number;
        sessionsHourly: number[];
    };

    billing: {
        bulkOpsCount: number;
        discountedApiCredit: number;
        freeApiCalls: number;
        netApiCount: number;
        totalApiCount: number;
        totalBilledToCustomer: number;
    };

    plan: {
        gen: number;
        subscriptionType: string;
        maxAccounts: number;
        maxDau: number;
        freeRTTCCx: number;
        includesDeepData: boolean;
        includesEnterprise: boolean;
        includesHosting: boolean;
    };

    puchases: {
        count: number;
        byPlatform: PlatformCounts;
        byItem: ItemCounts;
    };

    revenue: {
        count: number;
        byPlatform: PlatformCounts;
        byItem: ItemCounts;
        hourly: number[];
    };

    scripts: {
        scheduled: number;
        stats: ScriptStats;
    };

    sessions: {
        count: number;
        hourly: number[];
        byPlatform: PlatformCounts;
        byLibrary: LibraryCounts;
        byLibraryVersion: LibraryVersionCounts;
    };

    requests: {
        unprocessed: number;
        unreadable: number;
    };

    other: {
        dispV1CallCount: number;
        emailsSentCount: number;
        freePeerApiCallCount: number;
        heartbeatCount: number;
        loginsOverLimitCount: number;
        kbDownloaded: number;
        kbStorage: number;
    };
    dataInv: {
        segments: { [index: string]: number };
    };
}

export const processAppDailyCounts = (data: any): AppDailyCounts => {
    return {
        appId: data.gameId,

        createdAt: data.createdAt != null ? new Date(data.createdAt) : undefined,
        updatedAt: data.updatedAt != null ? new Date(data.updatedAt) : undefined,

        date: data.date,

        users: {
            count: data.userCount,
            byPlatform: data.userCountPlatform,
            hourly: data.userCountHourly,
        },

        accountCreation: {
            count: data.accountCreationCount,
            byPlatform: data.accountCreationCountPlatform,
        },
        mauCount: data.mauCount,

        apiCalls: {
            count: data.billingTotalBilledToCustomer,
            countsByServiceOperation: data.apiCallCount,
            timingsByServiceOperation: data.apiCallTime,
            errors: {
                sys: data.apiSysErrors,
                app: data.apiAppErrors,
            },
            warnings: {
                app: data.apiAppWarnings,
            },
        },

        s2sCalls: {
            count: data.s2sCallCountDay,
            errors: {
                sys: data.s2sCallSysErrors,
                app: data.s2sCallAppErrors,
            },
            warnings: {
                app: data.s2sCallAppWarnings,
            },
        },

        webCalls: {
            count: data.webCallCountDay,
        },

        webHooks: {
            count: data.webhookCallCountDay,
            errors: {
                sys: data.webhookSysErrors,
                app: data.webhookAppErrors,
            },
            warnings: {
                app: data.webhookAppWarnings,
            },
        },

        cloudCode: {
            called: data.ccApiCallCountDay,
            invoked: data.ccScriptInvokeCountDay,
        },

        bulkOperations: {
            count: data.bulkOpsCount,
        },

        hosted: {
            cost: data.hostedCost,
            hours: data.hostedHours,
        },

        deepData: data.deepData
            ? {
                  byChatHistory: data.deepData.byChatHistory,
                  byChatHistoryBase: data.deepData.byChatHistoryBase,
                  kbDeepData: data.deepData.kbDeepData,
                  kbDeepIndex: data.deepData.kbDeepIndex,
                  kbWeightedTotal: data.deepData.kbWeightedTotal,
                  numChatChannels: data.deepData.numChatChannels,
              }
            : {
                  byChatHistory: 0,
                  byChatHistoryBase: 0,
                  kbDeepData: 0,
                  kbDeepIndex: 0,
                  kbWeightedTotal: 0,
                  numChatChannels: 0,
              },

        dormant: data.dormant
            ? {
                  accountsStoredUpto30Days: data.dormant.accountsStoredUpto30Days,
                  accountsStored30to90Days: data.dormant.accountsStored30to90Days,
                  accountsStored90to180Days: data.dormant.accountsStored90to180Days,
                  accountsStored180to365Days: data.dormant.accountsStored180to365Days,
                  accountsStoredGT365Days: data.dormant.accountsStoredGT365Days,
                  accountsStoredDormant: data.dormant.accountsStoredDormant,
                  accountsStoredTotal: data.dormant.accountsStoredTotal,
              }
            : {
                  accountsStoredUpto30Days: 0,
                  accountsStored30to90Days: 0,
                  accountsStored90to180Days: 0,
                  accountsStored180to365Days: 0,
                  accountsStoredGT365Days: 0,
                  accountsStoredDormant: 0,
                  accountsStoredTotal: 0,
              },

        notifications: {
            count: data.notificationCount,
            byPlatform: data.notificationCountPlatform,
            byPromotion: data.notificationCountPromotionId,
            byTemplate: data.notificationCountTemplateId,
        },

        offboardEvents: {
            count: data.offboardEventCountDay,
        },

        rtt: {
            ccxHourly: data.rtt.ccxHourly || 0,
            ccxOverageHourly: data.rtt.ccxOverageHourly || 0,
            ccxOveragesTotal: data.rtt.ccxOveragesTotal || 0,
            ccxTotal: data.rtt.ccxTotal || 0,
            entries: data.rtt.entries || 0,
            sends: data.rtt.sends || 0,
            deliveries: data.rtt.deliveries || 0,
            sessionsHourly: data.rttSessionCountHourly || 0,
        },
        billing: {
            bulkOpsCount: data.billingBulkOpsCount,
            discountedApiCredit: data.billingDiscountedAPICredit,
            freeApiCalls: data.billingFreeApiCalls,
            netApiCount: data.billingNetAPICount,
            totalApiCount: data.billingTotalAPICount,
            totalBilledToCustomer: data.billingTotalBilledToCustomer,
        },

        plan: data.plan
            ? {
                  gen: data.plan.gen,
                  freeRTTCCx: data.plan.freeRTTCCx,
                  includesDeepData: data.plan.includesDeepData,
                  includesEnterprise: data.plan.includesEnterprise,
                  includesHosting: data.plan.includesHosting,
                  maxAccounts: data.plan.maxAccounts,
                  maxDau: data.plan.maxDau,
                  subscriptionType: data.plan.subscriptionType,
              }
            : {
                  gen: 0,
                  freeRTTCCx: 0,
                  includesDeepData: false,
                  includesEnterprise: false,
                  includesHosting: false,
                  maxAccounts: 0,
                  maxDau: 0,
                  subscriptionType: "",
              },

        puchases: {
            count: data.purchaseCount,
            byPlatform: data.purchaseCountPlatform,
            byItem: data.purchasedCountItem,
        },

        revenue: {
            count: data.revenue,
            byPlatform: data.revenuePlatform,
            byItem: data.revenueItem,
            hourly: data.revenueHourly,
        },

        scripts: {
            scheduled: data.scheduledScriptCallCountDay,
            stats: data.scriptCallStats,
        },

        sessions: {
            count: data.sessionCount,
            hourly: data.sessionCountHourly,
            byPlatform: data.sessionCountPlatform,
            byLibrary: data.sessionCountLibrary
                ? {
                      APIExplorer: data.sessionCountLibrary.APIExplorer,
                      CPP: data.sessionCountLibrary.cpp,
                      CSHARP: data.sessionCountLibrary.csharp,
                      JAVA: data.sessionCountLibrary.java,
                      JAVASCRIPT: data.sessionCountLibrary.js,
                      OBJC: data.sessionCountLibrary.objc,
                  }
                : {
                      APIExplorer: 0,
                      CPP: 0,
                      CSHARP: 0,
                      JAVA: 0,
                      JAVASCRIPT: 0,
                      OBJC: 0,
                  },
            byLibraryVersion: data.sessionCountLibraryVersion,
        },

        requests: {
            unprocessed: data.unprocessedRequestCount,
            unreadable: data.unreadableRequestCount,
        },

        other: {
            emailsSentCount: data.emailsSentCount,
            freePeerApiCallCount: data.freePeerApiCallCount,
            heartbeatCount: data.heartbeatCountDay,
            loginsOverLimitCount: data.loginsOverLimitCount,
            kbDownloaded: data.kbDownloaded,
            kbStorage: data.kbStorage,
            dispV1CallCount: data.dispV1CallCountDay,
        },
        dataInv: {
            segments: data?.appMetrics?.dataInv?.segments,
        },
    };
};
