import { AvailableAppAction } from "store/actions/availableApps";

import AppInfo from "types/models/AppInfo";

const initialState: AppInfo[] = [];

const reducer = (state: AppInfo[] = initialState, action: AvailableAppAction) => {
    let newState = state;

    switch (action.type) {
        case 'SET_AVAILABLE_APPS':
            newState = [...action.payload];

            break;
        default:
            // Do nothing.
    }

    return newState;
};

export default reducer;
