export default interface TermsOfServiceAcceptance {
    version: number;
    email: string;
    fullName: string;
    acceptedAt: Date;
}

export const processTermsOfServiceAcceptance = (data: any): TermsOfServiceAcceptance[] => {
    const result: TermsOfServiceAcceptance[] = [];

    if (data) {
        Object.keys(data).forEach((key) => {
            const item = data[key];

            result.push({
                version: Number.parseInt(item.version),
                email: item.email,
                fullName: item.name,
                acceptedAt: new Date(item.acceptedAt),
            });
        });
    }

    result.sort((a, b) => (a.version < b.version ? -1 : a.version > b.version ? 1 : 0));

    return result;
};
