// Import libraries.
import React, { CSSProperties } from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import classnames from "classnames";

// Import types.
import PortalRouteDefinition from "types/common/PortalRouteDefinition";

// Import components.
import { Link, MenuItem, Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";
import IconButton from "components/common/button/IconButton";
import PopperWithArrow from "components/common/PopperWithArrow";
import NavigatorRouteFlyout from "./NavigatorRouteFlyout";

// Import icons.
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface OWN_PROPS {
    id: string;
    style?: CSSProperties;
    icon?: React.ReactNode | null;
    label: string;
    onClick?: () => void;
    toggleFlyout?: (flyoutPath: string) => void;
    onNavigateToPath: (path: string) => void;
    context: "super" | "team" | "app" | "user";
    definitions: PortalRouteDefinition[];
    flyoutPath: string | null;
    selected?: boolean | null;
    disabled?: boolean | null;
    expandable?: boolean | null;
    expanded?: boolean | null;
    favorite?: boolean | null;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class NavigatorRouteItem extends React.PureComponent<PROPS> {
    onClick = () => {
        const { disabled } = this.props;

        if (!disabled && this.props.onClick) {
            this.props.onClick();
        }
    };

    toggleFlyout = (event?: React.MouseEvent<HTMLElement>) => {
        const { id } = this.props;

        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        if (this.props.toggleFlyout) {
            this.props.toggleFlyout(id);
        }
    };

    handleEvent = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
    };

    render() {
        const { classes, id, style, label, icon, context, definitions, flyoutPath, selected, disabled, expandable, expanded, favorite } = this.props;

        const hasFlyout = this.props.toggleFlyout != null;

        const isDashboard = ["/super/dashboard", "/team/dashboard", "/app/dashboard", "/user/user-browser/:companyId?/:appId?/:playerId?"].includes(id);
        const isFavorites = ["/super/favorites", "/team/favorites", "/app/favorites", "/user/favorites"].includes(id);
        const iconColor =
            context === "super"
                ? "var(--navigation-super-context-color, inherit)"
                : context === "team"
                ? "var(--navigation-team-context-color, inherit)"
                : context === "app"
                ? "var(--navigation-app-context-color, inherit)"
                : context === "user"
                ? "var(--navigation-user-context-color, inherit)"
                : "inherit";
        let href = id;
        const firstParameterIdx = href.indexOf("/:");
        if (firstParameterIdx > -1) {
            href = href.substring(0, firstParameterIdx);
        }

        return (
            <MenuItem
                data-id={id}
                data-type={"route-path"}
                component={"div"}
                className={classnames({
                    [classes.root]: true,
                    [classes.super]: context === "super",
                    [classes.team]: context === "team",
                    [classes.app]: context === "app",
                    [classes.user]: context === "user",
                    expandable: expandable,
                    expanded: expanded,
                    selected: selected,
                    disabled: disabled,
                })}
                style={style}
                disabled={disabled === true}
                onClick={this.onClick}
            >
                {icon && (
                    <span id={"context-icon"} className={classes.icon} style={{ flex: "0 0 auto", width: "1.5em", height: "1.5em", marginRight: "0.25em" }}>
                        {icon}
                    </span>
                )}

                <Link
                    id={"label"}
                    style={{ flex: "1 1 auto", fontWeight: favorite ? "bold" : undefined, display: "flex", alignItems: "center" }}
                    noWrap
                    href={window.origin + "/#" + href}
                    onClick={(event: React.MouseEvent) => event.preventDefault()}
                    underline={"none"}
                >
                    {expandable && (
                        <IconButton id={"toggle-expanded"} style={{ color: iconColor, marginRight: "0.25em" }} className={classes.chevronIcon} disabled={disabled === true}>
                            {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </IconButton>
                    )}
                    <Tooltip arrow title={label} placement="right">
                        <Typography style={{ marginLeft: expandable || isDashboard || isFavorites ? "0.3125em" : "2.075em", overflow: "hidden", textOverflow: "ellipsis" }}>{label}</Typography>
                    </Tooltip>
                </Link>

                {hasFlyout && (
                    <span style={{ marginLeft: "auto", paddingLeft: "0.3125em", paddingRight: "0.125em" }} onClick={disabled !== true ? this.toggleFlyout : undefined} onMouseDown={this.handleEvent} onMouseUp={this.handleEvent}>
                        <PopperWithArrow
                            className={classes.popper}
                            placement={"right"}
                            content={<NavigatorRouteFlyout context={context} definitions={definitions} path={flyoutPath || ""} onNavigateToPath={this.props.onNavigateToPath} />}
                            open={flyoutPath === id}
                            onClose={this.toggleFlyout}
                        >
                            <IconButton id={"toggle-flyout"} className={classes.chevronIcon} disabled={disabled === true}>
                                {id === flyoutPath ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                            </IconButton>
                        </PopperWithArrow>
                    </span>
                )}
            </MenuItem>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",

            minHeight: "unset",
            maxHeight: "unset",

            overflow: "hidden",

            padding: 0,

            "&.MuiListItem-root.Mui-disabled": {
                opacity: 1,
                cursor: "not-allowed",
                pointerEvents: "all",
            },
            "& > #label": {
                color: "inherit",

                "&:hover": {
                    textDecoration: "none",
                },
            },
        },
        icon: {
            flex: "0 0 auto",
            marginRight: "0.625em",
            overflow: "hidden",

            "& > svg": {
                width: "100% !important",
                height: "100% !important",
            },
        },
        chevronIcon: {
            width: "1.5em",
            height: "1.5em",
            margin: 0,
            "&:hover > .MuiTouchRipple-root": {
                backgroundColor: "var(--navigation-color, inherit)",
                opacity: "0.2",
            },
        },
        super: {
            "& > #context-icon": {
                color: "var(--navigation-super-context-color, inherit)",
            },

            "& > #selected-icon, & > #unselected-icon, & > #unselected-icon-hover": {
                color: "var(--navigation-super-context-color, inherit)",
            },

            "& > #unselected-icon": {
                display: "block",
            },
            "& > #unselected-icon-hover": {
                display: "none",
            },

            "&:hover": {
                backgroundColor: "inherit",

                "& > #label": {
                    color: "var(--navigation-super-context-color, inherit)",
                },

                "& > #icon-button-toggle-expanded": {
                    backgroundColor: "var(--navigation-super-context-background-color, inherit)",
                    color: "var(--navigation-super-context-color, inherit)",
                },

                "&:not(.disabled) > #unselected-icon": {
                    display: "none",
                },
                "&:not(.disabled) > #unselected-icon-hover": {
                    display: "block",
                },
            },

            "&.selected.expandable": {
                "& > #icon-button-toggle-flyout": {
                    color: "var(--navigation-color, inherit)",
                },
            },

            "&.selected:not(.expandable)": {
                backgroundColor: "var(--navigation-super-context-background-color, inherit)",
                color: "var(--navigation-super-context-color, inherit)",

                "& > #label": {
                    fontWeight: "bold",
                },
            },

            "&.disabled": {
                backgroundColor: "var(--navigation-disabled-background-color, inherit)",
                color: "var(--navigation-disabled-color, inherit)",

                "& > #context-icon": {
                    color: "var(--navigation-disabled-color, inherit)",
                },

                "& > #selected-icon, & > #unselected-icon, & > #unselected-icon-hover": {
                    color: "var(--navigation-disabled-color, inherit)",
                },

                "&:hover": {
                    backgroundColor: "var(--navigation-disabled-background-color, inherit)",
                    color: "var(--navigation-disabled-color, inherit)",

                    "& > #context-icon": {
                        color: "var(--navigation-disabled-color, inherit)",
                    },

                    "& > #label": {
                        color: "inherit",
                    },

                    "& > #icon-button-toggle-expanded": {
                        backgroundColor: "inherit",
                        color: "var(--navigation-disabled-color, inherit)",
                    },
                },

                opacity: 1,
                cursor: "not-allowed",
                pointerEvents: "all",
            },
        },
        team: {
            "& > #context-icon": {
                color: "var(--navigation-team-context-color, inherit)",
            },

            "& > #selected-icon, & > #unselected-icon, & > #unselected-icon-hover": {
                color: "var(--navigation-team-context-color, inherit)",
            },

            "& > #unselected-icon": {
                display: "block",
            },
            "& > #unselected-icon-hover": {
                display: "none",
            },

            "&:hover": {
                backgroundColor: "inherit",

                "& > #label": {
                    color: "var(--navigation-team-context-color, inherit)",
                },

                "& > #icon-button-toggle-expanded": {
                    backgroundColor: "var(--navigation-team-context-background-color, inherit)",
                    color: "var(--navigation-team-context-color, inherit)",
                },

                "&:not(.disabled) > #unselected-icon": {
                    display: "none",
                },
                "&:not(.disabled) > #unselected-icon-hover": {
                    display: "block",
                },
            },

            "&.selected.expandable": {
                "& > #icon-button-toggle-flyout": {
                    color: "var(--navigation-color, inherit)",
                },
            },

            "&.selected:not(.expandable)": {
                backgroundColor: "var(--navigation-team-context-background-color, inherit)",
                color: "var(--navigation-team-context-color, inherit)",

                "& > #label": {
                    fontWeight: "bold",
                },
            },

            "&.disabled": {
                backgroundColor: "var(--navigation-disabled-background-color, inherit)",
                color: "var(--navigation-disabled-color, inherit)",

                "& > #context-icon": {
                    color: "var(--navigation-disabled-color, inherit)",
                },

                "& > #selected-icon, & > #unselected-icon, & > #unselected-icon-hover": {
                    color: "var(--navigation-disabled-color, inherit)",
                },

                "&:hover": {
                    backgroundColor: "var(--navigation-disabled-background-color, inherit)",
                    color: "var(--navigation-disabled-color, inherit)",

                    "& > #context-icon": {
                        color: "var(--navigation-disabled-color, inherit)",
                    },

                    "& > #label": {
                        color: "inherit",
                    },

                    "& > #icon-button-toggle-expanded": {
                        backgroundColor: "inherit",
                        color: "var(--navigation-disabled-color, inherit)",
                    },
                },

                opacity: 1,
                cursor: "not-allowed",
                pointerEvents: "all",
            },
        },
        app: {
            "& > #context-icon": {
                color: "var(--navigation-app-context-color, inherit)",
            },

            "& > #selected-icon, & > #unselected-icon, & > #unselected-icon-hover": {
                color: "var(--navigation-app-context-color, inherit)",
            },

            "& > #unselected-icon": {
                display: "block",
            },
            "& > #unselected-icon-hover": {
                display: "none",
            },

            "&:hover": {
                backgroundColor: "inherit",

                "& > #label": {
                    color: "var(--navigation-app-context-color, inherit)",
                },

                "& > #icon-button-toggle-expanded": {
                    backgroundColor: "var(--navigation-app-context-background-color, inherit)",
                    color: "var(--navigation-app-context-color, inherit)",
                },

                "&:not(.disabled) > #unselected-icon": {
                    display: "none",
                },
                "&:not(.disabled) > #unselected-icon-hover": {
                    display: "block",
                },
            },

            "&.selected.expandable": {
                "& > #icon-button-toggle-flyout": {
                    color: "var(--navigation-color, inherit)",
                },
            },

            "&.selected:not(.expandable)": {
                backgroundColor: "var(--navigation-app-context-background-color, inherit)",
                color: "var(--navigation-app-context-color, inherit)",

                "& > #label": {
                    fontWeight: "bold",
                },
            },

            "&.disabled": {
                backgroundColor: "var(--navigation-disabled-background-color, inherit)",
                color: "var(--navigation-disabled-color, inherit)",

                "& > #context-icon": {
                    color: "var(--navigation-disabled-color, inherit)",
                },

                "& > #selected-icon, & > #unselected-icon, & > #unselected-icon-hover": {
                    color: "var(--navigation-disabled-color, inherit)",
                },

                "&:hover": {
                    backgroundColor: "var(--navigation-disabled-background-color, inherit)",
                    color: "var(--navigation-disabled-color, inherit)",

                    "& > #context-icon": {
                        color: "var(--navigation-disabled-color, inherit)",
                    },

                    "& > #label": {
                        color: "inherit",
                    },

                    "& > #icon-button-toggle-expanded": {
                        backgroundColor: "inherit",
                        color: "var(--navigation-disabled-color, inherit)",
                    },
                },

                opacity: 1,
                cursor: "not-allowed",
                pointerEvents: "all",
            },
        },
        user: {
            "& > #context-icon": {
                color: "var(--navigation-user-context-color, inherit)",
            },

            "& > #selected-icon, & > #unselected-icon, & > #unselected-icon-hover": {
                color: "var(--navigation-user-context-color, inherit)",
            },

            "& > #unselected-icon": {
                display: "block",
            },
            "& > #unselected-icon-hover": {
                display: "none",
            },

            "&:hover": {
                backgroundColor: "inherit",

                "& > #label": {
                    color: "var(--navigation-user-context-color, inherit)",
                },

                "& > #icon-button-toggle-expanded": {
                    backgroundColor: "var(--navigation-user-context-background-color, inherit)",
                    color: "var(--navigation-user-context-color, inherit)",
                },

                "&:not(.disabled) > #unselected-icon": {
                    display: "none",
                },
                "&:not(.disabled) > #unselected-icon-hover": {
                    display: "block",
                },
            },

            "&.selected.expandable": {
                "& > #icon-button-toggle-flyout": {
                    color: "var(--navigation-color, inherit)",
                },
            },

            "&.selected:not(.expandable)": {
                backgroundColor: "var(--navigation-user-context-background-color, inherit)",
                color: "var(--navigation-user-context-color, inherit)",

                "& > #label": {
                    fontWeight: "bold",
                },
            },

            "&.disabled": {
                backgroundColor: "var(--navigation-disabled-background-color, inherit)",
                color: "var(--navigation-disabled-color, inherit)",

                "& > #context-icon": {
                    color: "var(--navigation-disabled-color, inherit)",
                },

                "& > #selected-icon, & > #unselected-icon, & > #unselected-icon-hover": {
                    color: "var(--navigation-disabled-color, inherit)",
                },

                "&:hover": {
                    backgroundColor: "var(--navigation-disabled-background-color, inherit)",
                    color: "var(--navigation-disabled-color, inherit)",

                    "& > #context-icon": {
                        color: "var(--navigation-disabled-color, inherit)",
                    },

                    "& > #label": {
                        color: "inherit",
                    },

                    "& > #icon-button-toggle-expanded": {
                        backgroundColor: "inherit",
                        color: "var(--navigation-disabled-color, inherit)",
                    },
                },

                opacity: 1,
                cursor: "not-allowed",
                pointerEvents: "all",
            },
        },
        popper: {
            marginLeft: "0.85em !important",
        },
    });

export default withStyles(styles)(NavigatorRouteItem);
