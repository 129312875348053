// Import libraries.
import { all } from "redux-saga/effects";

// Import redux sagas.
import initialization from "./initializationSagas";
import authentication from "./authenticationSagas";
import user from "./userSagas";
import theme from "./themeSagas";
import session from "./sessionSagas";
import company from "./companySagas";
import app from "./appSagas";
import privilege from "./privilegeSagas";
import utility from "./utilitySagas";

export default function* root() {
    yield all([initialization(), authentication(), user(), theme(), session(), company(), app(), privilege(), utility()]);
}
