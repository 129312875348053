// Import libraries.
import React, { FunctionComponent } from "react";
import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

// Import components.
import Box from "@mui/material/Box";

// Define the properties accepted by this component.
interface OWN_PROPS {
    style?: React.CSSProperties;
    children?: React.ReactNode;
    index: any;
    value: any;
    keepMounted?: boolean;
}
interface PROPS extends OWN_PROPS {}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: "1 1 auto",
        display: "flex",
        backgroundColor: "inherit",
        color: "inherit",
        borderColor: "inherit",
        overflow: "hidden",
        "&[hidden]": {
            display: "none",
        },
    },
    inner: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",

        backgroundColor: "inherit",
        color: "inherit",
        borderColor: "inherit",

        overflow: "auto",

        "& > *": {
            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
    },
}));

const TabPanel: FunctionComponent<PROPS> = (props) => {
    const { style, children, value, index, keepMounted, ...other } = props;

    const classes = useStyles();

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other} className={classes.root} style={style}>
            {keepMounted && <Box className={classes.inner}>{children}</Box>}
            {!keepMounted && value === index && <Box className={classes.inner}>{children}</Box>}
        </div>
    );
};

export default TabPanel;
