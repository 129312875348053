// Import libraries.
import React, { CSSProperties } from "react";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import classnames from "classnames";

// Import types.
import PlayerSummary from "types/models/PlayerSummaryInfo";

// Import components.
import { MenuItem, Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";
import ImageWrapper from "components/common/ImageWrapper";
import UserImagePlaceHolder from "components/common/UserImagePlaceHolder";

// Import icons.
import SelectedIcon from "@mui/icons-material/FiberManualRecord";

// Import utilities.
import StringUtils from "utils/String";

interface OWN_PROPS {
    style?: CSSProperties;

    playerSummary: PlayerSummary;

    disabled?: boolean;
    selected?: boolean;

    onClick?: (playerId?: string) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class PlayerMenuItem extends React.PureComponent<PROPS> {
    render() {
        const { classes, style, playerSummary, disabled, selected } = this.props;

        return (
            <MenuItem
                data-id={playerSummary.playerId}
                data-name={playerSummary.name}
                data-email={playerSummary.email}
                data-type={"user"}
                component={"div"}
                className={classnames({ [classes.root]: true, [classes.selected]: selected != null && selected, [classes.hover]: disabled != null && !selected })}
                style={style}
                disabled={disabled != null && disabled}
                onClick={() => !disabled && this.props.onClick && this.props.onClick(playerSummary.playerId)}
                disableRipple
            >
                <ImageWrapper
                    style={{ width: "2em", height: "2em" }}
                    src={!StringUtils.isNullOrEmpty(playerSummary.pictureUrl) ? playerSummary.pictureUrl : null}
                    fallback={<UserImagePlaceHolder data={playerSummary} style={{ fontSize: "0.75em" }} />}
                    disableCache={playerSummary.updatedAt || null}
                />

                <Tooltip
                    alwaysShow
                    arrow
                    title={
                        <span style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>{playerSummary.name}</Typography>

                            <Typography>
                                <Trans>(Profile ID: {playerSummary.playerId})</Trans>
                            </Typography>
                        </span>
                    }
                >
                    <Typography style={{ flex: "1 1 auto", fontWeight: "bold", marginLeft: "0.3125em", marginRight: "0.3125em" }} noWrap>
                        {playerSummary.name || playerSummary.playerId}
                    </Typography>
                </Tooltip>

                {selected != null && selected && <SelectedIcon style={{ flex: "0 0 auto", marginLeft: "auto", color: "var(--navigation-app-context-color, inherit)" }} />}
            </MenuItem>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",

            padding: "0.3125em",

            overflow: "hidden",

            "&:hover": {
                backgroundColor: "inherit",
                color: "inherit",
                borderColor: "inherit",
            },
        },
        selected: {
            backgroundColor: "var(--navigation-user-selector-active-background-color, inherit)",
            color: "var(--navigation-user-selector-active-color, inherit)",

            "&:hover": {
                backgroundColor: "var(--navigation-user-selector-active-background-color, inherit)",
                color: "var(--navigation-user-selector-active-color, inherit)",
            },
        },
        hover: {
            "&:hover": {
                backgroundColor: "var(--navigation-user-selector-hover-background-color, inherit)",
                color: "var(--navigation-user-selector-hover-color, inherit)",
            },
        },
    });

export default withStyles(styles)(PlayerMenuItem);
