import LocalStorageUtils from "utils/LocalStorage";

import { CookieConsentLevel } from "./CookieConsent";

/**
 * Recently Viewed utility functions.
 */
export default abstract class RecentlyViewedUtils {
    static RECENTLY_VIEWED = "recentlyViewed";

    public static getRecentlyViewed(profileId: string): string[] {
        const recentlyViewedRaw = LocalStorageUtils.getItem(profileId + "-" + this.RECENTLY_VIEWED);

        try {
            const recentlyViewed = recentlyViewedRaw ? JSON.parse(recentlyViewedRaw) : [];

            if (Array.isArray(recentlyViewed)) {
                return recentlyViewed as string[];
            }
        } catch (error: any) {
            console.warn("Unable to read recently viewed for user", profileId, error);
        }

        return [];
    }

    public static setRecentlyViewed(profileId: string, recentlyViewed: string[]) {
        LocalStorageUtils.setItem(profileId + "-" + this.RECENTLY_VIEWED, JSON.stringify(recentlyViewed), CookieConsentLevel.FUNCTIONALITY);
    }
}
