import StringUtils from "utils/String";

export default interface ScreenSettings {
    id: string;
    parentId: string | null;
    nameEn: string | null;
    nameFr: string | null;
    namexEn: string | null;
    namexFr: string | null;
    descriptionEn: string | null;
    descriptionFr: string | null;
    sortCategory: string | null;
    sortMajor: number | null;
    sortMinor: number | null;
    isShowable: boolean;
    superOnly: boolean;
    enableName: string;
    systemEnableValue: string;
    applicationEnableValue?: string;
    newEnableValue?: string;
}

export const processScreenSettings = (data: any): ScreenSettings => {
    return {
        id: "" + data["privilege-id"],
        parentId: data["parent-id"] != null ? "" + data["parent-id"] : null,
        nameEn: data["name"] != null ? "" + data["name"] : null,
        nameFr: data["name-fr"] != null ? "" + data["name-fr"] : null,
        namexEn: data["namex"] != null ? "" + data["namex"] : null,
        namexFr: data["namex-fr"] != null ? "" + data["namex-fr"] : null,
        descriptionEn: data["description"] != null ? "" + data["description"] : null,
        descriptionFr: data["description-fr"] != null ? "" + data["description-fr"] : null,
        sortCategory: data["sort-category"] != null ? "" + data["sort-category"] : null,
        sortMajor: data["sort-major"] != null ? Number.parseInt(data["sort-major"]) : null,
        sortMinor: data["sort-minor"] != null ? Number.parseInt(data["sort-minor"]) : null,
        isShowable: StringUtils.isTruthy(data["is-showable"]),
        superOnly: StringUtils.isTruthy(data["super-only"]),
        enableName: "" + data["enable-name"],
        systemEnableValue: "" + data["system-enable-value"],
        applicationEnableValue: data["application-enable-value"] !== undefined ? "" + data["application-enable-value"] : undefined,
        newEnableValue: undefined,
    };
};
