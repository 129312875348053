import { DirectAPIAction } from "store/actions/directAPI";

import DirectAPI, { DirectAPILog, DirectAPISelection, DirectAPISession } from "types/common/DirectAPI";

import CloneUtils from "utils/Clone";

const initialState: DirectAPI = {
    sessions: [],

    logs: [],

    isBusy: false,

    bypassUserEnabledCheck: true,

    selections: [],
};

const reducer = (state: DirectAPI = initialState, action: DirectAPIAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_DIRECT_API_SESSION":
            const sessionToSet = newState.sessions.find((item) => item.appId === (action.payload as DirectAPISession).appId) || null;

            if (sessionToSet) {
                const sessionToSetIdx = newState.sessions.indexOf(sessionToSet);

                newState = CloneUtils.clone(state) as DirectAPI;

                newState.sessions[sessionToSetIdx] = CloneUtils.clone(action.payload);
            } else {
                newState = CloneUtils.clone(state) as DirectAPI;

                newState.sessions.push(CloneUtils.clone(action.payload));
            }

            break;
        case "CLEAR_DIRECT_API_SESSION":
            const sessionToClear = newState.sessions.find((item) => item.appId === (action.payload as DirectAPISession).appId) || null;

            if (sessionToClear) {
                const sessionToClearIdx = newState.sessions.indexOf(sessionToClear);

                newState = CloneUtils.clone(state) as DirectAPI;

                newState.sessions.splice(sessionToClearIdx, 1);
            }

            break;
        case "APPEND_DIRECT_API_LOG":
            if (action.payload) {
                newState = CloneUtils.clone(state) as DirectAPI;

                newState.logs.push(CloneUtils.clone(action.payload));
            }

            break;
        case "UPDATE_DIRECT_API_LOG":
            if (action.payload) {
                const log = action.payload as DirectAPILog;

                const target = newState.logs.find((item) => item.appId === log.appId && item.timestamp.getTime() === log.timestamp.getTime()) || null;
                const idx = target ? newState.logs.indexOf(target) : -1;

                if (idx > -1) {
                    newState = CloneUtils.clone(state) as DirectAPI;

                    newState.logs[idx] = log;
                }
            }

            break;
        case "CLEAR_DIRECT_API_LOGS":
            newState = CloneUtils.clone(state) as DirectAPI;

            newState.logs = newState.logs.filter((item) => !item.response && !item.custom);

            break;
        case "SET_DIRECT_API_BUSY":
            newState = CloneUtils.clone(state) as DirectAPI;

            newState.isBusy = action.payload as boolean;

            break;
        case "SET_DIRECT_API_BYPASS_USER_ENABLED_CHECK":
            newState = CloneUtils.clone(state) as DirectAPI;

            newState.bypassUserEnabledCheck = action.payload as boolean;

            break;
        case "SET_DIRECT_API_SELECTION":
            if (action.payload) {
                const newSelection = action.payload as DirectAPISelection;

                const existingSelection = newState.selections.find((item) => item.appId === newSelection.appId) || null;

                if (existingSelection) {
                    const idx = newState.selections.indexOf(existingSelection);

                    newState = CloneUtils.clone(state) as DirectAPI;

                    newState.selections[idx] = CloneUtils.clone(newSelection) as DirectAPISelection;
                } else {
                    newState = CloneUtils.clone(state) as DirectAPI;

                    newState.selections.push(CloneUtils.clone(newSelection) as DirectAPISelection);
                }
            }

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
