// Import libraries.
import React, { CSSProperties } from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

// Import types.
import User from "types/common/User";
import PlayerInfo from "types/models/PlayerInfo";
import PlayerSummary from "types/models/PlayerSummaryInfo";

// Import utils.
import Typography from "@mui/material/Typography";

interface OWN_PROPS {
    data?: PlayerSummary | PlayerInfo | User | null;
    style?: CSSProperties;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

interface STATE {
    initials: string;
    checksum: number;
}

class UserImagePlaceHolder extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        initials: "",
        checksum: 0,
    };

    componentDidMount(): void {
        this.calculateInitials();
        this.calculateChecksum();
    }

    componentDidUpdate(prevProps: Readonly<PROPS>): void {
        if (prevProps.data !== this.props.data) {
            this.calculateInitials();
            this.calculateChecksum();
        }
    }

    extractSourceText = () => {
        const { data } = this.props;

        const id: string | null = (data as any)?.profileId || (data as any)?.playerId || null;
        const name: string | null = (data as any)?.fullName || (data as any)?.playerName || (data as any)?.name || null;
        const email = data?.email || null;

        const text = name || email || id || null;

        return text?.trim() || null;
    };

    calculateInitials = () => {
        const text = this.extractSourceText();

        let initials = "";

        if (text) {
            if (text.includes("@")) {
                const [username, domain] = text.split("@").map((item) => item.trim());

                if (username) initials += [...username][0].toUpperCase();
                if (domain) initials += [...domain][0].toUpperCase();
            } else if (text.includes(" ")) {
                const names = text.split(" ");

                if (names.length > 0) initials += [...names[0]][0].toUpperCase();
                if (names.length > 1) initials += [...names[names.length - 1]][0].toUpperCase();
            } else {
                if (text.length > 0) initials += [...text][0].toUpperCase();
                if (text.length > 1) initials += [...text][1].toUpperCase();
            }
        }

        this.setState({ initials });
    };

    calculateChecksum = () => {
        const { data } = this.props;

        const text = (data as any)?.profileId || (data as any)?.playerId || "";

        let checksum = 0;

        for (let i = 0; i < text.length; i++) {
            checksum += text.charCodeAt(i);
        }

        this.setState({ checksum });
    };

    render() {
        const { classes, style } = this.props;
        const { initials, checksum } = this.state;

        const modulus21 = checksum % 21;

        return (
            <span className={classes.background} style={{ backgroundColor: `var(--chart-dataset-color-${modulus21}, inherit)`, height: style?.height, width: style?.width }}>
                <Typography
                    style={{
                        fontWeight: "bold",
                        fontSize: style?.fontSize ? style.fontSize : initials.length === 1 ? "4em" : "3em",
                        color: `var(--chart-dataset-font-color-${modulus21}, inherit)`,
                    }}
                    noWrap
                >
                    {initials}
                </Typography>
            </span>
        );
    }
}

const styles = () =>
    createStyles({
        background: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            borderRadius: "50%",
            overflow: "hidden",
        },
    });

export default withStyles(styles)(UserImagePlaceHolder);
