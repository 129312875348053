// Import libraries.
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from "@lingui/macro";

// Import types.
import SupportedLanguage from "types/enums/SupportedLanguage";
import PortalState from "types/store";
import Session from "types/common/Session";
import User from "types/common/User";
import SystemRole from "types/common/SystemRole";
import AppRole from "types/common/AppRole";
import TeamInfo from "types/models/TeamInfo";

// Import components.
import Typography from "@mui/material/Typography";
import FieldWrapper from "components/common/form/FieldWrapper";

// Import table components.
import { ColumnDefinition } from "components/common/table2/types";
import CustomTable from "components/common/table2";

// Import utilities.
import StringUtils from "utils/String";

interface STATE_PROPS {
    session: Session;
    currentUser: User | null;
    systemRoles: SystemRole[];
    currentCompanyAlias: TeamInfo | null;
    availableCompanies: TeamInfo[];
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    context: "self" | "super" | "team";
    hasApiAccess: boolean;
    isTeamAdmin: boolean;
    isCurrentSessionUser: boolean;
    appRoles: AppRole[];
    onChange: (isTeamAdmin: boolean, hasApiAccess: boolean) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles> {}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        currentUser: state.currentUser,
        systemRoles: state.systemRoles,
        currentCompanyAlias: state.currentCompanyAlias,
        availableCompanies: state.availableCompanies,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

class Permissions extends React.PureComponent<PROPS> {
    handleChange = (name: string, value: any) => {
        const { isTeamAdmin, hasApiAccess } = this.props;

        switch (name) {
            case "isTeamAdmin":
                this.props.onChange(value, hasApiAccess);
                break;
            case "hasApiAccess":
                this.props.onChange(isTeamAdmin, value);
                break;
            default:
            // Unknown field.
        }
    };

    render() {
        const { classes, session, currentUser, systemRoles, currentCompanyAlias, availableCompanies, context, isTeamAdmin, hasApiAccess, isCurrentSessionUser, appRoles } = this.props;

        const currentCompany = session.isSuper && context === "team" ? currentCompanyAlias : availableCompanies.find((item) => item.companyId === session.companyId);

        const columns: ColumnDefinition[] = [
            {
                id: "gameName",
                sortable: true,
                width: 300,
                resizable: true,
                label: <Trans>App Name</Trans>,
                format: (value: any, row: any): ReactNode => {
                    return <span style={{ overflow: "hidden" }}>{row.gameName}</span>;
                },
            },
            {
                id: "roles",
                align: "center",
                label: <Trans>Roles</Trans>,
                format: (value: any, row: any): ReactNode => {
                    const roles = systemRoles ? systemRoles.filter((item) => row.roles.includes(item.id)) : [];
                    const rolesLabel = roles
                        .map((item) => {
                            const label = currentUser?.preferredLanguage === SupportedLanguage.FRENCH ? item.nameFr : item.nameEn;

                            return label;
                        })
                        .join(",");

                    return <span>{!StringUtils.isNullOrEmpty(rolesLabel) ? rolesLabel : <Trans>None</Trans>}</span>;
                },
            },
        ];

        return (
            <div className={classes.root}>
                <Typography style={{ margin: "0.3125em", padding: "0.3125em" }}>
                    <Trans>Permissions for {currentCompany ? currentCompany.companyName + " (" + currentCompany.accountNumber + ")" : null}</Trans>
                </Typography>

                <FieldWrapper
                    type={"checkbox"}
                    name={"isTeamAdmin"}
                    value={isTeamAdmin}
                    label={<Trans>Team Administrator</Trans>}
                    labelPosition={"right"}
                    controlStyle={{ flex: "0 0 auto" }}
                    labelStyle={{ flex: "1 1 auto" }}
                    onChange={this.handleChange}
                    disabled={!session.isTeamAdmin || isCurrentSessionUser === true}
                />

                <FieldWrapper
                    type={"checkbox"}
                    name={"hasApiAccess"}
                    value={hasApiAccess}
                    label={<Trans>Builder API Access</Trans>}
                    labelPosition={"right"}
                    controlStyle={{ flex: "0 0 auto" }}
                    labelStyle={{ flex: "1 1 auto" }}
                    onChange={this.handleChange}
                    disabled={!session.isTeamAdmin || context === "self"}
                />

                {appRoles && appRoles.length > 0 && (
                    <div className={classes.visibleApps}>
                        <Typography variant={"h6"} style={{ padding: "0.3125em" }}>
                            <Trans>Visible Apps</Trans>
                        </Typography>

                        <CustomTable id={"app-roles"} columns={columns} primaryKey="gameId" hidePaginator={true} defaultPageSize={-1} dataProvider={{ rows: appRoles, total: appRoles.length }} />
                    </div>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
        },
        visibleApps: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            margin: "0.3125em",
            minHeight: "12.5em",
            overflow: "hidden",
            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Permissions));
