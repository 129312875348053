// Import libraries.
import { AbstractSearchPlugin } from "..";

// Import types.
import Session from "types/common/Session";
import AppInfo, { processAppInfo } from "types/models/AppInfo";
import { PluginName, Query, QueryOptions, SearchConfiguration, SearchResult } from "../../types";

// Import utilities.
import Http from "utils/networking/Http";
import { APP_ID_HINTS, APP_NAME_HINTS } from "../../utils";

// A union of all valid hints.
const VALID_HINTS = [...APP_ID_HINTS, ...APP_NAME_HINTS];

/**
 * This plugin looks for results that are app-oriented.
 *
 * This includes most of the screens under the APP context (based on the nature of the individual screen).
 */
class AppPlugin extends AbstractSearchPlugin {
    private session: Session | null = null;
    private availableApps: AppInfo[] = [];
    private options: QueryOptions | null = null;

    isHintSupported = (query: Query): boolean => {
        return VALID_HINTS.includes(query.hint.toLowerCase());
    };

    configure = (args?: SearchConfiguration | null) => {
        if (args) {
            this.session = args.session || null;
            this.availableApps = args.availableApps || [];
            this.options = args.options || null;
        }
    };

    executeQuery = async (query: Query, abortSignal?: AbortSignal) => {
        if (abortSignal?.aborted) {
            throw new DOMException("Aborted", "AbortError");
        }

        console.debug("Executing Plugin", PluginName.APP, query);

        // Define a collection to hold the results.
        const searchResults: SearchResult[] = [];

        // Extract those options we are concerned with (i.e. those that the plugin actually supports).
        const exhaustiveSearch = this.options?.exhaustiveSearch || false;

        // Extract the hint and searchTerm from the query.
        const { hint, searchTerm } = query;

        // By default we have the current list of available apps (those that the user has direct access to, based on the current company id and/or company id alias).
        // This would be used by default or as a fallback if more specialized methods of determnining the list of apps are unavailable.
        let apps = this.availableApps;

        if (this.session?.isSuper) {
            // For SUPER users we want to search against all apps system-wide (this could end up being a little slow... but it's probably not too big of a deal).
            const superAppResponse = await Http.GET(
                "admin/serveradmin/superUserReadAppsPage",
                {
                    scope: "All Apps",
                    isToday: true,
                    search: searchTerm,
                    pageNumber: 1,
                    rowsPerPage: exhaustiveSearch ? 9999 : 200,
                    sortColumnName: "userCount",
                    sortDirection: "desc",
                },
                Http.JSON_HEADERS,
                abortSignal
            );

            if (abortSignal?.aborted) {
                throw new DOMException("Aborted", "AbortError");
            }

            if (Http.isStatusOk(superAppResponse) && !Http.isRedirect(superAppResponse) && Array.isArray(superAppResponse.data?.rows)) {
                apps = superAppResponse.data.rows.map(processAppInfo);
            }
        } else if (this.session?.isTeamAdmin) {
            // For TEAM ADMIN users we want to search against all apps company-wide (based on the currently selected company).
            const teamAdminAppResponse = await Http.GET(
                "admin/serveradmin/readTeamAppsPage",
                {
                    scope: "All Apps",
                    isToday: true,
                    search: searchTerm,
                    pageNumber: 1,
                    rowsPerPage: exhaustiveSearch ? 9999 : 200,
                    sortColumnName: "userCount",
                    sortDirection: "desc",
                },
                Http.JSON_HEADERS,
                abortSignal
            );

            if (abortSignal?.aborted) {
                throw new DOMException("Aborted", "AbortError");
            }

            if (Http.isStatusOk(teamAdminAppResponse) && !Http.isRedirect(teamAdminAppResponse) && Array.isArray(teamAdminAppResponse.data)) {
                apps = teamAdminAppResponse.data.map(processAppInfo);
            }
        } else {
            // For all other users we simply use the currently available apps collection (which are those apps that they actually have access to).
        }

        // Filter to applicable apps (based on the hint and searchTerm supplied).
        // This is necessary since the server end-point used to fetch the list of apps does not necessarily support any filtering (meaning we may get back all apps).
        const applicableApps = apps.filter((item) => {
            if (APP_ID_HINTS.includes(hint.toLowerCase())) {
                return item.appId.toLowerCase().includes(searchTerm.toLowerCase());
            }

            if (APP_NAME_HINTS.includes(hint.toLowerCase())) {
                return item.appName.toLowerCase().includes(searchTerm.toLowerCase());
            }

            return false;
        });

        // Sort the applicable apps by appName.
        applicableApps.sort((a, b) => a.appName.localeCompare(b.appName));

        // Generate SearchResult's for each applicable app.
        applicableApps.forEach((app) => {
            // APP / Dashboard.
            searchResults.push({
                type: PluginName.APP,
                targetPath: "/app/dashboard",
                targetState: null,
                searchTerm: searchTerm,
                data: app,
            });
        });

        console.debug("Plugin Results", PluginName.APP, searchResults);

        return searchResults;
    };
}

export default AppPlugin;
