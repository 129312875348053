import { CurrentUserAction } from "store/actions/currentUser";

import User from "types/common/User";

const initialState: User | null = null;

const reducer = (state: User | null = initialState, action: CurrentUserAction) => {
    let newState = state;

    switch (action.type) {
        case 'SET_CURRENT_USER':
            if (action.payload != null) {
                newState = Object.assign({}, action.payload);
            } else {
                newState = null;
            }

            break;
        default:
            // Do nothing.
    }

    return newState;
};

export default reducer;
