import { createContext, useCallback, useState, useEffect, useMemo } from "react";

import FlyOutPanel from "components/common/FlyOutPanel";

import { MenuItemDefinition } from "components/common/menu/types";

export interface FlyoutOptions {
    id?: string;

    open?: boolean;

    direction?: "left" | "right";

    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    titleIcon?: React.ReactNode;
    actionButtons?: React.ReactNode;
    actions?: React.ReactNode | MenuItemDefinition[];
    content?: React.ReactNode;

    copyData?: any;

    resizable?: boolean;
    fullScreen?: boolean;

    persist?: string | null;

    onClose?: () => void;
    onClick?: (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => void;
}

export type FlyoutContextType = {
    providerId?: string;
    consumerId?: string;
    setFlyoutOptions: (options: FlyoutOptions | null) => void;
};

const DEFAULT_VALUE: FlyoutContextType = {
    setFlyoutOptions: (_options: FlyoutOptions | null) => {
        return;
    },
};

export const FlyoutContext = createContext<FlyoutContextType>(DEFAULT_VALUE);

export const FlyoutContextProvider: React.FC<React.PropsWithChildren<{ providerId?: string }>> = ({ children, providerId }) => {
    const [options, setOptions] = useState<FlyoutOptions | null>(null);

    const setFlyoutOptions = useCallback((options: FlyoutOptions | null) => {
        setOptions(options);
    }, []);

    const [value, setValue] = useState<FlyoutContextType>({
        setFlyoutOptions: setFlyoutOptions,
    });

    const { id, open, direction, title, subTitle, titleIcon, actionButtons, actions, content, copyData, resizable, fullScreen, persist, onClose, onClick } = options || {};

    useEffect(() => {
        setValue({
            providerId: providerId,
            consumerId: id,
            setFlyoutOptions: setFlyoutOptions,
        });
    }, [providerId, id, setFlyoutOptions]);

    const id_memo = useMemo(() => (id != null ? id : undefined), [id]);
    const open_memo = useMemo(() => (open != null ? open : false), [open]);
    const direction_memo = useMemo(() => (direction != null ? direction : undefined), [direction]);
    const title_memo = useMemo(() => (title != null ? title : undefined), [title]);
    const subTitle_memo = useMemo(() => (subTitle != null ? subTitle : undefined), [subTitle]);
    const titleIcon_memo = useMemo(() => (titleIcon != null ? titleIcon : undefined), [titleIcon]);
    const actionButtons_memo = useMemo(() => (actionButtons != null ? actionButtons : undefined), [actionButtons]);
    const actions_memo = useMemo(() => (actions != null ? actions : undefined), [actions]);
    const content_memo = useMemo(() => (content != null ? content : undefined), [content]);
    const copyData_memo = useMemo(() => (copyData != null ? copyData : undefined), [copyData]);
    const resizable_memo = useMemo(() => (resizable != null ? resizable : undefined), [resizable]);
    const fullScreen_memo = useMemo(() => (fullScreen != null ? fullScreen : undefined), [fullScreen]);
    const persist_memo = useMemo(() => (persist != null ? persist : undefined), [persist]);
    const onClose_callback = useCallback(() => (onClose != null ? onClose() : undefined), [onClose]);
    const onClick_callback = useCallback((event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => (onClick != null ? onClick(event) : undefined), [onClick]);

    const baseId = providerId || "common-flyout";

    return (
        <FlyoutContext.Provider value={value}>
            <>
                {children}

                <FlyOutPanel
                    id={baseId + (id_memo ? "-" + id_memo : "")}
                    open={open_memo}
                    direction={direction_memo}
                    title={title_memo}
                    subTitle={subTitle_memo}
                    titleIcon={titleIcon_memo}
                    actionButtons={actionButtons_memo}
                    actions={actions_memo}
                    copyData={copyData_memo}
                    resizable={resizable_memo}
                    fullScreen={fullScreen_memo}
                    persist={persist_memo}
                    onClose={onClose_callback}
                    onClick={onClick_callback}
                >
                    {content_memo}
                </FlyOutPanel>
            </>
        </FlyoutContext.Provider>
    );
};

export interface WithFlyoutProps {
    flyoutProviderId?: string;
    flyoutConsumerId?: string;
    setFlyoutOptions: (options: FlyoutOptions | null) => void;
}

export const withFlyout = () => {
    return <P extends WithFlyoutProps>(Component: React.ComponentType<P>): React.ComponentType<Pick<P, Exclude<keyof P, keyof WithFlyoutProps>>> => {
        return (props: Pick<P, Exclude<keyof P, keyof WithFlyoutProps>>) => {
            return <FlyoutContext.Consumer>{(flyout) => <Component {...(props as P)} flyoutProviderId={flyout.providerId} flyoutConsumerId={flyout.consumerId} setFlyoutOptions={flyout.setFlyoutOptions} />}</FlyoutContext.Consumer>;
        };
    };
};
