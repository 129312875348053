import ClientInformation from "types/common/ClientInformation";

export interface ClientInformationAction {
    type: 'SET_CLIENT_INFORMATION',
    payload: ClientInformation
}

export const SET_CLIENT_INFORMATION = (clientInformation: ClientInformation) : ClientInformationAction => {
    return {
        type: 'SET_CLIENT_INFORMATION',
        payload: clientInformation
    };
};
