// Import libraries.
import React from "react";
import { Theme } from "@mui/material";
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Trans } from "@lingui/macro";

// Import types.
import { FormatOptions, QueryOptions } from "../../engine/types";

// Import components.
import { Typography } from "@mui/material";
import FieldWrapper from "components/common/form/FieldWrapper";

interface OWN_PROPS {
    queryOptions: QueryOptions;

    formatOptions: FormatOptions;

    disabled: boolean;

    onQueryOptionChange: (name: string, value: any) => void;
    onFormatOptionChange: (name: string, value: any) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class Options extends React.PureComponent<PROPS> {
    render() {
        const { classes, queryOptions, formatOptions, disabled } = this.props;

        return (
            <div className={classes.root}>
                {/* Query Options */}
                <FieldWrapper
                    type={"checkbox"}
                    name={"exhaustiveSearch"}
                    value={queryOptions.exhaustiveSearch === true}
                    label={
                        <Typography noWrap>
                            <Trans>Exhaustive Search (can be slow)</Trans>
                        </Typography>
                    }
                    labelPosition={"right"}
                    className={classes.field}
                    onChange={this.props.onQueryOptionChange}
                    disabled={disabled}
                />

                {/* Format Options */}
                <FieldWrapper
                    type={"checkbox"}
                    name={"queries"}
                    value={formatOptions.queries === true}
                    label={
                        <Typography noWrap>
                            <Trans>Show Applicable Hints</Trans>
                        </Typography>
                    }
                    labelPosition={"right"}
                    className={classes.field}
                    onChange={this.props.onFormatOptionChange}
                    disabled={disabled}
                />

                <FieldWrapper
                    type={"checkbox"}
                    name={"highlight"}
                    value={formatOptions.highlight != null}
                    label={
                        <Typography noWrap>
                            <Trans>Highlight Matches</Trans>
                        </Typography>
                    }
                    labelPosition={"right"}
                    className={classes.field}
                    onChange={this.props.onFormatOptionChange}
                    disabled={disabled}
                />

                {/* <FieldWrapper
                    type={"checkbox"}
                    name={"condensed"}
                    value={formatOptions.condensed === true}
                    label={
                        <Typography noWrap>
                            <Trans>Condensed Results</Trans>
                        </Typography>
                    }
                    labelPosition={"right"}
                    className={classes.field}
                    onChange={this.props.onFormatOptionChange}
                    disabled={disabled}
                /> */}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "grid",
            gridTemplateColumns: "auto auto",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            borderBottomStyle: "solid",
            borderBottomWidth: "0.0625em",
            borderBottomColor: "inherit",

            padding: "0.5em 0.75em",

            [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: "auto",
            },
        },
        field: {
            "& > #control": {
                flex: "0 0 auto",
            },
            "& > #label": {
                flex: "1 1 auto",
                "& > span": {
                    overflow: "hidden",
                },
            },
        },
    });

export default withStyles(styles)(Options);
