import { Trans } from "@lingui/macro";

enum AccountStatus {
    GOOD = "Good",
    POOR_STANDING = "PoorStanding",
}

export const formatAccountStatusLabel = (accountStatus?: AccountStatus | null) => {
    switch (accountStatus) {
        case AccountStatus.GOOD:
            return <Trans>Good</Trans>;
        case AccountStatus.POOR_STANDING:
            return <Trans>Poor Standing</Trans>;
        default:
            return accountStatus || <Trans>---</Trans>;
    }
};

export const formatAccountStatusColor = (accountStatus?: AccountStatus | null) => {
    switch (accountStatus) {
        case AccountStatus.GOOD:
            return "var(--label-positive-color)";
        case AccountStatus.POOR_STANDING:
            return "var(--label-warning-color)";
        default:
            return "var(--label-default-color)";
    }
};

export default AccountStatus;
