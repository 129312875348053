import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";

/** Hook implementation. */
export const useWidth = () => {
    const theme = useTheme();

    const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();

    return [
        keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key), { noSsr: true });

            return !output && matches ? key : output;
        }, null) || "xs",
        (key: Breakpoint, width: Breakpoint, inclusive?: boolean | null) => {
            return inclusive ? keys.indexOf(width) <= keys.indexOf(key) : keys.indexOf(width) < keys.indexOf(key);
        },
        (key: Breakpoint, width: Breakpoint, inclusive?: boolean | null) => {
            return inclusive ? keys.indexOf(width) >= keys.indexOf(key) : keys.indexOf(width) > keys.indexOf(key);
        },
    ];
};

/** HoC implementation. */
export interface WithWidthProps {
    width: Breakpoint;
    isWidthUp: (key: Breakpoint, width: Breakpoint) => boolean;
    isWidthDown: (key: Breakpoint, width: Breakpoint, inclusive?: boolean) => boolean;
}
export const withWidth = () => {
    return <P extends WithWidthProps>(Component: React.ComponentType<P>): React.ComponentType<Pick<P, Exclude<keyof P, keyof WithWidthProps>>> => {
        return (props: Pick<P, Exclude<keyof P, keyof WithWidthProps>>) => {
            const [width, isWidthUp, isWidthDown] = useWidth();

            return <Component {...(props as P)} width={width} isWidthUp={isWidthUp} isWidthDown={isWidthDown} />;
        };
    };
};
