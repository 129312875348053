import { FavoritesAction } from "store/actions/favorites";

const initialState: string[] = [];

const reducer = (state: string[] = initialState, action: FavoritesAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_FAVORITES":
            newState = [...action.payload];

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
