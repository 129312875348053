// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans, t } from "@lingui/macro";
import { withI18n, withI18nProps } from "@lingui/react";
import { toast } from "react-toastify";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import AppInfo from "types/models/AppInfo";
import PlayerSummary, { PlayerPreview, UserPreview } from "types/models/PlayerSummaryInfo";
import { FlaggedData } from "components/screens/users/UserSummary/types";
import { Segment } from "components/screens/design/Segmentation/Segments/types";
import { getLanguageNamebyCode } from "types/models/LanguageInfo";

// Import components.
import { Link, Typography } from "@mui/material";
import ImageWrapper from "components/common/ImageWrapper";
import Timestamp from "components/common/Timestamp";
import IconButton from "components/common/button/IconButton";
import UserImagePlaceHolder from "components/common/UserImagePlaceHolder";
import CustomMenu from "components/common/menu";
import Tooltip from "components/common/Tooltip";
import PopperWithArrow from "components/common/PopperWithArrow";
import EllipsisInCenter from "components/common/EllipsisInCenter";
import PlayerLiveLock from "components/common/screen/PlayerLiveLock";

// Import Icons.
import LinkIcon from "@mui/icons-material/InsertLink";
import BugReportIcon from "@mui/icons-material/BugReport";
import DollarIcon from "@mui/icons-material/MonetizationOn";
import FlagIcon from "@mui/icons-material/Flag";

// Import Utils.
import ClipboardUtils from "utils/Clipboard";
import DateTimeFormatter from "utils/formatters/DateTime";
import NumberFormatter from "utils/formatters/Number";

// Import Services.
import Services from "./services";

interface STATE_PROPS {
    session: Session;
    availableApps: AppInfo[];
}
interface STATE {
    anchorElement: any;
    openPopper: boolean;
    playerPreview: PlayerPreview | null;
    playerFalggedData: FlaggedData | null;
    userPreviewConfig: UserPreview | null;
}
interface PROPS extends STATE_PROPS, WithStyles<typeof styles>, RouteComponentProps, withI18nProps {
    playerSummary: PlayerSummary;
}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        availableApps: state.availableApps,
    };
};

class PlayerBanner extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        anchorElement: null,
        openPopper: false,
        playerPreview: null,
        playerFalggedData: null,
        userPreviewConfig: null,
    };

    componentDidMount() {
        if (this.props.playerSummary.playerId) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        if (this.props.playerSummary.playerId !== prevProps.playerSummary.playerId) {
            this.loadData();
        }
    }

    loadData = async () => {
        const { playerSummary } = this.props;

        const promiseBatch = await Promise.allSettled([
            playerSummary.playerId ? Services.getPlayerPreview(playerSummary.playerId) : null,
            playerSummary.playerId ? Services.getPlayerFlaggedData(playerSummary.playerId) : null,
            Services.getUserPreviewConfig(),
        ]);

        promiseBatch.forEach((response) => {
            if (response.status === "rejected") {
                const error = response.reason;
                toast.error("[" + error.errorCode + "] - " + error.errorMessage);
            }
        });

        const playerPreview = promiseBatch[0].status === "fulfilled" ? promiseBatch[0].value : null;
        const playerFalggedData = promiseBatch[1].status === "fulfilled" ? promiseBatch[1].value : null;
        const userPreviewConfig = promiseBatch[2].status === "fulfilled" ? promiseBatch[2].value : null;

        this.setState({ playerPreview, playerFalggedData, userPreviewConfig });
    };

    openMenu = (event: any) => {
        this.setState({ anchorElement: event.currentTarget });
    };

    closeMenu = () => {
        this.setState({ anchorElement: null });
    };

    onCopyDeepLink = () => {
        const { session } = this.props;

        const deepLink = window.location.origin + "/#/user/user-browser/" + (session.isSuper ? session.companyIdAlias : session.companyId) + "/" + session.appId + "/" + session.playerId;

        ClipboardUtils.writeText(deepLink);

        this.closeMenu();
    };

    naviateToUserBrowser = (segment: Segment) => {
        const { history, location } = this.props;
        const targetPath = "/user/user-browser";
        const currentPath = location.pathname;

        if (currentPath === targetPath) {
            history.replace({ pathname: targetPath, state: { segmentId: segment.segmentId } });
        } else {
            history.push({ pathname: targetPath, state: { segmentId: segment.segmentId } });
        }
    };

    renderSummary = () => {
        const { classes, i18n, session, availableApps, playerSummary } = this.props;
        const { anchorElement, playerFalggedData } = this.state;

        const targetApp = availableApps.find((item) => item.appId === session.appId) || null;

        return (
            <div className={classes.card} style={{ flex: "1 1 auto", flexDirection: "row", alignItems: "center", gap: ".5em", borderRadius: "0 0.5em .5em 0", borderLeft: 0, minWidth: "16em" }}>
                <ImageWrapper style={{ width: "4.5em", height: "4.5em" }} src={playerSummary.pictureUrl} fallback={<UserImagePlaceHolder data={playerSummary} style={{ fontSize: "1.75em" }} />} />

                <div style={{ display: "flex", flexDirection: "column", overflow: "hidden", flex: 1 }}>
                    <span style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <EllipsisInCenter id={"playerName"} labelStyle={{ fontSize: "0.85em", fontWeight: "bold" }} value={playerSummary.name || playerSummary.email || i18n._(t`Unknown`)} />

                        <IconButton id={"copy-user-url"} onClick={this.openMenu} style={{ margin: 0 }}>
                            <LinkIcon style={{ transform: "rotate(-45deg)" }} />
                        </IconButton>

                        <CustomMenu
                            anchorElement={anchorElement}
                            id={"copy-user-url"}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                            open={Boolean(anchorElement)}
                            onClose={this.closeMenu}
                            menuItems={[
                                {
                                    id: "copy-page-url",
                                    label: <Trans>Copy User URL</Trans>,
                                    onClick: this.onCopyDeepLink,
                                },
                            ]}
                        />
                    </span>

                    <EllipsisInCenter id={"playerId"} labelStyle={{ fontSize: "0.85em", fontWeight: "bold" }} showCopyIcon value={playerSummary.playerId} />

                    <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        {targetApp?.isLive && <PlayerLiveLock style={{ flex: "1 1 auto", height: "1.5em", maxWidth: "8em" }} thumbAndTextColor={"var(--secondary-background-color)"} />}

                        <div style={{ flex: "0 0 auto", display: "flex", alignItems: "center", gap: "0.06125em" }}>
                            {playerSummary.isTester && (
                                <Tooltip alwaysShow arrow title={<Trans>Tester</Trans>}>
                                    <BugReportIcon style={{ color: "var(--portal-color-orange, inherit)" }} />
                                </Tooltip>
                            )}

                            {playerFalggedData && playerFalggedData.isActive && (
                                <Tooltip
                                    alwaysShow
                                    arrow
                                    title={
                                        <Trans>
                                            Flagged User
                                            <br />
                                            Notes: {playerFalggedData.notes}
                                        </Trans>
                                    }
                                >
                                    <FlagIcon style={{ color: "var(--button-primary-background-color, inherit)" }} />
                                </Tooltip>
                            )}

                            {playerSummary.amountSpent != null && playerSummary.amountSpent > 0 && (
                                <Tooltip alwaysShow arrow title={<Trans>Spender</Trans>}>
                                    <DollarIcon style={{ color: "var(--portal-color-green, inherit)" }} />
                                </Tooltip>
                            )}
                        </div>
                    </span>
                </div>
            </div>
        );
    };

    renderClient = () => {
        const { classes, playerSummary } = this.props;

        return (
            <div className={classes.card} style={{ minWidth: "11em", maxWidth: "15em" }}>
                <span className={classes.nameValuePair}>
                    <Typography noWrap style={{ minWidth: "7em" }}>
                        <Trans>Platforms</Trans>
                    </Typography>

                    <Tooltip arrow title={playerSummary.platforms.join(", ")}>
                        <Typography noWrap>{playerSummary.platforms.join(", ")}</Typography>
                    </Tooltip>
                </span>

                <span className={classes.nameValuePair}>
                    <Typography noWrap style={{ minWidth: "7em" }}>
                        <Trans>Language</Trans>
                    </Typography>

                    <Typography>{getLanguageNamebyCode(playerSummary.languageCode as string)}</Typography>
                </span>

                <span className={classes.nameValuePair}>
                    <Typography noWrap style={{ minWidth: "7em" }}>
                        <Trans>Location</Trans>
                    </Typography>

                    <Typography noWrap>{playerSummary.countryCode || "-"}</Typography>
                </span>
            </div>
        );
    };

    renderDatesAndCounts = () => {
        const { i18n, classes, playerSummary } = this.props;

        return (
            <div className={classes.card} style={{ minWidth: "11em", maxWidth: "18em" }}>
                <span className={classes.nameValuePair}>
                    <Typography noWrap>
                        <Trans>Created</Trans>
                    </Typography>

                    <Tooltip arrow alwaysShow title={<Timestamp value={playerSummary.createdAt} humanize={true} />}>
                        <Typography noWrap>{DateTimeFormatter.formatTimeAgoOrTimeUntil(playerSummary.createdAt, i18n, 2)}</Typography>
                    </Tooltip>
                </span>

                <span className={classes.nameValuePair}>
                    <Typography noWrap>
                        <Trans>Last Login</Trans>
                    </Typography>

                    <Tooltip alwaysShow arrow title={<Timestamp value={playerSummary.lastLogin} humanize={true} />}>
                        <Typography noWrap>{DateTimeFormatter.formatTimeAgoOrTimeUntil(playerSummary.lastLogin, i18n, 2)}</Typography>
                    </Tooltip>
                </span>

                <span className={classes.nameValuePair}>
                    <Typography noWrap>
                        <Trans>Login Count</Trans>
                    </Typography>

                    <Typography noWrap>{NumberFormatter.formatInteger(playerSummary.loginCount)}</Typography>
                </span>
            </div>
        );
    };

    renderDuration = () => {
        const { classes, i18n, playerSummary } = this.props;
        const { playerPreview } = this.state;

        const { logoutCount } = playerPreview || {};
        const { timeSpent } = playerSummary || {};

        if (timeSpent != null && timeSpent > 0 && logoutCount != null && logoutCount > 0) {
            return (
                <div className={classes.card} style={{ minWidth: "8em", maxWidth: "15em" }}>
                    <div className={classes.lifeTimeValuesPair}>
                        <Typography noWrap>
                            <Trans>Time Spent</Trans>
                        </Typography>
                        <Typography noWrap style={{ fontWeight: "bold", marginLeft: "1em", alignSelf: "flex-end" }}>
                            {DateTimeFormatter.formatDuration(timeSpent, i18n, 2)}
                        </Typography>

                        <Typography noWrap>
                            <Trans>Avg Session</Trans>
                        </Typography>
                        <Typography noWrap style={{ fontWeight: "bold", marginLeft: "1em", alignSelf: "flex-end" }}>
                            {DateTimeFormatter.formatDuration(timeSpent / logoutCount, i18n, 2)}
                        </Typography>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    renderRevenue = () => {
        const { classes, playerSummary } = this.props;
        const { userPreviewConfig } = this.state;

        const { amountSpent, purchaseCount } = playerSummary || {};
        const { showRevenueInfo } = userPreviewConfig || {};

        if (showRevenueInfo && ((amountSpent != null && amountSpent > 0) || (purchaseCount != null && purchaseCount > 0))) {
            return (
                <div className={classes.card} style={{ maxWidth: "15em" }}>
                    <div className={classes.lifeTimeValuesPair}>
                        <Link id={"navigate-to-purchases"} onClick={() => this.props.history.push("/user/marketplace/transactions")}>
                            <Trans>Purchases</Trans>
                        </Link>
                        <Typography noWrap style={{ fontWeight: "bold", marginLeft: "1em", alignSelf: "flex-end" }}>
                            {purchaseCount != null ? NumberFormatter.formatInteger(purchaseCount) : "-"}
                        </Typography>

                        <Link id={"navigate-to-revenue"} onClick={() => this.props.history.push("/user/marketplace/transactions")}>
                            <Trans>Total</Trans>
                        </Link>
                        <Typography noWrap style={{ fontWeight: "bold", marginLeft: "1em", alignSelf: "flex-end" }}>
                            {amountSpent != null ? NumberFormatter.formatCurrency(amountSpent / 100) : "-"}
                        </Typography>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    renderSegment = (segment: Segment, style?: React.CSSProperties) => {
        const { classes } = this.props;

        return (
            <Tooltip key={segment.segmentId} arrow title={segment.name}>
                <Link data-id={`segment-id-${segment.segmentId}`} className={classes.link} style={{ flex: "0 0 auto", maxWidth: "100%", ...style }} noWrap onClick={() => this.naviateToUserBrowser(segment)}>
                    {segment.name}
                </Link>
            </Tooltip>
        );
    };

    renderSegments = () => {
        const { classes } = this.props;
        const { openPopper, playerPreview } = this.state;

        if (playerPreview && playerPreview.segments.length > 0) {
            return (
                <div className={classes.card} style={{ flex: "1 1 auto", justifyContent: "flex-start", maxWidth: "20em" }}>
                    <div className={classes.lifeTimeValuesPair}>
                        <Typography noWrap>{<Trans>Segments</Trans>}</Typography>

                        <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "1em", overflow: "hidden" }}>
                            {playerPreview.segments.length > 0 && this.renderSegment(playerPreview.segments[0])}
                            {playerPreview.segments.length > 1 && this.renderSegment(playerPreview.segments[1])}

                            {playerPreview.segments.length > 2 && (
                                <span style={{ display: "flex", alignSelf: "stretch", justifyContent: "space-between" }}>
                                    {this.renderSegment(playerPreview.segments[2], { flex: "1 1 auto" })}

                                    {playerPreview.segments.length > 3 && (
                                        <PopperWithArrow
                                            key={"segments"}
                                            content={<span style={{ display: "flex", flexDirection: "column" }}>{playerPreview.segments.slice(3).map((segment) => this.renderSegment(segment))}</span>}
                                            open={openPopper}
                                            onClose={() => this.setState({ openPopper: false })}
                                        >
                                            <Link style={{ flex: "0 0 auto", fontWeight: "bold", justifySelf: "flex-end" }} onClick={() => this.setState({ openPopper: true })}>
                                                <Trans>+{playerPreview.segments.length - 3} more</Trans>
                                            </Link>
                                        </PopperWithArrow>
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                {this.renderSummary()}
                {this.renderClient()}
                {this.renderDatesAndCounts()}
                {this.renderDuration()}
                {this.renderRevenue()}
                {this.renderSegments()}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            gap: "0.5em",
            maxHeight: "5.6em",

            overflowX: "auto",
            overflowY: "hidden",

            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },

            "@media(min-width: 450px) and (max-width: 1500px)": {
                "& > *:nth-child(5)": {
                    display: "none",
                },
            },
            "@media(min-width: 450px) and (max-width: 1400px)": {
                "& > *:nth-child(4)": {
                    display: "none",
                },
            },
            "@media(min-width: 450px) and (max-width: 1300px)": {
                "& > *:nth-child(3)": {
                    display: "none",
                },
            },
            "@media(min-width: 450px) and (max-width: 1100px)": {
                "& > *:nth-child(2)": {
                    display: "none",
                },
            },
        },
        card: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            border: "0.0625em solid var(--secondary-border-color, inherit)",

            padding: "0.5em",
            borderRadius: "0.5em",

            overflow: "hidden",

            "& .MuiTypography-root, & .MuiLink-root": {
                fontSize: ".85em",
            },
        },
        nameValuePair: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& > *:first-child": {
                color: "var(--primary-color, inherit)",
                minWidth: "6.5em",
            },
            "& > *:last-child": {
                fontWeight: "bold",
            },
        },
        lifeTimeValuesPair: {
            flex: "0 0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        link: {
            fontWeight: "bold",
            color: "var(--primary-color, inherit)",
            "&:hover": {
                color: "var(--link-color, inherit)",
            },
        },
    });

export default connect(mapStateToProps)(withRouter(withI18n()(withStyles(styles)(PlayerBanner))));
