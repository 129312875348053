// Import libraries.
import React, { CSSProperties } from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";

// Import components.
import { Card } from "@mui/material";
import LoadingProgress from "../LoadingProgress";

interface OWN_PROPS {
    style?: CSSProperties;
    className?: string | null;

    id?: string;
    header?: React.ReactNode;

    isLoading?: boolean;
    loadingMessage?: React.ReactNode;

    children: React.ReactNode;

    onTotalHeightCalculated?: (height: number) => void;
    onHeaderHeightCalculated?: (height: number) => void;
    onContentHeightCalculated?: (height: number) => void;

    showScrollbars?: boolean;
}

interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class WidgetContainer extends React.PureComponent<PROPS> {
    rootRef = React.createRef<HTMLDivElement>();
    headerRef = React.createRef<HTMLSpanElement>();
    contentRef = React.createRef<HTMLSpanElement>();

    componentDidMount = () => {
        if (this.props.onTotalHeightCalculated || this.props.onHeaderHeightCalculated || this.props.onContentHeightCalculated) {
            this.calculateHeight();
        }
    };

    componentDidUpdate(prevProps: Readonly<PROPS>): void {
        if (
            (prevProps.style !== this.props.style || prevProps.header !== this.props.header || prevProps.children !== this.props.children) &&
            (this.props.onTotalHeightCalculated || this.props.onHeaderHeightCalculated || this.props.onContentHeightCalculated)
        ) {
            this.calculateHeight();
        }
    }

    calculateHeight = () => {
        if (this.props.onTotalHeightCalculated) this.props.onTotalHeightCalculated(this.rootRef.current?.offsetHeight || 0);
        if (this.props.onHeaderHeightCalculated) this.props.onHeaderHeightCalculated(this.headerRef.current?.offsetHeight || 0);
        if (this.props.onContentHeightCalculated) this.props.onContentHeightCalculated(this.contentRef.current?.offsetHeight || 0);
    };

    render() {
        const { classes, id, header, children, isLoading, loadingMessage, style, showScrollbars } = this.props;

        return (
            <Card ref={this.rootRef} id={id} className={classnames(classes.root, this.props.className)} style={style}>
                {header && (
                    <span ref={this.headerRef} className={classes.header}>
                        {header}
                    </span>
                )}

                <span ref={this.contentRef} className={classnames(classes.content, !showScrollbars ? classes.hideScrollbars : undefined)}>
                    {isLoading && <LoadingProgress label={loadingMessage} />}

                    {!isLoading && children}
                </span>
            </Card>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            borderColor: "var(--secondary-border-color, inherit)",

            overflow: "hidden",

            borderStyle: "solid",
            borderWidth: "0.0625em",
            borderRadius: "0.25em",

            boxShadow: "none",

            minWidth: 340,
            maxWidth: 700,
            width: "100%",

            margin: 0,
        },
        header: {
            flex: "0 0 auto",

            minHeight: "3.3125em",

            display: "flex",
            alignItems: "center",

            padding: "0.625em",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "hidden",
        },
        content: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflow: "auto",
        },

        hideScrollbars: {
            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
    });

export default withStyles(styles)(WidgetContainer);
